import {RouterModule, Routes} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';
import {MainComponent} from './main';
import {PageNotFoundComponent} from './error';
import {AuthNService, AuthGuard} from './core';
import {ReferenceDataService} from './shared-main/reference-data/reference-data.service';
import {AppConfig} from './shared-main/app-configuration';
import {DocumentProfileService} from './admin/document-profile/document-profile-edit/document-profile.service';
import {AccessGuard} from './core/access-guard.service';
import {UserConfigurationService} from './shared-main/user-configuration.service';
import {MessagesComponent} from './messages/messages.component';
import {ShareDocumentsComponent} from './share-documents/share-documents.component';
import {RequiredActionGuard} from './shared-main/required-actions/required-action-guard.service';
import {SetupPasswordComponent} from './login/first-login/setup-password/setup-password.component';
import {RegisterComponent} from './login/first-login/register/register.component';
import {ToBeDefinedComponent} from './login/first-login/toBeDefined/toBedefined.component';
import {AcceptTermsConditionsComponent} from './login/first-login/accept-terms-conditions/accept-terms-conditions.component';
import {CaslAcceptanceComponent} from './login/first-login/casl-acceptance/casl-acceptance.component';
import {TrustedPhoneNumberComponent} from './login/first-login/trusted-phone-number/trusted-phone-number.component';
import {TabsComponent} from './shared/tabbing';
import {AppComponent} from './app.component';

export const authProviders = [
  AuthGuard,
  RequiredActionGuard,
  AuthNService,
  AppConfig,
  UserConfigurationService,
  ReferenceDataService,
  DocumentProfileService,
  AccessGuard
];
export const appRoutesUrlParts = {
  firstLogin: 'first-login',
  acceptCasl: 'accept-casl',
  termsAndConditions: 'accept-terms-conditions',
  trustedPhoneNumber: 'trusted-phone-number',
  resetPassword: 'reset-password',
  landTitleOffice: 'land-title-office',
  register: 'register',
  setupPassword: 'setup-password'
};

const appRoutes: Routes = [
  {
    path: 'first-login',
    children: [
      {
        path: 'setup-password',
        component: SetupPasswordComponent
      },
      {
        path: 'register/:token',
        component: RegisterComponent
      },
      {
        path: 'toBeDefined',
        component: ToBeDefinedComponent
      },
      {
        path: 'accept-terms-conditions',
        component: AcceptTermsConditionsComponent
      },
      {
        path: 'accept-casl',
        component: CaslAcceptanceComponent
      },
      {
        path: 'trusted-phone-number',
        component: TrustedPhoneNumberComponent
      }
    ]
  },
  {
    path: 'reset-password/:token',
    component: TrustedPhoneNumberComponent
  },
  {
    path: 'main',
    component: MainComponent,
    canActivate: [AuthGuard, RequiredActionGuard],
        children : [
          {path : '', redirectTo : 'tabs/matters', pathMatch : 'full'
          },
      {
        path: 'tabs',
        loadChildren: () => import('./matters/matter.module').then((m) => m.MatterModule)
      },
      {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule)
      },
      {
        path: 'projects',
        loadChildren: () => import('./projects/projects.module').then((m) => m.ProjectModule)
      },
      {
        path: 'export-file-manager',
        loadChildren: () =>
          import('./export-file-manager/export-file-manager.module').then((m) => m.ExportFileManagerModule)
      },
      {
        path: 'contacts',
        loadChildren: () => import('./contact/contact.module').then((m) => m.ContactModule)
      },
      {
        path: 'events',
        loadChildren: () => import('./event/event.module').then((m) => m.EventModule)
      },
      {
        path: 'adjudication',
        loadChildren: () => import('./adjudications/adjudication.module').then((m) => m.AdjudicationModule)
      },
      {
        path: 'billing',
        loadChildren: () => import('./billing/billing.module').then((m) => m.BillingModule)
      },
      {
        path: 'integrations',
        loadChildren: () => import('./integrations/integrations.module').then((m) => m.IntegrationsModule)
      },
      {
        path: 'doc-services',
        loadChildren: () => import('./doc-services/doc-services.module').then((m) => m.DocServicesModule)
      },
      {
        path: 'opportunities',
        loadChildren: () => import('./opportunities/opportunities.module').then((m) => m.OpportunitiesModule)
      },
      {
        path: 'opportunity-matter',
        loadChildren: () =>
          import('./opportunity-matter/opportunity-matter.module').then((m) => m.OpportunityMatterModule)
      },
      {
        path: 'messages',
        component: TabsComponent,
        children: [
          {path: '', redirectTo: 'list', pathMatch: 'full'},
          {path: 'list', component: MessagesComponent},
          {path: 'redirectList', component: MessagesComponent},
          {
            path: 'share-documents/:id',
            component: ShareDocumentsComponent
          }
        ]
      },
      {
        path: 'deposit-manager',
        loadChildren: () => import('./deposit-manager/deposit-manager.module').then((m) => m.DepositManagerModule)
      },
      {
        path: 'gocardless',
        loadChildren: () => import('./gocardless/gocardless.module').then((m) => m.GoCardlessModule)
      }
    ]
  },
  {
    path: '',
    component: AppComponent,
    pathMatch: 'full'
  },
  {
    path: '**',
    component: PageNotFoundComponent
  },
  {
    path: 'no-content',
    component: PageNotFoundComponent
  }
];

export const appRoutingProviders: any[] = [authProviders];

export const appRouting: ModuleWithProviders<any> = RouterModule.forRoot(appRoutes, {
  onSameUrlNavigation: 'reload'
  // enableTracing: true
});
