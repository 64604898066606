import {Component, Input, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {dropDowns} from '../shared/matter-drop-downs';
import {ConsiderationLtt} from './consideration-ltt';
import {GetGlobalSaveModelService} from '../shared/get-global-save-model.service';
import {currentMatter} from '../shared/current-matter';
import {ConsiderationModal} from './consideration.modal.component';
import {CleanUpMatterComponent} from '../../shared-main/cleanUpMatterData.guard';
import {MatterService} from '../matter.service';
import {ConsiderationTaxes} from './consideration-taxes';
import * as _ from 'lodash';
import {ActivatedRoute} from '@angular/router';
import {TabsService} from '../../core/tabs.service';
import {DialogService} from '../../shared/dialog/dialog.service';
import {TaxRateService} from './tax-rate.service';
import {FocusFirstElementDecorator} from '../../shared-main/focus-first-element-decorator';
import {Subscription} from 'rxjs';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {DocumentProfileCache} from '../../shared-main/document-profile-cache.service';
import {booleanYesNoDropDowns} from '../../shared-main/constants';
import Utils from '../../shared-main/utils';
import {ErrorService} from '../../shared/error-handling/error-service';
import {DPError} from '../../shared/error-handling/dp-error';
import {CurrencyPipe} from '@angular/common';
import {Tax_RATE} from '../../shared-main/province-based-dropdowns';
import {Matter} from '../shared/matter';
import {ProjectTab} from '../../projects/shared/project-tab';
import {Project} from '../../projects/shared/project';
import {MatterCleanUpUtil} from '../shared/matter-utils/matter-clean-up-util';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'dp-consideration-ltt',
  templateUrl: 'consideration-ltt.component.html',
  providers: [CurrencyPipe],
  styles: [
    `
      .icon {
        width: 18px;
      }
      .msg {
        width: 485px;
      }
      .font-13 {
        font-size: 13px !important;
        padding-right: 0px;
      }
    `
  ]
})
@FocusFirstElementDecorator()
@AutoUnsubscribe()
export class ConsiderationLttComponent implements OnInit, CleanUpMatterComponent {


    prepareLTT : SelectItem[];
    prepareLTTRate : SelectItem[];
    prepareTableCalculation : SelectItem[];
    completionOfTaxInfoOptions : SelectItem[];
    yesNoOptions : SelectItem[];
    yNOptions : SelectItem[];
    nYOptions : SelectItem[];
    considerationLtt : ConsiderationLtt;
    considerationLttDialogOpen : boolean = false;
    considerationLttUnTouched : ConsiderationLtt;
    CALCULATE_F_TOTALLING_A_THOUGH_E_FLAG : boolean = false;
    AMOUNT_IN_A_EQUALS_AMOUNT_F_FLAG : boolean = false;
    CALCULATE_A_SUBTRACTING_B_THROUGH_E_FROM_F_FLAG : boolean = false;
    LINE_A_EQUAL_NET_SALE_PRICE : boolean = false;
    HIDDEN_TAX_AND_SET_AMOUNT_AS_2 : boolean = false;
    COMPLETE_TAX_INFO_MANUALLY : boolean = false;
    HIDDEN_TAX_AND_USE_NET_SALE_PRICE : boolean = false;
    subjectValueUpdated : boolean = false;
    allowMaxLength : boolean = true;
    isPropertyTeranetJurisdictrionToronto : boolean = false;
    ontarioTaxRateSlab : ConsiderationTaxes;
    torontoTaxRateSlab : ConsiderationTaxes;
    provinceHstRateSlab : ConsiderationTaxes;
    soaConsiderationTaxes :ConsiderationTaxes;
    globalSaveModelSub :Subscription ;
    taxRateServiceSub : Subscription;
    torontoLttMin : number = undefined;
    showTorontoLTTMsg : boolean = false;
    @Input() inModalMode: boolean = false;
    _matter: Matter;
    @ViewChild('ConsiderationLttForm') public considerationLttForm: NgForm;

  constructor(
    public getGlobalSaveModelService: GetGlobalSaveModelService,
    public dialogService: DialogService,
    public matterService: MatterService,
    public taxRateService: TaxRateService,
    public errorService: ErrorService,
    public route: ActivatedRoute,
    public tabService: TabsService,
    public currencyPipe: CurrencyPipe,
    public documentProfileCache: DocumentProfileCache,
    public viewContainerRef: ViewContainerRef
  ) {}

  get matter(): Matter {
    return this.tabService && this.tabService.activeTab && this.tabService.activeTab.isProject()
      ? (this.tabService.activeTab as ProjectTab).matter
      : currentMatter.value;
  }

  ngOnInit() {
    if (!this.inModalMode) {
      this.tabService.activeTab.section = this.route.routeConfig.path;
    }

    this.prepareLTTRate = dropDowns.prepareLTTRateOptions;
    this.prepareTableCalculation = dropDowns.tableCalculationOptions;
    this.completionOfTaxInfoOptions = dropDowns.completionOfTaxInfoOptions;
    this.yesNoOptions = dropDowns.considerationCityFlag;
    this.yNOptions = dropDowns.ontarioOptions;
    this.nYOptions = booleanYesNoDropDowns.NoYes_DefaultNo;
    this.subjectValueUpdated = false;

    this.isPropertyTeranetJurisdictrionToronto = this.matter.isPropertyTeranetJurisdictionToronto();
    if (
      this.isPropertyTeranetJurisdictrionToronto &&
      this.considerationLtt &&
      !this.considerationLtt.applyCityOfTorontoLandTransferTax
    ) {
      this.yesNoOptions = dropDowns.multipleDepositOptions;
      this.matter.updateApplyTorontoTaxFlagForToronto();
    }
    if (this.inModalMode) {
      this.openConsiderationLtt();
    } else {
      this.globalSaveModelSub = this.getGlobalSaveModelService.getItem$.subscribe(() => {
        //currentMatter.value = matter;
        this.openConsiderationLtt();
      });
    }
    this.taxRateServiceSub = this.taxRateService
      .cachedConsiderationTaxRate(this.matter.provinceCode)
      .subscribe((considerationTaxes: ConsiderationTaxes[]) => {
        this.populateTaxRatesFromConsiderationTaxes(considerationTaxes);
        this.populateCompletionOfTaxInfoOptions();
        this.calculateTaxRebate();
      });
  }

  populateCompletionOfTaxInfoOptions() {
    let netSalePrice = this.considerationLtt.getTotalNetSalePrice(
      this.matter.ignoreCreditsInDeedConsiderationInON,
      this.matter.isProjectHstReductionInSalePriceAdjustment,
      this.soaConsiderationTaxes
    );
    let formattedNetSalePrice = netSalePrice
      ? this.currencyPipe.transform(netSalePrice, 'CAD', 'symbol-narrow', '1.2-2').replace('CA', '')
      : 0;
    let optionIndex = this.completionOfTaxInfoOptions.findIndex(
      (item) => item.value === 'HIDDEN_TAX_AND_USE_NET_SALE_PRICE'
    );
    if (optionIndex > -1) {
      this.completionOfTaxInfoOptions[optionIndex].label =
        `Leave tax screen blank; Set Teraview "Amount" to Net Sale price of  ${formattedNetSalePrice}`;
    }
  }

  populateTaxRatesFromConsiderationTaxes(considerationTaxes: ConsiderationTaxes[]) {
    if (considerationTaxes && considerationTaxes.length > 0) {
      this.ontarioTaxRateSlab = TaxRateService.getLatestOntarioTaxRateSlabFromConsiderationTaxes(
                considerationTaxes);
      this.torontoTaxRateSlab = TaxRateService.getLatestTorontoTaxRateSlabFromConsiderationTaxes(
                considerationTaxes);
      this.provinceHstRateSlab = this.taxRateService.findConsiderationHstRateAccordingToEffectiveDate(
        considerationTaxes,
        this.matter.getSoaTaxRateEffectiveDate()
      );
      let formattedThreshold = this.ontarioTaxRateSlab
        ? this.currencyPipe.transform(2000000, 'CAD', 'symbol-narrow', '1.2-2').replace('CA', '')
        : null;
      dropDowns.prepareLTTRateOptions.shift();
      dropDowns.prepareLTTRateOptions.unshift({
        label: `??? (response not required if consideration does not exceed ${formattedThreshold})`,
        value: 'QUESTION'
      });
      this.prepareLTTRate = dropDowns.prepareLTTRateOptions;
      this.torontoLttMin = this.torontoTaxRateSlab && this.torontoTaxRateSlab.lttMinimum;
      if (this.matter.soaHst) {
        this.soaConsiderationTaxes = new ConsiderationTaxes();
        this.soaConsiderationTaxes.hstRate = this.matter.soaHst;
        this.soaConsiderationTaxes.hstFederalPortion = this.matter.soaFederalHst;
        this.soaConsiderationTaxes.hstProvincialPortion = this.matter.soaProvincialHst;
        this.soaConsiderationTaxes.rateType = this.matter.matterTaxType
          ? this.matter.matterTaxType
          : this.provinceHstRateSlab.rateType;
      } else {
        this.soaConsiderationTaxes = this.provinceHstRateSlab;
      }
      this.verifyValueSubjectToLtt();
    }
  }

  openConsiderationLtt(): void {
    if (this.matter.id && this.matter.considerationLtt) {
      this.considerationLtt = this.matter.considerationLtt;
      this.considerationLttUnTouched = this.matter.considerationLtt;
    } else if (this.matter.considerationLtt) {
      this.considerationLtt = this.matter.considerationLtt;
    } else {
      this.matter.considerationLtt = new ConsiderationLtt(
        this.matter.adjustmentStatusMode,
        undefined,
        this.matter.provinceCode
      );
      this.considerationLtt = this.matter.considerationLtt;
    }

    if (!this.matter.matterPropertyWithCondo) {
      this.matter.createMatterPropertyWithCondo();
    }

    this.initializeNewConsiderationLtt();
    if (this.matter.isTemplateMatterForMassUpdate) {
      this.setDefaultConsiderationLttForMassUpdate();
    }
    this.checkMoniesPaidValue();
  }

  async loadConsiderationTaxRates(): Promise<void> {
    let considerationTaxes: ConsiderationTaxes[] = await this.taxRateService
      .cachedConsiderationTaxRate(this.matter.provinceCode)
      .toPromise();
    if (considerationTaxes) {
      this.populateTaxRatesFromConsiderationTaxes(considerationTaxes);
    }
  }

  setLocalInstanceMatter(matter: Matter) {
    this._matter = matter;
  }

  notAvailableInPrepareLTTDrpDownOptions(): boolean {
    let dropDownPrepareLTT: any = _.find(
      this.prepareLTT,
      (dropDownsOption) => dropDownsOption.value == this.considerationLtt.lttAffidavitExecutedBy
    );
    return !dropDownPrepareLTT || dropDownPrepareLTT == null;
  }

  // Only the ON project sale matter has ConsiderationLtt
  // the AB project sale matter hasn't ConsiderationLt
  setDefaultConsiderationLttForMassUpdate() {
    let completionOfTaxInfoOptions = this.completionOfTaxInfoOptions.slice(0);
    let defaultOptionCompletionOfTaxInfo = completionOfTaxInfoOptions.find((option) => option.value === 'DEFAULT');
    if (!defaultOptionCompletionOfTaxInfo) {
      completionOfTaxInfoOptions.push({label: ' ', value: 'DEFAULT'});
    }
    this.considerationLtt.completionOfTaxInfoType = 'DEFAULT';
    this.considerationLtt.inTableBelowType = null;
    this.considerationLtt.lttRateBasedOnType = 'QUESTION';
    this.considerationLtt.lttAffidavitExecutedBy = 'CLIENT';
    this.considerationLtt.isMatterParticipantSpouseSelected = null;
  }

  initializeNewConsiderationLtt(): void {
    if (!this.considerationLtt.applyNrst) {
      this.considerationLtt.applyNrst = 'N_y';
    }

    if (this.matter.isMatterProvinceON && this.matter.isProjectSale) {
      this.matter.considerationLtt.lttRateBasedOnType = 'QUESTION';
      this.matter.considerationLtt.lttAffidavitExecutedBy = 'CLIENT';
      this.matter.considerationLtt.isMatterParticipantSpouseSelected = null;
    } else {
      if (this.matter.matterParticipantSpouse().length == 2) {
        if (this.matter.purchasers.length > 2) {
          this.prepareLTT = dropDowns.prepareLTTOptionsOnlySolictor;

          if (
            !this.considerationLtt.lttAffidavitExecutedBy ||
            (this.considerationLtt.lttAffidavitExecutedBy && this.notAvailableInPrepareLTTDrpDownOptions())
          ) {
            this.considerationLtt.lttAffidavitExecutedBy = 'SOLICITOR';
          }
        } else {
          this.prepareLTT = dropDowns.prepareLTTSpouseOptions;
          for (let i = 0; i < this.prepareLTT.length; i++) {
            if (this.prepareLTT[i].value === 'BOTH_SPOUSES') {
              let labelname: string = '';
              if (this.matter.purchasers[0].contact.contactName.firstName != null) {
                labelname = 'Spouse 1 (' + this.matter.purchasers[0].contact.contactName.firstName + ') & ';
              } else {
                labelname = 'Spouse 1 & ';
              }
              if (this.matter.purchasers[1].contact.contactName.firstName != null) {
                labelname += 'Spouse 2 (' + this.matter.purchasers[1].contact.contactName.firstName + ')';
              } else {
                labelname += 'Spouse 2';
              }
              this.prepareLTT[i].label = labelname;
            } else if (
              this.prepareLTT[i].value === 'SPOUSE1' &&
              this.matter.purchasers[0].contact.contactName.firstName != null
            ) {
              this.prepareLTT[i].label =
                'Spouse 1 Only' + ' (' + this.matter.purchasers[0].contact.contactName.firstName + ')';
            } else if (
              this.prepareLTT[i].value === 'SPOUSE2' &&
              this.matter.purchasers[1].contact.contactName.firstName != null
            ) {
              this.prepareLTT[i].label =
                'Spouse 2 Only' + ' (' + this.matter.purchasers[1].contact.contactName.firstName + ')';
            }
          }

          if (
            this.matter.considerationLtt.isMatterParticipantSpouseSelected == undefined ||
            !this.matter.considerationLtt.isMatterParticipantSpouseSelected
          ) {
            this.considerationLtt.isMatterParticipantSpouseSelected = true;
            if (
              !this.considerationLtt.lttAffidavitExecutedBy ||
              (this.considerationLtt.lttAffidavitExecutedBy && this.notAvailableInPrepareLTTDrpDownOptions())
            ) {
              this.considerationLtt.lttAffidavitExecutedBy = 'BOTH_SPOUSES';
            }
          } else if (!this.considerationLtt.lttAffidavitExecutedBy) {
            if (
              !this.considerationLtt.lttAffidavitExecutedBy ||
              (this.considerationLtt.lttAffidavitExecutedBy && this.notAvailableInPrepareLTTDrpDownOptions())
            ) {
              this.considerationLtt.lttAffidavitExecutedBy = 'BOTH_SPOUSES';
            }
          } else {
            this.matter.considerationLtt.isMatterParticipantSpouseSelected = true;
            if (
              !this.considerationLtt.lttAffidavitExecutedBy ||
              (this.considerationLtt.lttAffidavitExecutedBy && this.notAvailableInPrepareLTTDrpDownOptions())
            ) {
              this.considerationLtt.lttAffidavitExecutedBy = 'BOTH_SPOUSES';
            }
          }
        }
      } else {
        if (this.matter.purchasers.length > 2) {
          this.prepareLTT = dropDowns.prepareLTTOptionsOnlySolictor;
          if (
            !this.considerationLtt.lttAffidavitExecutedBy ||
            (this.considerationLtt.lttAffidavitExecutedBy && this.notAvailableInPrepareLTTDrpDownOptions())
          ) {
            this.considerationLtt.lttAffidavitExecutedBy = 'SOLICITOR';
          }
        } else {
          this.prepareLTT = dropDowns.prepareLTTOptions;
          if (
            this.matter.considerationLtt.isMatterParticipantSpouseSelected == undefined ||
            this.matter.considerationLtt.isMatterParticipantSpouseSelected
          ) {
            this.matter.considerationLtt.isMatterParticipantSpouseSelected = false;
            if (
              !this.considerationLtt.lttAffidavitExecutedBy ||
              (this.considerationLtt.lttAffidavitExecutedBy && this.notAvailableInPrepareLTTDrpDownOptions())
            ) {
              this.considerationLtt.lttAffidavitExecutedBy = 'CLIENT';
            }
          } else if (
            !this.considerationLtt.lttAffidavitExecutedBy ||
            (this.considerationLtt.lttAffidavitExecutedBy && this.notAvailableInPrepareLTTDrpDownOptions())
          ) {
            this.considerationLtt.lttAffidavitExecutedBy = 'CLIENT';
          } else {
            this.matter.considerationLtt.isMatterParticipantSpouseSelected = false;
          }
        }
      }
      if (!this.considerationLtt.lttRateBasedOnType) {
        this.considerationLtt.lttRateBasedOnType = 'QUESTION';
      }
    }

    if (this.matter.isMatterProvinceON && this.matter.isProjectSale) {
      this.considerationLtt.inTableBelowType = null;
      if (!this.considerationLtt.completionOfTaxInfoType) {
        this.considerationLtt.completionOfTaxInfoType = 'LINE_A_EQUAL_NET_SALE_PRICE';
        this.LINE_A_EQUAL_NET_SALE_PRICE = true;
      } else {
        this.updateCompletionOfTaxInfoFlags(this.considerationLtt.completionOfTaxInfoType);
      }
    } else {
      if (!this.considerationLtt.inTableBelowType) {
        this.considerationLtt.inTableBelowType = 'AMOUNT_IN_A_EQUALS_AMOUNT_F';
        this.AMOUNT_IN_A_EQUALS_AMOUNT_F_FLAG = true;
      } else {
        this.updateTableOptionFlags(this.considerationLtt.inTableBelowType);
      }
    }

    if (!this.considerationLtt.applyCityOfTorontoLandTransferTax) {
      this.considerationLtt.applyCityOfTorontoLandTransferTax = 'QUESTION';
    }

    if (!this.considerationLtt.lttRefundEntitlementOntario) {
      this.considerationLtt.lttRefundEntitlementOntario = 'N/y';
    }

    if (!this.considerationLtt.lttRefundEntitlementToronto && this.considerationLtt.lttRefundEntitlementOntario) {
      this.considerationLtt.lttRefundEntitlementToronto = this.considerationLtt.lttRefundEntitlementOntario;
    }

    if (this.considerationLtt.percentageClaimed == undefined || this.considerationLtt.percentageClaimed == null) {
      this.considerationLtt.percentageClaimed = '100.00';
    }

    if (!this.considerationLtt.purchasePriceType) {
      this.considerationLtt.purchasePriceType = 'SALE_PRICE_AS_IN_SOA';
    }
  }

  enableSave(): void {
    this.matter.dirty = true;
  }

  public resetAllCurrencyFieldsForConsideration(): void {
    this.considerationLtt.moniesPaidOrToBePaidIn = 0;
    this.considerationLtt.mortgagesAssumed = 0.0;
    this.considerationLtt.mortgagesGivenBackToVendor = 0;

    if (this.inModalMode) {
      this.matter.recalculateProjectSaleConsiderationGivenBackToVendor();
    }
    this.considerationLtt.propertyTransferredInExchange = 0;
    this.considerationLtt.otherConsiderationSubjectToTax = 0;
    this.considerationLtt.fairMarketValueOfLands = 0;
    this.considerationLtt.valueSubjectToLttAToE1 = 0;
    this.considerationLtt.valueSubjectToLttAToE2 = 0;
    if (!this.inModalMode) {
      this.considerationLtt.valueOfAllChattles = 0;
    }
    this.considerationLtt.otherConsiderationNotInFOrG = 0;
    this.considerationLtt.totalConsideration = 0;
    this.subjectValueUpdated = false;
    this.considerationLtt.purchasePriceType = 'SALE_PRICE_AS_IN_SOA';
    if (this.matter.matterPropertyWithCondo) {
      let forERegConsideration: boolean =
        this.matter.isMatterProvinceON && (this.matter.isSale || this.matter.isProjectOrProjectSale);
      this.considerationLtt.updateSalePrice(
        this.matter.matterPropertyWithCondo.purchasePrice,
        forERegConsideration,
        this.matter.ignoreCreditsInDeedConsiderationInON,
        this.matter.isProjectHstReductionInSalePriceAdjustment,
        this.soaConsiderationTaxes
      );
    }
    this.calculateSubjectValueAndTotalConsideration();
  }

  onTableOptionChange(tableOptionValue: string): void {
    this.enableSave();
    this.CALCULATE_F_TOTALLING_A_THOUGH_E_FLAG = false;
    this.AMOUNT_IN_A_EQUALS_AMOUNT_F_FLAG = false;
    this.CALCULATE_A_SUBTRACTING_B_THROUGH_E_FROM_F_FLAG = false;
    this.resetAllCurrencyFieldsForConsideration();
    this.updateTableOptionFlags(tableOptionValue);
    this.checkMoniesPaidValue();
  }

  onCompletionOfTaxInfoChange(optionValue: string): void {
    this.enableSave();
    this.LINE_A_EQUAL_NET_SALE_PRICE = false;
    this.HIDDEN_TAX_AND_SET_AMOUNT_AS_2 = false;
    this.COMPLETE_TAX_INFO_MANUALLY = false;
    this.HIDDEN_TAX_AND_USE_NET_SALE_PRICE = false;
    this.resetAllCurrencyFieldsForConsideration();
    if (optionValue == 'HIDDEN_TAX_AND_SET_AMOUNT_AS_2' || optionValue == 'HIDDEN_TAX_AND_USE_NET_SALE_PRICE') {
      this.considerationLtt.valueOfAllChattles = 0;
      this.considerationLtt.applyCityOfTorontoLandTransferTax = 'YES';
      this.considerationLtt.resetTaxRebate();
    }

    this.updateCompletionOfTaxInfoFlags(optionValue);
    if (this.considerationLtt && this.considerationLtt.completionOfTaxInfoType === 'LINE_A_EQUAL_NET_SALE_PRICE') {
      this.checkMoniesPaidValue();
    }
  }

  updateCompletionOfTaxInfoFlags(optionValue: string): void {
    if (optionValue == 'HIDDEN_TAX_AND_SET_AMOUNT_AS_2') {
      this.HIDDEN_TAX_AND_SET_AMOUNT_AS_2 = true;
    } else if (optionValue == 'COMPLETE_TAX_INFO_MANUALLY') {
      this.COMPLETE_TAX_INFO_MANUALLY = true;
    } else if (optionValue == 'HIDDEN_TAX_AND_USE_NET_SALE_PRICE') {
      this.HIDDEN_TAX_AND_USE_NET_SALE_PRICE = true;
    } else if (optionValue == 'LINE_A_EQUAL_NET_SALE_PRICE') {
      this.LINE_A_EQUAL_NET_SALE_PRICE = true;
    }
  }

  hideInput(): boolean {
    return (
      this.considerationLtt &&
      this.matter.isProjectSale &&
      this.matter.isMatterProvinceON &&
      (this.HIDDEN_TAX_AND_SET_AMOUNT_AS_2 || this.HIDDEN_TAX_AND_USE_NET_SALE_PRICE)
    );
  }

  updateTableOptionFlags(tableOptionValue: string): void {
    if (tableOptionValue == 'CALCULATE_F_TOTALLING_A_THOUGH_E') {
      this.CALCULATE_F_TOTALLING_A_THOUGH_E_FLAG = true;
    } else if (tableOptionValue == 'AMOUNT_IN_A_EQUALS_AMOUNT_F') {
      this.AMOUNT_IN_A_EQUALS_AMOUNT_F_FLAG = true;
    } else if (tableOptionValue == 'CALCULATE_A_SUBTRACTING_B_THROUGH_E_FROM_F') {
      this.CALCULATE_A_SUBTRACTING_B_THROUGH_E_FROM_F_FLAG = true;
    }
  }

  updatePercentageValue(event): void {
    if (event.which != 13) {
      this.considerationLtt.percentageClaimed = event.target.value.replace(/[^0-9.]/g, '');
      this.enableSave();
    }
  }

  addPercentageSymbol(): void {
    if (
      !this.considerationLtt.percentageClaimed ||
      (this.considerationLtt.percentageClaimed && this.considerationLtt.percentageClaimed.toString().trim() === '')
    ) {
      this.considerationLtt.percentageClaimed = '100.00';
    } else {
    }
  }

  calculateSubjectValueAndTotalConsideration(): void {
    this.enableSave();
    let valueSubjectToLttAToE1 = isNaN(this.considerationLtt.valueSubjectToLttAToE1)
      ? 0
      : this.considerationLtt.valueSubjectToLttAToE1;
    this.considerationLtt.calculateSubjectValueAndTotalConsideration(
      this.ontarioTaxRateSlab,
      this.torontoTaxRateSlab,
      this.matter.isProjectSale && this.matter.isMatterProvinceON
        ? this.considerationLtt.getTotalNetSalePrice(
            this.matter.ignoreCreditsInDeedConsiderationInON,
            this.matter.isProjectHstReductionInSalePriceAdjustment,
            this.soaConsiderationTaxes
          )
        : null
    );
    if (
      this.considerationLtt.inTableBelowType === 'CALCULATE_A_SUBTRACTING_B_THROUGH_E_FROM_F' ||
      this.considerationLtt.completionOfTaxInfoType === 'LINE_A_EQUAL_NET_SALE_PRICE' ||
      this.considerationLtt.completionOfTaxInfoType === 'COMPLETE_TAX_INFO_MANUALLY'
    ) {
      if (
        valueSubjectToLttAToE1 != this.considerationLtt.valueSubjectToLttAToE1 &&
        this.considerationLtt.valueSubjectToLttAToE1 != null
      ) {
        this.updatePurchasePrice();
      }
    }
    this.matter.updateLTTOnStatementOfAccount();
  }

  isPurchasePriceTypeSoaForOptionA_EQ_F(): boolean {
    return (
      this.matter.matterPropertyWithCondo &&
      this.considerationLtt.inTableBelowType === 'AMOUNT_IN_A_EQUALS_AMOUNT_F' &&
      this.matter.matterPropertyWithCondo.purchasePriceType != 'SALE_PRICE'
    );
  }

  openSalePriceModal(): void {
    let soaPurchasePrice: number = 0;
    let purchaseType: string;
    let purchaseIsOfCode: string;
    if (this.matter.matterPropertyWithCondo) {
      purchaseType = this.matter.matterPropertyWithCondo.purchasePriceType;
      soaPurchasePrice = this.matter.matterPropertyWithCondo.soaPurchasePrice;
      purchaseIsOfCode = this.matter.matterPropertyWithCondo.purchaseIsOfCode;
    }

    this.dialogService.matDialogContent({
      content: ConsiderationModal,
      context: {
        salePrice: this.considerationLtt.moniesPaidOrToBePaidIn,
        priceType: purchaseType,
        statementOfAdjustments: this.matter.statementOfAdjustments,
        salePriceSoa: soaPurchasePrice,
        salePriceAdjustment: this.considerationLtt.salePriceAdjustment,
        closingDate: this.matter.matterCloseDate,
        viewContainerRef: this.viewContainerRef,
        provinceHstRateSlab: this.soaConsiderationTaxes,
        statementOfAdjustmentHeading: this.matter.statementOfAdjustmentHeading,
        purchaseIsOfCode: purchaseIsOfCode,
        autoInsertHst: this.matter.autoInsertHst,
        matter: this.matter,
        considerationLtt: this.considerationLtt
      },
      onFulfillment: (result: any) => {
        if (result) {
          if (result.priceType) {
            this.matter.hideShowSalePriceSoA(result.priceType);
          }
          if (result.salePrice != undefined && result.priceType) {
            this.considerationLtt.moniesPaidOrToBePaidIn =
              result.priceType && result.priceType == 'SALE_PRICE_AS_IN_SOA' ? result.salePriceSoa : result.salePrice;
            this.calculateSubjectValueAndTotalConsideration();
            this.matter.updateMatterPropertyWithCondoPurchasePrice(
              this.considerationLtt.moniesPaidOrToBePaidIn,
              result.salePriceSoa,
              result.priceType
            );
            this.considerationLtt.salePriceAdjustment = result.salePriceAdjustment;
            this.matter.autoInsertHst = result.autoInsertHst;
            this.matter.createUpdateSalePriceAdjustment(this.soaConsiderationTaxes);
            this.matter.calculateStatementAdjustment();
            if (this.provinceHstRateSlab) {
              this.matter.createAutomaticHstAdjustment(
                result.salePriceSoa,
                this.provinceHstRateSlab.hstFederalPortion,
                this.provinceHstRateSlab.hstProvincialPortion
              );
            }
          }
          this.verifyValueSubjectToLtt();
          this.matter.checkHSTRebateToDisplayWarningMessage(this.errorService);
          this.matter.updateConsiderationDataForAb(result.affidavitTobeSignedBy);
          this.matter.updateChattelsConsiderationLTTFromSalePriceAdjustment();
        }
      }
    });
  }

  openSubjectValueModal(): void {
    let soaPurchasePrice: number = 0;
    let purchaseType: string;
    let purchaseIsOfCode: string;
    if (this.matter.matterPropertyWithCondo) {
      purchaseType = this.matter.matterPropertyWithCondo.purchasePriceType;
      soaPurchasePrice = this.matter.matterPropertyWithCondo.soaPurchasePrice;
      purchaseIsOfCode = this.matter.matterPropertyWithCondo.purchaseIsOfCode;
    }

    this.dialogService.matDialogContent({
      content: ConsiderationModal,
      context: {
        salePrice: this.considerationLtt.valueSubjectToLttAToE1,
        priceType: purchaseType,
        salePriceAdjustment: this.considerationLtt.salePriceAdjustment,
        salePriceSoa: soaPurchasePrice,
        closingDate: this.matter.matterCloseDate,
        viewContainerRef: this.viewContainerRef,
        provinceHstRateSlab: this.soaConsiderationTaxes,
        statementOfAdjustmentHeading: this.matter.statementOfAdjustmentHeading,
        purchaseIsOfCode: purchaseIsOfCode,
        autoInsertHst: this.matter.autoInsertHst,
        considerationLtt: this.considerationLtt,
        matter: this.matter
      },
      onFulfillment: (result) => {
        if (result || result == 0) {
          if (result.salePrice != undefined && result.priceType) {
            this.considerationLtt.valueSubjectToLttAToE1 =
              result.priceType && result.priceType == 'SALE_PRICE_AS_IN_SOA' ? result.salePriceSoa : result.salePrice;
            this.verifyValueSubjectToLtt();
            this.considerationLtt.valueSubjectToLttAToE2 = this.considerationLtt.valueSubjectToLttAToE1;
            this.calculateSubjectValueAndTotalConsideration();
            if (this.considerationLtt.isCalculateABySubstractBThroughEFromFOrManualOrLINE_A()) {
              this.checkMoniesPaidValue();
            }
            this.subjectValueUpdated = true;
            this.matter.autoInsertHst = result.autoInsertHst;
            this.calculateSubjectValueAndTotalConsideration();
            this.matter.updateMatterPropertyWithCondoPurchasePrice(
              this.considerationLtt.valueSubjectToLttAToE1,
              result.salePriceSoa,
              result.priceType
            );
            this.considerationLtt.salePriceAdjustment = result.salePriceAdjustment;
            this.matter.createUpdateSalePriceAdjustment(this.soaConsiderationTaxes);
            this.matter.calculateStatementAdjustment();
            if (this.provinceHstRateSlab) {
              this.matter.createAutomaticHstAdjustment(
                result.salePriceSoa,
                this.provinceHstRateSlab.hstFederalPortion,
                this.provinceHstRateSlab.hstProvincialPortion
              );
            }
          }
          this.matter.updateConsiderationDataForAb(result.affidavitTobeSignedBy);
          this.matter.updateChattelsConsiderationLTTFromSalePriceAdjustment();
        }
      }
    });
  }

  checkMathOnCalculatedFields(): boolean {
    if (this.considerationLtt.inTableBelowType === 'CALCULATE_A_SUBTRACTING_B_THROUGH_E_FROM_F') {
      let ltt: ConsiderationLtt = this.considerationLtt;
      //console.log(`ltt.mortgagesAssumed=${ltt.mortgagesAssumed}; ltt.mortgagesGivenBackToVendor=${ltt.mortgagesGivenBackToVendor}; ltt.propertyTransferredInExchange=${ltt.propertyTransferredInExchange}; ltt.otherConsiderationSubjectToTax=${ltt.otherConsiderationSubjectToTax}; ltt.fairMarketValueOfLands=${ltt.fairMarketValueOfLands}`);
      // sometimes the selected number comes back as $XX.XX instead of XX.XX
      let totalItems: number =
        Number(ltt.mortgagesAssumed.toString().replace('$', '')) +
        Number(ltt.mortgagesGivenBackToVendor.toString().replace('$', '')) +
        Number(ltt.propertyTransferredInExchange.toString().replace('$', '')) +
        Number(ltt.otherConsiderationSubjectToTax.toString().replace('$', '')) +
        Number(ltt.fairMarketValueOfLands.toString().replace('$', ''));
      //console.log(totalItems);
      if (isNaN(totalItems)) {
        //'cause users can enter non-numeric values in any of these fields
        return true;
      }

      //console.log(`ltt.valueSubjectToLttAToE1 = ${ltt.valueSubjectToLttAToE1}`);
      let valSubjectToLtt: number = Number(ltt.valueSubjectToLttAToE1.toString().replace('$', ''));
      //console.log(valSubjectToLtt);

      if (isNaN(valSubjectToLtt)) {
        return true;
      }

      return totalItems <= valSubjectToLtt;
    }
    return true;
  }

  updateRelatedAmounts(targetName: string): void {
    // if(this.considerationLtt.inTableBelowType === 'CALCULATE_A_SUBTRACTING_B_THROUGH_E_FROM_F'){
    //     // event from related field
    //     if( _.includes(['mortgagesAssumed','mortgagesGivenBackToVendor','propertyTransferredInExchange','otherConsiderationSubjectToTax','fairMarketValueOfLands'], targetName)) {
    //
    //         // make sure there is no typo in html for considerationLtt property/key
    //         if(_.has(this.considerationLtt,targetName)){
    //
    //             if(!this.checkMathOnCalculatedFields()){
    //
    //                 // as of Feb 09 due to the way 'dp-currency' directive works it will call this method twice
    //                 // to prevent opening the modal twice, considerationLttDialogOpen flag was added
    //                 if(! this.considerationLttDialogOpen ) {
    //                     this.considerationLttDialogOpen = true;
    //                     this.dialogService.confirm("Consideration & LTT", "The total of your values from \"a\" through \"e\" cannot be greater than the value in \"f\".", true, "OK")
    //                         .subscribe(res => {
    //                             this.considerationLttDialogOpen = false;
    //                         });
    //
    //                     setTimeout( () =>  this.considerationLtt[targetName] = 0);
    //                 }
    //                 return;
    //             }
    //         }
    //     }
    // }

    if (this.considerationLtt.completionOfTaxInfoType === 'COMPLETE_TAX_INFO_MANUALLY') {
      this.onChangeApplyTorontoLtt();
    }

    this.calculateSubjectValueAndTotalConsideration();

    if (this.considerationLtt.isCalculateABySubstractBThroughEFromFOrManualOrLINE_A()) {
      this.checkMoniesPaidValue();
    }
    //console.log(this.considerationLtt.valueSubjectToLttAToE1);

    if (
      (this.considerationLtt.inTableBelowType === 'CALCULATE_F_TOTALLING_A_THOUGH_E' ||
        this.considerationLtt.inTableBelowType === 'AMOUNT_IN_A_EQUALS_AMOUNT_F') &&
      targetName == 'moniesPaidOrToBePaidIn' &&
      this.considerationLtt.moniesPaidOrToBePaidIn != null
    ) {
      this.updatePurchasePrice();
    } else if (
      (this.considerationLtt.inTableBelowType === 'CALCULATE_A_SUBTRACTING_B_THROUGH_E_FROM_F' ||
        this.considerationLtt.completionOfTaxInfoType === 'LINE_A_EQUAL_NET_SALE_PRICE' ||
        this.considerationLtt.completionOfTaxInfoType === 'COMPLETE_TAX_INFO_MANUALLY') &&
      this.considerationLtt.valueSubjectToLttAToE1 != null
    ) {
      this.updatePurchasePrice();
    }

    this.verifyValueSubjectToLtt();
  }

  checkMoniesPaidValue(): void {
    if (this.considerationLtt.moniesPaidOrToBePaidIn < 0 && !this.matter.isTemplateMatterForMassUpdate) {
      this.errorService.addDpFieldError(DPError.createDPError('considerationLtt.moniesPaidOrToBePaidIn'));
    } else {
      this.errorService.removeDpFieldError('considerationLtt.moniesPaidOrToBePaidIn');
    }
  }

  checkSingleFamilyResidenceValue(): boolean {
    if (
      this.considerationLtt.lttRateBasedOnType == 'APPORTION' &&
      !this.considerationLtt.valueOfSingleFamilyResidence
    ) {
      return true;
    } else if (
      this.considerationLtt.lttRateBasedOnType == 'APPORTION' &&
      this.considerationLtt.valueOfSingleFamilyResidence &&
      (this.considerationLtt.valueOfSingleFamilyResidence.toString() === '0' ||
        this.considerationLtt.valueOfSingleFamilyResidence === 0 ||
        this.considerationLtt.valueOfSingleFamilyResidence === 0.0)
    ) {
      return true;
    } else {
      return false;
    }
  }

  cleanUpMatter(): boolean {
    MatterCleanUpUtil.cleanUpConsiderationLtt(this.matter);
    return true;
  }

  verifyValueSubjectToLtt() {
    this.checkValueSubjectToLtt(this.considerationLtt.lttRateBasedOnType);
  }

  onChangeLttRateBasedOn(value): void {
    this.enableSave();
    this.calculateTaxRebate();
    this.checkValueSubjectToLtt(value);
  }

  onChangeApplyTorontoLtt(): void {
    this.enableSave();
    this.calculateTaxRebate();
  }

  onChangeLttOntario(): void {
    this.enableSave();
    this.considerationLtt.lttRefundEntitlementToronto = this.considerationLtt.lttRefundEntitlementOntario;
    this.calculateTaxRebate();
  }

  checkValueSubjectToLtt(value): void {
    let threshold = this.maxThresholdForOntarioResidentialLTT;
    if (threshold) {
      let warningMsg: string;
      warningMsg =
        'The field “LTT rate based on” must be completed when the “Value Subject to LTT” is over ' +
        this.currencyPipe.transform(threshold, 'CAD', 'symbol-narrow', '1.2-2').replace('CA', '');
      if (this.considerationLtt && this.considerationLtt.valueSubjectToLttAToE1) {
        if (this.considerationLtt.valueSubjectToLttAToE1 > threshold) {
          if (value == 'QUESTION') {
            this.errorService.addDpFieldError(
              DPError.createCustomDPError('considerationLtt.lttRateBasedOn', warningMsg, 'Consideration/LTT', 'WARNING')
            );
            return;
          }
        }
      }
    }
    this.errorService.removeDpFieldError('considerationLtt.lttRateBasedOn');
  }

  calculateTaxRebate(): void {
    this.considerationLtt.calculateTaxRebate(this.ontarioTaxRateSlab, this.torontoTaxRateSlab);
    this.matter.updateLTTOnStatementOfAccount();
  }

  calculateRefund(): void {
    this.considerationLtt.calculateRefund(this.ontarioTaxRateSlab, this.torontoTaxRateSlab);
    this.matter.updateLTTOnStatementOfAccount();
  }

  updatePurchasePrice(): void {
    //console.log(this.considerationLtt.valueSubjectToLttAToE1);
    let soaPurchasePrice: number = 0;
    let purchaseType: string;
    if (this.matter.matterPropertyWithCondo) {
      purchaseType = this.matter.matterPropertyWithCondo.purchasePriceType;
      soaPurchasePrice = this.matter.matterPropertyWithCondo.soaPurchasePrice;
    }
    if (
      (this.considerationLtt.inTableBelowType === 'CALCULATE_A_SUBTRACTING_B_THROUGH_E_FROM_F' ||
        this.considerationLtt.completionOfTaxInfoType === 'LINE_A_EQUAL_NET_SALE_PRICE' ||
        this.considerationLtt.completionOfTaxInfoType === 'COMPLETE_TAX_INFO_MANUALLY') &&
      this.considerationLtt.valueSubjectToLttAToE1 != null
    ) {
      let valSubjectToLttAToE1 = Utils.getNumberOrDefault(this.considerationLtt.valueSubjectToLttAToE1, 0);
      this.matter.updateMatterPropertyWithCondoPurchasePrice(
        valSubjectToLttAToE1,
        purchaseType === 'SALE_PRICE_AS_IN_SOA' || !purchaseType ? valSubjectToLttAToE1 : soaPurchasePrice,
        purchaseType
      );
      // this.considerationLtt.updateSalePriceAdjustment(((purchaseType === 'SALE_PRICE_AS_IN_SOA' || !purchaseType) ? valSubjectToLttAToE1 : soaPurchasePrice ));
      this.matter.createUpdateSalePriceAdjustment(this.soaConsiderationTaxes);
    } else {
      this.matter.updateMatterPropertyWithCondoPurchasePrice(
        this.considerationLtt.moniesPaidOrToBePaidIn,
        purchaseType === 'SALE_PRICE_AS_IN_SOA' || !purchaseType
          ? this.considerationLtt.moniesPaidOrToBePaidIn
          : soaPurchasePrice,
        purchaseType
      );
      //this.considerationLtt.updateSalePriceAdjustment(((purchaseType === 'SALE_PRICE_AS_IN_SOA' || !purchaseType) ? this.considerationLtt.moniesPaidOrToBePaidIn : soaPurchasePrice ));
      this.matter.createUpdateSalePriceAdjustment(this.soaConsiderationTaxes);
    }
  }

  handleF9RemainderOfLandUsedFor() {
    let commercial: string = 'commercial';
    if (this.considerationLtt.remainderOfLandUsedFor !== commercial) {
      this.considerationLtt.remainderOfLandUsedFor = commercial;
    } else {
      this.considerationLtt.remainderOfLandUsedFor = '';
    }
  }

  handleF9Monies(): void {
    if (this.AMOUNT_IN_A_EQUALS_AMOUNT_F_FLAG && this.considerationLtt.moniesPaidOrToBePaidIn != null) {
      this.openSalePriceModal();
    }
  }

  handleF9SubjectValueA(event): void {
    if (this.CALCULATE_A_SUBTRACTING_B_THROUGH_E_FROM_F_FLAG || this.LINE_A_EQUAL_NET_SALE_PRICE) {
      this.openSubjectValueModal();
    }
  }

  updateSoaTaxRetailFee(): void {
    if (
      this.matter.soaTrustLedgerCollection &&
      this.matter.soaTrustLedgerCollection.statementConfig &&
      this.considerationLtt.valueOfAllChattles > 0
    ) {
      this.matter.soaTrustLedgerCollection.updateRetailTaxChattels(this.provinceHstRateSlab, true);
      if (this.matter.secondarySoaSheetsCollection) {
        this.matter.secondarySoaSheetsCollection.forEach((collection) => {
          collection.updateRetailTaxChattels(this.provinceHstRateSlab, true);
        });
      }
    }
  }

  updateSoaTrustLedgerNrst(): void {
    if (this.matter.soaTrustLedgerCollection) {
      this.matter.soaTrustLedgerCollection.addNRST();
      if (
        this.matter.soaTrustLedgerCollection.statementConfig &&
        !this.matter.soaTrustLedgerCollection.statementConfig.isNrstSetForSoa()
      ) {
        this.matter.soaTrustLedgerCollection.updateF9ForPaidToYouAndOtherTrustLedgers();
      }
      if (this.matter.secondarySoaSheetsCollection) {
        this.matter.secondarySoaSheetsCollection.forEach((collection) => {
          collection.addNRST();
        });
      }
    }
  }

  get maxThresholdForOntarioResidentialLTT(): number {
    if (this.ontarioTaxRateSlab) {
      return 2000000;
    } else {
      return undefined;
    }
  }

  get torontoLandTransferTaxMessage(): string {
    if (this.torontoLttMin) {
      let formattedAmt = this.currencyPipe
        .transform(this.torontoLttMin, 'CAD', 'symbol-narrow', '1.0-2')
        .replace('CA', '');
      let msg: string =
        'City of Toronto Bylaw No.1423-2007 states: "No Tax is payable where the amount of tax payable, subsequent to the calculation of any applicable refunds and rebates is less than ';
      msg += formattedAmt;
      msg += '".';
      return msg;
    } else {
      return '';
    }
  }

  checkTorontoLttValues(): void {
    this.showTorontoLTTMsg = false;
    if (
      this.isPropertyTeranetJurisdictrionToronto &&
      this.considerationLtt &&
      this.considerationLtt.applyCityOfTorontoLandTransferTax == 'YES'
    ) {
      if (this.torontoLttMin) {
        //if the sale price is 0, then don't show the info msg
        if (
          this.considerationLtt.torontoNetLttPayable < this.torontoLttMin &&
          this.considerationLtt.lttRefundEntitlementOntario == 'Yes'
        ) {
          this.considerationLtt.torontoNetLttPayable = 0;
          this.showTorontoLTTMsg = this.considerationLtt.valueSubjectToLttAToE1 != 0;
        } else if (this.considerationLtt.torontoLandTransferTax < this.torontoLttMin) {
          this.considerationLtt.torontoLandTransferTax = 0;
          this.showTorontoLTTMsg = this.considerationLtt.valueSubjectToLttAToE1 != 0;
        }
      }
    }
  }

  get torontoLandTransferTax(): number {
    this.checkTorontoLttValues();
    return this.considerationLtt ? this.considerationLtt.torontoLandTransferTax : 0;
  }

  get isProjectSale(): boolean {
    return this.matter && this.matter.isProjectSale;
  }

  get isProject(): boolean {
    return this.tabService && this.tabService.activeTab && this.tabService.activeTab.isProject();
  }

  get project(): Project {
    return this.isProjectSale
      ? this.matter.project
      : this.isProject && (this.tabService.activeTab as ProjectTab).project;
  }

  ngOnDestroy() {}

  ngAfterViewInit() {}
}
