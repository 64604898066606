<div class="form-horizontal">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Appearance</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body">

        <div class="margin-bottom-20">
            <div class="col-lg-12 fields-group padding-top-20 margin-bottom-0" style="margin-top: 0!important;">
                                <div class="form-group main-level">
                                    <span class="title min-height-30">Choose login landing page:</span>
                                </div>

                                <div class="form-group padding-bottom-20">
                                    <div class="col-lg-2">
                                        <label class="control-label" for="landingPage">Login Landing Page</label>
                                    </div>
                                    <div class="col-lg-3">
                                        <select class="form-control" id="landingPage" name="landingPage" [(ngModel)]="selectedLandingPage">
                                            <option *ngFor="let landingpage of landingPageItems" [value]="landingpage.value">
                                                {{landingpage.label}}
                                            </option>
                                        </select>
                                    </div>
                                </div>

            <div class="form-group main-level" >
                    <span class="title min-height-30">Choose theme colour:</span>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="form-group">
                    <button *ngFor="let theme of dpThemes;" (click)="setThemeStyle(theme.class)"
                            [ngClass]="{'box-theme-selected': themeStyle==(theme.class)}"
                            class="control-label col-lg-2 box-theme-color
                            {{theme.class+'-swatch'}}">{{ theme.name }}
                    </button>
                </div>
            </div>
        </div>
        <div class="margin-bottom-20">
            <div class="col-lg-12 fields-group padding-top-20 margin-bottom-0">
                <div class="form-group main-level">
                    <span class="title min-height-30">Choose Page Font:</span>
                </div>
            </div>
            <div class="col-lg-6 padding-top-10">
                <span class="slider-label fa fa-minus padding-right-5" (click)="decreasePageFont()"></span>
                <input type="range"
                       [min]="userConfigurationService.minPageFontPercentage"
                       [max]="userConfigurationService.maxPageFontPercentage"
                       [step]="userConfigurationService.fontStep"
                       [(ngModel)]="pageFont" class="slider" (change)="updatePageFont()">
                <span class="slider-label fa fa-plus padding-left-5" (click)="increasePageFont()"></span>
            </div>
            <div class="col-lg-2 offset-lg-1 vertical-align-label padding-top-7">
                <label class="control-label right page-font-style" for="sampleInput">Sample Label</label>
            </div>
            <div class="col-lg-3">
                <input type="text"
                       id="sampleInput"
                       name="sampleInput"
                       class="form-control page-font-style"
                       value="Sample Contents"
                       [dp-read-only]="true"
                />
            </div>
        </div>
        <div class="margin-bottom-20">
            <div class="col-lg-12 fields-group padding-top-20 margin-bottom-0">
                <div class="form-group main-level">
                    <span class="title min-height-30">Sidebar Style:</span>
                </div>
            </div>
            <div class="col-lg-6 fields-group margin-bottom-0">
                <div class="form-group main-level">
                    <span class="title min-height-30">Sidebar Font Color:</span>
                </div>
                <div class="form-group padding-bottom-20">
                    <button *ngFor="let theme of dpColors;" (click)="setFontColor(theme.class)"
                            [ngClass]="{'box-theme-selected': sidebarFontColor==(theme.class)}"
                            class="control-label col-lg-2 box-font-color {{theme.class}}">
                        {{ theme.name }}
                    </button>
                </div>
                <div class="form-group main-level">
                    <span class="title min-height-30">Sidebar Font Weight:</span>
                </div>
                <div class="form-group padding-bottom-20">
                    <dp-checkbox fieldId="sidebarBold">
                        <input id="sidebarBold"
                               name="sidebarBold"
                               type="checkbox"
                               [(ngModel)]="isSidebarFontBold"
                               (change)="updateSidebarFontWeight()"
                        />
                    </dp-checkbox>
                    <label class="control-label padding-left-10 display-inline" for="sidebarBold">Bold</label>
                </div>
                <div class="form-group main-level">
                    <span class="title min-height-30">Sidebar Font Size:</span>
                </div>
                <div class="form-group padding-bottom-20">
                    <span class="slider-label fa fa-minus padding-right-5" (click)="decreaseSidebarFont()"></span>
                    <input type="range"
                           [min]="userConfigurationService.minPageFontPercentage"
                           [max]="userConfigurationService.maxPageFontPercentage"
                           [step]="userConfigurationService.fontStep"
                           [(ngModel)]="sidebarFontSize" class="slider" (change)="updateSidebarFontSize()">
                    <span class="slider-label fa fa-plus padding-left-5" (click)="increaseSidebarFont()"></span>
                </div>
            </div>
            <div class="col-lg-3 offset-lg-3">
                <div class="form-group main-level">
                    <span class="title min-height-30">Sample Sidebar:</span>
                </div>
                <div class="left-nav">
                    <ul>
                        <li><a class="sidebar-style">Matter Opening</a></li>
                        <li><a class="sidebar-style">Purchasers</a></li>
                        <li><a class="sidebar-style">Vendors</a></li>
                        <li><a class="sidebar-style">Subject Property</a></li>
                    </ul>
                </div>
            </div>

        </div>


    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button" id="okBtn" class="dp-btn" (click)="ok()">
                <span>OK</span>
            </button>
            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>Cancel</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</div>
