import {api} from '../common/api';

export const configApi = {

  apiVersion: 'api/app',

  clientVersion: 'readme.json',

  customerAccountConfiguration: `${ api }/accounts/{accountId}/customerAccountConfiguration`,

  unityDriveInstallParam: `${ api }/reference/unityDrive/installable`,

  chatAIApi: `${ api }/chatbot/sendmessage`,

  clearchatAISession: `${ api }/chatbot/clearsession`,

  addSentimentToAIResponse: `${ api }/chatbot/addusersentiment`,

  getTermsConditionsContent: `${ api }/chatbot/tnc`,

  getFAQLink: `${ api }/chatbot/faq`,

  getTnCAcceptanceCheckApi: `${ api }/chatbot/tncaccepted`,

  saveTnCAcceptanceApi: `${ api }/chatbot/tncaccept`

};
