<!-- modal -->
<div class="container-fluid custom-modal-container">
    <form class="form-horizontal border-radius">
        <div class="row modal-header margin-bottom-20">
            <div class="col-lg-12">
                <div class="col-lg-10">
                    <h1>{{context.txnType}} Price</h1>
                </div>
                <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
            </div>
        </div>
        <div class="row modal-body">
            <div class="form-group">
                <div class="col-lg-1"></div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <div class="col-lg-1">
                              <span class="radio" style="margin-top: 4px;">
                                        <input type="radio"
                                               id="1_SalePrice"
                                               name="1_SalePrice"
                                               (click)="onPriceTypeChange('SALE_PRICE');"
                                               [(ngModel)]="priceType"
                                               [value]="'SALE_PRICE'"
                                        />

                                        <label tabindex="0" for="1_SalePrice" class="focus-first">1</label>
                                        </span>
                        </div>
                        <label class="control-label col-lg-4 text-left">Enter Sale Price</label>
                        <div class="col-lg-4">
                            <input type="text"
                                   [(ngModel)]="newSalePrice"
                                   name="salePrice"
                                   tabindex="0"
                                   [disabled]="isPriceTypeSalePriceSOA"
                                   dp-default-currency
                                   dp-currency
                                   dp-error-validator
                                   fieldKey="matter.considerationLtt.modal.newSalePrice"
                                   class="form-control"/>
                        </div>
                    </div>
                </div>
                <!--
                <div class="col-lg-4"  *dpShowByProvince="'matter.subjectProperty.overrideParcels'">
                    <div class="col-lg-1 margin-top-10">
                        <dp-checkbox fieldId="overrideParcels"><input type="checkbox"
                                                                      id="overrideParcels"
                                                                      name="overrideParcels"
                                                                      [(ngModel)]="newOverrideValueOfParcels"
                                                                      [checked]="newOverrideValueOfParcels"
                                                                      (click)="onRPRChange($event);"
                                                                      #overrideParcels></dp-checkbox>
                    </div>
                    <label class="control-label col-lg-6" for="overrideParcels">Override value of Parcels</label>
                </div>-->
            </div>
            <div class="form-group">
                <div class="col-lg-1"></div>
                <div class="col-lg-10">
                    <div class="form-group">
                        <label class="control-label col-lg-12 text-left">Select this option if you wish the sale price
                            to be different than the sale
                            price in the
                            Statement of adjustments. (e.g. sale price for land registration/transfer fee is different
                            than sale price in statement of
                            adjustments)</label>
                    </div>
                    <div class="form-group padding-top-10">
                        <label class="control-label col-lg-12 text-left">OR</label>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="col-lg-1"></div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <div class="col-lg-1">
                              <span class="radio" style="margin-top: 4px;">
                                        <input type="radio"
                                               id="2_SalePrice"
                                               name="2_SalePrice"
                                               (click)="onPriceTypeChange('SALE_PRICE_AS_IN_SOA'); "
                                               [(ngModel)]="priceType"
                                               [value]="'SALE_PRICE_AS_IN_SOA'"
                                        />
                                        <label tabindex="0" for="2_SalePrice">2</label>
                                        </span>
                        </div>
                        <label class="control-label col-lg-4 text-left vertical-align-label" style="top:-10px; padding-right: 3px;">Sale
                            Price as entered in the Statement of Adjustments</label>
                        <div class="col-lg-4">
                            <input type="text"
                                   [(ngModel)]="newSalePriceSOA"
                                   id="salePriceSOA"
                                   name="salePriceSOA"
                                   tabindex="0"
                                   (keydown.f9)="openSalePriceModal()"
                                   readonly="true"
                                   [disabled]="priceType === 'SALE_PRICE'"
                                   dp-default-currency
                                   dp-currency
                                   dp-error-validator
                                   fieldKey="matter.considerationLtt.modal.newSalePriceSOA"
                                   class="form-control"/>
                            <button type="button"
                                    id="unitLevelPlanBtn"
                                    tabindex="-1"
                                    *ngIf="priceType == 'SALE_PRICE_AS_IN_SOA'"
                                    (click)="openSalePriceModal()"
                                    class="button-glyph">
                                <span class="glyphicon glyphicon-edit"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="purchaseIsOfCode=='NEW_BUILDER_HOME'">
                <div class="col-lg-1"></div>
                <div class="col-lg-10">
                    <div class="form-group-warning-message margin-bottom-0"
                    >
                        <div class="col-lg-1">
                            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        </div>
                        <div class="col-lg-11">
                            <p>
                                If {{appliedTaxRate}} is to be netted out of sale price, then select No. 2 and complete
                                the Sale Price adjustment by selecting the edit button above.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group" *ngIf="isAutoInsertVisible()">
                <div class="col-lg-1"></div>
                <div class="col-lg-10">


                    <div class="col-lg-11">
                        <dp-checkbox fieldId="autoSelected">
                            <input id="autoSelected"
                                   name="autoSelected"
                                   type="checkbox"
                                   [(ngModel)]="context.autoInsertHst"
                                   #autoSelected
                            />
                        </dp-checkbox>
                        <label class="control-label text-left">
                            Automatically insert {{appliedTaxRate}} on Sale Price and {{appliedTaxRate}} Rebate
                            adjustments into Statement of Adjustments<br>
                            (Note: above is only applicable if the {{appliedTaxRate}} is netted out from the sale price
                            adjustment)
                        </label>
                    </div>

                </div>
            </div>
            <div *dpShowByProvince="'matter.considerationModal';code:context.matter.provinceCode">
                <div class="form-group">
                    <div class="col-lg-12">
                        <hr class="hr-title margin-bottom-0 margin-top-0">
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-lg-1"></div>
                    <div class="col-lg-6" *ngIf="isAffidavitTobeSignedByVisble">
                        <!--<div class="col-lg-1"></div>-->
                        <label class="control-label col-lg-5 text-left">Affidavit re: Value to be signed by</label>
                        <div class="col-lg-4">
                            <select class="form-control"
                                    id="affidavitTobeSignedBy"
                                    [(ngModel)]="affidavitTobeSignedBy"
                                    [dp-read-only]="isAffidavitValueSignedByReadOnly"
                                    name="affidavitTobeSignedBy">
                                <option *ngFor="let item  of affidavitTobeSignedByOptions" [value]="item.value">
                                    {{item.label}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row modal-footer">
            <div class="col-lg-12">
                <div class="row padding-top-0">
                    <div class="col-lg-12 text-center">
                        <button type="button" id="okBtn" class="dp-btn keyboard-save-action" (click)="ok()">
                            <span>OK</span>
                        </button>

                        <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                            <span>Cancel</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </form>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
    <dp-modal-f9-help #modalF9HelpComponent></dp-modal-f9-help>
</div>
