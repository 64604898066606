import {Injectable, ViewContainerRef} from '@angular/core';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {StatementAdjustment} from '../statement-adjustment';
import {MatterTax} from '../../shared/property-taxes';
import {RollNumberMatterTax} from '../../shared/roll-number-matter-tax';
import {StatementAdjustmentAmountTypes, StatementAdjustmentKey} from '../statement-adjustment-constants';
import {RollNumberPropertyTaxesModalComponent} from '../../property-teranet/property-taxes/roll-number-property-taxes.modal.component';
import {DpBooleanValueTypes} from '../../shared/dp-boolean';
import {Matter, MatterProperty} from '../../shared';
import {SoaStateUtil} from './soa-state-util';
import {ConsiderationSoaModal} from '../../consideration-ltt/consideration-soa.modal.component';
import {SalePriceAdjustment} from '../sale-price-adjustment/sale-price-adjustment';
import {Deposit} from '../../shared/deposit';
import {StatementAdjustmentUtil} from '../statement-adjustment-util';
import {DepositModal, DepositModalContextValue} from '../../property-teranet/deposit/deposit.modal.component';
import {HstSalePriceSoaModal} from '../modals/hst-sale-price/hst-sale-price-soa.modal.component';
import {TaxOtherFactorModalComponent} from '../modals/tax-other-factor.modal.component';
import {ModalResult} from '../../../shared-main/enums';
import {TaxOtherCostModalComponent} from '../modals/tax-other-cost.modal.component';
import {WaterSoaModal} from '../water-soa.modal.component';
import {HeatSoaModal} from '../modals/heat/heat-soa.modal.component';
import {TaxesPaidDuringOccupancyModalComponent} from '../modals/taxes-paid-during-occupancy/taxes-paid-during-occupancy.modal.component';
import {Form4ChargeModal} from '../modals/form-4-charge/form-4-charge.modal.component';
import {ItemizedCreditToVendorPurchaserModalComponent} from '../modals/itemized-credit-to-vendor-purchaser/itemized-credit-to-vendor-purchaser.modal.component';
import {SoAdjInterimOccupancyFee} from '../model/so-adj-interim-occupancy-fee';
import {SoAdjInterimEarlyPossessionFee} from '../model/so-adj-interim-early-possession-fee';
import {InterimOccupancyFeeModalComponent} from '../modals/interim-occupancy-fee/interim-occupancy-fee.modal.component';
import {SoAdjFinalOccupancyFee} from '../model/so-adj-final-occupancy-fee';
import {SoAdjFinalEarlyPossessionFee} from '../model/so-adj-final-early-possession-fee';
import {FinalOccupancyFeeModalComponent} from '../modals/final-occupancy-fee/final-occupancy-fee.modal.component';
import {SoAdjTotalOccupancyFee} from '../model/so-adj-total-occupancy-fee';
import {TotalOccupancyFeeModalComponent} from '../modals/total-occupancy-fee/total-occupancy-fee.modal.component';
import {SoaPayableToComponent} from '../modals/payable-to/soa-payable-to.modal.component';
import {projectConsts} from '../../../projects/shared/project-consts';

import {
  SoAdjTarionWarranty,
  SoAdjVTBMortgage,
  StatementAdjustmentDisplayItem,
  StatementOfAdjustmentPayable
} from '../model';
import {ProjectTab} from '../../../projects/shared/project-tab';
import {ProjectDeleteSoaComponent} from '../modals/project-delete-soa/project-delete-soa.modal.component';
import {ConsiderationLttModalComponent} from '../../consideration-ltt/consideration-ltt.modal.component';
import * as _ from 'lodash';
import {LegendSoaModal} from '../modals/legend/legend-soa.modal.component';
import {SoAdjCreditVendorTaxRebate} from '../modals/credit-vendor-tax-rebate/so-adj-credit-vendor-tax-rebate';
import {CreditVendorTaxRebateModal} from '../modals/credit-vendor-tax-rebate/credit-vendor-tax-rebate.modal.component';
import {SoaCommonExpenseModal} from '../modals/common-expense/common-expenses-soa.modal.component';
import {SoaNetSalePriceAdjustmentModalComponent} from '../modals/sale-price-minus-net-sale-price/soa-net-sale-price-adjustment.modal.component';
import {SoaRealtyTaxModalComponent} from '../modals/realty-tax/soa-realty-tax.modal.component';
import {PurchasePriceDeferredPortionModal} from '../modals/purchase-price-deferred-portion/purchase-price-deferred-portion.component';
import {AmountPaidOnInterimClosingModalComponent} from '../modals/amount-paind-on-interim-closing/amount-paid-on-interim-closing.modal.component';
import {ConsiderationPaidOnInterimClosingModalComponent} from '../modals/consideration-paid-on-interim-closing/consideration-paid-on-interim-closing.modal.component';
import {TotalDuringOccupancyPeriodModal} from '../modals/total-during-occupancy-period/total-during-occupancy-period.modal.component';
import {SoaExpenseAdjustmentModal} from '../modals/reserve-fund/soa-expense-adjustment.modal.component';
import {SoaInterestModal} from '../interest-soa.modal.component';
import {InterestOnDepositCondoModal} from '../interest-on-deposit-condo-modal.component';
import {SoAdjInterestOnDepositCondo} from '../so-adj-interest-on-deposit-condo';
import {SoaOtherFixedModal} from '../other/other-fixed.modal.component';
import {ComponentAdjustmentModalComponent} from '../modals/component-adjustment/component-adjustment.modal.component';
import {OtherProratedModalComponent} from '../other/other-prorated.modal.component';
import {SoaOtherPurchasePricePercentageModal} from '../other/other-purchase-price-percentage.modal.component';
import {TenancyCurrentModalComponent} from '../modals/tenancy/tenancy-current.modal.component';
import {TenancyPrepaidModalComponent} from '../modals/tenancy/tenancy-prepaid.modal.component';
import {SoAdjAssumedMortgageModalComponent} from '../modals/assumed-mortgage/so-adj-assumed-mortgage-modal.component';
import {SoAdjHCRAFee} from '../model/so-adj-hcra-fee';
import {HCRAFeeModalComponent} from '../modals/hcra/hcra-fee.modal.component';
import {SoAdjVacantOccupancyTax} from '../model/so-adj-vacant-occupancy-tax';
import {VacantOccupancyTaxModalComponent} from '../modals/vacant-occupancy-tax/vacant-occupancy-tax.modal.component';
import {TarionWarrantyModalComponent} from '../modals/tarion-warranty/tarion-warranty.modal.component';
import {SoAdjVtbMortgageModalComponent} from '../modals/vtb-mortgage/so-adj-vtb-mortgage.modal.component';
import {StatementAdjustmentConfig} from '../../../admin/statement-adjustment/statement-adjustment-config';
import {StatementAdjustmentConfigComponent} from '../../../admin/statement-adjustment/statement-adjustment-config.component';
import {TaxRatesComponent} from '../tax-rates.component';
import {taxRateOpenFromValues} from '../../../shared-main/constants';
import {ConsiderationTaxes} from '../../consideration-ltt/consideration-taxes';
import {StatementAdjustmentHeadingComponent} from '../modals/heading/statement-adjustment-heading.component';
import {StatementOfAdjustmentHeading} from '../model/statement-adjustment-heading';
import {StatementAdjustmentFormatModalComponent} from '../modals/format/statement-adjustment-format.modal.component';
import {SoaFulfillment} from './soa-fulfillment';
import {Project} from '../../../projects/shared/project';
import {StatementAdjustmentDisplayBuilder} from '../builders/statement-adjustment-display-builder';
import {TabsService} from '../../../core';
import {DocumentProfileCache} from '../../../shared-main/document-profile-cache.service';
import {ConsiderationLtt} from '../../consideration-ltt/consideration-ltt';

@Injectable({
  providedIn: 'root'
})
export class SoaModal {
  prevConsiderationLtt: ConsiderationLtt;
  prevMatterStatementOfAdjustmentFooters: StatementAdjustmentConfig[] = [];

  constructor(
    public dialogService: DialogService,
    private soaUtils: SoaStateUtil,
    private soaFulfillmentService: SoaFulfillment,
    public tabService: TabsService,
    public documentProfileCache: DocumentProfileCache
  ) {}

  get matter() {
    return this.soaUtils.matter;
  }

  get propertyModel(): MatterProperty {
    return this.matter.matterPropertyWithCondo;
  }

  get adjustmentDate(): string {
    return this.matter.isMatterProvinceBC ? this.matter.adjustAsAtClosingDate : this.matter.getClosingDate();
  }

  get soaConsiderationTaxes(): ConsiderationTaxes {
    return this.soaUtils.soaConsiderationTaxes;
  }

  get project(): Project {
    return this.soaUtils.project;
  }

  get statementAdjustmentDisplayUtil(): StatementAdjustmentDisplayBuilder {
    return this.soaUtils.statementAdjustmentDisplayUtil;
  }

  get isProjectConfigTab(): boolean {
    return this.tabService && this.tabService.activeTab && this.tabService.activeTab.isProject();
  }

  get isProjectSale(): boolean {
    return this.matter && this.matter.isProjectSale;
  }

  get isProjectOrProjectSale(): boolean {
    return this.isProjectSale || this.isProjectConfigTab;
  }

  openPropertyTaxModal(
    statementAdjustmentKey: string,
    statementAdjustment?: StatementAdjustment,
    isOriginPropertyTax?: boolean
  ): void {
    if (this.soaUtils.isAdjustDateNotValid()) {
      this.dialogService.confirm(
        'Information',
        'Cannot enter adjustments until a valid adjustment date is entered',
        true
      );
    } else {
      let matterPropertyTax: MatterTax;
      let separateTaxAdjustmentByRolNumber: boolean = false;
      let matterTaxByRollNumber: RollNumberMatterTax[];
      let selectedMatterPropertyTax: MatterTax;
      let realtyTaxesPaidByVendor: boolean = false;

      if (this.matter.matterPropertyWithCondo && statementAdjustment && statementAdjustment.isOriginPropertyTaxes()) {
        matterPropertyTax = this.matter.matterPropertyWithCondo.matterTax;
      } else if (statementAdjustment && statementAdjustment.addedFlag && statementAdjustment.matterTax) {
        matterPropertyTax = statementAdjustment.matterTax;
      }
      if (statementAdjustment && !statementAdjustment.addedFlag && isOriginPropertyTax) {
        separateTaxAdjustmentByRolNumber = this.matter.matterProperties[0].separateTaxAdjustmentByRollNumber;
        matterTaxByRollNumber = this.matterTaxByRollNumbers(isOriginPropertyTax);
        selectedMatterPropertyTax = statementAdjustment.matterTax;
      }

      if (statementAdjustmentKey && statementAdjustmentKey === StatementAdjustmentKey.REALTY_TAXES_PAID_BY_VENDOR) {
        realtyTaxesPaidByVendor = true;
      }
      let dateForAdj: string =
        statementAdjustmentKey &&
        statementAdjustmentKey === StatementAdjustmentKey.TAXES_BEGINNING_OF_YEAR_UNTIL_OCCUPANCY
          ? this.matter.adjustmentDateForTaxBeginningOfYearUntilOcc
          : this.adjustmentDate;
      this.dialogService.matDialogContent({
        content: RollNumberPropertyTaxesModalComponent,
        context: {
          propertyTax: matterPropertyTax,
          closingDate: dateForAdj,
          adjustmentLabelDateForTaxBeginningOfYearUntilOcc: this.adjustmentDate,
          estTaxIncrease: this.soaUtils.matterEstTaxIncrease,
          statementAdjustmentHeading: this.matter.statementOfAdjustmentHeading,
          matterProvinceCode: this.matter.provinceCode,
          separateTaxAdjustmentByRollNumber: separateTaxAdjustmentByRolNumber,
          matterTaxesByRollNumber: matterTaxByRollNumber,
          currentMatter: this.matter,
          hideDeleteFromModal: statementAdjustment && !statementAdjustment.addedFlag,
          disableCheckBoxFromSoa: this.matter.isMatterProvinceAB || this.matter.isMatterProvinceMB,
          selectedMatterPropertyTax: selectedMatterPropertyTax,
          statementAdjustmentKey: statementAdjustmentKey,
          realtyTaxesPaidByVendor: realtyTaxesPaidByVendor,
          sourceFromProject: statementAdjustment && statementAdjustment.isAdjustmentSourceFromProject,
          infoOnly: statementAdjustment && statementAdjustment.infoOnly,
          autoInsertTaxAdjustment: this.propertyModel.autoInsertTaxAdjustment,
          hideAutoInsertFromModal: true
        },

        onFulfillment: (result: any) => {
          this.soaFulfillmentService
            .onPropertyTaxUpdate(result, statementAdjustmentKey, statementAdjustment)
            .then(() => {
              this.soaUtils.setFocusAfterModalClose();
            });
        }
      });
    }
  }

  matterTaxByRollNumbers(isOriginPropertyTax: boolean): RollNumberMatterTax[] {
    let materTaxesByRollNumbers: RollNumberMatterTax[] = [];
    if (this.matter.isMatterProvinceAB && isOriginPropertyTax) {
      if (this.propertyModel.isCondominium == DpBooleanValueTypes.YES) {
        if (this.propertyModel.condominiumExpenses) {
          this.propertyModel.condominiumExpenses.forEach((condoExpense, index) => {
            if (condoExpense.rollNumber) {
              let matterTaxByRollNumber = new RollNumberMatterTax();
              matterTaxByRollNumber.rollNumber = condoExpense.rollNumber;
              if (this.propertyModel.matterTaxesByRollNumber && this.propertyModel.matterTaxesByRollNumber.length > 0) {
                let matterTax = this.propertyModel.matterTaxesByRollNumber.find(
                  (item) => item.rollNumberOrder == index
                );
                if (matterTax) {
                  matterTaxByRollNumber.matterTax = new MatterTax(matterTax);
                }
              }
              materTaxesByRollNumbers.push(matterTaxByRollNumber);
            }
          });
        }
      } else {
        this.matter.matterProperties.forEach((matterProperty, index) => {
          if (matterProperty && matterProperty.rollNumber && matterProperty.rollNumber.city != '') {
            let matterTaxByRollNumber = new RollNumberMatterTax();
            matterTaxByRollNumber.rollNumber = matterProperty.rollNumber.city;
            if (this.propertyModel.matterTaxesByRollNumber && this.propertyModel.matterTaxesByRollNumber.length > 0) {
              let matterTax = this.propertyModel.matterTaxesByRollNumber.find((item) => item.rollNumberOrder == index);
              if (matterTax) {
                matterTaxByRollNumber.matterTax = new MatterTax(matterTax);
              }
            }
            materTaxesByRollNumbers.push(matterTaxByRollNumber);
          }
        });
      }
    } else if (this.matter.isMatterProvinceMB && isOriginPropertyTax) {
      return this.matter.matterTaxByRollNumbers();
    }
    return materTaxesByRollNumbers;
  }

  openSalePriceModal(): void {
    let priceType: string =
      this.matter.matterPropertyWithCondo && this.matter.matterPropertyWithCondo.purchasePriceType;

    this.dialogService.matDialogContent({
      content: ConsiderationSoaModal,
      context: {
        closingDate: this.matter.getClosingDate(),
        statementOfAdjustments: this.matter.statementOfAdjustments,
        salePriceAdjustment: this.matter.considerationLtt.salePriceAdjustment,
        provinceHstRateSlab: this.soaConsiderationTaxes,
        statementAdjustmentHeading: this.matter.statementOfAdjustmentHeading,
        considerationLtt: this.matter.considerationLtt,
        priceType: priceType,
        statementAdjustmentDisplayUtil: this.statementAdjustmentDisplayUtil,
        matter: this.matter,
        project: this.project
      },
      onFulfillment: (result: SalePriceAdjustment) => {
        if (result != null) {
          this.soaFulfillmentService.onSalePriceUpdateInterimAndFinal(result, false);
        }
        this.soaUtils.setFocusAfterModalClose();
      },
      modalGrid: 6
    });
  }

  private isCondo(): boolean {
    const project = this.project;
    return project ? project.condominiumFlag : this.matter.isPropertyCondo();
  }

  private isProjectRelated() {
    return this.soaUtils.isProjectSale || this.soaUtils.isProjectConfigTab;
  }

  async openDepositModal(interimBDOCAmount): Promise<void> {
    let matterPropertyMultipleDeposit: string;
    let matterPropertyAdjustmentFormat: string;
    let matterPropertyDeposits: Deposit[] = [];

    const matter = this.matter;
    const project = this.project;
    const projectCondo = project ? project.projectCondo : null;

    if (matter.matterPropertyWithCondo) {
      matterPropertyAdjustmentFormat = matter.matterPropertyWithCondo.adjustmentFormat;
      matterPropertyMultipleDeposit = matter.matterPropertyWithCondo.multipleDeposit;
      matterPropertyDeposits = matter.matterPropertyWithCondo.deposits;
    }

    // TODO remove this block when matter.project is guaranteed to be set (especially for template matter)
    if (!matter.project && project) {
      matter.project = project;
    }

    this.dialogService.matDialogContent({
      content: DepositModal,
      context: {
        depositAmount: matter.getAdjustmentAmount(StatementAdjustmentKey.DEPOSIT),
        multipleDeposit: matterPropertyMultipleDeposit,
        payDepositOutOfTrust: matter.matterPropertyWithCondo
          ? matter.matterPropertyWithCondo.payDepositOutOfTrust
          : null,
        closingDate: this.adjustmentDate,
        adjustmentFormat: matterPropertyAdjustmentFormat,
        deposits: matterPropertyDeposits,
        matter: matter,
        isCondo: this.isCondo(),
        isProjectRelated: this.isProjectRelated(),
        projectOccupancyFeesCalBasedOn: projectCondo ? projectCondo.occupancyFeesCalculatedBasedOn : null,
        infoOnly: this.matter.isDepositSOAInfoOnly(),
        interimBDOCAmount: interimBDOCAmount,
        hidePrintF4Menu: this.soaUtils.isProjectConfigTab // Do not show 'Print Form 4' menu when Deposit opened from Project tab Adjustments
      },
      onFulfillment: (result: DepositModalContextValue) => {
        if (result) {
          this.soaFulfillmentService.fulfillDepositModal(result);
        }
      }
    });
  }

  isAdjustmentTpePSTRebate(rateType: string) {
    return rateType && rateType === 'PST';
  }

  get federalHstRate(): number {
    return this.soaConsiderationTaxes && this.soaConsiderationTaxes.hstFederalPortion
      ? this.soaConsiderationTaxes.hstFederalPortion
      : 0;
  }

  get provinceHstRate(): number {
    return this.soaConsiderationTaxes && this.soaConsiderationTaxes.hstProvincialPortion
      ? this.soaConsiderationTaxes.hstProvincialPortion
      : 0;
  }

  getTaxRateType(rateType: string) {
    return rateType && rateType === 'PST' ? 'PST' : this.matter.taxRateByProvinceForHSTSalePrice();
  }

  openHSTModal(
    adjustmentType: string,
    statementAdjustment?: StatementAdjustment,
    setCreditPurchaserOrVendor?: string,
    rateType?: string
  ): void {
    if (!this.matter.considerationLtt) {
      this.matter.createNewConsiderationLtt();
    }

    if (this.soaUtils.isAdjustDateNotValid()) {
      this.dialogService.confirm(
        'Information',
        'Cannot enter adjustments until a valid adjustment date is entered',
        true
      );
    } else {
      let hstSalePriceSoa = statementAdjustment ? statementAdjustment.hstSalePrice : undefined;
      let rateTypeSoaAdj = rateType
        ? rateType
        : statementAdjustment
          ? statementAdjustment.hstSalePrice.rateType
          : undefined;
      this.dialogService.matDialogContent({
        content: HstSalePriceSoaModal,
        context: {
          closingDate: this.adjustmentDate,
          provinceHstRateSlab: this.soaConsiderationTaxes,
          hstSalePriceSoa: hstSalePriceSoa,
          hstType: adjustmentType,
          statementAdjustment: statementAdjustment,
          salePrice: this.matter.getSalePriceBasedOnAdjustment(
            this.federalHstRate,
            this.provinceHstRate,
            this.isAdjustmentTpePSTRebate(rateTypeSoaAdj)
          ),
          setCreditPurchaserOrVendor: setCreditPurchaserOrVendor,
          taxRateType: this.getTaxRateType(rateTypeSoaAdj),
          matter: this.matter,
          sourceFromProject: statementAdjustment && statementAdjustment.isAdjustmentSourceFromProject
        },
        onFulfillment: (result) => {
          this.soaFulfillmentService.fulfillHSTModal(result, statementAdjustment);
        }
      });
    }
  }

  get purchaserLabel(): string {
    return this.soaUtils.purchaserLabel;
  }

  get vendorLabel(): string {
    return this.soaUtils.vendorLabel;
  }

  openTaxFactorOther(statementAdjustment?: StatementAdjustment) {
    if (this.soaUtils.isAdjustDateNotValid()) {
      this.dialogService.confirm(
        'Information',
        'Cannot enter adjustments until a valid adjustment date is entered',
        true
      );
    } else {
      let salePrice = this.matter.considerationLtt
        ? this.matter.considerationLtt.creditVendorPrice(this.soaConsiderationTaxes)
        : 0;
      let hstSalePriceSoa = statementAdjustment ? statementAdjustment.hstSalePrice : undefined;
      this.dialogService.matDialogContent({
        content: TaxOtherFactorModalComponent,
        context: {
          closingDate: this.adjustmentDate,
          provinceHstRateSlab: this.soaConsiderationTaxes,
          statementAdjustmentHeading: this.matter.statementOfAdjustmentHeading,
          salePrice: salePrice,
          vendorLabel: this.vendorLabel,
          purchaserLabel: this.purchaserLabel,
          hstSalePriceSoa: hstSalePriceSoa,
          matterProvinceCode: this.matter.provinceCode,
          adjustmentStatusMode: this.matter.adjustmentStatusMode,
          taxRateType: this.getTaxRateType(null),
          sourceFromProject: statementAdjustment && statementAdjustment.isAdjustmentSourceFromProject
        },
        onFulfillment: (result) => {
          if (result && result != ModalResult.Cancel && result !== ModalResult.Delete) {
            this.soaFulfillmentService.onTaxFactorUpdate(result, statementAdjustment);
          } else {
            if (result === ModalResult.Delete) {
              this.soaUtils.removeStatementAdjustment(statementAdjustment, true, true);
            }
          }
          this.soaUtils.enableSave().then(() => {
            this.soaUtils.setFocusAfterModalClose();
          });
        }
      });
    }
  }

  get hstRate(): number {
    return this.soaConsiderationTaxes && this.soaConsiderationTaxes.hstRate ? this.soaConsiderationTaxes.hstRate : 0;
  }

  openTaxOther(statementAdjustment?: StatementAdjustment) {
    if (this.soaUtils.isAdjustDateNotValid()) {
      this.dialogService.confirm(
        'Information',
        'Cannot enter adjustments until a valid adjustment date is entered',
        true
      );
    } else {
      let taxOther = statementAdjustment ? statementAdjustment.soAdjTaxOther : undefined;
      this.dialogService.matDialogContent({
        content: TaxOtherCostModalComponent,
        context: {
          closingDate: this.adjustmentDate,
          leftColumnListsCreditsTo: this.matter.statementOfAdjustmentHeading.leftColumnListsCreditsTo,
          rightColumnListsCreditsTo: this.matter.statementOfAdjustmentHeading.rightColumnListsCreditsTo,
          hstRate: this.hstRate,
          gstRate: this.federalHstRate,
          taxOther: taxOther,
          taxRateType: this.soaConsiderationTaxes.rateTypeLabel,
          isMatterProvinceMBorSK: this.matter.isMatterProvinceMBorSK,
          sourceFromProject: statementAdjustment && statementAdjustment.isAdjustmentSourceFromProject
        },
        onFulfillment: (rslt) => {
          if (rslt) {
            if (rslt.taxOther) {
              this.soaFulfillmentService.onTaxAdjustmentUpdate(rslt.taxOther, statementAdjustment);
            } else {
              if (statementAdjustment) {
                this.soaUtils.removeStatementAdjustment(statementAdjustment, true);
              }
            }
          }
          this.soaUtils.enableSave().then(() => {
            this.soaUtils.setFocusAfterModalClose();
          });
        }
      });
    }
  }

  openWaterModal(statementAdjustment?: StatementAdjustment): void {
    if (this.soaUtils.isAdjustDateNotValid()) {
      this.dialogService.confirm(
        'Information',
        'Cannot enter adjustments until a valid adjustment date is entered',
        true
      );
    } else {
      let salePrice = this.matter.considerationLtt
        ? this.matter.considerationLtt.creditVendorPrice(this.soaConsiderationTaxes)
        : 0;
      let soaWater = statementAdjustment ? statementAdjustment.soaWater : undefined;
      this.dialogService.matDialogContent({
        content: WaterSoaModal,
        context: {
          closingDate: this.adjustmentDate,
          provinceHstRateSlab: this.soaConsiderationTaxes,
          statementAdjustmentHeading: this.matter.statementOfAdjustmentHeading,
          salePrice: salePrice,
          soaWater: soaWater,
          vendorLabel: this.vendorLabel,
          purchaserLabel: this.purchaserLabel,
          sourceFromProject: statementAdjustment && statementAdjustment.isAdjustmentSourceFromProject,
          matter: this.matter
        },
        onFulfillment: (result) => {
          this.soaFulfillmentService.fulfillWaterModal(result, statementAdjustment);
        }
      });
    }
  }

  openHeatModal(statementAdjustment?: StatementAdjustment): void {
    if (this.soaUtils.isAdjustDateNotValid()) {
      this.dialogService.confirm(
        'Information',
        'Cannot enter adjustments until a valid adjustment date is entered',
        true
      );
    } else {
      let salePrice = this.matter.considerationLtt
        ? this.matter.considerationLtt.creditVendorPrice(this.soaConsiderationTaxes)
        : 0;
      let soaHeat = statementAdjustment ? statementAdjustment.soaHeat : undefined;
      this.dialogService.matDialogContent({
        content: HeatSoaModal,
        context: {
          closingDate: this.adjustmentDate,
          provinceHstRateSlab: this.soaConsiderationTaxes,
          statementAdjustmentHeading: this.matter.statementOfAdjustmentHeading,
          salePrice: salePrice,
          soaHeat: soaHeat,
          vendorLabel: this.vendorLabel,
          purchaserLabel: this.purchaserLabel,
          sourceFromProject: statementAdjustment && statementAdjustment.isAdjustmentSourceFromProject,
          statementAdjustmentDisplayUtil: this.statementAdjustmentDisplayUtil
        },
        onFulfillment: (result) => {
          if (result && result !== ModalResult.Cancel && result !== ModalResult.Delete) {
            this.soaFulfillmentService.onHeatAdjustmentUpdate(result, statementAdjustment);
          } else {
            if (result === ModalResult.Delete) {
              this.soaUtils.removeStatementAdjustment(statementAdjustment, true, true);
            }
          }
          this.soaUtils.enableSave().then(() => {
            this.soaUtils.setFocusAfterModalClose();
          });
        }
      });
    }
  }

  /**
   * prompts the user to enter a valid adjustment date first
   * @returns {boolean}
   */
  confirmAdjustDateValid(): boolean {
    if (this.soaUtils.isAdjustDateNotValid()) {
      this.dialogService.confirm(
        'Information',
        'Cannot enter adjustments until a valid adjustment date is entered',
        true
      );
      return false;
    } else {
      return true;
    }
  }

  openTaxesPaidDuringOccupancyModal(statementAdjustmentKey: string, statementAdjustment?: StatementAdjustment): void {
    let existingTaxPortion = statementAdjustment ? statementAdjustment.taxPortion : undefined;
    if (this.confirmAdjustDateValid()) {
      this.dialogService.matDialogContent({
        content: TaxesPaidDuringOccupancyModalComponent,
        context: {
          matter: this.matter,
          existingTaxPortion: existingTaxPortion,
          sourceFromProject: statementAdjustment && statementAdjustment.isAdjustmentSourceFromProject
        },
        onFulfillment: (result) => {
          if (result && result != ModalResult.Cancel) {
            this.soaFulfillmentService.onTaxesPaidDuringOccupancyUpdate(result, statementAdjustment);
          }
          this.soaUtils.setFocusAfterModalClose();
        }
      });
    }
  }

  openForm4ChargesModal(statementAdjustmentKey: string, statementAdjustment?: StatementAdjustment): void {
    if (this.soaUtils.isAdjustDateNotValid()) {
      this.dialogService.confirm(
        'Information',
        'Cannot enter adjustments until a valid adjustment date is entered',
        true
      );
    } else {
      let soAdjForm4Charge = statementAdjustment ? statementAdjustment.soAdjForm4Charge : undefined;
      this.dialogService.matDialogContent({
        content: Form4ChargeModal,
        context: {
          closingDate: this.adjustmentDate,
          provinceHstRateSlab: this.soaConsiderationTaxes,
          soAdjForm4Charge: soAdjForm4Charge,
          statementAdjustment: statementAdjustment,
          taxRateType: this.soaConsiderationTaxes.rateType,
          matter: this.matter,
          sourceFromProject: statementAdjustment && statementAdjustment.isAdjustmentSourceFromProject
        },
        onFulfillment: (result) => {
          if (result != ModalResult.Cancel) {
            this.soaFulfillmentService.onForm4ChargeUpdate(result, statementAdjustment);
          }

          this.soaUtils.setFocusAfterModalClose();
        }
      });
    }
  }

  openItemizedCreditToVendorPurchaserModal(
    statementAdjustmentKey: string,
    statementAdjustment?: StatementAdjustment
  ): void {
    if (this.soaUtils.isAdjustDateNotValid()) {
      this.dialogService.confirm(
        'Information',
        'Cannot enter adjustments until a valid adjustment date is entered',
        true
      );
    } else {
      let itemizedAdjustmentBackup: StatementAdjustment;
      if (statementAdjustment) {
        itemizedAdjustmentBackup = new StatementAdjustment(
          this.matter.adjustmentStatusMode,
          this.matter.provinceCode,
          statementAdjustment
        );
      }
      this.dialogService.matDialogContent({
        content: ItemizedCreditToVendorPurchaserModalComponent,
        context: {
          closingDate: this.adjustmentDate,
          matter: this.matter,
          project: this.project,
          salePriceAdjustment: this.matter.considerationLtt.salePriceAdjustment,
          itemizedCreditAdjustment: statementAdjustment
            ? statementAdjustment.soAdjItemizedCreditToVendorPurchaser
            : undefined,
          provinceHstRateSlab: this.soaConsiderationTaxes,
          sourceFromProject: statementAdjustment && statementAdjustment.isAdjustmentSourceFromProject
        },
        minWidth: '1037px',
        onFulfillment: (result) => {
          this.soaFulfillmentService.fulfillItemizedCreditToVendor(
            result,
            statementAdjustmentKey,
            itemizedAdjustmentBackup,
            statementAdjustment
          );
        }
      });
    }
  }

  /*
		Please note that InterimOccupancyFee adj and InterimEarlyPossessionFee share the component(modal) used for editing and also data structure
*/
  openInterimOccupancyFeeModal(statementAdjustmentKey: string, statementAdjustment?: StatementAdjustment): void {
    if (this.confirmAdjustDateValid()) {
      let existingInterimFee: SoAdjInterimOccupancyFee | SoAdjInterimEarlyPossessionFee;
      if (statementAdjustmentKey === StatementAdjustmentKey.INTERIM_OCCUPANCY_FEE) {
        existingInterimFee = statementAdjustment ? statementAdjustment.soAdjInterimOccupancyFee : undefined;
      }
      if (statementAdjustmentKey === StatementAdjustmentKey.INTERIM_EARLY_POSSESSION_FEE) {
        existingInterimFee = statementAdjustment ? statementAdjustment.soAdjInterimEarlyPossessionFee : undefined;
      }
      this.dialogService.matDialogContent({
        content: InterimOccupancyFeeModalComponent,
        context: {
          closingDate: this.adjustmentDate,
          existingInterimFee: existingInterimFee,
          adjustmentKey: statementAdjustmentKey,
          project: this.project,
          matter: this.matter,
          soaConsiderationTaxes: this.soaConsiderationTaxes,
          statementAdjustmentDisplayUtil: this.statementAdjustmentDisplayUtil,
          sourceFromProject: statementAdjustment && statementAdjustment.isAdjustmentSourceFromProject
        },
        onFulfillment: (result) => {
          if (result) {
            if (result != ModalResult.Cancel) {
              if (statementAdjustmentKey === StatementAdjustmentKey.INTERIM_OCCUPANCY_FEE) {
                this.soaFulfillmentService.onInterimOccupancyFeeUpdate(
                  result,
                  statementAdjustmentKey,
                  statementAdjustment
                );
              }
              if (statementAdjustmentKey === StatementAdjustmentKey.INTERIM_EARLY_POSSESSION_FEE) {
                this.soaFulfillmentService.onInterimEarlyPossessionFeeUpdate(
                  result,
                  statementAdjustmentKey,
                  statementAdjustment
                );
              }
            }
          }
          this.soaUtils.setFocusAfterModalClose();
        }
      });
    }
  }

  /*
			Please note that FinalOccupancyFee adj and FinalEarlyPossessionFee share the component(modal) used for editing and also data structure
			*/
  openFinalOccupancyFeeModal(statementAdjustmentKey: string, statementAdjustment?: StatementAdjustment): void {
    if (this.confirmAdjustDateValid()) {
      let existingFinalFee: SoAdjFinalOccupancyFee | SoAdjFinalEarlyPossessionFee;
      if (statementAdjustmentKey === StatementAdjustmentKey.FINAL_OCCUPANCY_FEE) {
        existingFinalFee = statementAdjustment ? statementAdjustment.soAdjFinalOccupancyFee : undefined;
      }
      if (statementAdjustmentKey === StatementAdjustmentKey.FINAL_EARLY_POSSESSION_FEE) {
        existingFinalFee = statementAdjustment ? statementAdjustment.soAdjFinalEarlyPossessionFee : undefined;
      }
      this.dialogService.matDialogContent({
        content: FinalOccupancyFeeModalComponent,
        context: {
          closingDate: this.adjustmentDate,
          existingFinalFee: existingFinalFee,
          adjustmentKey: statementAdjustmentKey,
          soaConsiderationTaxes: this.soaConsiderationTaxes,
          project: this.project,
          matter: this.matter,
          statementAdjustmentDisplayUtil: this.statementAdjustmentDisplayUtil,
          sourceFromProject: statementAdjustment && statementAdjustment.isAdjustmentSourceFromProject
        },
        onFulfillment: (result) => {
          if (result) {
            if (result != ModalResult.Cancel) {
              if (statementAdjustmentKey === StatementAdjustmentKey.FINAL_OCCUPANCY_FEE) {
                this.soaFulfillmentService.onFinalOccupancyFeeUpdate(
                  result,
                  statementAdjustmentKey,
                  statementAdjustment
                );
              }
              if (statementAdjustmentKey === StatementAdjustmentKey.FINAL_EARLY_POSSESSION_FEE) {
                this.soaFulfillmentService.onFinalEarlyPossessionFeeUpdate(
                  result,
                  statementAdjustmentKey,
                  statementAdjustment
                );
              }
            }
          }
          this.soaUtils.setFocusAfterModalClose();
        }
      });
    }
  }

  openTotalOccupancyFeeModal(statementAdjustment?: StatementAdjustment): void {
    if (this.confirmAdjustDateValid()) {
      let existingTotalOccFee: SoAdjTotalOccupancyFee = statementAdjustment
        ? statementAdjustment.soAdjTotalOccupancyFee
        : undefined;
      this.dialogService.matDialogContent({
        content: TotalOccupancyFeeModalComponent,
        context: {
          closingDate: this.adjustmentDate,
          statementAdjustmentHeading: this.matter.statementOfAdjustmentHeading,
          existingTotalFee: existingTotalOccFee,
          project: this.project,
          matter: this.matter,
          hideDeleteFromModal: statementAdjustment && !!statementAdjustment.sourceProjectAdjustmentId,
          soaConsiderationTaxes: this.soaConsiderationTaxes,
          statementAdjustmentDisplayUtil: this.statementAdjustmentDisplayUtil,
          sourceFromProject: statementAdjustment && statementAdjustment.isAdjustmentSourceFromProject
        },
        onFulfillment: (result) => {
          if (result) {
            if (result != ModalResult.Cancel) {
              this.soaFulfillmentService.onTotalOccupancyFeeUpdate(result, statementAdjustment);
            }
          }
          this.soaUtils.setFocusAfterModalClose();
        }
      });
    }
  }

  openEditPayableToModal(templateMatter?: Matter) {
    this.dialogService.matDialogContent({
      content: SoaPayableToComponent,
      context: {
        payable: this.matter.statementOfAdjustmentPayable,
        matter: this.matter,
        docProfile: this.soaUtils.matterDocumentProfile,
        defaultDocProfile: this.documentProfileCache.cachedDefaultDocumentProfile,
        templateMatter: templateMatter
      },
      onFulfillment: (result) => {
        if (result && result.action === 'MODIFIED') {
          this.soaUtils.addToDataPropagationCommands(projectConsts.dataPropagationCmdType.SoaPayableTo);
          this.matter.statementOfAdjustmentPayable = new StatementOfAdjustmentPayable(result.payableTo);
          this.soaUtils.enableSave();
        }
      }
    });
  }

  async openResetImportModal(): Promise<void> {
    let projectMatters = (this.tabService.activeTab as ProjectTab).projectMatters;
    this.dialogService.matDialogContent({
      content: ProjectDeleteSoaComponent,
      context: {
        project: this.project,
        numberOfProjectMatters: projectMatters && projectMatters.length
      },
      onFulfillment: (result: any) => {
        this.soaFulfillmentService.resetImportProjectStatementAdjustment(result);
      }
    });
  }

  async openERegConsiderationModal(): Promise<void> {
    //the following method should not be called here, it will overwrite the manually entered value
    //it should ( and already) being called when the Sale price modal is exit through OK.
    this.dialogService.matDialogContent({
      content: ConsiderationLttModalComponent,
      context: {
        prevConsiderationLtt: _.cloneDeep(this.prevConsiderationLtt)
      },
      onFulfillment: (result: any) => {
        if (result && result.prevConsiderationLtt) {
          this.soaUtils.setConsiderationLtt(result);
        }
        this.soaUtils.enableSave(true);
      }
    });
  }

  openLegendModal() {
    this.dialogService.matDialogContent({
      modalGrid: 5,
      content: LegendSoaModal
    });
  }

  openCreditVendorTaxRebateModal(statementAdjustment?: StatementAdjustment): void {
    if (!this.matter.considerationLtt) {
      this.matter.createNewConsiderationLtt();
    }

    if (this.soaUtils.isAdjustDateNotValid()) {
      this.dialogService.confirm(
        'Information',
        'Cannot enter adjustments until a valid adjustment date is entered',
        true
      );
    } else {
      let soAdjCreditVendorTaxRebate: SoAdjCreditVendorTaxRebate = statementAdjustment
        ? statementAdjustment.soAdjCreditVendorTaxRebate
        : undefined;
      this.dialogService.matDialogContent({
        content: CreditVendorTaxRebateModal,
        context: {
          closingDate: this.adjustmentDate,
          provinceHstRateSlab: this.soaConsiderationTaxes,
          soAdjCreditVendorTaxRebate: soAdjCreditVendorTaxRebate,
          statementAdjustment: statementAdjustment,
          salePrice: this.matter.getSalePriceForTaxAdjustments(this.federalHstRate, this.provinceHstRate),
          taxRateType: this.soaConsiderationTaxes.rateType,
          matter: this.matter,
          sourceFromProject: statementAdjustment && statementAdjustment.isAdjustmentSourceFromProject
        },
        onFulfillment: (result) => {
          this.soaFulfillmentService.fulfillCreditVendorTaxRebateModal(result, statementAdjustment);
        }
      });
    }
  }

  openCommonExpensesModal(expenseType: string, statementAdjustment?: StatementAdjustment): void {
    if (this.soaUtils.isAdjustDateNotValid()) {
      this.dialogService.confirm(
        'Information',
        'Cannot enter adjustments until a valid adjustment date is entered',
        true
      );
    } else {
      let soaCommonExpense = statementAdjustment ? statementAdjustment.soaCommonExpense : undefined;
      this.dialogService.matDialogContent({
        content: SoaCommonExpenseModal,
        context: {
          closingDate: this.adjustmentDate,
          provinceHstRateSlab: this.soaConsiderationTaxes,
          soaCommonExpense: soaCommonExpense,
          expenseType: expenseType,
          statementAdjustmentHeading: this.matter.statementOfAdjustmentHeading,
          addedFlag: statementAdjustment ? statementAdjustment.addedFlag : true,
          currentMatter: this.matter,
          isMBEditBtnVisible:
            this.matter.isMatterProvinceMBorSK && statementAdjustment && !statementAdjustment.addedFlag,
          parcelLegalDescriptionId: statementAdjustment ? statementAdjustment.parcelLegalDescriptionId : null,
          isONProject:
            ((this.project && this.project.isCondo) || this.matter.isCondoCorporation) &&
            (this.matter.templateForProject || this.isProjectSale) &&
            ((this.project && this.project.isProjectProvinceON) || this.matter.isMatterProvinceON),
          sourceFromProject: statementAdjustment && statementAdjustment.isAdjustmentSourceFromProject,
          statementAdjustment: statementAdjustment
        },
        onFulfillment: (result) => {
          if (result) {
            this.soaFulfillmentService.onCommonExpenseModalUpdate(
              result.soaCommonExpense,
              result.jumpToSubjectProperty,
              statementAdjustment,
              result.infoOnly
            );
          }

          this.soaUtils.enableSave().then(() => {
            this.soaUtils.setFocusAfterModalClose();
          });
        }
      });
    }
  }

  salePriceAdjustmentNSP(considerationLtt: ConsiderationLtt): number {
    return considerationLtt &&
      considerationLtt.salePriceAdjustment &&
      considerationLtt.salePriceAdjustment.netOutHstFromHSTSalePrice === 'YES_FACTOR_IN_HST_REBATE'
      ? Number(
          considerationLtt.salePriceAdjustment.totalNetSalePrice(
            this.soaConsiderationTaxes.hstFederalPortion,
            this.soaConsiderationTaxes.hstProvincialPortion
          )
        )
      : 0;
  }

  openSalePriceMinusNetSalePriceModal(statementAdjustmentKey: string, statementAdjustment?: StatementAdjustment): void {
    if (this.soaUtils.isAdjustDateNotValid()) {
      this.dialogService.confirm(
        'Information',
        'Cannot enter adjustments until a valid adjustment date is entered',
        true
      );
    } else {
      let salePriceAdjustment = this.matter.considerationLtt.salePriceAdjustment;
      this.dialogService.matDialogContent({
        content: SoaNetSalePriceAdjustmentModalComponent,
        context: {
          closingDate: this.adjustmentDate,
          rateType: this.soaConsiderationTaxes.rateType,
          salePriceAdjustment: salePriceAdjustment,
          totalConsideration: this.salePriceAdjustmentNSP(this.matter.considerationLtt),
          statementAdjustmentHeading: this.matter.statementOfAdjustmentHeading,
          addedFlag: statementAdjustment ? statementAdjustment.addedFlag : true,
          vendorShare: this.soaUtils.calculateSalePriceMinusNetSalePrice(this.matter.considerationLtt),
          sourceFromProject: statementAdjustment && statementAdjustment.isAdjustmentSourceFromProject,
          newAdj: !statementAdjustment
        },
        onFulfillment: (result) => {
          if (result) {
            if (result != ModalResult.Cancel) {
              this.soaUtils.onSalePriceMinusNetSalePriceUpdate(result, statementAdjustment);
            }
          }
          this.soaUtils.enableSave().then(() => {
            this.soaUtils.setFocusAfterModalClose();
          });
        }
      });
    }
  }

  openRealtyTaxesModal(statementAdjustmentKey: string, statementAdjustment?: StatementAdjustment): void {
    if (this.soaUtils.isAdjustDateNotValid()) {
      this.dialogService.confirm(
        'Information',
        'Cannot enter adjustments until a valid adjustment date is entered',
        true
      );
    } else {
      this.dialogService.matDialogContent({
        content: SoaRealtyTaxModalComponent,
        context: {
          closingDate: this.adjustmentDate,
          provinceHstRateSlab: this.soaConsiderationTaxes,
          soaPreviousYearRealtyTaxAdjustment: statementAdjustment && statementAdjustment.soaRealtyTaxAdjustment,
          expenseType: statementAdjustmentKey,
          statementAdjustmentHeading: this.matter.statementOfAdjustmentHeading,
          addedFlag: statementAdjustment ? statementAdjustment.addedFlag : true,
          project: this.isProjectConfigTab ? this.project : this.matter.project,
          isOpenedFromProjectConfig: this.isProjectConfigTab,
          matter: this.matter,
          sourceFromProject: statementAdjustment && statementAdjustment.isAdjustmentSourceFromProject
        },
        onFulfillment: (result) => {
          if (result) {
            if (result != ModalResult.Cancel) {
              this.soaFulfillmentService.onRealtyTaxAdjustmentUpdate(result, statementAdjustment);
            }
          }
          this.soaUtils.enableSave().then(() => {
            this.soaUtils.setFocusAfterModalClose();
          });
        }
      });
    }
  }

  openPurchasePriceDeferredPortionModalModal(statementAdjustment?: StatementAdjustment): void {
    if (this.soaUtils.isAdjustDateNotValid()) {
      this.dialogService.confirm(
        'Information',
        'Cannot enter adjustments until a valid adjustment date is entered',
        true
      );
    } else {
      if (
        this.project &&
        !this.project.isCondo &&
        (this.project.isPOTL || this.project.isOccupancyDateRequired) &&
        this.matter.interimEarlyPossessionFeeSoas &&
        this.matter.interimEarlyPossessionFeeSoas.length <= 0
      ) {
        this.dialogService.confirm(
          'Information',
          'In order to add a "Deferred Portion of Purchase Price" adjustment, an "Early Possession Fees (Interim Closing)" adjustment must first be added.',
          true
        );
      } else {
        let soAdjPurchasePriceDeferredPortion = statementAdjustment
          ? statementAdjustment.soAdjPurchasePriceDeferredPortion
          : undefined;

        this.dialogService.matDialogContent({
          content: PurchasePriceDeferredPortionModal,
          context: {
            closingDate: this.adjustmentDate,
            soAdjPurchasePriceDeferredPortion: soAdjPurchasePriceDeferredPortion,
            matter: this.matter,
            project: this.project,
            sourceFromProject: statementAdjustment && statementAdjustment.isAdjustmentSourceFromProject
          },
          onFulfillment: (result) => {
            if (result) {
              if (result != ModalResult.Cancel) {
                this.soaFulfillmentService.onPurchasePriceDeferredPortionModalUpdate(result, statementAdjustment);
              }
            }
            this.soaUtils.setFocusAfterModalClose();
          }
        });
      }
    }
  }

  //In Progress
  openAmountPaidOnInterimClosingModal(statementAdjustment?: StatementAdjustment): void {
    if (this.soaUtils.isAdjustDateNotValid()) {
      this.dialogService.confirm(
        'Information',
        'Cannot enter adjustments until a valid adjustment date is entered',
        true
      );
    } else {
      let soAdjAmountPaidOnInterimClosing = statementAdjustment
        ? statementAdjustment.soAdjAmountPaidOnInterimClosing
        : undefined;
      if (statementAdjustment) {
        soAdjAmountPaidOnInterimClosing.infoOnly = statementAdjustment.infoOnly;
      }
      this.dialogService.matDialogContent({
        content: AmountPaidOnInterimClosingModalComponent,
        context: {
          closingDate: this.adjustmentDate,
          matter: this.matter,
          amountPaidOnInterimClosing: soAdjAmountPaidOnInterimClosing,
          statementAdjustments: this.matter.uniqueStatementAdjustments,
          salePriceAdjustment: this.matter.considerationLtt && this.matter.considerationLtt.salePriceAdjustment,
          statementAdjustmentDisplayUtil: this.statementAdjustmentDisplayUtil,
          adjustmentStatusMode: this.matter.adjustmentStatusMode,
          provinceCode: this.matter.provinceCode,
          sourceFromProject: statementAdjustment && statementAdjustment.isAdjustmentSourceFromProject
        },
        onFulfillment: (result) => {
          if (result) {
            if (result != ModalResult.Cancel) {
              this.soaFulfillmentService.onAmountPaidOnInterimClosingModalUpdate(result, statementAdjustment);
            }
          }
          this.soaUtils.enableSave().then(() => {
            this.soaUtils.setFocusAfterModalClose();
          });
        }
      });
    }
  }

  openConsiderationPaidOnInterimClosingModal(statementAdjustment?: StatementAdjustment): void {
    if (this.soaUtils.isAdjustDateNotValid()) {
      this.dialogService.confirm(
        'Information',
        'Cannot enter adjustments until a valid adjustment date is entered',
        true
      );
    } else {
      let soAdjConsiderationPaidOnInterimClosing = statementAdjustment
        ? statementAdjustment.soAdjConsiderationPaidOnInterimClosing
        : undefined;
      if (statementAdjustment) {
        soAdjConsiderationPaidOnInterimClosing.infoOnly = statementAdjustment.infoOnly;
      }
      this.dialogService.matDialogContent({
        content: ConsiderationPaidOnInterimClosingModalComponent,
        context: {
          closingDate: this.adjustmentDate,
          considerationPaidOnInterimClosing: soAdjConsiderationPaidOnInterimClosing,
          statementAdjustments: this.matter.uniqueStatementAdjustments,
          salePriceAdjustment: this.matter.considerationLtt && this.matter.considerationLtt.salePriceAdjustment,
          statementAdjustmentDisplayUtil: this.statementAdjustmentDisplayUtil,
          adjustmentStatusMode: this.matter.adjustmentStatusMode,
          provinceCode: this.matter.provinceCode,
          sourceFromProject: statementAdjustment && statementAdjustment.isAdjustmentSourceFromProject
        },
        onFulfillment: (result) => {
          if (result) {
            if (result != ModalResult.Cancel) {
              this.soaFulfillmentService.onConsiderationPaidOnInterimClosingModalUpdate(result, statementAdjustment);
            }
          }
          this.soaUtils.enableSave().then(() => {
            this.soaUtils.setFocusAfterModalClose();
          });
        }
      });
    }
  }

  openTotalDuringOccupancyPeriodAdjustmentModal(statementAdjustment?: StatementAdjustment): void {
    if (this.soaUtils.isAdjustDateNotValid()) {
      this.dialogService.confirm(
        'Information',
        'Cannot enter adjustments until a valid adjustment date is entered',
        true
      );
    } else {
      let soAdjExpenseOccupancyPeriod = statementAdjustment
        ? statementAdjustment.soAdjExpenseOccupancyPeriod
        : undefined;
      this.dialogService.matDialogContent({
        content: TotalDuringOccupancyPeriodModal,
        context: {
          closingDate: this.adjustmentDate,
          soAdjExpenseOccupancyPeriod: soAdjExpenseOccupancyPeriod,
          provinceHstRateSlab: this.soaConsiderationTaxes,
          matter: this.matter,
          isProjectTab: this.isProjectConfigTab,
          sourceFromProject: statementAdjustment && statementAdjustment.isAdjustmentSourceFromProject
        },
        onFulfillment: (result) => {
          if (result) {
            this.soaFulfillmentService.onTotalDuringOccupancyPeriodAdjustmentModalUpdate(
              result.soAdjExpenseOccupancyPeriod,
              statementAdjustment
            );
          }
          this.soaUtils.enableSave().then(() => {
            this.soaUtils.setFocusAfterModalClose();
          });
        }
      });
    }
  }

  openExpenseAdjustmentModal(statementAdjustmentKey: string, statementAdjustment?: StatementAdjustment): void {
    let soaCommonExpenseAmount = this.soaUtils.getPropertyTabTotalCondoExpenseAmount();
    if (this.soaUtils.isAdjustDateNotValid()) {
      this.dialogService.confirm(
        'Information',
        'Cannot enter adjustments until a valid adjustment date is entered',
        true
      );
    } else {
      let soaExpenseAdjustment = statementAdjustment ? statementAdjustment.soaExpenseAdjustment : undefined;
      this.dialogService.matDialogContent({
        content: SoaExpenseAdjustmentModal,
        context: {
          closingDate: this.adjustmentDate,
          provinceHstRateSlab: this.soaConsiderationTaxes,
          soaExpenseAdjustment: soaExpenseAdjustment,
          soaCommonExpenseAmount: soaCommonExpenseAmount,
          expenseType: statementAdjustmentKey,
          statementAdjustmentHeading: this.matter.statementOfAdjustmentHeading,
          addedFlag: statementAdjustment ? statementAdjustment.addedFlag : true,
          matter: this.matter,
          sourceFromProject: statementAdjustment && statementAdjustment.isAdjustmentSourceFromProject
        },
        onFulfillment: (result) => {
          if (result) {
            if (result != ModalResult.Cancel) {
              this.soaUtils.onExpenseAdjustmentUpdate(result, statementAdjustment);
            }
          }
          this.soaUtils.enableSave().then(() => {
            this.soaUtils.setFocusAfterModalClose();
          });
        }
      });
    }
  }

  openInterestModal(statementAdjustmentKey: string, statementAdjustment?: StatementAdjustment): void {
    if (this.soaUtils.isAdjustDateNotValid()) {
      this.dialogService.confirm(
        'Information',
        'Cannot enter adjustments until a valid adjustment date is entered',
        true
      );
    } else {
      let existingInterestSoa = statementAdjustment ? statementAdjustment.soAdjInterestCal : undefined;
      this.dialogService.matDialogContent({
        content: SoaInterestModal,
        context: {
          closingDate: this.adjustmentDate,
          interestSoa: existingInterestSoa,
          statementAdjustmentHeading: this.matter.statementOfAdjustmentHeading,
          isPaysForDateOfClosingVendor: this.matter.isPaysForDateOfClosingVendor,
          project: this.project,
          matter: this.matter,
          statementAdjustmentKey: statementAdjustmentKey,
          sourceFromProject: statementAdjustment && statementAdjustment.isAdjustmentSourceFromProject
        },
        minWidth: '1037px',
        onFulfillment: (result) => {
          if (result) {
            this.soaFulfillmentService.onInterestAdjustmentUpdate(result, statementAdjustmentKey, statementAdjustment);
          }
          this.soaUtils.enableSave().then(() => {
            this.soaUtils.setFocusAfterModalClose();
          });
        }
      });
    }
  }

  openInterestOnDepositCondoModal(adjustmentKey: string, statementAdjustment?: StatementAdjustment): void {
    if (this.soaUtils.isAdjustDateNotValid()) {
      this.dialogService.confirm(
        'Information',
        'Cannot enter adjustments until a valid adjustment date is entered',
        true
      );
    } else {
      let soAdjInterestOnDepositCondo = statementAdjustment
        ? statementAdjustment.soAdjInterestOnDepositCondo
        : undefined;
      if (soAdjInterestOnDepositCondo) {
        soAdjInterestOnDepositCondo.infoOnly = statementAdjustment.infoOnly;
      }
      this.dialogService.matDialogContent({
        content: InterestOnDepositCondoModal,
        context: {
          closingDate: this.adjustmentDate,
          soAdjInterestOnDepositCondo: soAdjInterestOnDepositCondo,
          statementAdjustmentHeading: this.matter.statementOfAdjustmentHeading,
          isPaysForDateOfClosingVendor: this.matter.isPaysForDateOfClosingVendor,
          project: this.project,
          matter: this.matter,
          adjustmentKey: adjustmentKey,
          sourceFromProject: statementAdjustment && statementAdjustment.isAdjustmentSourceFromProject
        },
        onFulfillment: (result) => {
          if (result) {
            if (statementAdjustment && statementAdjustment.soAdjInterestOnDepositCondo) {
              statementAdjustment.soAdjInterestOnDepositCondo = new SoAdjInterestOnDepositCondo(result);
              statementAdjustment.amount = statementAdjustment.soAdjInterestOnDepositCondo.getTotalInterestOnDeposit(
                this.matter,
                statementAdjustment.isAdjustmentStatusFinal()
              );
              statementAdjustment.description = statementAdjustment.soAdjInterestOnDepositCondo.titleOnAdjustment;
              statementAdjustment.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.PURCHASER;
              statementAdjustment.infoOnly = statementAdjustment.soAdjInterestOnDepositCondo.infoOnly;
            } else {
              let statementAdjustment = new StatementAdjustment(
                this.matter.adjustmentStatusMode,
                this.matter.provinceCode
              );
              statementAdjustment.itemKey = adjustmentKey;
              statementAdjustment.soAdjInterestOnDepositCondo = new SoAdjInterestOnDepositCondo(result);
              statementAdjustment.soAdjInterestOnDepositCondo.id = undefined;
              statementAdjustment.description = statementAdjustment.soAdjInterestOnDepositCondo.titleOnAdjustment;
              statementAdjustment.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.PURCHASER;
              statementAdjustment.addedFlag = true;
              statementAdjustment.infoOnly = statementAdjustment.soAdjInterestOnDepositCondo.infoOnly;
              this.soaUtils.selectedItem(statementAdjustment);
              statementAdjustment.amount = statementAdjustment.soAdjInterestOnDepositCondo.getTotalInterestOnDeposit(
                this.matter,
                statementAdjustment.isAdjustmentStatusFinal()
              );
            }
          }
          this.soaUtils.enableSave().then(() => {
            this.soaUtils.setFocusAfterModalClose();
          });
        }
      });
    }
  }

  openOtherFixedModal(statementAdjustmentKey: string, statementAdjustment?: StatementAdjustment): void {
    if (this.confirmAdjustDateValid()) {
      let existingOtherFixedSoa = statementAdjustment ? statementAdjustment.soAdjOtherFixed : undefined;
      this.dialogService.matDialogContent({
        content: SoaOtherFixedModal,
        context: {
          closingDate: this.adjustmentDate,
          otherFixedSoa: existingOtherFixedSoa,
          statementAdjustmentHeading: this.matter.statementOfAdjustmentHeading,
          defaultHstRate: this.matter.soaHst,
          defaultPstRate: this.matter.soaProvincialHst,
          defaultGstRate: this.matter.soaFederalHst,
          soaConsiderationTaxes: this.soaConsiderationTaxes,
          purchaseIsOfANewHomeFromABuilder: this.matter && this.matter.purchaseIsOfANewHomeFromABuilder,
          salePriceAdjustmentFactorsInHstRebate: this.matter.considerationLtt.salePriceAdjustment.isInclusivePrice(),
          provinceCode: this.matter.provinceCode,
          isOtherFixedAdjAvailable: StatementAdjustmentUtil.anyOtherFixedAdjustmentsWithAnyConsideration(
            this.matter.statementOfAdjustments
          ),
          aTarionWarrantyWithConsiderationAlreadyExists:
            StatementAdjustmentUtil.isTarionWarrantyAdjAvailableAndWithConsideration(
              this.matter.statementOfAdjustments
            ),
          additionalConsiderationsInclHst:
            this.matter.considerationLtt.salePriceAdjustment.additionalConsiderationsInclHst,
          aHCRAWithConsiderationAlreadyExists: StatementAdjustmentUtil.isHCRAAdjAvailableAndWithConsideration(
            this.matter.statementOfAdjustments
          ),
          matter: this.matter,
          project: this.project,
          statementAdjustmentDisplayUtil: this.statementAdjustmentDisplayUtil,
          sourceFromProject: statementAdjustment && statementAdjustment.isAdjustmentSourceFromProject,
          statementAdjustmentKey: statementAdjustmentKey
        },
        onFulfillment: (result) => {
          this.soaFulfillmentService.fulfillOtherFixedAdjustment(result, statementAdjustment);
        }
      });
    }
  }

  openOtherFixedPayableOnOccupancyModal(
    statementAdjustmentKey: string,
    statementAdjustment?: StatementAdjustment
  ): void {
    if (this.confirmAdjustDateValid()) {
      let existingOtherFixedSoa = statementAdjustment
        ? statementAdjustment.soAdjOtherFixedPayableOnOccupancy
        : undefined;
      this.dialogService.matDialogContent({
        content: SoaOtherFixedModal,
        context: {
          closingDate: this.adjustmentDate,
          otherFixedSoa: existingOtherFixedSoa,
          statementAdjustmentHeading: this.matter.statementOfAdjustmentHeading,
          provinceDefaultTaxRate: this.soaConsiderationTaxes.provinceDefaultTaxRate,
          defaultHstRate: this.matter.soaHst,
          defaultPstRate: this.matter.soaProvincialHst,
          defaultGstRate: this.matter.soaFederalHst,
          taxRateType: this.soaConsiderationTaxes.rateType,
          purchaseIsOfANewHomeFromABuilder: this.matter && this.matter.purchaseIsOfANewHomeFromABuilder,
          salePriceAdjustmentFactorsInHstRebate: this.matter.considerationLtt.salePriceAdjustment.isInclusivePrice(),
          provinceCode: this.matter.provinceCode,
          isOtherFixedAdjAvailable: StatementAdjustmentUtil.anyOtherFixedAdjustmentsWithAnyConsideration(
            this.matter.statementOfAdjustments
          ),
          aTarionWarrantyWithConsiderationAlreadyExists:
            StatementAdjustmentUtil.isTarionWarrantyAdjAvailableAndWithConsideration(
              this.matter.statementOfAdjustments
            ),
          additionalConsiderationsInclHst:
            this.matter.considerationLtt.salePriceAdjustment.additionalConsiderationsInclHst,
          aHCRAWithConsiderationAlreadyExists: StatementAdjustmentUtil.isHCRAAdjAvailableAndWithConsideration(
            this.matter.statementOfAdjustments
          ),
          matter: this.matter,
          project: this.project,
          statementAdjustmentKey: statementAdjustmentKey,
          soaConsiderationTaxes: this.soaConsiderationTaxes,
          statementAdjustmentDisplayUtil: this.statementAdjustmentDisplayUtil,
          sourceFromProject: statementAdjustment && statementAdjustment.isAdjustmentSourceFromProject
        },
        onFulfillment: (result) => {
          this.soaFulfillmentService.fulfillOtherFixedPayableOnOccupancyAdjustment(result, statementAdjustment);
        }
      });
    }
  }

  openComponentAdjustmentModal(statementAdjustmentKey: string, statementAdjustment?: StatementAdjustment): void {
    if (this.confirmAdjustDateValid()) {
      let existinComponentSoa = statementAdjustment ? statementAdjustment.soAdjComponent : undefined;
      this.dialogService.matDialogContent({
        content: ComponentAdjustmentModalComponent,
        context: {
          closingDate: this.adjustmentDate,
          componentSoa: existinComponentSoa,
          matter: this.matter,
          sourceFromProject: statementAdjustment && statementAdjustment.isAdjustmentSourceFromProject
        },
        onFulfillment: (result) => {
          if (result) {
            if (result.action == ModalResult.OK) {
              this.soaFulfillmentService.onComponentAdjustmentUpdate(
                result,
                statementAdjustmentKey,
                statementAdjustment
              );
              this.soaUtils.enableSave().then(() => {
                this.soaUtils.setFocusAfterModalClose();
              });
            } else if (result.action == ModalResult.Delete) {
              if (statementAdjustment) {
                (<any>this.matter.statementOfAdjustments).remove(statementAdjustment);
              }
              this.soaUtils.enableSave().then(() => {
                this.soaUtils.setFocusAfterModalClose();
              });
            }
          }
          this.soaUtils.setFocusAfterModalClose();
        }
      });
    }
  }

  openOtherProratedModal(statementAdjustmentKey: string, statementAdjustment?: StatementAdjustment): void {
    if (this.confirmAdjustDateValid()) {
      let existingOtherProrated = statementAdjustment ? statementAdjustment.soAdjOtherProrated : undefined;
      this.dialogService.matDialogContent({
        content: OtherProratedModalComponent,
        context: {
          closingDate: this.adjustmentDate,
          otherProrated: existingOtherProrated,
          provinceDefaultTaxRate: this.soaConsiderationTaxes.provinceDefaultTaxRate,
          taxRateType: this.soaConsiderationTaxes.rateType,
          statementAdjustmentHeading: this.matter.statementOfAdjustmentHeading,
          matter: this.matter,
          statementAdjustmentKey: statementAdjustmentKey,
          addedFlag: statementAdjustment ? statementAdjustment.addedFlag : true,
          sourceFromProject: statementAdjustment && statementAdjustment.isAdjustmentSourceFromProject
        },
        onFulfillment: (result) => {
          if (result) {
            if (result != ModalResult.Cancel) {
              if (result == ModalResult.Delete) {
                if (statementAdjustment) {
                  this.soaUtils.removeStatementAdjustment(statementAdjustment, true, true);
                }
              } else {
                this.soaFulfillmentService.onOtherProRatedAdjustmentUpdate(
                  statementAdjustmentKey,
                  result,
                  statementAdjustment
                );
              }
              this.soaUtils.enableSave().then(() => {
                this.soaUtils.setFocusAfterModalClose();
              });
            }
          }
          this.soaUtils.setFocusAfterModalClose();
        }
      });
    }
  }

  openOtherProratedOnPercentageInterest(
    statementAdjustmentKey: string,
    statementAdjustment?: StatementAdjustment
  ): void {
    if (this.confirmAdjustDateValid()) {
      let existingOtherFixedSoa = statementAdjustment
        ? statementAdjustment.soAdjOtherProratedOnPercentageInterest
        : undefined;
      this.dialogService.matDialogContent({
        content: SoaOtherFixedModal,
        context: {
          closingDate: this.adjustmentDate,
          otherFixedSoa: existingOtherFixedSoa,
          statementAdjustmentHeading: this.matter.statementOfAdjustmentHeading,
          provinceDefaultTaxRate: this.soaConsiderationTaxes.provinceDefaultTaxRate,
          defaultHstRate: this.matter.soaHst,
          defaultPstRate: this.matter.soaProvincialHst,
          defaultGstRate: this.matter.soaFederalHst,
          taxRateType: this.soaConsiderationTaxes.rateType,
          purchaseIsOfANewHomeFromABuilder: this.matter && this.matter.purchaseIsOfANewHomeFromABuilder,
          salePriceAdjustmentFactorsInHstRebate: this.matter.considerationLtt.salePriceAdjustment.isInclusivePrice(),
          provinceCode: this.matter.provinceCode,
          isOtherFixedAdjAvailable: StatementAdjustmentUtil.anyOtherFixedAdjustmentsWithAnyConsideration(
            this.matter.statementOfAdjustments
          ),
          aTarionWarrantyWithConsiderationAlreadyExists:
            StatementAdjustmentUtil.isTarionWarrantyAdjAvailableAndWithConsideration(
              this.matter.statementOfAdjustments
            ),
          additionalConsiderationsInclHst:
            this.matter.considerationLtt.salePriceAdjustment.additionalConsiderationsInclHst,
          aHCRAWithConsiderationAlreadyExists: StatementAdjustmentUtil.isHCRAAdjAvailableAndWithConsideration(
            this.matter.statementOfAdjustments
          ),
          matter: this.matter,
          project: this.project,
          statementAdjustmentKey: statementAdjustmentKey,
          soaConsiderationTaxes: this.soaConsiderationTaxes,
          statementAdjustmentDisplayUtil: this.statementAdjustmentDisplayUtil,
          sourceFromProject: statementAdjustment && statementAdjustment.isAdjustmentSourceFromProject
        },
        onFulfillment: (result) => {
          if (result) {
            this.soaFulfillmentService.fulfillOtherProratedOnPercentageInterestModal(
              result,
              statementAdjustmentKey,
              statementAdjustment
            );
          }
          this.soaUtils.setFocusAfterModalClose();
        }
      });
    }
  }

  openPurchasePricePercentage(statementAdjustmentKey: string, statementAdjustment?: StatementAdjustment): void {
    if (this.confirmAdjustDateValid()) {
      let soAdjPurchasePricePercentage = statementAdjustment
        ? statementAdjustment.soAdjPurchasePricePercentage
        : undefined;
      this.dialogService.matDialogContent({
        content: SoaOtherPurchasePricePercentageModal,
        context: {
          closingDate: this.adjustmentDate,
          soAdjPurchasePricePercentage: soAdjPurchasePricePercentage,
          statementAdjustmentHeading: this.matter.statementOfAdjustmentHeading,
          provinceDefaultTaxRate: this.soaConsiderationTaxes.provinceDefaultTaxRate,
          defaultHstRate: this.matter.soaHst,
          defaultPstRate: this.matter.soaProvincialHst,
          defaultGstRate: this.matter.soaFederalHst,
          taxRateType: this.soaConsiderationTaxes.rateType,
          provinceCode: this.matter.provinceCode,
          additionalConsiderationsInclHst:
            this.matter.considerationLtt.salePriceAdjustment.additionalConsiderationsInclHst,
          matter: this.matter,
          project: this.project,
          statementAdjustmentKey: statementAdjustmentKey,
          soaConsiderationTaxes: this.soaConsiderationTaxes,
          statementAdjustmentDisplayUtil: this.statementAdjustmentDisplayUtil,
          sourceFromProject: statementAdjustment && statementAdjustment.isAdjustmentSourceFromProject
        },
        onFulfillment: (result) => {
          if (result) {
            if (result != ModalResult.Cancel) {
              this.soaFulfillmentService.onOtherPurchasePricePercentageAdjustmentUpdate(
                statementAdjustmentKey,
                result,
                statementAdjustment
              );
            }
            this.soaUtils.enableSave().then(() => {
              this.soaUtils.setFocusAfterModalClose();
            });
          }
          this.soaUtils.setFocusAfterModalClose();
        }
      });
    }
  }

  openHomeOwnersResidentFeeModal(statementAdjustmentKey: string, statementAdjustment?: StatementAdjustment): void {
    if (this.confirmAdjustDateValid()) {
      let existingHomeOwnersResidentFee = statementAdjustment
        ? statementAdjustment.soAdjHomeOwnersResidentFee
        : undefined;
      this.dialogService.matDialogContent({
        content: OtherProratedModalComponent,
        context: {
          closingDate: this.adjustmentDate,
          otherProrated: existingHomeOwnersResidentFee,
          provinceDefaultTaxRate: this.soaConsiderationTaxes.provinceDefaultTaxRate,
          taxRateType: this.soaConsiderationTaxes.rateType,
          statementAdjustmentHeading: this.matter.statementOfAdjustmentHeading,
          matter: this.matter,
          statementAdjustmentKey: statementAdjustmentKey,
          addedFlag: statementAdjustment ? statementAdjustment.addedFlag : true,
          sourceFromProject: statementAdjustment && statementAdjustment.isAdjustmentSourceFromProject
        },
        onFulfillment: (result) => {
          if (result) {
            if (result != ModalResult.Cancel) {
              this.soaFulfillmentService.onHomeOwnersResidentFeeModalUpdate(
                result,
                statementAdjustmentKey,
                statementAdjustment
              );
              this.soaUtils.enableSave().then(() => {
                this.soaUtils.setFocusAfterModalClose();
              });
            }
          }
          this.soaUtils.setFocusAfterModalClose();
        }
      });
    }
  }

  openTenancyCurrentModal(statementAdjustment?: StatementAdjustment): void {
    if (this.confirmAdjustDateValid()) {
      let existingAdj = statementAdjustment ? statementAdjustment.soAdjTenancyCurrent : undefined;
      this.dialogService.matDialogContent({
        content: TenancyCurrentModalComponent,
        context: {
          closingDate: this.adjustmentDate,
          adjustment: existingAdj,
          statementAdjustmentHeading: this.matter.statementOfAdjustmentHeading,
          provinceDefaultTaxRate: this.soaConsiderationTaxes.provinceDefaultTaxRate,
          defaultHstRate: this.matter.soaHst,
          defaultPstRate: this.matter.soaProvincialHst,
          defaultGstRate: this.matter.soaFederalHst,
          statementAdjustmentDisplayUtil: this.statementAdjustmentDisplayUtil,
          taxRateType: this.soaConsiderationTaxes.rateType,
          currentMatter: this.matter,
          sourceFromProject: statementAdjustment && statementAdjustment.isAdjustmentSourceFromProject
        },
        onFulfillment: (result) => {
          if (result) {
            if (result != ModalResult.Cancel) {
              if (result == ModalResult.Delete) {
                if (statementAdjustment) {
                  this.soaUtils.removeStatementAdjustment(statementAdjustment, true, true);
                }
              } else {
                this.soaFulfillmentService.onTenancyCurrentUpdate(result, statementAdjustment);
              }
              this.soaUtils.enableSave().then(() => {
                this.soaUtils.setFocusAfterModalClose();
              });
            }
          }
          this.soaUtils.setFocusAfterModalClose();
        }
      });
    }
  }

  openTenancyPrepaidModal(viewContainerRef: ViewContainerRef, statementAdjustment?: StatementAdjustment): void {
    if (this.confirmAdjustDateValid()) {
      let existingAdj = statementAdjustment ? statementAdjustment.soAdjTenancyPrepaid : undefined;
      this.dialogService.matDialogContent({
        content: TenancyPrepaidModalComponent,
        context: {
          closingDate: this.adjustmentDate,
          adjustment: existingAdj,
          statementAdjustmentHeading: this.matter.statementOfAdjustmentHeading,
          defaultHstRate: this.matter.soaHst,
          defaultPstRate: this.matter.soaProvincialHst,
          defaultGstRate: this.matter.soaFederalHst,
          statementAdjustmentDisplayUtil: this.statementAdjustmentDisplayUtil,
          viewContainerRef,
          soaConsiderationTaxes: this.soaConsiderationTaxes,
          currentMatter: this.matter,
          sourceFromProject: statementAdjustment && statementAdjustment.isAdjustmentSourceFromProject,
          docProfile: this.soaUtils.matterDocumentProfile
        },
        onFulfillment: (result) => {
          if (result) {
            if (result != ModalResult.Cancel) {
              if (result == ModalResult.Delete) {
                if (statementAdjustment) {
                  this.soaUtils.removeStatementAdjustment(statementAdjustment, true, true);
                }
              } else {
                this.soaFulfillmentService.onTenancyPrepiadUpdate(result, statementAdjustment);
              }
              this.soaUtils.enableSave().then(() => {
                this.soaUtils.setFocusAfterModalClose();
              });
            }
          }
          this.soaUtils.setFocusAfterModalClose();
        }
      });
    }
  }

  openAssumedMortgageModal(statementAdjustment?: StatementAdjustment): void {
    if (this.confirmAdjustDateValid()) {
      let existingAdj = statementAdjustment ? statementAdjustment.soAdjAssumedMortgage : undefined;
      this.dialogService.matDialogContent({
        content: SoAdjAssumedMortgageModalComponent,
        context: {
          closingDate: this.adjustmentDate,
          adjustment: existingAdj,
          statementAdjustmentDisplayUtil: this.statementAdjustmentDisplayUtil,
          isPaysForDateOfClosingVendor: this.matter.isPaysForDateOfClosingVendor,
          sourceFromProject: statementAdjustment && statementAdjustment.isAdjustmentSourceFromProject
        },
        onFulfillment: (result) => {
          if (result) {
            if (result != ModalResult.Cancel) {
              if (result == ModalResult.Delete) {
                if (statementAdjustment) {
                  this.soaUtils.removeStatementAdjustment(statementAdjustment, true, true);
                }
              } else {
                this.soaFulfillmentService.onAssumedMortgageUpdate(result, statementAdjustment);
              }
              this.soaUtils.enableSave().then(() => {
                this.soaUtils.setFocusAfterModalClose();
              });
            }
          }
          this.soaUtils.setFocusAfterModalClose();
        }
      });
    }
  }

  openHCRAFeeModal(statementAdjustment?: StatementAdjustment): void {
    if (this.confirmAdjustDateValid()) {
      let soAdjHCRAFee: SoAdjHCRAFee = statementAdjustment ? statementAdjustment.soAdjHCRAFee : undefined;

      this.dialogService.matDialogContent({
        content: HCRAFeeModalComponent,
        context: {
          closingDate: this.adjustmentDate,
          adjustment: soAdjHCRAFee,
          salePriceAdjustment: this.matter.considerationLtt.salePriceAdjustment,
          statementAdjustmentDisplayUtil: this.statementAdjustmentDisplayUtil,
          taxRateType: this.soaConsiderationTaxes.rateType,
          project: this.isProjectConfigTab ? this.project : this.matter.project,
          sourceFromProject: statementAdjustment && statementAdjustment.isAdjustmentSourceFromProject,
          aTarionWarrantyWithConsiderationAlreadyExists:
            StatementAdjustmentUtil.isTarionWarrantyAdjAvailableAndWithConsideration(
              this.matter.statementOfAdjustments
            ),
          isOtherFixedAdjAvailable: StatementAdjustmentUtil.anyOtherFixedAdjustmentsWithAnyConsideration(
            this.matter.statementOfAdjustments
          ),
          aHCRAWithConsiderationAlreadyExists: StatementAdjustmentUtil.isHCRAAdjAvailableAndWithConsideration(
            this.matter.statementOfAdjustments
          )
        },
        onFulfillment: (result) => {
          if (result) {
            if (result != ModalResult.Cancel) {
              this.soaFulfillmentService.fullFillHCRAFee(result, statementAdjustment);
            }
          }
          this.soaUtils.setFocusAfterModalClose();
        }
      });
    }
  }

  openVacantOccupancyTaxModal(statementAdjustment?: StatementAdjustment): void {
    if (this.confirmAdjustDateValid()) {
      let soAdjVacantOccupancyTax: SoAdjVacantOccupancyTax = statementAdjustment
        ? statementAdjustment.soAdjVacantOccupancyTax
        : undefined;

      this.dialogService.matDialogContent({
        content: VacantOccupancyTaxModalComponent,
        context: {
          closingDate: this.adjustmentDate,
          adjustment: soAdjVacantOccupancyTax,
          statementAdjustmentDisplayUtil: this.statementAdjustmentDisplayUtil,
          statementAdjustmentHeading: this.matter.statementOfAdjustmentHeading
        },
        onFulfillment: (result) => {
          if (result) {
            if (result != ModalResult.Cancel) {
              this.soaFulfillmentService.fullFillVacantOccupancyTax(result, statementAdjustment);
            }
          }
          this.soaUtils.setFocusAfterModalClose();
        }
      });
    }
  }

  openTarionWarrantyModal(statementAdjustment?: StatementAdjustment): void {
    if (this.confirmAdjustDateValid()) {
      let existingAdj: SoAdjTarionWarranty = statementAdjustment ? statementAdjustment.soAdjTarionWarranty : undefined;

      this.dialogService.matDialogContent({
        content: TarionWarrantyModalComponent,
        context: {
          closingDate: this.adjustmentDate,
          adjustment: existingAdj,
          salePriceAdjustment: this.matter.considerationLtt.salePriceAdjustment,
          statementAdjustmentDisplayUtil: this.statementAdjustmentDisplayUtil,
          aTarionWarrantyWithConsiderationAlreadyExists:
            StatementAdjustmentUtil.isTarionWarrantyAdjAvailableAndWithConsideration(
              this.matter.statementOfAdjustments
            ),
          isOtherFixedAdjAvailable: StatementAdjustmentUtil.anyOtherFixedAdjustmentsWithAnyConsideration(
            this.matter.statementOfAdjustments
          ),
          aHCRAWithConsiderationAlreadyExists: StatementAdjustmentUtil.isHCRAAdjAvailableAndWithConsideration(
            this.matter.statementOfAdjustments
          ),
          taxRateType: this.soaConsiderationTaxes.rateType,
          project: this.isProjectConfigTab ? this.project : this.matter.project,
          sourceFromProject: statementAdjustment && statementAdjustment.isAdjustmentSourceFromProject
        },
        onFulfillment: (result) => {
          if (result) {
            if (result != ModalResult.Cancel) {
              this.soaFulfillmentService.fullFillTarionWarranty(result, statementAdjustment);
            }
          }
          this.soaUtils.setFocusAfterModalClose();
        }
      });
    }
  }

  openVTBMorgageModal(statementAdjustment?: StatementAdjustment): void {
    let existingAdj: SoAdjVTBMortgage = statementAdjustment ? statementAdjustment.soAdjVTBMortgage : undefined;
    if (existingAdj) {
      existingAdj.infoOnly = statementAdjustment.infoOnly;
      let mortgage = this.matter.mortgages.find((mrtg) => mrtg.id == existingAdj.mortgageId);
      if (mortgage) {
        this.dialogService.matDialogContent({
          content: SoAdjVtbMortgageModalComponent,
          context: {
            closingDate: this.adjustmentDate,
            mortgagePriority: mortgage.mortgagePriority,
            principalAmount: this.matter.getMortgagePrincipalByMortgageId(mortgage.id),
            adjustment: existingAdj,
            statementAdjustmentDisplayUtil: this.statementAdjustmentDisplayUtil,
            matter: this.matter,
            mortgage: mortgage,
            sourceFromProject: statementAdjustment && statementAdjustment.isAdjustmentSourceFromProject
          },
          onFulfillment: (result) => {
            if (result) {
              if (result != ModalResult.Cancel) {
                if (statementAdjustment && statementAdjustment.soAdjVTBMortgage) {
                  statementAdjustment.soAdjVTBMortgage = new SoAdjVTBMortgage(result);
                  statementAdjustment.infoOnly = statementAdjustment.soAdjVTBMortgage.infoOnly;
                  statementAdjustment.description = statementAdjustment.soAdjVTBMortgage.heading;
                  this.soaUtils.enableSave().then(() => {
                    this.soaUtils.setFocusAfterModalClose();
                  });
                }
              }
            }
            this.soaUtils.setFocusAfterModalClose();
          }
        });
      }
    }
  }

  editFootersWithReset(projectLevelStatementAdjustmentFooters: StatementAdjustmentConfig[]): void {
    if (this.matter.matterStatementOfAdjustmentFooters) {
      this.prevMatterStatementOfAdjustmentFooters = _.cloneDeep(this.matter.matterStatementOfAdjustmentFooters);
      this.dialogService.matDialogContent({
        content: StatementAdjustmentConfigComponent,
        context: {
          matterStatementAdjustmentConfigList: this.matter.matterStatementOfAdjustmentFooters.slice(),
          projectStatementAdjustmentConfigList: projectLevelStatementAdjustmentFooters,
          matterNo: this.matter.matterRecordNumber,
          projectRecordNo: this.project ? this.project.projectRecordNumber : '',
          isModalOpenFromMatter: true,
          provinceCode: !this.isProjectConfigTab ? this.matter.provinceCode : this.project.provinceCode,
          isProjectSale: this.isProjectSale,
          isProject: this.isProjectConfigTab,
          isCondoProjectSale: this.matter.matterPropertyWithCondo.isCondominium === DpBooleanValueTypes.YES
        },
        onFulfillment: (statementAdjustmentConfigList: StatementAdjustmentConfig[]) => {
          if (statementAdjustmentConfigList) {
            this.matter.matterStatementOfAdjustmentFooters = statementAdjustmentConfigList;
            if (this.isProjectConfigTab) {
              this.soaUtils.addToDataPropagationCommands(
                projectConsts.dataPropagationCmdType.statementAdjustmentFooters
              );
            }

            this.soaUtils.enableSave();
          } else {
            //in case if nothing is saved in popup
            this.matter.matterStatementOfAdjustmentFooters = _.cloneDeep(this.prevMatterStatementOfAdjustmentFooters);
          }
        }
      });
    }
  }

  openTaxRateModal(openTaxRateConfirmationBox: boolean): void {
    this.dialogService.matDialogContent({
      content: TaxRatesComponent,
      context: {
        soaHst: this.matter.soaHst,
        matterHst: this.matter.matterHst,
        considerationTaxRateSlabs: this.soaUtils.taxRateSlabs,
        selectedHstRateSlab: this.soaConsiderationTaxes,
        openConfirmationBox: openTaxRateConfirmationBox,
        taxRateType: this.matter.matterTaxType,
        taxRateOpenFrom: taxRateOpenFromValues.statementOfAdjustment,
        provinceCode: this.matter.provinceCode
      },
      onFulfillment: (considerationTaxes: ConsiderationTaxes) => {
        if (considerationTaxes) {
          this.soaFulfillmentService.fulfillTaxRateModal(considerationTaxes);
        }
      }
    });
  }

  get hasMultipleSOAHeadings(): boolean {
    let isABorON: boolean = this.matter.isMatterProvinceAB || this.matter.isMatterProvinceON;
    if (isABorON && this.isProjectOrProjectSale) {
      return (
        this.project &&
        (this.project.isCondo ||
          (isABorON && !this.project.isCondo && this.project.occupancyDateRequired) ||
          (isABorON && !this.project.isCondo && this.project.isPOTL))
      );
    }
    return false;
  }

  get isSoaHeadingWithVendorSolicitor(): boolean {
    let isABorON: boolean = this.matter.isMatterProvinceAB || this.matter.isMatterProvinceON;
    if (isABorON && this.isProjectOrProjectSale) {
      return (
        this.project &&
        (!this.project.isCondo ||
          (isABorON && !this.project.occupancyDateRequired) ||
          (isABorON && !this.project.isCondoOrPotl))
      );
    }
    return false;
  }

  editHeadingInfo2(): void {
    const selectedIdx: number = this.soaUtils.selectedIndex; //selectedIndex is an expensive getter, so call it only needed
    let statementAdjustmentDisplayItem: StatementAdjustmentDisplayItem =
      selectedIdx >= 0 && this.matter.isProjectSale ? this.soaUtils.orderedStatementDisplayItems[selectedIdx] : null;
    let statementOfAdjustment: StatementAdjustment = statementAdjustmentDisplayItem
      ? statementAdjustmentDisplayItem.soaItem
      : null;
    this.dialogService.matDialogContent({
      content: StatementAdjustmentHeadingComponent,
      context: {
        statementAdjustmentHeadingMatter: this.matter.statementOfAdjustmentHeading,
        statementAdjustmentHeadingProject:
          this.soaUtils.projectTemplateMatter && this.soaUtils.projectTemplateMatter.statementOfAdjustmentHeading,
        documentProfile: this.soaUtils.matterDocumentProfile,
        defaultDocumentProfile: this.documentProfileCache.cachedDefaultDocumentProfile,
        matterType: this.matter.matterType,
        isGlobalFormatVisible: this.isProjectOrProjectSale,
        isGlobalFormatReadonly: !this.isProjectConfigTab,
        hasMultipleSOAHeadings: this.hasMultipleSOAHeadings,
        isSoaHeadingWithVendorSolicitor: this.isSoaHeadingWithVendorSolicitor,
        isProjectSaleMatter: this.isProjectSale,
        selectedStatementAdjustment: statementOfAdjustment
      },
      onFulfillment: (statementAdjustmentHeading: StatementOfAdjustmentHeading) => {
        this.soaFulfillmentService.onHeadingInfoUpdate(statementAdjustmentHeading, true);
      }
    });
  }

  editStatementAdjustmentFormat(statementAdjustment: StatementAdjustment): void {
    this.dialogService.matDialogContent({
      content: StatementAdjustmentFormatModalComponent,
      context: {
        statementAdjustmentHeadingMatter: this.matter.statementOfAdjustmentHeading,
        statementAdjustment: statementAdjustment,
        viewOnly: this.isProjectSale
      },
      onFulfillment: (result: StatementAdjustment) => {
        if (result) {
          if (this.isProjectConfigTab) {
            this.soaUtils.updateDataPropagation(statementAdjustment, result);
          }
          statementAdjustment.soAdjHeading = result.soAdjHeading;
          this.soaFulfillmentService.fulfillEditStatementAdjustmentFormat();
        }
      }
    });
  }
}
