<div class="dropdown multi-select-dropdown" [ngClass]="{'multi-select-list': listStyle, 'disabled': disabled}" (keyup.enter)="toggleDropDown($event)"
    [attr.tabindex]="-1" (mouseover)="openDropDownMenu($event)" (mouseout)="closeDropDownMenu($event)"
     (focus)="gotoNextFocus($event);">
    <a class="select" [ngClass]="{'focus-first': isFocusFirstVisible}" data-target="#" aria-haspopup="true"
       aria-expanded="false" [attr.tabindex]="listStyle ? '-1' : '0'" (keydown)="preventDefaultArrowKeys($event);">
        <span *ngIf="selectedValues.length===0 || showLabelInTitle===false">{{title}}</span>
        <span *ngIf="selectedValues.length>1 && !isSelected('ALL') && multiSelectedTitle==='' && showLabelInTitle===true">{{listSelectedValues}}</span>
        <span *ngIf="multiSelectedTitle!=='' && selectedValues.length>1  && !isSelected('ALL')">{{multiSelectedTitle}}</span>
        <span *ngIf="selectedValues.length===1 && showLabelInTitle===true && !inputNameIsTask && getInputLabel().length > 0" style="text-transform: capitalize;">{{getInputLabel()}}: {{lookupLabelValue}}</span>
        <span *ngIf="selectedValues.length===1 && showLabelInTitle===true && !inputNameIsTask && getInputLabel().length === 0" style="text-transform: capitalize;">{{lookupLabelValue}}</span>
        <span *ngIf="inputNameIsTask && selectedValues.length===1 && showLabelInTitle===true" style="text-transform: capitalize;">{{lookupLabelValue}}</span>
        <span *ngIf="isSelected('ALL') && showLabelInTitle===true">{{allLabel}}</span>
        <span class="caret dp-custom-arrow"><img src="/assets/img/dropdown-firefox-primeng.png"/></span>
    </a>
    <ul class="dropdown-menu" *ngIf="multiSelectData.length>0" [ngClass]="{'dropdown-open': isDropDownMenuOpened}">
        <li *ngFor="let msData of multiSelectData; let i = index;">
            <div  [ngClass]="{'col-lg-8': msData.secondLabel}">
                <dp-checkbox fieldId="{{inputName + i}}">
                    <input type="checkbox" id="{{inputName + i}}" name="{{inputName + i}}" (click)="dataFilter(msData.value)"
                           [checked]="isSelected(msData.value)" [disabled]="isDisabled(msData.disabled)"/>
                </dp-checkbox>
                <label for="{{inputName + i}}" class="label-text"><span>{{msData.label}}</span></label>
            </div>

            <div *ngIf="msData.secondLabel" class="col-lg-4">
                <label class="label-text"><span>{{msData.secondLabel}}</span></label>
            </div>

            <div *ngIf="i === (multiSelectData.length-1) && listStyle===false">
                <a tabindex="0" (focus)="blurTarget($event);"></a>
            </div>
        </li>
    </ul>
</div>
