import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {messages} from '../common';
import {AuthNService} from '../core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ReferenceDataService} from '../shared-main/reference-data/reference-data.service';
import {UtilsService} from '../main/utils.service';
import {AuthZService} from '../core/authz/auth-z.service';
import {DppmCookieService} from '../main/dppm-cookie.service';
import {COOKIE_KEYS} from '../shared/cookie-keys';
import {LockScreenService} from '../core/lock-screen.service';
import {User} from '../matters/shared/user';
import {TabsService} from '../core/tabs.service';
import {SESSION_STORAGE_KEYS} from '../shared/session-storage-keys';
import {FocusFirstElementDecorator} from '../shared-main/focus-first-element-decorator';
import {UUIDService} from '../main/uuid.service';
import {appRoutesUrlParts} from '../app.routes';
import {AppComponent} from '../app.component';
import {UserStateService} from '../shared-main/user-state/user-state.service';
import {StaffProfilesService} from '../admin/staff-profiles/staff-profiles.service';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'dp-login-form',
  templateUrl: 'login.component.html',
  styleUrls: [
    './unity-login.styles.scss'
  ]
})

@FocusFirstElementDecorator()
export class LoginComponent implements OnInit {
  loginForm: FormGroup;

  user: User = new User();
  errorMsg: string;
  submitted: boolean = false;
  // if forgotCredentialsFlag is true redirect to ForgotPassword component
  forgotCredentialsFlag: boolean = false;
  loginTimeout: any;
  privacyPolicyText: string;

  public readonly formTypeId: string = 'loginPage';

  constructor(
    public fb: FormBuilder,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public cookieService: DppmCookieService,
    public authNService: AuthNService,
    public authZService: AuthZService,
    public referenceDataService: ReferenceDataService,
    public utilsService: UtilsService,
    public tabsStateService: TabsService,
    public lockScreenService: LockScreenService,
    public userStateService: UserStateService,
    public staffProfilesService: StaffProfilesService,
    public uuidService: UUIDService,
    private http: HttpClient
  ) {
  }

  ngOnInit(): void {
    this.cleanUpBrowserSession();
    this.buildForm();
    // Commenting this line to remove any anonymous call for Appcues.
    // The only place to call appcues is in MainComponent with the logged in user information
    // (<any>window).Appcues.anonymous();
    this.activatedRoute
      .params
      .subscribe((params: Params) => {
        if (params['timeout'] == 1) {
          this.errorMsg = messages[this.formTypeId].sessionExpired;
        }
        if (params['timeout'] == 2) {
          this.errorMsg = messages[this.formTypeId].sessionTimedOut;
        }
        if (params['timeout'] == 3) {
          this.errorMsg = messages[this.formTypeId].suspended;
        }

      });

    this.loadPrivacyPolicy();
  }

  public cleanUpBrowserSession(): void {
    this.staffProfilesService.cleanCachedLoggedInStaffProfile();
    this.authZService.removeAuthenticatedUser();
    this.tabsStateService.clearAllTabs();
    this.userStateService.clearUserStat();
    sessionStorage.clear();
  }

  buildForm(): void {
    let publicAccountId = this.cookieService.getCookie(COOKIE_KEYS.rememberMeAccountId) ? this.cookieService.getCookie(COOKIE_KEYS.rememberMeAccountId) : '';
    let loginId = this.cookieService.getCookie(COOKIE_KEYS.rememberMeUserId) ? this.cookieService.getCookie(COOKIE_KEYS.rememberMeUserId) : '';
    this.loginForm = this.fb.group({
      publicAccountId: [publicAccountId, [Validators.required]],
      loginId: [loginId, [Validators.required]],
      password: ['', [
        Validators.required,
        Validators.maxLength(100)
      ]],
      rememberMe: [!!(publicAccountId && loginId)]
    });
  }

  login(form: FormGroup): void {
    this.removeErrorMsg();
    this.submitted = true;
    // if form is invalid return
    if (!form.valid) {
      return;
    }
    form.value.loginId = form.value.loginId.trim();
    form.value.publicAccountId = form.value.publicAccountId.trim();

    // form is valid and authenticate user
    this.cleanUpBrowserSession();
    this.lockScreenService.lockForUpdate = true;
    let userAgentId = this.cookieService.getUserAgentId(form.value.loginId, form.value.publicAccountId);
    form.value.agentId = userAgentId ? userAgentId.agentId : null;
    this.authNService.login(form.value).subscribe((data: boolean) => {
        if (data) {
          //On success authentication initializing reference data cache
          this.submitted = false;
          let user = new User(form.value);
          this.cookieService.setRememberMeOptions(user);

          // true means login successful and response have loggedInUser information
          // if last attempted url is present in authenticateService redirect to same
          // otherwise redirect to application's default url
          let sessionUser: any = sessionStorage.getItem(SESSION_STORAGE_KEYS.user);
          if (sessionUser && sessionUser != null) {
            let authenticatedUser = new User(JSON.parse(sessionUser));
            if (authenticatedUser.authChallengeRequired) {
              this.router.navigate([`./${appRoutesUrlParts.firstLogin}/${appRoutesUrlParts.trustedPhoneNumber}`]);
            } else {
              if (authenticatedUser.passwordChangeRequired) {
                this.router.navigate([`./${appRoutesUrlParts.firstLogin}/${appRoutesUrlParts.setupPassword}`]);
              } else {
                this.authZService.initializationAfterLogin(this.referenceDataService, this.uuidService);
                this.authZService.navigateToGetRedirectUrl();
              }

            }
          }
        } else {
          // login successful but response doesn't have loggedInUser information
          // should never come here api must return the loggedInUser information
          // stay on login page and display error
          this.lockScreenService.lockForUpdate = false;
        }
      },
      (error) => {
        // authentication is unsuccessful
        // display error message on login page
        this.lockScreenService.lockForUpdate = false;
        if (error.errorCode == 'app.accountLockedOut') {
          this.errorMsg = messages[this.formTypeId].suspended;
        } else {
          this.errorMsg = messages[this.formTypeId].general;
        }

      });
  }

  removeErrorMsg() {
    if (this.loginTimeout) {
      clearTimeout(this.loginTimeout);
    }
    this.errorMsg = null;
  }

  loadPrivacyPolicy() {
    this.http.get('/assets/static/privacy-policy/privacy-policy.html', ({responseType: 'text'}))
      .subscribe((res) => {
        if (res) {
          this.privacyPolicyText = res;
        }
      });
  }

  ngAfterViewInit() {
  }
}