import {APP_INITIALIZER, DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {appRouting, appRoutingProviders} from './app.routes';
import {AppComponent} from './app.component';
import {MainModule} from './main';
import {LoginModule} from './login';
// import {GlobalErrorHandler} from './core/global-error-handler';
import {ErrorService} from './shared/error-handling/error-service';
import {LockScreenService} from './core/lock-screen.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {UUIDService} from './main/uuid.service';
import {UserConfigurationService} from './shared-main/user-configuration.service';
import {AppLoaderService} from './core/app-loader-service';
import {UtilsService} from './main/utils.service';
import {AuthNService, HttpClient} from './core';
import {UserStateService} from './shared-main/user-state/user-state.service';
import {GlobalLogger} from './core/global-logger';
import {Level, NgLoggerModule} from '@nsalaun/ng-logger';
import {NotifierModule} from 'angular-notifier';
import {customNotifierOptions} from './shared-main/constants';
import {NgxFileDropModule} from 'ngx-file-drop';
import {RouteReuseStrategy} from '@angular/router';
import {CustomRouteReuseStrategy} from './core/custom-route-reuse-strategy';
import {ProjectDocSharingNotifierService} from './projects/shared/project-doc-sharing-notifier.service';
import {MatterRestrictedPeriodService} from './core/matter-restricted-period.service';
import {NotificationOptOutService} from './main/notification-opt-out.service';
import {MaterialModule} from './MaterialModule';
import {DppmCookieService} from './main/dppm-cookie.service';
import {CookieService} from 'ngx-cookie-service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {InputTextModule} from 'primeng/inputtext';
import {TooltipModule} from 'primeng/tooltip';
import {OAuthModule, OAuthService} from 'angular-oauth2-oidc';
import {FeatureService} from './feature-service';
import {AccountSelectionInterceptor} from './shared-main/account-selection/account-selection-interceptor';
import {ContactSectionedService} from './contact/contact-sectioned.service';
import {Environment} from '../environments/environment';
import {GoCardlessModule} from './gocardless/gocardless.module';
import {AppInitService} from './app-init-service';
import {MatDialogModule} from '@angular/material/dialog';
import {ModalDialogService} from './shared/dialog/modal-dialog.service';
import {AccessGroupsService} from './admin/access-groups/access-groups.service';
import {DocServicesService} from './doc-services/doc-services.service';
import {PageNotFoundComponent} from './error';
import {UserSessionService} from './admin/active-user-sessions/user-session.service';

export function intializeApp(appInitService: AppInitService) {
  return appInitService.loadSSOConfigAndTryAuth();
}

// Main module of dppm app where all other module will be inserted
// it hold all the sub module and common dependencies.
@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: ['/api'],
        sendAccessToken: true
      }
    }),
    appRouting,
    InputTextModule,
    LoginModule,
    MainModule,
    MaterialModule,
    NgxFileDropModule,
    TooltipModule,
    NgLoggerModule.forRoot(Level.LOG),
    NotifierModule.withConfig(customNotifierOptions),
    GoCardlessModule,
    MatDialogModule,
    PageNotFoundComponent
  ],
  declarations: [AppComponent],
  providers: [
    Environment,
    {provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy},
    {
      provide: APP_INITIALIZER,
      useFactory: intializeApp,
      deps: [AppInitService],
      multi: true
    },
    {provide: DEFAULT_CURRENCY_CODE, useValue: 'USD'},
    {provide: LOCALE_ID, useValue: 'en-US'},
    OAuthService,
    AuthNService,
    GlobalLogger,
    HttpClient,
    UserConfigurationService,
    FeatureService,
    appRoutingProviders,
    ErrorService,
    LockScreenService,
    UUIDService,
    AppLoaderService,
    UtilsService,
    UserStateService,
    MatterRestrictedPeriodService,
    ProjectDocSharingNotifierService,
    NotificationOptOutService,
    CookieService,
    DppmCookieService,
    ContactSectionedService,
    ModalDialogService,
    AccessGroupsService,
    DocServicesService,
    UserSessionService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
