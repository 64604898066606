<!--<div id="div-navbar">-->
<div class="fixed-header-bg"></div>
<nav>
    <div class="container">
        <div class="nav-table">
            <div class="nav-logo"><a><img src="assets/img/UPM_RGB_app_72.svg" alt="Unity Practice Management Logo"/></a>
                <span class="version">{{clientVersion}} {{harvestingMode}}</span>
            </div>
            <div class="float-right-topnav">
                <div class="nav-main">
                    <ul>
                        <li [user-access]="{'operationName': authKeys.ACCOUNT_LIST_VIEW}"
                            [ngClass]="{'route-active': (anchorTab && anchorTab.isAdmin() && (isAdminAnchorTabFromAccountList || router.url.indexOf('main/admin/list')!==-1))}">
                            <a
                                (click)="activateAccountAdminRouteFromMain()">
                                <span>Accounts</span></a>
                        </li>

                        <li [user-access]="{'operationName': authKeys.CUSTOMER_ACCOUNT_LIST}"
                            [ngClass]="{'route-active': (anchorTab && anchorTab.isAdmin() && (isAdminAnchorTabFromAccountList || router.url.indexOf('main/admin/list')!==-1))}">
                            <a
                                (click)="activateAccountAdminRouteFromMain()">
                                <span>Accounts</span></a>
                        </li>

                        <li [user-access]="{'operationName': authKeys.MATTERS}"
                            [ngClass]="{'route-active': activeNav==='MATTERS'}"><a
                            (click)="activateMatterListRouteFromMain()"><span>Matters</span></a>
                        </li>
                        <li [user-access]="{'operationName': authKeys.OPPORTUNITIES}"
                            [ngClass]="{'route-active': activeNav==='OPPORTUNITY_MATTER'}"><a
                            (click)="activateOpportunityMatterRouteFromMain()"><span>Opportunities</span></a>
                        </li>
                        <li *ngIf="isProjectsMenuVisible()"
                            class="padding-right-20 nav-profile dropdown projects-dropdown-menu"
                            [ngClass]="{'route-active': isProjectRoutesActive()}">
                            <a data-toggle="dropdown" class="">
                                <div class="inline-block padding-right-5 padding-top-2">Projects</div>
                                <div class="fa fa-angle-down projects-dropdown-menu-arrow" aria-hidden="true"></div>
                            </a>
                            <ul class="dropdown-menu">
                                <li *ngIf="hasProjectsAccess()">
                                    <a class="project-item-link" (click)="activateProjectListRouteFromMain()"><span>Projects</span></a>
                                </li>
                                <li *ngIf="hasProjectsFullAccess()">
                                    <a class="project-item-link" (click)="activateDepositManagerRouteFromMain()"><span>Deposits</span></a>
                                </li>
                                <li *ngIf="hasERegAccess()">
                                    <a class="project-item-link"
                                       (click)="activateExportFileManagerRouteFromMain()"><span>E-Reg</span></a>
                                </li>
                            </ul>
                        </li>
                        <li *ngIf="hasContactManagementAccess() || hasManageGlobalContactsAccess()"
                            [ngClass]="{'route-active':  activeNav==='CONTACTS'}"
                        ><a (click)="activateContactListRouteFromMain()"><span>Contacts</span></a></li>
                        <li [user-access]="{'operationName': authKeys.FORMS}"
                            [ngClass]="{'route-active':  activeNav==='DOC-SERVICES'}"
                        ><a (click)="activateDocServicesRouteFromMain()"><span>Doc Services</span></a></li>
                        <li *ngIf="!hasSystemRole() && !appConfig.enableGeneralEnhancementsPh1"

                            [ngClass]="{'route-active': activeNav==='INTEGRATIONS'}">
                            <a (click)="activateIntegrationsRouteFromMain()"><span>Integrations</span></a>

                        </li>
                        <li *ngIf="!hasSystemRole()"
                            [ngClass]="{'route-active': activeNav==='OPPORTUNITIES'}">
                            <a (click)="activateOpportunitiesRouteFromMain()">
                                <span>UnityC</span>
                                <div *ngIf="getActiveOpportunities() > 0"
                                     class="red-circle">{{getActiveOpportunities()}}</div>
                            </a>

                        </li>
                        <li *ngIf="appConfig.enableGeneralEnhancementsPh1">
                            <a (click)="openAccountingModal()">
                                <span>Accounting</span>
                            </a>
                        </li>
                        <li *ngIf="hasEventAccess() && !appConfig.enableGeneralEnhancementsPh1" class="padding-right-20 nav-profile dropdown projects-dropdown-menu"
                            [ngClass]="{'route-active': isEventsRoutesActive()}">
                            <a data-toggle="dropdown" class="">
                                <div class="inline-block padding-right-5 padding-top-2">Events</div>
                                <div class="fa fa-angle-down projects-dropdown-menu-arrow" aria-hidden="true"></div>
                            </a>
                            <ul class="dropdown-menu">
                                <li>
                                    <a class="project-item-link" (click)="activateEventListRouteFromMain(false)"><span>Events</span></a>
                                </li>
                                <li>
                                    <a class="project-item-link" (click)="activateDayAtGlanceRouteFromMain()"><span>Day At A Glance</span></a>
                                </li>
                                <li>
                                    <a class="project-item-link" (click)="activateEventListRouteFromMain(true)"><span>Staff Availability View</span></a>
                                </li>
                            </ul>
                        </li>
                        <li *ngIf="hasEventAccess() && appConfig.enableGeneralEnhancementsPh1" class="padding-right-20 nav-profile dropdown projects-dropdown-menu"
                            [ngClass]="{'route-active': isEventsRoutesActive()}">
                            <a data-toggle="dropdown" class="">
                                <div class="inline-block padding-right-5 padding-top-2">Planning</div>
                                <div class="fa fa-angle-down projects-dropdown-menu-arrow" aria-hidden="true"></div>
                            </a>
                            <ul class="dropdown-menu">
                                <li>
                                    <a class="project-item-link" (click)="activateEventListRouteFromMain(false)"><span>Events</span></a>
                                </li>
                                <li>
                                    <a class="project-item-link" (click)="activateDayAtGlanceRouteFromMain()"><span>Day At A Glance</span></a>
                                </li>
                                <li>
                                    <a class="project-item-link" (click)="activateEventListRouteFromMain(true)"><span>Staff Availability View</span></a>
                                </li>
                            </ul>
                        </li>
                        <li [user-access]="{'operationName': authKeys.MANAGE_ADJUDICATION}"
                            [ngClass]="{'route-active': activeNav==='MANAGE_ADJUDICATION'}">
                            <a (click)="activateAdjudicationListRouteFromMain()"><span>Adjudications</span></a>

                        </li>
                        <li *ngIf="!appConfig.enableGeneralEnhancementsPh1" [ngClass]="{'route-active': activeNav==='MESSAGES'}">
                            <a (click)="activateMessagesListRouteFromMain()">
                                <span>Messages</span>
                                <div *ngIf="getMessageCount() > 0" class="red-circle">{{getMessageCount()}}</div>
                            </a>
                        </li>
                        <li [user-access]="{'operationName': authKeys.WORK_MANAGEMENT}"><a><span>Calendar</span></a>
                        </li>
                        <li [user-access]="{'operationName': authKeys.MESSAGING}"><a><span>Messages</span></a></li>
                        <li [user-access]="{'operationName': authKeys.WORK_MANAGEMENT}"><a><span>Tasks</span></a></li>
                        <li [user-access]="{'operationName': authKeys.REPORTING}"><a><span>Reports</span></a>
                            <div class="circle-num">21</div>
                        </li>
                    </ul>
                    <div class="clear"></div>
                </div>
                <div class="nav-divider"><i></i></div>

                <ul class="right-nav-main">
                    <ng-container *ngIf="(isAssystPayoutEnabled || isTcolEnabled) && !hasSystemRole()">
                        <li class="dnd-suite-box">
                            <div class="nav-profile dropdown">
                                <a data-toggle="dropdown" class=" nine-dot-icon">
                                    <span class="fa fa-ellipsis-v padding-right-3" aria-hidden="true" ></span>
                                    <span class="fa fa-ellipsis-v padding-right-3" aria-hidden="true"></span>
                                    <span class="fa fa-ellipsis-v" aria-hidden="true"></span>
                                </a>
                                <ul class="dropdown-menu user-preference-dropdown-menu">
                                    <li class="dnd-suite-box-header" style="font-weight: bold; font-size: 18px">
                                        {{displayWaffleMenu}}
                                    </li>
                                    <ng-container *ngIf="appConfig.enableGeneralEnhancementsPh1">
                                        <li class="lender-center-box" (click)="openNewMortgageInstructions()" title="Import mortgage instructions from over 50 lenders to your conveyancing matter">
                                            <span class="display-block boldfont">Check for New Mortgage Instructions</span>
                                        </li>
                                    </ng-container>
                                    <ng-container *ngIf="isAssystPayoutEnabled">
                                    <li class="lender-center-box"  (click)="openAssyst()" title="Canada's national destination for mortgage instructions and discharges">
                                        <span class="display-block boldfont">Lender Centre <i class="fa fa-external-link" style="font-size:12px" aria-hidden="true"></i></span>
                                    </li>
                                    </ng-container>
                                    <ng-container *ngIf="isTcolEnabled">
                                    <li class="lender-center-box" (click)="openTaxCertificates()" title="Simplify the way you order Tax Certificates">
                                        <span class="display-block boldfont">Tax Certificates <i class="fa fa-external-link" style="font-size:12px" aria-hidden="true"></i></span>
                                    </li>
                                    </ng-container>
                                    <ng-container *ngIf="adminAccess && isPaymentProviderEnabled() && hasPaymentProviderToken">
                                        <li class="lender-center-box" (click)="revokeGoCardlessSignup()">
                                            <span class="display-block boldfont">Revoke GoCardless Access<i class="fa fa-external-link" style="font-size:12px" aria-hidden="true"></i></span>
                                        </li>
                                    </ng-container>
                                    <ng-container *ngIf="adminAccess && isPaymentProviderEnabled() && hasPaymentProviderToken === false">
                                        <li class="lender-center-box" (click)="goCardlessSignup()" title="Simplify the way you order Tax Certificates">
                                            <span class="display-block boldfont">GoCardless Signup <i class="fa fa-external-link" style="font-size:12px" aria-hidden="true"></i></span>
                                        </li>
                                    </ng-container>
                                    <ng-container *ngIf="appConfig.enableGeneralEnhancementsPh1">
                                        <li class="lender-center-box"  (click)="openOtherIntegrationService()" title="View other integration services provided by our partners">
                                            <span class="display-block boldfont">Other Integration Services</span>
                                        </li>
                                    </ng-container>
                                </ul>
                                <div class="clear"></div>
                            </div>
                            <div class="clear"></div>
                        </li>
                        <div class="nav-divider padding-right-10"><i></i></div>
                    </ng-container>
                    <li *ngIf="appConfig.enableGeneralEnhancementsPh1" [ngClass]="{'route-active': activeNav==='MESSAGES'}">
                        <div class="nav-item-icon nav-profile">
                            <a (click)="activateMessagesListRouteFromMain()">
                                <img src="/assets/img/messages_icon_mail_24px.svg" alt="Messages" title="Messages" />
                                <div id="messages-red-circle" *ngIf="getMessageCount() > 0">{{getMessageCount()}}</div>
                            </a>
                        </div>
                    </li>
                    <li>
                        <div id="appcuesWidget">
                            <div class="appcues-widget-icon">
                                <span><img src="assets/img/newsletter.svg" title="Messages & Tool Tips" alt="Messages and Tool Tips"/></span>
                            </div>
                        </div>
                    </li>
                    <li
                        [ngClass]="{'route-active': activeNav==='BILLING' }"
                        [user-access]="{'operationName': authKeys.CUSTOMER_BILLING_INFORMATION }">
                        <div class="nav-item-icon nav-profile">
                            <ng-container *ngIf="!appConfig.CBPLiveDate">
                                <a (click)="activateBillingRouteFromMain()">
                                    <span><img src="assets/img/nav-icon-dollar-sign.svg" title="Billing Information"  alt="Billing Information"/></span>
                                </a>
                            </ng-container>

                            <ng-container *ngIf="appConfig.CBPLiveDate">
                                <ng-container *ngIf="account && !account.ispaymentMethodTransactionCredits">
                                    <a (click)="activateCustomerBillingPortalUrl()">
                                        <span><img src="assets/img/nav-icon-dollar-sign.svg" title="Billing Information"  alt="Billing Information"/></span>
                                    </a>
                                </ng-container>
                                <ng-container *ngIf="account && account.ispaymentMethodTransactionCredits">
                                    <div class="dropdown">
                                    <a data-toggle="dropdown" class="">
                                        <div class="inline-block padding-right-5 padding-top-2"><span><img
                                                    src="assets/img/nav-icon-dollar-sign.svg" title="Billing Information" alt="Billing Information" /></span></div>
                                        <div class="fa fa-angle-down projects-dropdown-menu-arrow padding-right-5" aria-hidden="true"></div>
                                    </a>
                                    <ul class="dropdown-menu user-preference-dropdown-menu">
                                        <li>
                                            <a class="project-item-link" (click)="activateCustomerBillingPortalUrl()"><span>Customer Billing
                                                    Portal</span></a>
                                        </li>
                                        <li>
                                            <a class="project-item-link" (click)="openSetWarningLevel()"><span>Set Warning Level</span></a>
                                        </li>
                                    </ul>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </li>
                    <li *ngIf="hasAdminAccess()"
                        [ngClass]="{'route-active': (anchorTab && anchorTab.isAdmin() && !isAdminAnchorTabFromAccountList && router.url.indexOf('main/admin/list') < 0)}">
                        <div class="nav-item-icon nav-profile">
                            <a (click)="activateAdminRouteFromMain()">
                                <img src="/assets/img/nav-icon-gear.svg" alt="" title="Admin Settings"/>
                            </a></div>
                    </li>
                     <li *ngIf="(isMarketingPageEnabled || isConveyancingDataPageEnabled) && !isMarketingPageDown">
                         <div class="nav-item-icon nav-profile pointerCursor">
                             <a (click)="openUnityMarketingPage()">
                                 <i class="fa fa-info-circle font-22" title="Return to Information Page"></i>
                             </a>
                         </div>
                     </li>
                      <li>
                        <div class="nav-profile dropdown">
                            <a data-toggle="dropdown" class="">
                                <div class="img-profile"><img src="/assets/img/nav-icon-profile.svg" alt=""/></div>
                                <div
                                    class="text-overflow user-login">{{ dppmUserlogin }}</div>
                                <div class="fa fa-angle-down"
                                     aria-hidden="true"></div>
                            </a>
                            <ul class="dropdown-menu user-preference-dropdown-menu">
                                <li *ngIf="!isSsoEnabled()"><a (click)="confirmPasswordModal()" class="dropdown-item">My Credentials</a></li>
                                <li *ngIf="!hasSystemRole()"><a (click)="openManageThirdPartyCredentials()" class="dropdown-item">My
                                    Integration Credentials</a></li>
                                <li><a (click)="openPersonalDetailsModal()" class="dropdown-item">My Personal Details</a></li>
                                <li><a (click)="openAppearanceModal()" class="dropdown-item">Appearance</a></li>
                                <li><a (click)="openUnitySystemStatusLink()" class="dropdown-item">Unity System Status</a></li>
                                <li *ngIf="!appConfig.enableGeneralEnhancementsPh1"><a target=”_blank” href="https://welcometounity.doprocess.com/performance/" class="dropdown-item">Unity
                                    Performance</a></li>
                                <li *ngIf="hasUnityDriveAccess()"><a target=”_blank” href="{{unityDriveInstallUrl}}" class="dropdown-item">Install
                                    Unity Drive</a></li>
                                <li *ngIf="appConfig.enableGeneralEnhancementsPh1"><a (click)="openCustomerSupport()" class="dropdown-item">Customer Support</a></li>
                                <li>
                                    <a (click)="logout()" class="dropdown-item">
                                        <span class="glyphicon glyphicon-log-out"></span>
                                        <span *ngIf="isSsoEnabled()">Log out of Dye & Durham</span>
                                        <span *ngIf="!isSsoEnabled()">Logout</span>
                                        <i class="icon-arrow-right"></i>
                                    </a>
                                </li>
                            </ul>
                            <div class="clear"></div>
                        </div>
                        <div class="clear"></div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>
<div class="main-outlet-content">

    <router-outlet></router-outlet>
</div>

<div class="landing" *ngIf="isAIAvailable">
    <div class="landing__aiRobo">
        <app-chatairobo *ngIf="showRobo"
        (showChatbot)="openAIChatBot($event)"
        (showTermsConditions)="openAIChatbotTermsConditions($event)"
        [isTnCClosBtnClicked]="termsConditionsWndCloseFlag"
        [isChatbotWndCloseBtnClicked]="chatbotWndCloseFlag"
        [userAcceptedTnC]="userAcceptedTnC">
        </app-chatairobo>
    </div>
      <div *ngIf="showToasterMessage && (termsConditionsWndCloseFlag || chatbotWndCloseFlag)" class="landing__aiToaster">
        <app-chataitoaster [messageText]="toasterMessageText" (closeToasterClicked)="closeToaster()"></app-chataitoaster>
    </div>
</div>

<ng-template #notification let-notificationData="notification">
    <i *ngIf="notificationData.type && (notificationData.type === 'ERROR')" aria-hidden="true"
       class="padding-right-10 fa fa-times-circle red font-16"></i>
    <i *ngIf="notificationData.type && (notificationData.type === 'WARNING')" aria-hidden="true"
       class="padding-right-10 fa fa-exclamation-triangle yellow font-16"></i>
    <i *ngIf="notificationData.type && (notificationData.type === 'NOTIFICATION')" aria-hidden="true"
       class="padding-right-10 fa fa-info-circle tarion-info-circle padding-bottom-2 font-16"></i>
    <span class="error-msg-bubble" (click)="openNotificationFooter()" [innerHTML]="notificationData.message"></span>
    <button (click)="closeNotificationBubble(notificationData.id)"
            class="notifier__notification-button vertical-align-middle-imp" title="dismiss" type="button">
        <svg class="notifier__notification-button-icon" height="20" viewBox="0 0 24 24" width="20">
            <path
                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
        </svg>
    </button>
</ng-template>

