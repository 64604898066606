import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2} from '@angular/core';
import {BurgerMenuExtendedItem} from '../../matters/shared/burger-menu-extended-item';
import {EXCEL_TAB_STOP_CLASS_NAME, EXTRA_HEIGHT} from '../constants';

declare var jQuery: any;

@Component({
  selector: 'dp-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: [
    './dropdown-menu.component.scss'
  ]

})

export class DpDropDownComponent implements OnInit, OnDestroy {
  @Input('dropDownItems') dropDownItems: BurgerMenuExtendedItem[] = [];
  @Input() addFocus: boolean = false;
  @Input() addExtraHeight: boolean = false;
  @Input() focusFirst: boolean = false;
  @Input() menuSize: number[] = [];
  @Input() buttonLabel: string;
  @Input() openSubMenuOnLeft: boolean = false;
  @Input() openSubSubMenuOnLeft: boolean = false;
  @Input() allowPopup: boolean = true;
  @Input() customColor;
  @Input() disabled: boolean = false;
  @Input() tabIndex: number = 0;
  @Input() firstLevelScrolling: boolean = false;
  @Input() secondLevelScrolling: boolean = false;
  @Input() thirdLevelScrolling: boolean = false;
  @Input() buttonLabelOnLeft: boolean = false;

  //  addTabStopClass related to dp-excel-style.ts to add classes for the tabbing sequence
  @Input() addTabStopClass: boolean = false;
  @Input() addDpReadOnlyNotDisabledClass: boolean = false;
  @Output() itemClicked = new EventEmitter();
  @Output() menuOpened = new EventEmitter();
  @Output() burgerFocused = new EventEmitter();
  @Output() f9Pressed = new EventEmitter();
  @Output() burgerBlured = new EventEmitter();

  static activeInstance: DpDropDownComponent;

  readonly firstLevelMenuIndex: number = 0;
  readonly secondLevelMenuIndex: number = 1;
  readonly thirdLevelMenuIndex: number = 2;

  readonly firstLevelMenuWidth: number = 150;
  readonly secondLevelMenuWidth: number = 145;
  readonly thirdLevelMenuWidth: number = 145;

  // up to 3 levels of sub navigation
  public index: number = -1; //index of currently selected item
  public indexSub: number = -1; //index of currently selected item

  public indexSubSub: number = -1; //index of currently selected item

  set active(isActive: boolean) {
    DpDropDownComponent.activeInstance = isActive ? this : null;
    // temporary solution until we unify the two drop down components
    //DpBurgerMenuComponent.activeInstance = null;
  }

  get active() {
    return DpDropDownComponent.activeInstance == this;
  }

  get excelTabStopClassName() {
    return EXCEL_TAB_STOP_CLASS_NAME;
  }

  public constructor(public renderer: Renderer2) {

    /*if(!DpDropDownComponent.outsideMenuClickListener) {
        DpDropDownComponent.outsideMenuClickListener = renderer.listen('document', 'click', (event) => {
            this.closeMenu();
        });
    }*/
  }

  public ngOnInit(): void {

  }

  itemText(item: any): string {
    if (typeof item == 'string') {
      return item;
    }
    return (item as BurgerMenuExtendedItem).text;
  }

  setFocus(i): void {
    this.index = i;
    this.indexSub = -1;
    this.indexSubSub = -1;
  }

  resetFocusedIndexes() {
    this.index = -1;
    this.indexSub = -1;
    this.indexSubSub = -1;
  }

  isSelected(i): boolean {
    return i === this.index;
  }

  setSubFocus(i): void {
    this.indexSub = i;
    this.indexSubSub = -1;
  }

  isSubSelected(i): boolean {
    return i === this.indexSub;
  }

  setSubSubFocus(i): void {
    this.indexSubSub = i;
  }

  isSubSubSelected(i): boolean {
    return i === this.indexSubSub;
  }

  public ngOnDestroy(): void {
  }

  handleTab($event) {
    if (($event.shiftKey == false && jQuery($event.target).hasClass('last-item')) || ($event.shiftKey == true && jQuery($event.target).hasClass('toggleBurger')) || ($event.shiftKey == true && jQuery($event.target).hasClass('dropdown-matter-type-list')) || ($event.shiftKey == true && jQuery($event.target).hasClass('first-item'))) {
      this.closeMenu();
    }

  }

  handleBurgerClick($event) {
    $event.stopPropagation();
    if (!this.disabled) {
      this.toggleOpenClose();
    }
  }

  handleItemClick(event, i) {
    this.index = i;
    this.openMenu(event);
  }

  handleSubItemClick(event, i) {
    this.indexSub = i;
    event.stopPropagation();
    event.preventDefault();

    this.onClick(this.dropDownItems[ this.index ].items[ this.indexSub ]);
    this.closeMenu();
  }

  handleSubSubItemClick(event, i) {
    this.indexSubSub = i;
    this.onClick(this.dropDownItems[ this.index ].items[ this.indexSub ].items[ this.indexSubSub ]);
    this.closeMenu();
  }

  handleF9(event) {
    this.f9Pressed.emit('F9');
    this.closeMenu();
  }

  handleArrowUp($event) {
    if (this.active) {

      $event.stopPropagation();
      $event.preventDefault();
      if (this.indexSub >= 0) {
        /// arrow keys toggle through submenu
        if (this.indexSubSub >= 0) {
          /// arrow keys toggle through sub-sub-menu
          if (this.indexSubSub == 0) {
            this.indexSubSub = this.dropDownItems[ this.index ].items[ this.indexSub ].items.length - 1;
          } else {
            this.indexSubSub--;
          }

        } else {
          if (this.indexSub == 0) {
            this.indexSub = this.dropDownItems[ this.index ].items.length - 1;
          } else {
            this.indexSub--;
          }
        }
      } else {
        /// arrow keys toggle through menu
        this.setFocus(this.index);
        if (this.index <= 0) {
          this.index = this.dropDownItems.length - 1;
        } else {
          this.index--;
        }
      }
    }
  }

  handleArrowDown($event) {
    if (this.active) {

      $event.stopPropagation();
      $event.preventDefault();
      if (this.indexSub >= 0) {
        /// arrow keys toggle through submenu
        if (this.indexSubSub >= 0) {
          /// arrow keys toggle through sub-sub-menu
          if (this.indexSubSub >= this.dropDownItems[ this.index ].items[ this.indexSub ].items.length - 1) {
            this.indexSubSub = 0;
          } else {
            this.indexSubSub++;
          }

        } else {
          if (this.indexSub >= this.dropDownItems[ this.index ].items.length - 1) {
            this.indexSub = 0;
          } else {
            this.indexSub++;
          }
        }
      } else {
        /// arrow keys toggle through menu
        this.setFocus(this.index);
        if (this.index >= this.dropDownItems.length - 1) {
          this.index = 0;
        } else {
          this.index++;
        }
      }
    }
  }

  openSubMenuFromArrowKey($event) {
    if (this.active) {
      $event.stopPropagation();
      $event.preventDefault();
      console.log($event);
      if (this.dropDownItems[ this.index ] && this.dropDownItems[ this.index ].items) {
        if (this.indexSub < 0) {
          this.indexSub = 0;
        } else {
          if (this.dropDownItems[ this.index ].items[ this.indexSub ] && this.dropDownItems[ this.index ].items[ this.indexSub ].items) {
            if (this.indexSubSub < 0) {
              this.indexSubSub = 0;
            }
          }
        }

      }

    }
  }

  closeSubMenuFromArrowKey($event) {
    if (this.active) {
      $event.stopPropagation();
      $event.preventDefault();
      if (this.dropDownItems[ this.index ].items[ this.indexSub ] && this.dropDownItems[ this.index ].items[ this.indexSub ].items && this.dropDownItems[ this.index ].items[ this.indexSub ].items.length > 0) {
        if (this.indexSubSub > -1) {
          this.indexSubSub = -1;
        } else {
          this.indexSub = -1;
        }
      } else if (this.dropDownItems[ this.index ] && this.dropDownItems[ this.index ].items && this.dropDownItems[ this.index ].items.length > 0) {
        if (this.indexSub > -1) {
          this.indexSub = -1;
        } else {
          this.index = -1;
        }

      }
    }
  }

  handleArrowLeft($event) {
    if (!this.openSubSubMenuOnLeft) {
      this.closeSubMenuFromArrowKey($event);
    } else {
      this.openSubMenuFromArrowKey($event);
    }

  }

  handleArrowRight($event) {
    if (!this.openSubSubMenuOnLeft) {
      this.openSubMenuFromArrowKey($event);
    } else {
      this.closeSubMenuFromArrowKey($event);
    }
  }

  handleEsc($event) {
    if (this.active) {
      $event.stopPropagation();
      this.closeMenu();
    }
  }

  onClick(item: any) {
    if (!item.isDisabled) {
      if (!(item.items && item.items.length)) {
        this.itemClicked.emit(item);
        this.closeMenu();
      }
    }
  }

  openMenu(event): void {
    if (!this.disabled) {
      event.stopPropagation();
      event.preventDefault();
      if (this.index >= 0 && this.indexSub == -1 && this.indexSubSub == -1) {
        this.onClick(this.dropDownItems[ this.index ]);
      } else if (this.index >= 0 && this.indexSub >= 0 && this.indexSubSub == -1) {
        this.onClick(this.dropDownItems[ this.index ].items[ this.indexSub ]);
      } else if (this.index >= 0 && this.indexSub >= 0 && this.indexSubSub >= 0) {
        this.onClick(this.dropDownItems[ this.index ].items[ this.indexSub ].items[ this.indexSubSub ]);
      } else {
        this.toggleOpenClose();
      }
    }

  }

  closeMenu(): void {
    this.index = -1;
    this.active = false;
  }

  toggleOpenClose(): void {
    if (this.active == false) {
      this.resetFocusedIndexes();
      this.menuOpened.emit('open');
      this.active = true;
      setTimeout(() => {
        this.determineDropDirection();
      }, 0);
    } else {
      this.closeMenu();
    }
  }

  determineDropDirection() {
    let dropDownMenu = jQuery('.dp-dropdown-menu.active .dropdown-component-type-list');
    if (this.allowPopup) {
      jQuery('.popup').removeClass('popup');
      let menuHeight = dropDownMenu.height();
      let containerPos;

      //Check the closest modal
      if (dropDownMenu.closest('div.mat-modal-dialog').length) {
        let menuOffset = this.getPosition('.dp-dropdown-menu.active .dropdown-component-type-list');
        containerPos = this.getPosition('.mat-modal-dialog') + jQuery('.mat-modal-dialog').outerHeight();
        if (menuOffset + menuHeight + 60 > containerPos) {
          dropDownMenu.addClass('popup');
        }
      } else {
        let menuOffset = this.getPosition('.dp-dropdown-menu.active .dropdown-component-type-list') - jQuery(document).scrollTop();
        let scrollableParent = this.getFirstScrollParent(dropDownMenu);
        if (scrollableParent.length && scrollableParent[ 0 ] != document) {
          containerPos = this.getPosition(scrollableParent) + jQuery(scrollableParent).outerHeight();
        } else {
          containerPos = this.getPosition(window);
        }

        if (menuOffset + menuHeight + 22 > containerPos
          && menuOffset >= (menuHeight + 60)) { //So we don't get a negative top position
          let width = dropDownMenu.css('width');
          dropDownMenu.css({'max-width': width, 'top': menuOffset - menuHeight - 22, 'position': 'fixed'});
        }
      }
    }

    if (this.openSubMenuOnLeft) {
      let txt = this.buttonLabel ? 'dp-dropdown-menu .dp-dropdown-menu.active .drop-down-button i' : 'dp-dropdown-menu .dp-dropdown-menu .toggleBurger.active i';
      if (jQuery(txt).length) {
        let menuLeft = jQuery(txt).offset().left - dropDownMenu.width();
        dropDownMenu.offset({left: menuLeft});
      }
    }
  }

  getFirstScrollParent(dropDownMenu) {
    let parent = dropDownMenu.scrollParent();
    if (parent.length && parent[ 0 ] == document) {
      let scrollParents = dropDownMenu
      .parents()
      .filter(function () {
        return this.style.overflowY == 'auto'; //Check if inline style "overflowY" is used
      });
      if (scrollParents && scrollParents.length) {
        parent = scrollParents.first();
      }
    }
    return parent;
  }

  getPosition(element): number {
    if (element == window) {
      return window.innerHeight;
    }
    return jQuery(element).offset() && jQuery(element).offset().top;
  }

  getMenuWidth(menuLevelIndex: number): string {
    let width: number = this.firstLevelMenuWidth;
    if (menuLevelIndex > this.firstLevelMenuIndex) {
      width = this.secondLevelMenuWidth;
    }

    // menuSize is actually more like a "width factor" array i.e. [1.5,1.2,1.6]
    if (this.menuSize && this.menuSize.length && menuLevelIndex < this.menuSize.length) {
      width = Number(width) * this.menuSize[ menuLevelIndex ];
    }
    return String(width + 'px');

  }

  getSubSubMenuLeft(menuLevelIndex: number): string {
    if (this.openSubSubMenuOnLeft) {
      if (menuLevelIndex == this.secondLevelMenuIndex) {
        if (this.menuSize && this.menuSize.length >= 2) {
          return `-${ this.secondLevelMenuWidth * this.menuSize[ 1 ] }px`;
        } else {
          return `-${ this.secondLevelMenuWidth }px`;
        }

      }
      if (menuLevelIndex == this.thirdLevelMenuIndex) {
        if (this.menuSize && this.menuSize.length == 3) {
          return `-${ this.thirdLevelMenuWidth * this.menuSize[ 2 ] }px`;
        } else {
          return `-${ this.thirdLevelMenuWidth }px`;
        }

      }
    }
    return null;
  }

  getSubSubMenuTop(menuLevelIndex: number, itemsCount: number): string {
    const itemMenuHeight = 20;
    const dropDownMenu = jQuery('.dp-dropdown-menu.active .dropdown-component-type-list');
    const menuHeight = dropDownMenu.height();
    const menuOffset = this.getPosition('.dp-dropdown-menu.active .dropdown-component-type-list') - jQuery(document).scrollTop();
    const totalMenuHeight = menuOffset + menuHeight + itemMenuHeight * itemsCount + (this.addExtraHeight ? EXTRA_HEIGHT : 0);

    let visibleWin = this.getPosition(window);
    // console.table([menuHeight, menuOffset, visibleWin]);
    if (itemsCount < 10 && menuLevelIndex === this.secondLevelMenuIndex && (totalMenuHeight > visibleWin)) {
      return `-${ ((itemsCount - 1) * itemMenuHeight) - 3 }px`;
    }
    return '10px';
  }

  emitClose(): void {
    this.menuOpened.emit('close');
  }

  emitFocus(): void {
    this.burgerFocused.emit('focus');
  }

  burgerOnFocused(event) {
    this.burgerFocused.emit(event);
  }

  burgerOnBlur(event) {
    this.burgerBlured.emit(event);
  }

  get isImageCssClassExisit(): boolean {
    return (this.dropDownItems && this.dropDownItems.some(item => item.isImageCssClassDefined != undefined));
  }
}
