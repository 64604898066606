import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {AccountingItemKeys} from '../../admin/soa/soa-constants';

export type AccountingStatementType = 'STATEMENT_OF_ACCOUNT' | 'TRUST_LEDGER';

export class AccountingMatter extends BaseEntity {

  constructor(am?: AccountingMatter) {
    super(am);
  }

  itemKey: string;

  isRegistrationOfCharge(): boolean {
    return (this.itemKey === AccountingItemKeys[ AccountingItemKeys.REGISTRATION_OF_CHARGE_KEY ]);
  }

  isRegistrationOfMortgageDischarge(): boolean {
    return (this.itemKey === AccountingItemKeys[ AccountingItemKeys.REGISTRATION_OF_MORTGAGE_DISCHARGE_KEY ]);
  }

  isRegistrationTransfer(): boolean {
    return (this.itemKey === AccountingItemKeys[ AccountingItemKeys.REGISTRATION_OF_TRANSFER_KEY ]);
  }

  isRegistrationOfMortgage(): boolean {
    return (this.itemKey === AccountingItemKeys[ AccountingItemKeys.REGISTRATION_OF_MORTGAGE_KEY ]);
  }

  isRegistrationOfVTBMortgage(): boolean {
    return (this.itemKey === AccountingItemKeys[ AccountingItemKeys.REGISTRATION_OF_VTB_MORTGAGE_KEY ]);
  }

  isRegistrationDischargeOfOther(): boolean {
    return (this.itemKey === AccountingItemKeys[ AccountingItemKeys.REGISTRATION_OF_DISCHARGE_OF_OTHER_ENCUMBRANCES_KEY ]);
  }

  isRegisterVendorsLienCaveat(): boolean {
    return (this.itemKey === AccountingItemKeys[ AccountingItemKeys.REGISTRATION_OF_VENDOR_LIEN_CAVEAT_KEY ]);
  }

  isDischargeVendorsLienCaveat(): boolean {
    return (this.itemKey === AccountingItemKeys[ AccountingItemKeys.DISCHARGE_VENDOR_LIEN_CAVEAT_KEY ]);
  }

  isDTT(): boolean {
    return (this.itemKey == AccountingItemKeys[ AccountingItemKeys.NS_DTT_KEY ]);
  }

  // Land Title Tax for New Brunswick
  isNBLTT(): boolean {
    return (this.itemKey === AccountingItemKeys[ AccountingItemKeys.NB_LTT_KEY ]);
  }

  //PAID_TITLE_REGISTRATION_FEE
  isPaidTitleRegistrationFee(): boolean {
    return (this.itemKey === AccountingItemKeys[ AccountingItemKeys.PAID_TITLE_REGISTRATION_FEE ]);
  }

  //PAID_MORTGAGE_REGISTRATION_FEE
  isPaidMortgageRegistrationFee(): boolean {
    return (this.itemKey === AccountingItemKeys[ AccountingItemKeys.PAID_MORTGAGE_REGISTRATION_FEE ]);
  }

  isOurFee(): boolean {
    return this.itemKey === AccountingItemKeys[ AccountingItemKeys.FEE_PURCHASE_TRANSACTION_KEY ]
      || this.itemKey === AccountingItemKeys[ AccountingItemKeys.FEE_SALE_TRANSACTION_KEY ]
      || this.itemKey === AccountingItemKeys[ AccountingItemKeys.FEE_MORTGAGE_TRANSACTION_KEY ]
      || this.itemKey === AccountingItemKeys[ AccountingItemKeys.FEE_ARRANGED_MORTGAGE ]
      || this.itemKey === AccountingItemKeys[ AccountingItemKeys.FEE_ADDITIONAL_MORTGAGE ]
            || this.itemKey === AccountingItemKeys[AccountingItemKeys.FEE_WILL_TRANSACTION_KEY];
    }

}
