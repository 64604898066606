<ng-container *ngIf="!showWizardFields">
    <div class="row margin-bottom-140 property-teranet-header margin-left-0" tabindex="0">
        <div class="col-lg-12" [dp-read-only-block]="isMatterEditingDisabledAfterPostClosingDate"  [elementRefComp]="elementRef">
            <div *ngIf="propertyModel">
                <form #propertyTeranetForm="ngForm" id="propertyTeranet" role="form" (keyup)="formChangeAfterInit()"
                      class="form-horizontal"
                      autocomplete="off">
                    <div tabindex="4">
                        <ng-container *ngTemplateOutlet="jurisdiction"></ng-container>
                        <ng-container *ngTemplateOutlet="propertyAddress"></ng-container>

                        <div class="form-group">
                            <ng-container *ngTemplateOutlet="isCondo"></ng-container>
                            <div *dpShowByProvince="'matter.propertyTeranet.purchaseIsOf'">
                                <label class="control-label col-lg-2"
                                       *dpIfApplicableForMatter="'matter.propertyTeranet.purchaseIsOf.PS'"
                                       for="purchaseTypes">{{txnType}} is of</label>
                                <label class="control-label col-lg-2"
                                       *dpIfApplicableForMatter="'matter.propertyTeranet.purchaseIsOf.M'"
                                       for="purchaseTypes">Property to be<br>Mortgaged is</label>
                                <div class="col-lg-4">
                                    <select class="form-control"
                                            id="purchaseTypes"
                                            name="purchaseTypes"
                                            [dp-read-only]="matter.isProjectSale"
                                            [ngModel]="propertyModel.purchaseIsOfCode"
                                            [fieldKey]="'matter.propertyteranet.soAdj.other.fixed'"
                                            dp-error-validator
                                            (change)="onPurchaseTypeChange($event)">
                                        <option *ngFor="let purchaseType of purchaseTypes" [value]="purchaseType.value">
                                            {{purchaseType.label}}
                                        </option>
                                    </select>

                                </div>
                            </div>
                        </div>

                        <ng-container *ngIf="isInsurerStewartChicagoFCT()">
                            <div class="form-group">
                                <label class="control-label col-lg-2" for="propertyType">Title Ins Property Type</label>
                                <div class="col-lg-4">
                                    <select class="form-control" [ngClass]="{ 'not-disabled': isLinkFieldNotDisable()}"
                                            id="propertyType"
                                            name="propertyType"
                                            [(ngModel)]="propertyModel.titleInsurancePropertyType"
                                            (change)="formChangeAfterInit();enableSave()">
                                        <option *ngFor="let propertyTyp of titleInsurancePropertyTypes"
                                                [value]="propertyTyp.value">
                                            {{propertyTyp.label}}
                                        </option>
                                    </select>
                                </div>
                                <div *ngIf="isTitleInsurancePropertyTypeMultiUnit()">
                                    <label class="control-label col-lg-2" for="purchaseTypes">{{noOfUnitsLabel}}</label>
                                    <div class="col-lg-1">
                                        <select class="form-control" id="noUnits"
                                                [ngClass]="{ 'not-disabled': isLinkFieldNotDisable()}"
                                                name="noUnits" dp-error-validator (change)="enableSave()"
                                                [fieldKey]="'matter.propertyTeranet.noOfUnits'"
                                                [(ngModel)]="propertyModel.titleInsuranceNumOfUnits">
                                            <option *ngFor="let noOfUnit of titleInsuranceNoOfUnits" [value]="noOfUnit">
                                                {{noOfUnit}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *dpShowByProvince="'matter.propertyTeranet.propertyType'">
                            <div class="form-group">
                                <label class="control-label col-lg-2">Property Type</label>
                                <div class="col-lg-4">
                                    <select class="form-control"
                                            id="propertyTypeNB"
                                            name="propertyTypeNB"
                                            [(ngModel)]="propertyModel.propertyType"
                                            (change)="setDefaultNoOfUnitsOnPropertyChange();enableSave()">
                                        <option *ngFor="let propertyTyp of propertyTypes" [value]="propertyTyp.value">
                                            {{propertyTyp.label}}
                                        </option>
                                    </select>
                                </div>
                                <div *ngIf="isPropertyTypeMultiUnit()">
                                    <label class="control-label col-lg-2" for="purchaseTypes">No. of residential
                                        Units</label>
                                    <div class="col-lg-1">
                                        <input type="text" id="noUnitsNB" name="noUnitsNB" class="form-control"
                                               [(ngModel)]="propertyModel.numOfUnits" trimmed numberOnly
                                               maxlength="2"
                                               (ngModelChange)="onChange($event)"/>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <div
                            [ngClass]="{'group_indicator_bg': isPotlExpanded(), 'relative-position' : isPotlExpanded() }"
                            *dpShowByProvince="'matter.propertyTeranet.isParcelOfTiedLand'">
                            <div class="form-group">
                                <label class="control-label col-lg-2" for="isParcelOfTiedLand">Is there a POTL?</label>
                                <div class="col-lg-4">
                                    <div class="col-lg-3 padding-left-0">
                                        <select class="form-control" (ngModelChange)="onChange($event)"
                                                id="isParcelOfTiedLand"
                                                name="isParcelOfTiedLand" [(ngModel)]="propertyModel.isParcelOfTiedLand"
                                                [dp-read-only]="matter && !!matter.project">
                                            <option *ngFor="let parcelOfTiedLand of parcelOfTiedLandOptions"
                                                    [value]="parcelOfTiedLand.value">
                                                {{parcelOfTiedLand.label}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="propertyModel.isParcelOfTiedLand == 'YES'">
                                <div class="form-group">
                                    <label class="control-label col-lg-2" for="nameOfCondominiumPlan">Name of<br/>Condominium
                                        Plan</label>
                                    <div class="col-lg-10">
                                        <input type="text" trimmed id="nameOfCondominiumPlan"
                                               name="nameOfCondominiumPlan" class="form-control" maxlength="100"
                                               [(ngModel)]="propertyModel.nameOfCondominiumPlan"
                                               [dp-read-only]="disablePOTL"
                                               [dynamicHelpCallBackFunction]="generateF9NameOfCondominiumPlanHelpText()"
                                               statusBarHelp
                                               (keydown.f9)="handleF9NameOfCondominiumPlan()"
                                        />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="control-label col-lg-2" for="percentageInterest">Percentage
                                        Interest</label>
                                    <div class="col-lg-4">
                                        <div class="col-lg-4 padding-left-0">
                                            <input type="text" id="percentageInterest" name="percentageInterest"
                                                   class="form-control"
                                                   [(ngModel)]="propertyModel.percentageInterest"
                                                   (ngModelChange)="onAutoCalculateCommonExpense($event)"
                                                   dp-percentage
                                                   max-decimals=9
                                            />
                                        </div>
                                        <label class="control-label col-lg-1 text-left">%</label>
                                    </div>
                                    <div *ngIf="matter?.project?.potlFlag">
                                    <span
                                        title="Check to automatically calculate monthly common expenses based on the Total POTL common expenses of ${{ matter.project && matter.project.commonExpensesForEntireCondo ? matter.project.commonExpensesForEntireCondo : 0  | currency:'CAD':'':'1.2-2' }}"
                                    >
                                        <div class="col-lg-1 width-25">
                                                <dp-checkbox fieldId="autoCalculateCommonExpense">
                                                        <input type="checkbox"
                                                               id="autoCalculateCommonExpense"
                                                               name="autoCalculateCommonExpense"
                                                               [(ngModel)]="propertyModel.autoCalculateCommonExpense"
                                                               (ngModelChange)="onAutoCalculateCommonExpense($event)"
                                                        />
                                                        </dp-checkbox>
                                            </div>
                                            <label class="col-lg-4 semi-bold font-size-12"
                                                   for="autoCalculateCommonExpense">Automatically calculate common
                                                expenses?</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="control-label col-lg-2" for="commonExpenses">Common Expenses</label>
                                    <div class="col-lg-4">
                                        <div class="col-lg-4 padding-left-0">
                                            <input type="text" id="commonExpenses" name="commonExpenses"
                                                   class="form-control"
                                                   [(ngModel)]="propertyModel.commonExpenses"
                                                   (ngModelChange)="onChange($event);matter.updatePOTLAdjustmentsWithCommonExpense();"
                                                   dp-currency
                                                   [dp-read-only]="propertyModel.autoCalculateCommonExpense"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isPotlExpanded()" class="bottom-tick"><img
                                src="/assets/img/bottom-shutter-tick.png" alt=""/></div>
                        </div>

                        <ng-container
                            *ngTemplateOutlet="interestEstateInput"
                        ></ng-container>

                        <ng-container
                            *ngTemplateOutlet="strataMaintenanceFeesInput"
                        ></ng-container>

                        <div class="form-group" *dpShowByProvince="'matter.propertyTeranet.farmlandEvidence'">
                            <label class="control-label col-lg-2" for="farmlandEvidence">Farmland's Evidence</label>
                            <div class="col-lg-4">
                                <select class="form-control" (ngModelChange)="farmlandEvidenceChange($event)"
                                        id="farmlandEvidence"
                                        name="farmlandEvidence" [(ngModel)]="propertyModel.farmlandEvidence">
                                    <option *ngFor="let farmlandEvidence of farmlandEvidenceOptions"
                                            [value]="farmlandEvidence.value">
                                        {{farmlandEvidence.label}}
                                    </option>
                                </select>
                            </div>
                            <ng-container *ngIf="isFarmlandEvidenceOther()">
                                <label class="control-label col-lg-2" for="section">Section</label>
                                <div class="col-lg-4">
                                    <input type="text" id="farmlandSection" name="farmlandSection" class="form-control"
                                           [(ngModel)]="propertyModel.farmlandSection" maxlength="25"
                                    />
                                </div>
                            </ng-container>

                        </div>

                        <div class="form-group" *dpShowByProvince="'matter.propertyTeranet.registryOrLt'">
                            <label class="control-label col-lg-2" for="registryOrLt">Registry or LT</label>
                            <div class="col-lg-4">
                                <select class="form-control" (ngModelChange)="onRegistryOrLTChange($event)"
                                        id="registryOrLt"
                                        name="registryOrLt" [(ngModel)]="propertyModel.registryOrLt"
                                        [dp-read-only]="disableRegistryOrLt">
                                    <option *ngFor="let registry of registryLt" [value]="registry.value">
                                        {{registry.label}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group" *dpShowByProvince="'matter.matterProperty.nameOfProject'">
                            <label class="control-label col-lg-2" for="commonExpenses">Name of Project</label>
                            <div class="col-lg-10">
                                <input type="text" id="nameOfProject" name="nameOfProject" class="form-control"
                                       [(ngModel)]="propertyModel.projectName"
                                       maxlength="100"
                                       (ngModelChange)="onChange($event)"/>
                            </div>
                        </div>

                        <ng-container *ngTemplateOutlet="thumbnailDesc"></ng-container>

                        <ng-container *ngTemplateOutlet="unitLevelPlan"></ng-container>

                        <div class="main-level" *ngIf="matter.isMatterProvinceSK">
                            <span class="title no-min-height">Parcels</span>
                        </div>
                        <ng-container *ngTemplateOutlet="condominiumTypeAndPlan"></ng-container>
                        <ng-container *ngTemplateOutlet="parcels"></ng-container>
                        <ng-container *ngTemplateOutlet="titleRollNumbers"></ng-container>
                        <ng-container *ngTemplateOutlet="titleRollNumberLegalDescription"></ng-container>
                        <ng-container *ngTemplateOutlet="condomoniumTypePlanMBSK"></ng-container>

                        <ng-container *ngTemplateOutlet="legalDescriptionAB"></ng-container>
                        <ng-container *ngIf="matter.isMatterProvinceBC">
                            <ng-container *ngTemplateOutlet="legalDescriptionBC; context: {property: propertyModel, j: -1}"></ng-container>
                            <ng-container *ngFor="let matterProperty of matter.additionalMatterProperty;let j=index;">
                                <ng-container *ngTemplateOutlet="legalDescriptionBC; context: {property: matterProperty, j: j}"></ng-container>
                            </ng-container>
                            <ng-container
                                *ngTemplateOutlet="addAnotherPidInput"
                            ></ng-container>
                            <ng-container *ngTemplateOutlet="titleSearchPerformedInput"></ng-container>
                            <ng-container *dpShowByProvince="'matter.subjectProperty.percentInterestOfProperty'">
                                <div class="form-group">
                                    <label class="control-label col-lg-2" for="commonExpenses">% Interest of Property</label>
                                    <div class="col-lg-4">
                                        <input type="text"
                                            id="percentInterestOfProperty"
                                            name="percentInterestOfProperty"
                                            [(ngModel)]="propertyModel.percentInterestOfProperty"
                                            class="form-control text-right"
                                            dp-percentage
                                        />
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>

                        <div class="form-group" *dpShowByProvince="'matter.subjectProperty.titleNumber'">
                            <label class="control-label col-lg-2"
                                   *dpIfApplicableForMatter="'matter.subjectProperty.titleNumber.PM'">Title No.</label>
                            <label class="control-label col-lg-2"
                                   *dpIfApplicableForMatter="'matter.subjectProperty.titleNumber.S'">Vendor's Title
                                Number</label>
                            <div class="col-lg-4">
                                <input type="text" trimmed id="titleNumber" name="titleNumber" class="form-control"
                                       maxlength="200"
                                       [(ngModel)]="propertyModel.titleNumber"
                                />

                            </div>
                        </div>

                        <ng-container *ngTemplateOutlet="nonCondoFieldsON"></ng-container>

                        <div class="form-group" *dpShowByProvince="'matter.subjectProperty.parcelSection'">
                            <label class="control-label col-lg-2" for="parcel">"Parcel"</label>
                            <div class="col-lg-4">
                                <input type="text" trimmed [dp-read-only]="disableParcel" id="parcel" name="parcel"
                                       maxlength="80"
                                       [dynamicHelpCallBackFunction]="generateF9OnParcel()"
                                       statusBarHelp
                                       (change)="updateTeraviewLegalDescs()"
                                       class="form-control" [(ngModel)]="propertyModel.parcel"
                                       (keydown.f9)="handleF9OnParcel()"/>
                            </div>
                            <label class="control-label col-lg-2" for="section">"Section"</label>
                            <div class="col-lg-4">
                                <input type="text" trimmed id="section"
                                       [dp-read-only]="disableSection" name="section"
                                       (change)="updateTeraviewLegalDescs()"
                                       [fieldKey]="'property.section'"
                                       statusBarHelp
                                       class="form-control" [(ngModel)]="propertyModel.section"
                                       (keydown.f9)="handleF9OnSection()" maxlength="80"/>
                            </div>
                        </div>

                        <div class="form-group" *dpShowByProvince="'matter.subjectProperty.easementRightOfWay'">
                            <label class="control-label col-lg-2" for="easementRightOfWay">"Easement/R of W"</label>
                            <div class="col-lg-10">
                                <input *ngIf="!isHandF9EasementRightOfWayVisible()" type="text" trimmed
                                       id="easementRightOfWay" name="easementRightOfWay"
                                       maxlength="250"
                                       (change)="updateTeraviewLegalDescs()" [dp-read-only]="matter.isMatterProvinceNB"
                                       class="form-control" [(ngModel)]="propertyModel.easementRightOfWay"
                                />
                                <input *ngIf="isHandF9EasementRightOfWayVisible()" type="text" trimmed
                                       id="easementRightOfWayF9" name="easementRightOfWayF9"
                                       maxlength="250"
                                       (change)="updateTeraviewLegalDescs()"
                                       (keydown.f9)="handF9EasementRightOfWay()"
                                       [fieldKey]="'property.easementRightOfWay'" statusBarHelp
                                       class="form-control" [(ngModel)]="propertyModel.easementRightOfWay"
                                />
                            </div>
                        </div>

                        <div class="col-lg-12" *dpShowByProvince="'matter.subjectProperty.rOfWCovenants'">
                            <dp-accordion [expanded]="true">
                                <div class="dp-accordion-header">
                                    <div class="row  row-group">
                                        <div class="col-lg-2 vertical-align-label">
                                            <label class="control-label">R of W/Covenants</label>
                                        </div>
                                        <div class="col-lg-6">
                                            <input type="text" id="rOfWCovenants" name="rOfWCovenants"
                                                   class="form-control text-overflow rightPadding95"
                                                   [ngModel]="getROfWCovenantsPropertyTab()" [dp-read-only]="true">
                                        </div>
                                    </div>
                                </div>

                                <div class="dp-accordion-content row">
                                    <div class="col-lg-12 form-group margin-top-15">
                                        <div class="col-lg-2 vertical-align-label">
                                            <label class="control-label" for="interestToBenefitParcel">Interest to
                                                benefit <br>parcel</label>
                                        </div>
                                        <div class="col-lg-1">
                                            <select class="form-control" id="interestToBenefitParcel"
                                                    name="interestToBenefitParcel"
                                                    [(ngModel)]="propertyModel.interestToBenefitParcel"
                                                    (ngModelChange)="enableSave()">
                                                <option *ngFor="let item of interestToBenefitParcelOptions"
                                                        [value]="item.value">{{item.label}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 form-group margin-top-15">
                                        <div class="col-lg-2 vertical-align-label">
                                            <label class="control-label" for="interestToBurndenParcel">Interest to
                                                burden <br>the parcel</label>
                                        </div>
                                        <div class="col-lg-1">
                                            <select class="form-control" id="interestToBurndenParcel"
                                                    name="interestToBurndenParcel"
                                                    [(ngModel)]="propertyModel.interestToBurndenParcel"
                                                    (ngModelChange)="enableSave()">
                                                <option *ngFor="let item of interestToBurdenParcelOptions"
                                                        [value]="item.value">{{item.label}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 form-group margin-top-15">
                                        <div class="col-lg-2 vertical-align-label">
                                            <label class="control-label" for="subjectToCovenantsAndConditions">Subject
                                                to covenants<br> and conditions</label>
                                        </div>
                                        <div class="col-lg-1">
                                            <select class="form-control" id="subjectToCovenantsAndConditions"
                                                    name="subjectToCovenantsAndConditions"
                                                    [(ngModel)]="propertyModel.subjectToCovenantsAndConditions"
                                                    (ngModelChange)="enableSave()">
                                                <option *ngFor="let item of subjectToCovenantsAndConditionsOptions"
                                                        [value]="item.value">{{item.label}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </dp-accordion>
                        </div>


                        <div class="form-group" *dpShowByProvince="'matter.subjectProperty.city'">
                            <label class="control-label col-lg-2" for="city"
                                   dp-province-field-label="matter.subjectProperty.city">"City"</label>
                            <div class="col-lg-10">
                                <input type="text" trimmed id="city" name="city" class="form-control"
                                       [(ngModel)]="propertyModel.city" maxlength="100"
                                       (change)="updatePropertyTaxPaidTrustLedger()"/>
                            </div>
                        </div>

                        <div class="form-group" *dpShowByProvince="'matter.subjectProperty.municipality'">
                            <label class="control-label col-lg-2" for="municipality"
                                   dp-province-field-label="matter.subjectProperty.municipality">"Municipality"</label>
                            <div class="col-lg-10">
                                <input type="text" trimmed id="municipality" name="municipality" class="form-control"
                                       [(ngModel)]="propertyModel.municipality" maxlength="100"
                                />
                            </div>
                        </div>
                        <ng-container *ngTemplateOutlet="landTitleOfficeLocation"></ng-container>

                        <div class="form-group" *ngIf="!matter.isMatterProvinceBC">
                            <ng-container *ngTemplateOutlet="registryLandTitleOffice"></ng-container>
                        </div>

                        <div class="form-group" [fieldKey]="'property.rollNumber'"
                             *dpShowByProvince="'matter.subjectProperty.rollNumberFields'" statusBarHelp>
                            <label class="control-label col-lg-2">Roll Number</label>
                            <div *ngIf="isRollNumberFieldsVisible" (keydown.f9)="handleF9OnRollNumber($event)">
                                <div class="col-lg-1">
                                    <input #rollNumberFocusable type="text" trimmed numberOnly id="rollnumber0"
                                           name="rollnumber0"
                                           class="form-control" [(ngModel)]="propertyModel.rollNumber.city"
                                           maxlength="2"
                                           dp-autoMoveNextOnMaxlength="rollnumber1"
                                    />
                                </div>
                                <div class="col-lg-1">
                                    <input type="text" trimmed numberOnly id="rollnumber1" name="rollnumber1"
                                           class="form-control" [(ngModel)]="propertyModel.rollNumber.municipality"
                                           maxlength="2"
                                           dp-autoMoveNextOnMaxlength="rollnumber2"
                                    />
                                </div>
                                <div class="col-lg-1">
                                    <input type="text" trimmed numberOnly id="rollnumber2" name="rollnumber2"
                                           class="form-control" [(ngModel)]="propertyModel.rollNumber.map" maxlength="3"
                                           dp-autoMoveNextOnMaxlength="rollnumber3"
                                    />
                                </div>
                                <div class="col-lg-1">
                                    <input type="text" trimmed numberOnly id="rollnumber3" name="rollnumber3"
                                           class="form-control" [(ngModel)]="propertyModel.rollNumber.subdivision"
                                           maxlength="3"
                                           dp-autoMoveNextOnMaxlength="rollnumber4"
                                    />
                                </div>
                                <div class="col-lg-2">
                                    <input type="text" trimmed numberOnly id="rollnumber4" name="rollnumber4"
                                           class="form-control" [(ngModel)]="propertyModel.rollNumber.parcel"
                                           maxlength="5"
                                           dp-autoMoveNextOnMaxlength="rollnumber5"
                                    />
                                </div>
                                <div class="col-lg-2">
                                    <input type="text" trimmed numberOnly id="rollnumber5" name="rollnumber5"
                                           class="form-control" [(ngModel)]="propertyModel.rollNumber.portion"
                                           maxlength="4"
                                           dp-autoMoveNextOnMaxlength="rollnumber6"
                                    />
                                </div>
                                <div class="col-lg-1">
                                    <input type="text" trimmed numberOnly id="rollnumber6" name="rollnumber6"
                                           class="form-control" [(ngModel)]="propertyModel.rollNumber.jurisdiction"
                                           maxlength="2"
                                           dp-autoMoveNextOnMaxlength
                                    />
                                </div>
                            </div>

                            <div *ngIf="!isRollNumberFieldsVisible" (keydown.f9)="handleF9OnRollNumber($event)">
                                <div class="col-lg-2">
                                    <input #noRollNumberFocusable type="text" trimmed id="noRollNumber"
                                           name="noRollNumber" class="form-control"
                                           [(ngModel)]="rollNumberType" readonly
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="form-group" *dpShowByProvince="'matter.subjectProperty.assessmentRollNumber'">
                            <label class="control-label col-lg-2" for="assessmentRollNumber">Assessment Roll No.</label>
                            <div class="col-lg-4">
                                <input type="text" trimmed numberOnly id="assessmentRollNumber"
                                       name="assessmentRollNumber" [(ngModel)]="propertyModel.rollNumber.city"
                                       class="form-control"
                                       maxlength="20"/>
                            </div>
                        </div>
                        <ng-container *ngIf="matter.isMatterProvinceBC">
                            <ng-container *ngTemplateOutlet="titleSearchPerformedInput"></ng-container>
                        </ng-container>

                        <div class="form-group" *dpShowByProvince="'matter.subjectProperty.lincNo'">
                            <div *ngIf="propertyModel.isCondominium  === 'YES'">
                                <ng-container *ngIf="matter.isTemplateMatterForMassUpdate">
                                    <label class="control-label col-lg-2" for="lincNo">LINC Number</label>
                                    <div class="col-lg-4">
                                        <input type="text" trimmed id="lincNo_massUpdate" name="lincNo_massUpdate"
                                               class="form-control" uppercased dp-lincnumber trimmed
                                               [(ngModel)]="propertyModel.lincNumber" maxlength="100"
                                               [dp-read-only]="true"
                                        />
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="!matter.isTemplateMatterForMassUpdate">
                                    <label class="control-label col-lg-2" for="lincNo">LINC Number</label>
                                    <div class="col-lg-4">
                                        <input type="text" trimmed id="lincNo" name="lincNo" class="form-control"
                                               uppercased dp-lincnumber trimmed
                                               [(ngModel)]="propertyModel.lincNumber" maxlength="100"
                                               [dp-read-only]="propertyModel.isCondominium  == 'YES'"
                                        />
                                        <button type="button"
                                                *ngIf="propertyModel.isCondominium  == 'YES'"
                                                tabIndex="-1"
                                                id="lincNoEditBtn"
                                                (click)="selectUnitLevelPlan()" class="button-glyph">
                                            <span class="glyphicon glyphicon-edit"></span>
                                        </button>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="matter.isTemplateMatterForMassUpdate">
                                    <label class="control-label col-lg-2" for="section">Roll Number </label>
                                    <div class="col-lg-4">
                                        <input type="text" trimmed numberOnly id="rollnumber_massUpdate"
                                               name="rollnumber0_massUpdate" uppercased
                                               class="form-control" [(ngModel)]="propertyModel.rollNumber.city"
                                               [dp-read-only]="true"
                                        />
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="!matter.isTemplateMatterForMassUpdate">
                                    <label class="control-label col-lg-2" for="section">Roll Number </label>
                                    <div class="col-lg-4">
                                        <input #rollNumberFocusable type="text" trimmed numberOnly id="rollnumber"
                                               name="rollnumber0" uppercased
                                               class="form-control" [(ngModel)]="propertyModel.rollNumber.city"
                                               [dp-read-only]="propertyModel.isCondominium  == 'YES'"
                                        />
                                        <button type="button"
                                                tabIndex="-1"
                                                *ngIf="propertyModel.isCondominium  == 'YES'"
                                                id="rollNoEditBtn"
                                                (click)="selectUnitLevelPlan()" class="button-glyph">
                                            <span class="glyphicon glyphicon-edit"></span>
                                        </button>
                                    </div>
                                </ng-container>
                            </div>
                        </div>

                        <div class="form-group" *ngIf="!matter?.isMatterProvinceBC">
                            <div *dpShowByProvince="'matter.subjectProperty.lastTransfer'">
                                <label class="control-label col-lg-2" for="lastTransfer">Last Transfer Number</label>
                                <div class="col-lg-4">
                                    <input type="text" trimmed id="lastTransfer" name="lastTransfer"
                                           class="form-control" maxlength="11"
                                           [(ngModel)]="propertyModel.lastTransferNumber"
                                    />
                                </div>
                            </div>
                            <div *dpShowByProvince="'matter.subjectProperty.lastInstrument'">
                                <label class="control-label col-lg-2" for="lastInstrument"
                                       dp-province-field-label="matter.subjectProperty.lastInstrumentLabel">Last
                                    Instrument Number</label>
                                <div class="col-lg-4">
                                    <input type="text" trimmed id="lastInstrument" name="lastInstrument"
                                           class="form-control" maxlength="40"
                                           [(ngModel)]="propertyModel.lastInstrumentNumber"
                                    />
                                </div>
                            </div>
                        </div>
                        <ng-container *ngTemplateOutlet="panAanNumber"></ng-container>

                        <div *dpShowByProvince="'matter.subjectProperty.pinDetails'">
                            <ng-container *ngTemplateOutlet="pinDetails"></ng-container>
                        </div>

                        <div class="form-group" *dpShowByProvince="'matter.subjectProperty.isVendorToSupplyRPR'">
                            <label class="control-label col-lg-2" for="isVendorToSupplyRPR ">Is the vendor to supply
                                RPR?</label>
                            <div class="col-lg-4">
                                <div class="col-lg-3 padding-left-0">
                                    <select class="form-control"
                                            id="isVendorToSupplyRPR " (ngModelChange)="onChange($event)"
                                            name="isVendorToSupplyRPR "
                                            [(ngModel)]="propertyModel.isVendorToSupplyRPR"
                                    >
                                        <option *ngFor="let option of vendorToSupplyRPROptions" [value]="option.value">
                                            {{option.label}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div *dpShowByProvince="'matter.subjectProperty.isRPRApplicable'">
                            <div class="form-group"
                                 *ngIf="propertyModel.isCondominium  == 'YES' && (matter && !matter.isMatterTypeDischarge)">
                                <label class="control-label col-lg-2" for="isVendorToSupplyRPR "
                                       dp-province-field-label="matter.subjectProperty.RPRApplicable">RPR
                                    Applicable</label>
                                <div class="col-lg-1 margin-top-10">
                                    <dp-checkbox fieldId="rprApplicable"><input type="checkbox"
                                                                                id="rprApplicable"
                                                                                name="rprApplicable"
                                                                                [(ngModel)]="propertyModel.rprApplicable"
                                                                                [checked]="propertyModel.rprApplicable"
                                                                                (click)="onRPRChange($event);"
                                                                                #rprApplicable></dp-checkbox>
                                </div>
                                <div class="col-lg-9" *ngIf="!propertyModel.rprApplicable">
                                    <input type="text" trimmed id="rprApplicableText" name="rprApplicableText"
                                           [dp-read-only]="true"
                                           class="form-control" value={{rprApplicableText}}
                                           maxlength="255"/>

                                </div>
                            </div>
                        </div>

                        <div class="form-group" *ngIf="isSurveyDateAndSurveyorApplicableForProvince()">
                            <label class="control-label col-lg-2" for="surveyDate"
                                   dp-province-field-label="matter.subjectProperty.surveyDate">Survey Date</label>
                            <div class="col-lg-4">
                                <dp-partial-date #partialSurveyorDate [fieldKey]="'property.surveyDate'"
                                                 statusBarHelp [inputDate]="propertyModel.surveyDate"
                                                 dp-error-validator (dateChange)="onDateChangeSurveyDate($event)">
                                </dp-partial-date>
                            </div>
                            <div>
                                <label class="control-label col-lg-2">Surveyor</label>

                                <div class="col-lg-4" *ngIf="isReadOnlyForSurveyor()">
                                    <input type="text"
                                           id="autocomplete-surveyor"
                                           readonly="readonly"
                                           class="form-control surveyor-header-name"
                                           *ngIf="isReadOnlyForSurveyor()"
                                           [value]="surveyorDisplayName"
                                           autocomplete="off"/>
                                    <i *ngIf="isReadOnlyForSurveyor()"
                                       class="fa fa-trash trash-icon"
                                       (click)="deleteSurveyor()"></i>
                                </div>
                                <div class="col-lg-4" id="surveyor" *ngIf="!isReadOnlyForSurveyor()">
                                    <p-autoComplete
                                        id="autocomplete-surveyor"
                                        #surveyorOmnibar
                                        name="autocomplete-surveyor"

                                        [(ngModel)]="selectedSurveyorContact"
                                        [class.autocomplete-searching]="surveyorsLoading"
                                        [suggestions]="surveyorContacts"
                                        field="organizationName"
                                        (completeMethod)="searchSurveyor($event)"
                                        [minLength]="0"
                                        [delay]="1000"
                                        scrollHeight="350px"
                                        fieldKey="matter.propertyteranet.surveyor"

                                        (onSelect)="dataSelectedSurveyor()"
                                        (focusout)="onBlurSurveyor($event)"
                                        [disabled]="isMatterLocked"
                                        placeholder="Search by Name, Address or Add new"
                                        class="autocomplete-search-icon">
                                        <ng-template let-surveyor pTemplate="item">
                                            <li [ngClass]="{'bold-font' : isAddNew(surveyor)}" [disableItem]="surveyor">
                                            <span class="col-lg-6 text-overflow"
                                                  [ngClass]="{'col-lg-9' : isAddNew(surveyor) , 'col-lg-6' : !isAddNew(surveyor)}">
                                                {{getSurveyorDisplayNameFromContact(surveyor)}}</span>
                                                <span
                                                    class="col-lg-6 text-overflow">{{getContactDisplayMailingAddress(surveyor)}}</span>
                                            </li>
                                        </ng-template>
                                    </p-autoComplete>
                                </div>
                            </div>
                        </div>
                        <div class="form-group"
                             *ngIf="isAttentionApplicable() && selectedSurveyorContact && selectedSurveyorContact.sourceContactId">
                            <div class="col-lg-6"></div>
                            <label class="control-label col-lg-2"
                                   for="surveyor-attention-autocomplete">Attention</label>
                            <div class="col-lg-4">
                                <p-autoComplete id="surveyor-attention-autocomplete"
                                                name="surveyor-attention-autocomplete"
                                                [(ngModel)]="selectedSurveyorContact.attention"
                                                (ngModelChange)="enableSave()"
                                                [suggestions]="attentionList"
                                                [class.dropdown-arrow-refferedBy]="true"
                                                [dropdown]="true"
                                                (keydown.space)="utils.handleSpaceKeyDropdown($event, selectedSurveyorContact.attention, onAttentionDropdownClick)"
                                                (keydown.enter)="utils.handleEnterKeyDropdown($event, selectedSurveyorContact.attention, onAttentionDropdownClick)"
                                                (onDropdownClick)="onAttentionDropdownClick($event)"
                                                (onSelect)="populateAttention($event)"
                                                [minLength]="0"
                                                [delay]="1000"
                                                maxlength="75"
                                                scrollHeight="350px"
                                                autocomplete="off"
                                                tabIndexIgnore>
                                    <ng-template let-attention pTemplate="item">
                                        <li class="ui-autocomplete-list-item ui-helper-clearfix"
                                            [disableItem]="attention">
                                        <span class="omnibarLi100 col-lg-4">
                                        {{attention.subContactDisplayName}}
                                        </span>
                                        </li>
                                    </ng-template>

                                </p-autoComplete>
                            </div>
                        </div>
                        <div *ngIf="propertyModel.purchaseIsOfCode  == 'NEW_BUILDER_HOME'">
                            <div class="form-group">
                                <label class="control-label col-lg-2" for="newHomeWarranty">New Home Warranty</label>
                                <div class="col-lg-4">
                                    <input type="text" trimmed id="newHomeWarranty" name="newHomeWarranty"
                                           class="form-control" maxlength="60"
                                           [(ngModel)]="propertyModel.newHomeWarranty"
                                    />
                                </div>
                                <label class="control-label col-lg-2" for="builderNumber">Builder No.</label>
                                <div class="col-lg-4">
                                    <input type="text" trimmed id="builderNumber" name="builderNumber"
                                           class="form-control" maxlength="60"
                                           [(ngModel)]="propertyModel.builderNumber"
                                           [dynamicHelpCallBackFunction]="generateF9BuilderNumberHelpText()"
                                           statusBarHelp
                                           (keydown.f9)="handleF9BuilderNumber()"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="control-label col-lg-2" for="propertyNumber">Property No.</label>
                                <div class="col-lg-4">
                                    <input type="text" trimmed id="propertyNumber" name="propertyNumber"
                                           class="form-control" maxlength="60"
                                           [(ngModel)]="propertyModel.propertyNumber"
                                    />
                                </div>
                            </div>
                        </div>
                        <div *ngIf="propertyModel.purchaseIsOfCode == 'MOBILE_HOME' && !matter.isMatterProvinceNS">
                            <div class="form-group">
                                <label class="control-label col-lg-2" for="mobileHomeBrand">Mobile Home Brand</label>
                                <div class="col-lg-4">
                                    <input type="text" trimmed id="mobileHomeBrand" name="mobileHomeBrand"
                                           class="form-control" maxlength="60"
                                           [(ngModel)]="propertyModel.mobileHomeBrand"
                                    />
                                </div>
                                <label class="control-label col-lg-2" for="mobileHomeModel">Mobile Home Model</label>
                                <div class="col-lg-4">
                                    <input type="text" trimmed id="mobileHomeModel" name="mobileHomeModel"
                                           class="form-control" maxlength="60"
                                           [(ngModel)]="propertyModel.mobileHomeModel"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="control-label col-lg-2" for="mobileHomeYear">Mobile Home Year</label>
                                <div class="col-lg-4">
                                    <input type="text" trimmed id="mobileHomeYear" name="mobileHomeYear"
                                           class="form-control" maxlength="60"
                                           [(ngModel)]="propertyModel.mobileHomeYear"
                                    />
                                </div>
                                <label class="control-label col-lg-2" for="mobileHomeSerial">Mobile Home Serial</label>
                                <div class="col-lg-4">
                                    <input type="text" trimmed id="mobileHomeSerial" name="mobileHomeSerial"
                                           class="form-control" maxlength="60"
                                           [(ngModel)]="propertyModel.mobileHomeSerial"
                                    />
                                </div>
                            </div>
                            <div class="form-group" *dpShowByProvince="'matter.subjectProperty.mobileHomeRegistration'">
                                <label class="control-label col-lg-2" for="mobileHomeRegistration">Mobile Home
                                    Registration No.</label>
                                <div class="col-lg-4">
                                    <input type="text" trimmed id="mobileHomeRegistration" name="mobileHomeRegistration"
                                           class="form-control" maxlength="60"
                                           [(ngModel)]="propertyModel.mobileHomeRegistration"
                                    />
                                </div>
                            </div>
                        </div>

                        <ng-container *ngIf="matter && !matter.isMatterTypeDischarge">
                            <div class="form-group" *dpShowByProvince="'matter.subjectProperty.zoningMemorandum'">
                                <div class="form-group"
                                     *ngIf="(isNotCondo() || (!isNotCondo() && propertyModel.rprApplicable))">
                                    <label class="control-label col-lg-2">Zoning Memorandum</label>
                                    <div class="col-lg-4">
                                        <dp-partial-date #zoningMemorandumDate
                                                         (dateChange)="onDateChangeZoningMemorandumDate($event)"
                                                         statusBarHelp [inputDate]="propertyModel.zoningMemorandum">
                                        </dp-partial-date>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group" *dpShowByProvince="'matter.subjectProperty.rentalAgreements'">
                                <label class="control-label col-lg-2">Rental agreements, etc</label>
                                <div class="col-lg-10">
                                    <input type="text" trimmed id="rentalAgreements" name="rentalAgreements"
                                           class="form-control" maxlength="250"
                                           [(ngModel)]="propertyModel.rentalAgreements"
                                    />
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="matter && !matter.isMatterTypeDischarge">
                            <div class="form-group"
                                 *dpShowByProvince="'matter.subjectProperty.surveyInCurrentOwnersName'">
                                <label class="control-label col-lg-2">Is the survey in the current owner's name?</label>
                                <div class="col-lg-1">
                                    <select class="form-control"
                                            id="surveyInCurrentOwnersName "
                                            name="surveyInCurrentOwnersName "
                                            [(ngModel)]="propertyModel.surveyInCurrentOwnersName"
                                            (ngModelChange)="enableSave()"
                                    >
                                        <option *ngFor="let option of surveyInCurrentOwnerNameOptions"
                                                [value]="option.value">
                                            {{option.label}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group"
                                 *dpShowByProvince="'matter.subjectProperty.changeInPropertyAfterSurvey'">
                                <label class="control-label col-lg-2">Has there been a change to the property since the
                                    date of the survey?</label>
                                <div class="col-lg-1">
                                    <select class="form-control"
                                            id="changeInPropertyAfterSurvey "
                                            name="changeInPropertyAfterSurvey "
                                            [(ngModel)]="propertyModel.changeInPropertyAfterSurvey"
                                            (ngModelChange)="enableSave()"
                                    >
                                        <option *ngFor="let option of changeInPropertyAfterSurveyOptions"
                                                [value]="option.value">
                                            {{option.label}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group" *dpShowByProvince="'matter.subjectProperty.heatType'">
                                <label class="control-label col-lg-2">How is property heated?</label>
                                <div class="col-lg-2">
                                    <select class="form-control"
                                            id="heatType " (ngModelChange)="onChange($event)"
                                            name="heatType "
                                            [(ngModel)]="propertyModel.heatType"
                                    >
                                        <option *ngFor="let option of heatTypeOptions" [value]="option.value">
                                            {{option.label}}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group" *dpShowByProvince="'matter.subjectProperty.hasPropertyMigrated'">
                                <label class="control-label col-lg-2">Has property been migrated?</label>
                                <div class="col-lg-1">
                                    <select class="form-control"
                                            id="hasPropertyMigrated " (ngModelChange)="onChange($event)"
                                            name="hasPropertyMigrated "
                                            [(ngModel)]="propertyModel.hasPropertyMigrated"
                                    >
                                        <option *ngFor="let option of hasPropertyMigratedOptions"
                                                [value]="option.value">
                                            {{option.label}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngTemplateOutlet="purchasePriceAndDeposit"></ng-container>

                        <ng-container *ngTemplateOutlet="NonResidentDeedTransferTax"></ng-container>

                        <div class="form-group" *dpShowByProvince="'matter.subjectProperty.assessedValue'">
                            <label class="control-label col-lg-2" for="assessedValue">Assessed Value </label>
                            <div class="col-lg-4">
                                <input type="text" style="text-align:right;" id="assessedValue" name="assessedValue"
                                       class="form-control"
                                       [(ngModel)]="propertyModel.assessedValue" (change)="calculateLandTransferTax()"
                                       dp-currency/>
                            </div>
                        </div>

                        <div class="form-group" *dpShowByProvince="'matter.subjectProperty.landTransferTaxRate'">
                            <label class="control-label col-lg-2" for="landTransferTax">Transfer Tax </label>
                            <div class="col-lg-4">
                                <select
                                    class="form-control"
                                    id="landTransferTaxRate"
                                    name="landTransferTaxRate"
                                    [(ngModel)]="propertyModel.landTransferTaxRateId"
                                    (ngModelChange)="calculateLandTransferTax();enableSave()"
                                >
                                    <option *ngFor="let landTransferTaxRate of landTransferTaxRateOptions"
                                            [value]="landTransferTaxRate.value">
                                        {{landTransferTaxRate.label}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-lg-2"></div>
                            <div class="col-lg-4">
                                <input type="text" id="landTransferTaxNB" name="landTransferTaxNB"
                                       class="form-control text-padding-update"
                                       style="text-align:right;" dp-read-only="true" dp-currency
                                       [(ngModel)]="propertyModel.landTransferTax"/>
                            </div>
                        </div>

                        <div class="form-group" *dpShowByProvince="'matter.subjectProperty.landTransferTax'">
                            <label class="control-label col-lg-2" for="landTransferTax"
                                   dp-province-field-label="matter.subjectProperty.landTransferTax">Land Transfer
                                Tax</label>
                            <div class="col-lg-4">
                                <input type="text" id="landTransferTax" name="landTransferTax"
                                       class="form-control text-right"
                                       [(ngModel)]="propertyModel.landTransferTax"
                                       dp-read-only="true"
                                       dp-currency
                                />
                            </div>
                        </div>

                        <div class="form-group" *dpShowByProvince="'matter.subjectProperty.form54'">
                            <label class="control-label col-lg-2" for="assessedValue"
                                   dp-province-field-label="matter.subjectProperty.form54">Form 54 </label>
                            <div class="col-lg-4">
                                <input class="form-control"
                                       id="form54"
                                       name="form54"
                                       [ngModel]="getForm54DefaultText()"
                                       [dp-read-only]/>
                                <button type="button"
                                        tabIndex="-1"
                                        id="form54Btn" class="button-glyph"
                                        (click)="openForm54Modal()">
                                    <span class="glyphicon glyphicon-edit"></span>
                                </button>
                            </div>
                            <div class="col-lg-4">
                                <p class="date-string">{{getForm54SignedByText()}}</p>
                            </div>
                        </div>

                        <div class="form-group" *ngIf="matter && !matter.isMatterTypeDischarge && !matter.isMatterProvinceBC">
                            <div *dpShowByProvince="'matter.subjectProperty.recordingMortgage'">
                                <label class="control-label col-lg-2" for="recordingMortgage">Recording a
                                    Mortgage</label>
                                <div class="col-lg-4">
                                    <input type="text" id="recordingMortgage" name="recordingMortgage"
                                           class="form-control  text-right"
                                           [(ngModel)]="propertyModel.recordingMortgage" dp-read-only="true" dp-currency
                                           dp-default-currency
                                    />
                                </div>
                            </div>
                            <div *dpShowByProvince="'matter.subjectProperty.recordingDeed'">
                                <label class="control-label col-lg-2" for="recordingDeed">Recording a Deed</label>
                                <div class="col-lg-4">
                                    <input type="text" id="recordingDeed" name="recordingDeed"
                                           class="form-control  text-right"
                                           [(ngModel)]="propertyModel.recordingDeed" dp-read-only="true" dp-currency
                                           dp-default-currency
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="form-group" *ngIf="!matter?.isMatterProvinceBC">
                            <div *dpShowByProvince="'matter.subjectProperty.titleRegistrationFee'">
                                <label class="control-label col-lg-2" for="titleRegistrationFee">Title Registration
                                    Fee</label>
                                <div class="col-lg-4">
                                    <input type="text" id="titleRegistrationFee" name="titleRegistrationFee"
                                           class="form-control"
                                           [(ngModel)]="propertyModel.titleRegistrationFee" dp-read-only="true"
                                           dp-currency
                                           dp-default-currency
                                    />
                                </div>
                            </div>
                            <ng-container *ngIf="matter && !matter.isMatterTypeDischarge">
                                <div *dpShowByProvince="'matter.subjectProperty.mortgageRegistrationFee'">
                                    <label class="control-label col-lg-2" for="mortgageRegistrationFee">Mortgage
                                        Registration Fee</label>
                                    <div class="col-lg-4">
                                        <input type="text" id="mortgageRegistrationFee" name="mortgageRegistrationFee"
                                               class="form-control"
                                               [(ngModel)]="propertyModel.mortgageRegistrationFee" dp-read-only="true"
                                               dp-currency
                                               dp-default-currency
                                        />
                                    </div>
                                </div>
                            </ng-container>

                        </div>
                        <div class="form-group" *dpShowByProvince="'matter.subjectProperty.occupancy'">
                            <label class="control-label col-lg-2" for="occupancy">Occupancy</label>
                            <div class="col-lg-4">
                                <select class="form-control" id="occupancy"
                                        name="occupancy" [(ngModel)]="propertyModel.occupancy"
                                        (ngModelChange)="onChange($event)">
                                    <option *ngFor="let occupancyOption of occupancyOptions"
                                            [value]="occupancyOption.value">
                                        {{occupancyOption.label}}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <ng-container *ngTemplateOutlet="propertyTaxes"></ng-container>

                        <ng-container *ngTemplateOutlet="propertyUtilities"></ng-container>

                        <ng-container *ngIf="!isLinkedMatterProjectSale() && !isDischargeMatter()">
                            <div class="form-group" *dpShowByProvince="'matter.subjectProperty.residentAssociation'">
                                <label class="control-label col-lg-2" for="autocomplete-residentAssociation">Residents&apos;
                                    Association</label>
                                <div class="col-lg-10" *ngIf="residentAssociationReadOnly">
                                    <input type="text" id="autocomplete-residentAssociation"
                                           class="form-control"
                                           [value]="residentAssociationDisplayName"
                                           readonly="readonly"
                                           autocomplete="off"
                                    />
                                    <span class="trash-icon">
                                <i class="fa fa-trash"
                                   (click)="deleteResidentAssociation()"
                                   aria-hidden="true"
                                   [title]="'Delete Residents\' Association'"
                                   tabindex="0"
                                   (keyup.enter)="deleteResidentAssociation()"
                                >
                                </i>
                            </span>
                                </div>
                                <div class="col-lg-10 fullLength-autocomplete" *ngIf="!residentAssociationReadOnly">
                                    <p-autoComplete id="autocomplete-residentAssociation"
                                                    #residentAssociationOmnibar
                                                    name="residentAssociation"
                                                    [(ngModel)]="selectedResidentAssociation"
                                                    [class.autocomplete-searching]="residentAssociationLoading"
                                                    [suggestions]="residentAssociations"
                                                    (completeMethod)="searchResidentAssociation($event)"
                                                    field="organizationName"
                                                    [minLength]="0"
                                                    [delay]="1000"
                                                    maxlength="100"
                                                    scrollHeight="550px"
                                                    placeholder="Search by Name or Add new"
                                                    fieldKey="matter.propertyteranet.residentAssociation"
                                                    [disabled]="isMatterLocked"
                                                    (onSelect)="dataSelectedResidentAssociation()"
                                                    (focusout)="onBlurResidentAssociation($event)"
                                                    class="autocomplete-search-icon">

                                        <ng-template let-residentAssociation pTemplate="item">
                                            <li class="ui-autocomplete-list-item ui-helper-clearfix"
                                                [disableItem]="residentAssociation"
                                                [ngClass]="{'font-style-italic': residentAssociation?.fullName == constantNoResultValue,
                                        'bold-font' : isAddNew(residentAssociation) }">
                                        <span class="col-lg-4 text-overflow"
                                              *ngIf="residentAssociation.displayName != null">
                                                {{residentAssociation.displayName }}</span>
                                            </li>
                                        </ng-template>
                                    </p-autoComplete>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="!isLinkedMatterProjectSale() &&  !isDischargeMatter()">
                            <div class="form-group"
                                 *dpShowByProvince="'matter.subjectProperty.annualFeeAndFiscalYearEnd'">
                                <label class="control-label col-lg-2" for="annualFee">Annual Fee</label>
                                <div class="col-lg-4">
                                    <input type="text" style="text-align:right;" id="annualFee" name="annualFee"
                                           class="form-control"
                                           [(ngModel)]="propertyModel.annualFee" [dp-currency]="allowMaxLength"
                                           (change)="changeAnnualFee()"
                                    />
                                </div>
                                <label class="control-label col-lg-2" for="partialfiscalYearEndDate">Fiscal Year
                                    End</label>
                                <div class="col-lg-4">
                                    <dp-partial-date #partialfiscalYearEndDate
                                                     [fullDateRequired]="true"
                                                     [fieldKey]="'matter.propertTeranet.fiscalYearEndDate'"
                                                     statusBarHelp
                                                     [inputDate]="propertyModel.fiscalYearEndDate"
                                                     (dateChange)="onDateChangeFiscalYearEndDate($event)">
                                    </dp-partial-date>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="!isDischargeMatter()">
                            <div class="form-group" *dpShowByProvince="'matter.subjectProperty.instrumentNumber'">
                                <label class="control-label col-lg-2"
                                       for="instrumentNumber">
                                    Instrument No.</label>
                                <div class="col-lg-4">
                                    <input type="text" id="instrumentNumber" name="instrumentNumber"
                                           class="form-control"
                                           [(ngModel)]="propertyModel.instrumentNumber" maxlength="100"
                                    >
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="!isLinkedMatterProjectSale() && !isDischargeMatter()">
                            <div class="form-group" *dpShowByProvince="'matter.subjectProperty.purchaserShare'">
                                <label class="control-label col-lg-2" for="purchaserShare">Purchaser's Share
                                    ({{purchaserShareInDays}})</label>
                                <div class="col-lg-4">
                                    <ng-container *ngIf="matter.isTemplateMatterForMassUpdate">
                                        <input type="text" id="purchaserShare_mu" name="purchaserShare_mu"
                                               class="form-control text-padding-update"
                                               style="text-align:right;"
                                               [(ngModel)]="propertyModel.purchaserShareAmount"
                                               [dp-currency]="allowMaxLength"
                                               [dp-read-only]="true"
                                               statusBarHelp
                                               [fieldKey]="'matter.propert-teranet.purchasershare'"
                                               (keydown.f9)="openHomeOwnersResidentFeeModal()"
                                        >
                                    </ng-container>
                                    <ng-container *ngIf="!matter.isTemplateMatterForMassUpdate">
                                        <input type="text" id="purchaserShare" name="purchaserShare"
                                               class="form-control text-padding-update"
                                               style="text-align:right;"
                                               [(ngModel)]="propertyModel.purchaserShareAmount"
                                               [dp-currency]="allowMaxLength"
                                               [dp-read-only]="true"
                                               statusBarHelp
                                               [fieldKey]="'matter.propert-teranet.purchasershare'"
                                               (keydown.f9)="openHomeOwnersResidentFeeModal()"
                                        >
                                        <button type="button"
                                                tabIndex="-1"
                                                class="button-glyph"
                                                [dp-read-only]="isMatterLocked"
                                                (click)="openHomeOwnersResidentFeeModal()">
                                            <span class="glyphicon glyphicon-edit"></span>
                                        </button>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="!isDischargeMatter()">
                            <div class="form-group" style="padding-left: 175px; padding-top: 10px"
                                 *dpShowByProvince="'matter.subjectProperty.taxCertificates'">
                                <a href=" https://login.apicanada.com/login" target="_blank">Order Tax Certificates
                                    Online</a>
                            </div>
                        </ng-container>

                        <div class="form-group" *dpShowByProvince="'matter.subjectProperty.section501'">
                            <label class="control-label col-lg-2 vertical-top-align-label">'Prescribed Information for
                                the Purpose of Section 5.0.1 Form'
                                Confirmation No.</label>
                            <div class="col-lg-4">
                                <!--It will move to style to SCSS file-->
                                <input type="text" id="confirmationNo" name="confirmationNo" class="form-control"
                                       maxlength="35"
                                       [(ngModel)]="propertyModel.section501Information"
                                       statusBarHelp
                                       fieldKey="matter.propertyTeranet.confirmationNoSection"
                                />
                            </div>
                            <div class="col-lg-6 padding-top-8">
                                <label class="control-label vertical-top-align-label display-inline"><a
                                    href="https://www.teranetexpress.ca/csp/land-transfer-tax-info-login"
                                    target="_blank">Teranet Express - Land Transfer Tax</a></label>
                            </div>
                        </div>

                        <dp-user-defined-fields [topicName]="'PROPERTY'" (onChange)="enableSave()">
                        </dp-user-defined-fields>

                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="!!showWizardFields">
    <div [ngClass]="showWizardFields? 'col-lg-12' : ''" [dp-read-only-block]="dpReadOnlyBlock"
         [elementRefComp]="elementRef">
        <div class="form-group text-right">
            <ng-container *dpShowByProvince="'matter.subjectProperty.jurisdiction'">
                <ng-container *ngTemplateOutlet="jurisdiction"></ng-container>
            </ng-container>
        </div>

        <div class="form-group text-right">
            <ng-container *ngTemplateOutlet="propertyAddress"></ng-container>
        </div>
        <div class="form-horizontal">
            <ng-container *ngTemplateOutlet="isCondo"></ng-container>
        </div>

        <div class="form-horizontal">
            <ng-container
                *ngTemplateOutlet="interestEstateInput"
            ></ng-container>
        </div>

        <div class="form-horizontal">
            <ng-container
                *ngTemplateOutlet="strataMaintenanceFeesInput"
            ></ng-container>
        </div>

        <div class="form-horizontal">
            <ng-container *ngIf="matter.isMatterProvinceBC">
                <ng-container
                    *ngTemplateOutlet="
                        legalDescriptionBC;
                        context: { property: propertyModel, j: -1 }
                    "
                ></ng-container>
                <ng-container
                    *ngFor="
                        let matterProperty of matter.additionalMatterProperty;
                        let j = index
                    "
                >
                    <ng-container
                        *ngTemplateOutlet="
                            legalDescriptionBC;
                            context: { property: matterProperty, j: j }
                        "
                    ></ng-container>
                </ng-container>
            </ng-container>
        </div>

        <div class="form-horizontal">
            <ng-container *ngIf="matter.isMatterProvinceBC">
                <ng-container
                    *ngTemplateOutlet="addAnotherPidInput"
                ></ng-container>
            </ng-container>
        </div>

        <div class="form-horizontal">
            <ng-container *ngIf="matter.isMatterProvinceBC">
                <ng-container *ngTemplateOutlet="titleSearchPerformedInput"></ng-container>
            </ng-container>
        </div>

        <div class="form-horizontal">
            <ng-container *ngIf="matter.isMatterProvinceBC && !matter.isSale">
                <ng-container
                    *ngTemplateOutlet="interestOfPropertyInput"
                ></ng-container>
            </ng-container>
        </div>

        <div class="form-horizontal">
            <ng-container *ngTemplateOutlet="condominiumTypeAndPlan"></ng-container>
        </div>
        <ng-container *ngIf="matter?.teranetDocket?.parcelRegisters?.length > 0">
            <div class="form-horizontal">
                <ng-container *ngTemplateOutlet="thumbnailDesc"></ng-container>
            </div>
        </ng-container>
        <ng-container
            *ngIf="!matter.teranetDocket || !matter.teranetDocket?.parcelRegisters || matter.teranetDocket?.parcelRegisters.length == 0">
            <div class="form-horizontal">
                <ng-container *ngTemplateOutlet="nonCondoFieldsON"></ng-container>
            </div>
            <div class="form-horizontal">
                <ng-container *ngTemplateOutlet="parcels"></ng-container>
            </div>
            <div class="form-horizontal">
                <ng-container *ngTemplateOutlet="condomoniumTypePlanMBSK"></ng-container>
            </div>
        </ng-container>

        <div class="form-horizontal">
            <ng-container *ngTemplateOutlet="titleRollNumbers"></ng-container>
        </div>
        <div class="form-horizontal">
            <ng-container *ngTemplateOutlet="titleRollNumberLegalDescription"></ng-container>
        </div>
        <ng-container
            *ngIf="!matter.teranetDocket || !matter.teranetDocket?.parcelRegisters || matter.teranetDocket?.parcelRegisters.length == 0">
            <div class="form-horizontal">
                <ng-container *ngTemplateOutlet="unitLevelPlan"></ng-container>
            </div>
        </ng-container>
        <div class="form-group form-horizontal">
            <ng-container *ngTemplateOutlet="legalDescriptionAB"></ng-container>
        </div>
        <div class="form-group form-horizontal">
            <ng-container *ngTemplateOutlet="landTitleOfficeLocation"></ng-container>
        </div>
        <div class="form-horizontal">
            <ng-container *ngTemplateOutlet="registryLandTitleOffice"></ng-container>
        </div>
        <div class="form-horizontal">
            <ng-container *ngTemplateOutlet="panAanNumber"></ng-container>
        </div>
        <div class="form-horizontal" *dpShowByProvince="'matter.subjectProperty.pinDetails'">
            <ng-container *ngTemplateOutlet="pinDetails"></ng-container>
        </div>
        <div class="form-horizontal" *ngIf="!matter.isPurchaseBC">
            <ng-container *ngTemplateOutlet="lincNumber"></ng-container>
        </div>
        <div class="form-horizontal">
            <ng-container *ngTemplateOutlet="purchasePriceAndDeposit"></ng-container>
        </div>
        <div class="form-horizontal">
            <ng-container *ngTemplateOutlet="NonResidentDeedTransferTax"></ng-container>
        </div>
        <div class="form-horizontal padding-bottom-20">
            <ng-container *ngTemplateOutlet="propertyTaxes"></ng-container>
        </div>
        <div class="form-horizontal padding-bottom-20">
          <ng-container *ngTemplateOutlet="propertyUtilities"></ng-container>
        </div>
    </div>
</ng-container>

<ng-template #jurisdiction>
    <div class="form-horizontal">
        <div class="form-group">
            <label class="control-label col-lg-2" for="jurisdiction"
                   dp-province-field-label="matter.property.Jurisdiction">Jurisdiction</label>

            <div class="col-lg-10" *ngIf="jurisdictionReadOnly">
                <input type="text" id="autocomplete-jurisdiction"
                       class="form-control"
                       [value]="selectedJurisdiction?.jurisdictionName"
                       readonly="readonly"
                       autocomplete="off"
                />
                <span class="trash-icon">
                                    <i class="fa fa-trash"
                                       (click)="deleteJurisdiction()"
                                       aria-hidden="true"
                                       [title]="'Delete Jurisdiction'"
                                       tabindex="0"
                                       (keyup.enter)="deleteJurisdiction()"
                                    >
                                    </i>
                                </span>
            </div>

            <div class="col-lg-10 fullLength-autocomplete" *ngIf="!jurisdictionReadOnly">
                <p-autoComplete id="autocomplete-jurisdiction"
                                #jurisdictionOmnibar
                                name="jurisdiction"
                                placeholder="Search by Name or Add new"
                                [(ngModel)]="selectedJurisdiction"
                                [class.autocomplete-searching]="jurisdictionsLoading"
                                [suggestions]="jurisdictions"
                                (completeMethod)="searchJurisdiction($event)"
                                field="jurisdictionName"
                                [minLength]="0"
                                [delay]="1000"
                                maxlength="75"
                                fieldKey="matter.propertyteranet.jurisdiction"
                                scrollHeight="350px"
                                [disabled]="isMatterLocked || isOpenedFromWizardBeforeMatterSave()"
                                (onSelect)="dataSelectedJurisdiction()"
                                (focusout)="onBlurJurisdiction($event)"
                                class="autocomplete-search-icon"
                                (keyup)="checkIfJurisdictionEmpty(selectedJurisdiction)">

                    <ng-template let-jurisdiction pTemplate="item">
                        <li class="ui-autocomplete-list-item ui-helper-clearfix"
                            [ngClass]="{'bold-font' : isAddNewJurisdiction(jurisdiction && jurisdiction.jurisdictionName)}"
                            [disableItem]="jurisdiction">
                                        <span class="omnibarLi100 col-lg-4"
                                              *ngIf="jurisdictionAutoCompleteEmptyFlag == true">
                                        {{jurisdiction.jurisdictionName}}
                                        </span>
                            <span class="omnibarLi40 col-lg-4"
                                  *ngIf="jurisdictionAutoCompleteEmptyFlag == false">
                                        {{jurisdiction.jurisdictionName}}
                                        </span>
                            <span class="omnibarLi40 col-lg-4"
                                  *ngIf="jurisdictionAutoCompleteEmptyFlag == false">
                                        {{jurisdiction?.city?.name}}
                                        </span>
                            <span class="omnibarLi40 col-lg-4"
                                  *ngIf="jurisdictionAutoCompleteEmptyFlag == false">
                                        {{jurisdiction?.city?.municipality?.name}}
                                        </span>
                        </li>
                    </ng-template>
                </p-autoComplete>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #propertyAddress>
    <div class="form-horizontal" tabindex="4">
        <dp-address-form [address]="address" (onChange)="updateAddressFormChild($event)"
                         [fieldPrefix]="'property.address'"
                         [page]="page" [city]="propertyModel.city" addressSummaryLabel="Property Address"
                         [defaultForProvinceAndCountry]="!this.isMassUpdateTab()"
                         [disabled]="isOpenedFromWizardBeforeMatterSave()"
                         [addressMaxLength]="getMaxAddressLength()"
        ></dp-address-form>

    </div>

</ng-template>
<ng-template #isCondo>
    <div [ngClass]="showWizardFields? 'form-group' : ''">
        <label class="control-label col-lg-2" for="isCondominium " dp-province-field-label="matter.property.isThisCondo">Is this a condo?</label>
        <div class="col-lg-4">
            <div class="col-lg-3 padding-left-0">
                <select class="form-control" id="isCondominium" name="isCondominium"
                        [dp-read-only]="matter.isProjectSale"
                        (ngModelChange)="onCondoValueChange($event)" [(ngModel)]="propertyModel.isCondominium"
                        (change)="condominiumChange();">
                    <option *ngFor="let condo of condoOptions" [value]="condo.value">
                        {{condo.label}}
                    </option>
                </select>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #registryLandTitleOffice>
    <div *dpShowByProvince="'matter.subjectProperty.registryOfficeName'">
        <div [ngClass]="showWizardFields? 'form-group' : ''">
            <label class="control-label col-lg-2">{{registryOfficeLabel}}</label>
            <div class="col-lg-10">
                <input type="text" trimmed id="registryOfficeName" name="registryOfficeName" class="form-control"
                       [(ngModel)]="propertyModel.registryOfficeName" [dp-read-only]="true" maxlength="255"
                       (keydown.f9)="selectRegistry()"/>
                <button type="button" tabIndex="-1" id="tranTitleInsureBtn" (click)="selectRegistry()"
                        class="button-glyph">
                    <span class="glyphicon glyphicon-edit"></span>
                </button>
            </div>
        </div>
    </div>
    <ng-container *dpShowByProvince="'matter.subjectProperty.landTitleOffice'">
        <div class="form-group">
            <label class="control-label col-lg-2" for="registryOfficeName"
                   dp-province-field-label="matter.subjectProperty.registryOffice">Registry Office</label>
            <div [ngClass]="showWizardFields? 'col-lg-10' : 'col-lg-10 padding-left-8 padding-right-10'">
                <select class="form-control" id="landTitleOffice" [disabled]="disableLandTitleFields"
                        name="landTitleOffice"
                        [(ngModel)]="propertyModel.registryOfficeName" (change)="updateLandTitleOfficeName()">
                    <option value=""></option>
                    <option *ngFor="let landTitleOffice of landTitleOfficeOptions" [value]="landTitleOffice">
                        {{landTitleOffice}}
                    </option>
                </select>
            </div>
        </div>
    </ng-container>
    <ng-container *dpShowByProvince="'matter.subjectProperty.landTitlesOffice'">
        <div class="form-group">
            <label class="control-label col-lg-2" for="registryOfficeName">Land Titles Office</label>
            <div class="col-lg-10">
                <input type="text" id="landTitlesOffice" name="landTitlesOffice" class="form-control"
                       [dp-read-only]="true"
                       [(ngModel)]="propertyModel.registryOfficeName" maxlength="100"/>
            </div>
        </div>
    </ng-container>
</ng-template>
<ng-template #nonCondoFieldsON>
    <ng-container *ngIf="propertyModel.isCondominium  !== 'YES'" >
        <div *dpShowByProvince="'matter.subjectProperty.condoFields'">
            <div class="form-group">
                <label class="control-label col-lg-2" for="partLot">"Part (Lot)"</label>
                <div class="col-lg-10">
                    <input type="text" trimmed id="partLot" name="partLot" class="form-control" maxlength="100"
                            [(ngModel)]="propertyModel.partLot" (keydown.f9)="handleF9OnPartLot()"
                            (change)="updateTeraviewLegalDescs()"
                            [fieldKey]="'property.partLot'"
                            statusBarHelp
                    />
                </div>
            </div>

            <div class="form-group">
                <label class="control-label col-lg-2" for="plan">"Plan"</label>
                <div class="col-lg-10">
                    <input type="text" trimmed id="plan" name="plan" class="form-control" maxlength="100"
                            [dynamicHelpCallBackFunction]="generateF9OnPlan()"
                            statusBarHelp
                            [(ngModel)]="propertyModel.plan"
                            (change)="updateTeraviewLegalDescs()"
                            (keydown.f9)="handleF9OnPlan()"/>
                </div>
            </div>

            <div class="form-group" *dpShowByProvince="'matter.subjectProperty.beingPartAndPlan'">
                <label class="control-label col-lg-2" for="beingPart">"Being Part"</label>
                <div class="col-lg-4">
                    <input type="text" trimmed id="beingPart" name="beingPart" class="form-control" maxlength="80"
                            [fieldKey]="'property.beingPart'"
                            (change)="updateTeraviewLegalDescs()"
                            statusBarHelp
                            [(ngModel)]="propertyModel.beingPart" (keydown.f9)="handleF9OnBeingPart()"/>
                </div>
                <label class="control-label col-lg-2" for="onPlan">"On Plan"</label>
                <div class="col-lg-4">
                    <input type="text" trimmed id="onPlan" name="onPlan" class="form-control" maxlength="80"
                            [dynamicHelpCallBackFunction]="generateF9OnOnPlan()"
                            (change)="updateTeraviewLegalDescs()"
                            statusBarHelp
                            [(ngModel)]="propertyModel.onPlan" (keydown.f9)="handleF9OnOnPlan()"/>
                </div>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #pinDetails>
    <div class="form-group" *ngIf="propertyModel.isCondominium  === 'YES'">
        <label class="control-label col-lg-2" dp-province-field-label="matter.subjectProperty.pinNoLabel">PIN
            Number</label>
        <div class="col-lg-10">
            <input *dpShowByProvince="'matter.subjectProperty.pin'"
                   type="text" id="pinnumber.matter.subjectProperty.pin" name="pinnumber.matter.subjectProperty.pin"
                   dp-multi-pinFormat
                   [(ngModel)]="propertyModel.pin" [dp-read-only]="true"
                   class="pin57 form-control"/>

            <ng-container *dpShowByProvince="'matter.subjectProperty.pid'">
                <input
                    type="text" id="pinnumber.matter.subjectProperty.pid"
                    name="pinnumber.matter.subjectProperty.pid"
                    [(ngModel)]="propertyModel.pin" [dp-read-only]="true"
                    class="pin57 form-control"/>
                <button type="button"
                        id="pidBtn.matter.subjectProperty.pid"
                        tabIndex="-1"
                        (click)="selectUnitLevelPlan()" class="button-glyph">
                    <span class="glyphicon glyphicon-edit"></span>
                </button>
            </ng-container>

        </div>
    </div>
    <div class="form-group" *ngIf="!matter.isMortgageBC && propertyModel.isCondominium  != 'YES'">
        <label class="control-label col-lg-2">{{getPinLabel()}} 1</label>
        <div [ngClass]="matter.isMatterProvinceNBorNS ? 'col-lg-3' : 'col-lg-2'">
            <div *dpShowByProvince="'matter.subjectProperty.pin'" class="col-lg-12 padding-0">
                <input type="text" dp-pinFormat [pinFormatMaxLength]="getPinMaxLength()"
                       id="pinnumber_{{propertyModel.identifier}}" #pinnumber="ngModel"
                       name="pinnumber_{{propertyModel.identifier}}"
                       [(ngModel)]="propertyModel.pin" [disabled]="isOpenedFromWizardBeforeMatterSave()"
                       [dp-error-validator]="{elementFunction : checkPinFormat, data: {identifier: propertyModel.identifier}}"
                       class="pin57 form-control" [fieldKey]="getPinFieldKey(true)"
                       placeholder="{{matter.isMatterProvinceNBorNS ? ' ':'     -    '}}"
                />
            </div>
            <div *dpShowByProvince="'matter.subjectProperty.pid'" class="col-lg-12 padding-0">
                <input type="text"
                       id="pinnumber_{{propertyModel.identifier}}" #pinnumber="ngModel"
                       name="pinnumber_{{propertyModel.identifier}}"
                       [(ngModel)]="propertyModel.pin"
                       class="pin57 form-control" [disabled]="isOpenedFromWizardBeforeMatterSave()"
                       maxlength="{{propertyPinHelper.getPid(matter)}}"
                       fieldKey="pinnumber_{{propertyModel.identifier}}"
                       dp-error-validator
                       (change)="propertyPinHelper.validatePinWithPan(errorService,matter,propertyModel, propertyModel.identifier, 1, true)"
                />
            </div>

        </div>
        <ng-container *dpShowByProvince="'matter.subjectProperty.pan'">
            <label class="control-label col-lg-2" dp-province-field-label="matter.subjectProperty.pan1">PAN
                1</label>
            <div [ngClass]="matter.isProjectSale ? 'col-lg-2' : 'col-lg-3'">
                <input type="text" id="pan_{{propertyModel.identifier}}" #pannumber="ngModel"
                       name="pan_{{propertyModel.identifier}}" maxlength="{{getPANMaxLength()}}"
                       [(ngModel)]="propertyModel.assessmentAccountNumberSummary"
                       class="form-control" [disabled]="isOpenedFromWizardBeforeMatterSave()"
                       (change)="propertyPinHelper.validatePinWithPan(errorService,matter,propertyModel, propertyModel.identifier, 1, true)"
                       [fieldKey]="'pan'+ propertyModel.identifier"
                       dp-error-validator
                />
            </div>
        </ng-container>

        <div class="col-lg-4 padding-top-5" *dpShowByProvince="'matter.subjectProperty.overrideLegalDesc'">
            <ng-container *ngIf="isOverrideDescVisible()">
                <dp-checkbox fieldId="overrideDescription" class="margin-left-40">
                    <input type="checkbox"
                           id="overrideDescription"
                           name="overrideDescription"
                           (change)="overrideDescChange()"
                           [(ngModel)]="propertyModel.overrideDescription">
                </dp-checkbox>
                <label class="control-label padding-left-10 display-inline" for="overrideDescription">Override
                    Teraview Legal Description</label>
            </ng-container>
        </div>
        <div class="col-lg-4" *ngIf="propertyModel.overrideDescription">
            <select
                class="form-control"
                id="descriptionOverriddenType"
                name="descriptionOverriddenType"
                [(ngModel)]="propertyModel.descriptionOverriddenType"
                (change)="updateTeraviewLegalDescs(propertyModel)"
            >
                <option *ngFor="let teraviewLegalDescOverriddenType of teraviewLegalDescOverriddenTypes"
                        [value]="teraviewLegalDescOverriddenType.value">
                    {{teraviewLegalDescOverriddenType.label}}
                </option>
            </select>
        </div>
    </div>
    <div class="form-group" *ngIf="propertyModel.isCondominium  != 'YES' && propertyModel.overrideDescription">
        <div class="col-lg-10 offset-lg-2">
                            <textarea rows="5"
                                      class="form-control"
                                      id="teraviewLegalDesc"
                                      name="teraviewLegalDesc"
                                      [dp-read-only]="propertyModel.isLegalDescriptionApplyLegalDescEasement()"
                                      (change)="enableSave()"
                                      [(ngModel)]="propertyModel.fullLegalDescription">
                            </textarea>
        </div>
    </div>
    <div id="matterpropertyteranetpin_container" attr-offset="1" class="xpath-parent-container" *ngIf="!matter?.isMatterProvinceBC">
        <div *ngFor="let matterPropertie of matter.additionalMatterProperty; let j=index"
             class="xpath-child-container">
            <div class="form-group" *ngIf="propertyModel.isCondominium  != 'YES'"
                 [ngClass]="{'has-error' : !matterPropertie.isPinValid}">
                <label class="control-label col-lg-2">{{getPinLabel()}} {{j + 2}}</label>
                <div [ngClass]="matter.isProjectSale ? 'col-lg-2' : 'col-lg-3'">
                    <div *dpShowByProvince="'matter.subjectProperty.pin'" class="col-lg-12 padding-0">
                        <input type="text" dp-pinFormat [pinFormatMaxLength]="getPinMaxLength()"
                               id="pinnumber_{{matterPropertie.identifier}}"
                               name="pinnumber_{{matterPropertie.identifier}}"
                               [(ngModel)]="matterPropertie.pin" [fieldKey]="getPinFieldKey(false)"
                               [dp-error-validator]="{elementFunction : checkPinFormat, data: {identifier: matterPropertie.identifier}}"
                               class="pin57 form-control"
                               placeholder="{{matter.isMatterProvinceNBorNS ? ' ':'     -    '}}"
                        />
                    </div>
                    <div *dpShowByProvince="'matter.subjectProperty.pid'" class="col-lg-12 padding-0">
                        <input type="text"
                               id="pinnumber_{{matterPropertie.identifier}}"
                               name="pinnumber_{{matterPropertie.identifier}}"
                               [(ngModel)]="matterPropertie.pin"
                               fieldKey="pinnumber_{{matterPropertie.identifier}}"
                               dp-error-validator
                               class="pin57 form-control"
                               maxlength="{{propertyPinHelper.getPid(matter)}}"
                               (change)="propertyPinHelper.validatePinWithPan(errorService,matter,matterPropertie, matterPropertie.identifier, j+2, false)"
                        />
                    </div>

                    <div class="actionbtns">
                        <div class="width-20">
                            <span>
                                <i class="fa fa-trash"
                                   (click)="removeMatterProperty(matterPropertie, 'pinnumber_'+matterPropertie.identifier)"
                                   aria-hidden="true"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <ng-container *dpShowByProvince="'matter.subjectProperty.pan'">
                    <label class="control-label col-lg-2">{{getAANLabel()}} {{j + 2}}</label>
                    <div class="col-lg-3">
                        <input type="text" id="pan_{{matterPropertie.identifier}}"
                               name="pan_{{matterPropertie.identifier}}"
                               [(ngModel)]="matterPropertie.assessmentAccountNumberSummary"
                               maxlength="{{getPANMaxLength()}}"
                               (change)="propertyPinHelper.validatePinWithPan(errorService,matter,matterPropertie, matterPropertie.identifier, j+2, false)"
                               class="form-control"
                               [fieldKey]="'pan'+ matterPropertie.identifier"
                               dp-error-validator
                        />
                    </div>
                </ng-container>
                <div class="col-lg-4 padding-top-5"
                     *dpShowByProvince="'matter.subjectProperty.propertyTaxesSummaryOption'">
                    <dp-checkbox fieldId="pinnumber_desc_{{j + 1 + currentTimeStamp}}">
                        <input type="checkbox"
                               id="pinnumber_desc_{{j + 1 + currentTimeStamp}}"
                               [checked]="matterPropertie.propertyTaxesSummaryOption"
                               (change)="updatePropertyTaxSummaryCheckbox(matterPropertie);enableSave()"/>
                    </dp-checkbox>
                    <label for="pinnumber_desc_{{j + 1 + currentTimeStamp}}">Legal Description for Acknowledgment &
                        Direction</label>
                </div>
                <div class="col-lg-3 padding-top-5" *dpShowByProvince="'matter.subjectProperty.addressOption'">
                    <dp-checkbox fieldId="pinnumber_address_{{j + 1 + currentTimeStamp}}">
                        <input type="checkbox"
                               id="pinnumber_address_{{j + 1 + currentTimeStamp}}"
                               [checked]="matterPropertie.addressOption"
                               (change)="updateAddressyCheckbox(matterPropertie)"/>
                    </dp-checkbox>
                    <label for="pinnumber_address_{{j + 1 + currentTimeStamp}}">Property Address</label>
                </div>
                <div class="col-lg-4 padding-top-5" *dpShowByProvince="'matter.subjectProperty.overrideLegalDesc'">
                    <ng-container *ngIf="isOverrideDescVisible()">
                        <dp-checkbox fieldId="overrideDescription_{{j + 1 + currentTimeStamp}}"
                                     class="margin-left-40">
                            <input type="checkbox"
                                   id="overrideDescription_{{j + 1 + currentTimeStamp}}"
                                   name="overrideDescription_{{j + 1 + currentTimeStamp}}"
                                   (change)="overrideDescChange(matterPropertie)"
                                   [(ngModel)]="matterPropertie.overrideDescription">
                        </dp-checkbox>
                        <label class="control-label padding-left-10 display-inline"
                               for="overrideDescription_{{j + 1 + currentTimeStamp}}">
                            Override Teraview Legal Description</label>
                    </ng-container>

                </div>
                <div class="col-lg-4" *ngIf="matterPropertie.overrideDescription">
                    <select
                        class="form-control"
                        id="descriptionOverriddenType_{{j + 1 + currentTimeStamp}}"
                        name="descriptionOverriddenType_{{j + 1 + currentTimeStamp}}"
                        [(ngModel)]="matterPropertie.descriptionOverriddenType"
                        (change)="updateTeraviewLegalDescs(matterPropertie)"
                    >
                        <option *ngFor="let teraviewLegalDescOverriddenType of teraviewLegalDescOverriddenTypes"
                                [value]="teraviewLegalDescOverriddenType.value">
                            {{teraviewLegalDescOverriddenType.label}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group"
                 *ngIf="propertyModel.isCondominium  != 'YES' && matterPropertie.overrideDescription">
                <div class="col-lg-10 offset-lg-2">
                                    <textarea rows="5"
                                              class="form-control"
                                              id="teraviewLegalDesc{{j + 1 + currentTimeStamp}}"
                                              name="teraviewLegalDesc{{j + 1 + currentTimeStamp}}"
                                              [dp-read-only]="matterPropertie.isLegalDescriptionApplyLegalDescEasement()"
                                              (change)="enableSave()"
                                              [(ngModel)]="matterPropertie.fullLegalDescription">
                                    </textarea>
                </div>
            </div>
            <div class="form-group"
                 *ngIf="propertyModel.isCondominium  != 'YES' && matterPropertie.propertyTaxesSummaryOption">

                <label class="control-label col-lg-2" for="propertyTaxesSummary">Full Legal Description</label>
                <div class="col-lg-10">
                    <input type="textarea" id="pinnumber_fulldesc_{{j + 1 + currentTimeStamp}}"
                           name="pinnumber_fulldesc_{{j + 1 + currentTimeStamp}}"
                           [(ngModel)]="matterPropertie.propertyTaxesSummary"
                           class="form-control"/>
                </div>
            </div>
            <dp-address-form [address]="matterPropertie.address"
                             *ngIf="propertyModel.isCondominium  != 'YES' && matterPropertie.addressOption"
                             [fieldPrefix]="'pin.address'" [page]="page" addressSummaryLabel="Property Address"
                             [defaultForProvinceAndCountry]="!this.isMassUpdateTab()"
            ></dp-address-form>
        </div>
    </div>
    <div class="form-group padding-bottom-10" *ngIf="propertyPinHelper.isAddAnotherPinDisplayed(matter) && !matter?.isMatterProvinceBC">
        <label class="control-label col-lg-2"></label>
        <div class="col-lg-3">
                             <span class="actionlink">
                            <span>
                                <a href="javascript:void(0);" (click)="addNewPIN()"
                                   dp-province-field-label="matter.subjectProperty.addAnotherPIN">
                                    Add Another PIN
                                </a>
                            </span>
                            <span class="selector fa fa-plus-square-o"></span>
                        </span>
        </div>
    </div>
</ng-template>

<ng-template #lincNumber>
    <div class="form-group" *dpShowByProvince="'matter.subjectProperty.lincNo'">
        <div *ngIf="propertyModel.isCondominium  != 'YES'">
            <ng-container *ngIf="matter.isTemplateMatterForMassUpdate">
                <label class="control-label col-lg-2" for="lincNo">{{getLincLabel()}} 1</label>
                <div class="col-lg-4">
                    <input type="text" trimmed id="lincnumber_mu_{{propertyModel.identifier}}"
                           name="lincnumber_mu_{{propertyModel.identifier}}"
                           class="form-control" uppercased dp-lincnumber trimmed
                           [(ngModel)]="propertyModel.lincNumber" maxlength="200" [dp-read-only]="true"
                    />
                </div>
                <label class="control-label col-lg-2" for="section">Roll Number 1</label>
                <div class="col-lg-4">
                    <input type="text" trimmed id="rollnumber_mu_{{propertyModel.identifier}}"
                           name="rollnumber_mu_{{propertyModel.identifier}}" uppercased [dp-read-only]="true"
                           class="form-control" [(ngModel)]="propertyModel.rollNumber.city" maxlength="20"
                    />
                </div>
            </ng-container>
            <ng-container *ngIf="!matter.isTemplateMatterForMassUpdate">
                <label class="control-label col-lg-2" for="lincNo" >{{getLincLabel()}} 1</label>
                <div class="col-lg-4">
                    <input type="text" id="lincnumber_{{propertyModel.identifier}}"
                           name="lincnumber_{{propertyModel.identifier}}" class="form-control" uppercased
                           [(ngModel)]="propertyModel.lincNumber" maxlength="200" dp-lincnumber trimmed
                           (blur)="checkDuplicationOfLINC()"
                           [disabled]="isOpenedFromWizardBeforeMatterSave()"
                    />
                </div>
                <label class="control-label col-lg-2" for="section">Roll Number 1</label>
                <div class="col-lg-4">
                    <input type="text" trimmed id="rollnumber_{{propertyModel.identifier}}"
                           name="rollnumber_{{propertyModel.identifier}}" uppercased
                           class="form-control" [(ngModel)]="propertyModel.rollNumber.city" maxlength="20"
                           [disabled]="isOpenedFromWizardBeforeMatterSave()"
                    />
                </div>
            </ng-container>
        </div>
    </div>

    <div class="form-group xpath-parent-container" *dpShowByProvince="'matter.subjectProperty.lincNo'">
        <div *ngFor="let matterPropertie of matter.additionalMatterProperty; let j=index" class="xpath-child-container">
            <div class="form-group" *ngIf="propertyModel.isCondominium  != 'YES'">
                <label class="control-label col-lg-2">{{getLincLabel()}} {{j + 2}}</label>
                <div class="col-lg-4">
                    <input type="text" id="lincNumber_{{matterPropertie.identifier}}"
                           name="lincNumber_{{matterPropertie.identifier}}"
                           [fieldKey]="'lincNumber_'+ matterPropertie.identifier"
                           uppercased dp-lincnumber trimmed
                           dp-error-validator
                           (blur)="checkDuplicationOfLINC()"
                           [(ngModel)]="matterPropertie.lincNumber" maxlength="200"
                           class="form-control"/>
                    <div class="actionbtns">
                        <div class="width-20">
                                        <span>
                                            <i class="fa fa-trash"
                                               (click)="removeMatterPropertyByLincNumber(matterPropertie, 'lincNumber_'+matterPropertie.identifier)"
                                               aria-hidden="true"></i>
                                        </span>
                        </div>
                    </div>
                </div>

                <label class="control-label col-lg-2">Roll Number {{j + 2}}</label>
                <div class="col-lg-4">
                    <input type="text" class="form-control" trimmed uppercased maxlength="20"
                           id="rollnumber_{{matterPropertie.identifier}}"
                           name="rollnumber_{{matterPropertie.identifier}}"
                           [fieldKey]="'rollnumber_'+ matterPropertie.identifier"
                           dp-error-validator
                           (blur)="removeMatterTaxOnBlurEvent(matterPropertie)"
                           [(ngModel)]="matterPropertie.rollNumber.city"
                    />
                </div>
            </div>
        </div>
    </div>


    <div *dpShowByProvince="'matter.subjectProperty.lincNo'">
        <div class="form-group padding-bottom-10" *ngIf="propertyModel.isCondominium != 'YES'">
            <label class="control-label col-lg-1"></label>
            <div class="col-lg-4" style="padding-top: 10px;padding-left: 90px">
                <ng-container *ngIf="matter.isTemplateMatterForMassUpdate">
                                    <span class="actionlink" [class.disabled]="true">
                                        <span>
                                                <a href="javascript:void(0);" class="actionlinkdisabled">
                                                    Add Another {{getLincLabel()}} and Roll Number
                                                </a>
                                        </span>
                                        <span class="selector fa fa-plus-square-o"></span>
                                    </span>
                </ng-container>

                <ng-container *ngIf="!matter.isTemplateMatterForMassUpdate">
                                    <span class="actionlink">
                                        <span>
                                            <a href="javascript:void(0);" (click)="addNewLincAndRollNumber()">
                                                Add Another {{getLincLabel()}} and Roll Number
                                            </a>
                                        </span>
                                        <span class="selector fa fa-plus-square-o"></span>
                                    </span>
                </ng-container>

            </div>
        </div>
    </div>

</ng-template>
<ng-template #unitLevelPlan>
    <div *ngIf="propertyModel.isCondominium  === 'YES'">
        <div class="form-group" *dpShowByProvince="'matter.subjectProperty.unitLevelPlan'">
            <ng-container *dpShowByProvince="'ps.matter.subjectProperty.unitLevelPlan.fieldLbl.show'">
                <label class="control-label col-lg-2">{{getUnitLevelPlanFieldLabelForPS()}}</label>
            </ng-container>
            <ng-container *dpShowByProvince="'ps.matter.subjectProperty.unitLevelPlan.fieldLbl.hide'">
                <label class="control-label col-lg-2"
                       dp-province-field-label="matter.subjectproperty.unitLevelPlanLabel">Unit/Level/Plan</label>
            </ng-container>
            <div class="col-lg-10">
                <ng-container *ngIf="matter.isTemplateMatterForMassUpdate">
                    <input type="text"
                           name="unitLevelPlan_ro"
                           [ngModel]="propertyModel.unitLevelPlan"
                           class="form-control"
                           [dp-read-only]="true"/>
                </ng-container>

                <ng-container *ngIf="!matter.isTemplateMatterForMassUpdate">
                    <input type="text" id="unitLevelPlan" name="unitLevelPlan" [(ngModel)]="propertyModel.unitLevelPlan"
                           class="form-control unit-level-plan"
                           [fieldKey]="'property.unitLevelPlan'"
                           statusBarHelp
                           readonly="true" (keydown.f9)="selectUnitLevelPlan()"/>
                    <button type="button"
                            id="unitLevelPlanBtn"
                            tabIndex="-1"
                            (click)="selectUnitLevelPlan()"
                            class="button-glyph">
                        <span class="glyphicon glyphicon-edit"></span>
                    </button>
                </ng-container>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #condominiumTypeAndPlan>
    <div *ngIf="propertyModel.isCondominium  === 'YES'">
        <div *dpShowByProvince="'matter.subjectProperty.condominiumTypePlan'">
            <div class="form-group">
                <ng-container *dpShowByProvince="'matter.subjectProperty.condominiumType'">
                    <label class="control-label col-lg-2" for="condominiumType">Condominium Type</label>
                    <div class="col-lg-4">
                        <select class="form-control" id="condominiumType"
                                name="condominiumType" [(ngModel)]="propertyModel.condominiumType">
                            <option *ngFor="let condominiumType of condominiumTypeOptions"
                                    [value]="condominiumType.value">
                                {{condominiumType.label}}
                            </option>
                        </select>
                    </div>
                </ng-container>

                <div>
                    <label class="control-label col-lg-2" for="interestEstate">Condominium Plan Number</label>
                    <div class="col-lg-4">
                        <input type="text" id="condoPlanNumber" name="condoPlanNumber" class="form-control"
                               [(ngModel)]="propertyModel.condominiumPlanNumber" maxlength="100"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #parcels>
    <div *dpShowByProvince="'matter.subjectProperty.parcel'" class="xpath-parent-container">
        <div
            *ngFor="let parcelLegalDescription of matter.matterPropertyWithCondo.parcelLegalDescriptions;  let i=index">
            <div class="form-group xpath-child-container">
                <label class="control-label col-lg-2" for="parcel">Parcel {{i + 1}}</label>
                <div class="col-lg-10">
                    <input type="text" id="parcelField" name="parcelField"
                           [value]="updateParcelFieldDescription(parcelLegalDescription)"
                           class="form-control"
                           dp-read-only="true"/>
                    <div class="width-30 actionbtns">
                        <dp-dropdown-menu
                            [dropDownItems]="getBurgerMenuItemsParcel()"
                            [openSubMenuOnLeft]="true"
                            (itemClicked)="clickParcelBurgerMenuItem($event,parcelLegalDescription,i)">
                        </dp-dropdown-menu>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="isAddAnotherParcelLinkVisible">
            <div class="col-lg-2"></div>
            <div class="col-lg-4"><a href="javascript:void(0);" (click)="addParcelAndOpenModal()">
                Add another Parcel
                <span class="selector fa fa-plus-square-o"></span>
            </a></div>
        </div>
    </div>
</ng-template>
<ng-template #titleRollNumbers>
    <div *dpShowByProvince="'matter.propertyTeranet.titleRollNumber'">
        <div *ngIf="propertyModel.parcelLegalDescriptions && propertyModel.parcelLegalDescriptions.length"
             class="xpath-parent-container">
            <div class="form-group xpath-child-container"
                 *ngFor="let parcelLegalDescription of propertyModel.parcelLegalDescriptions; let i=index ">
                <label class="control-label col-lg-2" for="section">Title/Roll Number {{i + 1}}</label>
                <input type="text" class="col-lg-10 form-control roll-numbers-input"
                       [dp-read-only]="true"
                       id="titleRollNumber{{parcelLegalDescription.identifier}}"
                       [value]="parcelLegalDescription.titleRollNumber"
                />
                <div class="width-30 roll-numbers-actionbtn">
                    <dp-dropdown-menu
                        [dropDownItems]="rollNumbersActions"
                        (itemClicked)="clickDropDownMenu($event, parcelLegalDescription, i+1)">
                    </dp-dropdown-menu>
                </div>

            </div>
        </div>
        <div class="form-group" *ngIf="isNewTitleRollNumberVisible()">
            <label class="control-label col-lg-2" for="section">Title/Roll
                Number {{getNewTitleRollNumberOrder()}}</label>

            <input type="text" class="col-lg-10 form-control roll-numbers-input"
                   [dp-read-only]="true"
                   id="titleRollNumber"/>
            <div class="width-30 roll-numbers-actionbtn">
                <dp-dropdown-menu
                    [dropDownItems]="emptyRollNumbersActions"
                    (itemClicked)="clickDropDownMenu($event)">
                </dp-dropdown-menu>
            </div>

        </div>

        <div class="form-group" *ngIf="!isNewTitleRollNumberVisible()">
            <div class="col-lg-3 offset-lg-2 padding-top-10 padding-bottom-10"
                 *ngIf="!isNewTitleRollNumberVisible()">
                                <span>
                                    <a href="javascript:void(0);" (click)="showNewTitleRollNumber()">
                                        Add Another Title/Roll
                                    </a>
                                </span>
                <span class="selector fa fa-plus-square-o"></span>
            </div>

            <div class="col-lg-7 padding-top-10 padding-bottom-10" *ngIf="isOverrideLegalDescriptionFromTPRVisible()">
                <dp-checkbox fieldId="overrideDescriptionMB">
                    <input type="checkbox"
                           id="overrideDescriptionMB"
                           name="overrideDescriptionMB"
                           [(ngModel)]="propertyModel.overrideDescription"
                           (ngModelChange)="onOverrideLegalDescriptionFromTPR($event)"
                    />
                </dp-checkbox>
                <label for="overrideDescriptionMB" class="control-label vertical-align-label display-inline">Override
                    Legal Description from TPR</label>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #panAanNumber>
    <div *dpShowByProvince="'matter.subjectProperty.pan'">
        <div class="form-group" *ngIf="propertyModel.isCondominium  === 'YES'">
            <label class="control-label col-lg-2" for="lastTransfer"
                   dp-province-field-label="matter.subjectProperty.pans">PAN(s)</label>
            <div class="col-lg-10">
                <input type="text" trimmed id="pan" name="pan" class="form-control"
                       [(ngModel)]="propertyModel.assessmentAccountNumberSummary"
                       [dp-read-only]="true"
                />
                <button type="button"
                        id="panBtn.matter.subjectProperty.pan"
                        tabIndex="-1"
                        (click)="selectUnitLevelPlan()" class="button-glyph">
                    <span class="glyphicon glyphicon-edit"></span>
                </button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #landTitleOfficeLocation>
    <div class="form-group" *dpShowByProvince="'matter.subjectProperty.landTitleOfficeLocation'">
        <label class="control-label col-lg-2" for="landTitleOfficeLocation">Land Title Office Location</label>
        <div class="col-lg-4">
            <select class="form-control"
                    id="landTitleOfficeLocation"
                    [disabled]="disableLandTitleFields"
                    name="landTitleOfficeLocation" [(ngModel)]="propertyModel.landTitleOfficeLocation"
                    (change)="updateLandTitleOffice();"
            >
                <option value=""></option>
                <option *ngFor="let landTitleOfficeLocation of landTitleOfficeLocationOptions"
                        [value]="landTitleOfficeLocation">
                    {{landTitleOfficeLocation}}
                </option>
            </select>
        </div>
    </div>
</ng-template>
<ng-template #purchasePriceAndDeposit>
    <div class="form-group">
        <ng-container *dpShowByProvince="'matter.subjectProperty.purchasePrice'">
            <label class="control-label col-lg-2" for="purchasePrice">{{txnType}} Price </label>
            <div class="col-lg-4">
                <!--It will move to style to SCSS file-->
                <ng-container *ngIf="matter.isTemplateMatterForMassUpdate">
                    <input type="text" id="purchasePrice_mu" name="purchasePrice_mu"
                           class="form-control text-padding-update"
                           style="text-align:right;" [(ngModel)]="propertyModel.purchasePrice"
                           (keydown.f9)="openSalePriceModal()"
                           [fieldKey]="'property.purchasePrice'"
                           statusBarHelp
                           [dp-read-only]="true" dp-currency/>
                </ng-container>
                <ng-container *ngIf="!matter.isTemplateMatterForMassUpdate">
                    <input type="text" id="purchasePrice" name="purchasePrice" class="form-control text-padding-update"
                           style="text-align:right;" [(ngModel)]="propertyModel.purchasePrice"
                           (keydown.f9)="openSalePriceModal()"
                           [fieldKey]="'property.purchasePrice'"
                           statusBarHelp
                           [dp-read-only]="true" dp-currency/>
                    <button type="button"
                            id="purchasePriceBtn"
                            (click)="openSalePriceModal()"
                            class="button-glyph" tabIndex="-1">
                        <span class="glyphicon glyphicon-edit"></span>
                    </button>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *dpShowByProvince="'matter.subjectProperty.purchasePrice.simple.input'">
            <label class="control-label col-lg-2" for="purchaseSimplePrice">{{ txnType }} Price </label>
            <div class="col-lg-4">
                <input type="text"
                       id="purchaseSimplePrice"
                       name="purchaseSimplePrice"
                       class="form-control text-right"
                       style="text-align:right;"
                       [(ngModel)]="propertyModel.purchasePrice"
                       (change)="onPurchasePriceChange($event)"
                       tabindex="0"
                       dp-currency/>
            </div>
        </ng-container>
        <ng-container *dpShowByProvince="'matter.subjectProperty.depositAmount'">
            <label class="control-label col-lg-2" for="purchasePrice">Deposit Amount</label>
            <div class="col-lg-4">
                <!--It will move to style to SCSS file-->
                <input type="text" id="depositAmount" name="depositAmount" class="form-control text-padding-update"
                       style="text-align:right;" [(ngModel)]="propertyModel.depositAmount"
                       (keydown.f9)="openDepositModal()"
                       [fieldKey]="'property.deposit'"
                       statusBarHelp
                       readonly="true" [dp-currency]="allowMaxLength"/>
                <button type="button"
                        id="depositAmountBtn"
                        (click)="openDepositModal()"
                        class="button-glyph" tabIndex="-1">
                    <span class="glyphicon glyphicon-edit"></span>
                </button>
            </div>
        </ng-container>

        <ng-container *dpShowByProvince="'matter.subjectProperty.affidavitTobeSignedByItemLabelText'">
            <div class="col-lg-2"></div>
            <div class="col-lg-4">
                <p>Affid re Value to be signed by {{affidavitTobeSignedByItemLable}}</p>
            </div>
        </ng-container>
    </div>
</ng-template>
<ng-template #NonResidentDeedTransferTax>
    <div class="form-group" *ngIf="!matter?.isMortgageBC">
        <ng-container *dpShowByProvince="'matter.subjectProperty.NRDTTax'">
            <label class="control-label col-md-2" for="isNRDTTax">Is Non-Resident Deed Transfer Tax Required?</label>
            <div class="col-md-4">
                <div class="col-md-3 padding-left-0">
                    <!--[dp-read-only]="matter.isProjectSale"-->
                    <select class="form-control" id="isNRDTTax" name="isNRDTTax" (change)="onNRDTTaxValueChange()" [(ngModel)]="propertyModel.isNRDTTax">
                        <option *ngFor="let taxOpt of nRDTTaxOptions" [ngValue]="taxOpt.value">
                            {{taxOpt.label}}
                        </option>
                    </select>
                </div>
            </div>
        </ng-container>

        <ng-container *dpShowByProvince="'matter.subjectProperty.depositAmount'">
            <div class="form-group">
                <div class="col-md-6"></div>
                <div class="col-md-2 vertical-align-label">
                    <label class="control-label">
                        Deposit held by
                    </label>
                </div>
                <div class="col-md-4">
                    <select class="form-control" id="depositHeldBy" name="depositHeldBy" [(ngModel)]="matter.depositHeldBy" (ngModelChange)="onDepositHeldByChange($event)">
                        <option *ngFor="let item of depositHeldByOptions" [value]="item.value">
                            {{item.label}}
                        </option>
                    </select>
                </div>
            </div>
        </ng-container>
    </div>

    <div class="form-group" *ngIf="propertyModel.isNRDTTax === 'YES'">
        <ng-container>
            <label class="control-label col-md-2" for="nRDTTaxPercentage">Percentage Applied</label>
            <div class="col-md-4">
                <div class="col-md-4 padding-left-0">
                    <input type="text" id="nRDTTaxPercentage" name="nRDTTaxPercentage" class="form-control" [(ngModel)]="propertyModel.nRDTTaxPercentage" (change)="onNRDTTaxPercentageChange()" dp-percentage max-decimals=2 />
                </div>
                <label class="control-label col-md-1 text-left">%</label>
            </div>

            <label class="control-label col-md-2" for="assessedValueNRDT">Assessed Value</label>
            <div class="col-md-4">
                <input type="text" id="assessedValueNRDT" name="assessedValueNRDT" class="form-control" style="text-align:right;" [(ngModel)]="propertyModel.nRDTTaxAssessedValue" (change)="onNRDTTaxAssessedValueChange()" dp-currency />
            </div>
        </ng-container>
    </div>

    <div class="form-group" *ngIf="propertyModel.isNRDTTax === 'YES'">
        <ng-container>
            <label class="control-label col-md-2" for="nRDTTax">Non-Resident Deed Transfer Tax</label>
            <div class="col-md-4">
                <input type="text" id="nRDTTax" name="nRDTTax" class="form-control" style="text-align:right;" [(ngModel)]="propertyModel.nRDTTax" [dp-read-only]="true" dp-currency />
            </div>
        </ng-container>

    </div>
</ng-template>
<ng-template #propertyTaxes>
    <ng-container *ngIf="!isLinkedMatterProjectSale() && !matter.isMatterTypeDischarge">
        <div class="form-group" *dpShowByProvince="'matter.propertyTeranet.propertyTax'">
            <label class="control-label col-lg-2" for="purchasePrice"
                   *dpShowByProvince="'matter.propertyTeranet.propertyTax.label.AB'"
                   dp-province-field-label="matter.subjectProperty.propertyTax">Property Taxes</label>
            <label class="control-label col-lg-2" for="purchasePrice"
                   *dpShowByProvince="'matter.propertyTeranet.propertyTax.label.AB.Mortgage'">{{matter.matterClosingDateYear}}
                taxes</label>
            <div class="col-lg-10">
                <!--It will move to style to SCSS file-->
                <ng-container *ngIf="matter.isTemplateMatterForMassUpdate">
                    <input type="text" id="propertyTaxes_mu" name="propertyTaxes_mu"
                           class="form-control text-padding-update"
                           style="text-align:right;" [(ngModel)]="propertyModel.propertyTax"
                           [fieldKey]="'property.propertyTaxes'"
                           statusBarHelp
                           (keydown.f9)="openPropertyTaxModal()"
                           [dp-read-only]="true">
                </ng-container>
                <ng-container *ngIf="!matter.isTemplateMatterForMassUpdate">
                    <input type="text" id="propertyTaxes" name="propertyTaxes" class="form-control text-padding-update"
                           style="text-align:right;" [(ngModel)]="propertyModel.propertyTax"
                           [fieldKey]="'property.propertyTaxes'"
                           statusBarHelp
                           (keydown.f9)="openPropertyTaxModal()"
                           [dp-read-only]="true">
                    <button type="button"
                            id="propertyTaxesBtn"
                            (click)="openPropertyTaxModal()"
                            class="button-glyph" tabIndex="-1">
                        <span class="glyphicon glyphicon-edit"></span>
                    </button>
                </ng-container>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #propertyUtilities>
    <ng-container *ngIf="!isLinkedMatterProjectSale() && !matter.isMatterTypeDischarge">
        <div class="form-group" *dpShowByProvince="'matter.propertyTeranet.propertyUtility.label'">
            <label class="control-label col-lg-2" for="purchasePrice">Utilities</label>
            <div class="col-lg-10">
                <!--It will move to style to SCSS file-->
                <ng-container *ngIf="matter.isTemplateMatterForMassUpdate">
                    <input type="text" id="totalAmountFormatted" name="totalAmountFormatted"
                           class="form-control text-padding-update"
                           style="text-align:right;" [(ngModel)]="propertyUtilityModel.totalAmountFormatted"
                           [fieldKey]="'property.totalAmountFormatted'"
                           statusBarHelp
                           (keydown.f9)="openUtilityModal()"
                           [dp-read-only]="true">
                </ng-container>
                <ng-container *ngIf="!matter.isTemplateMatterForMassUpdate">
                    <input type="text" id="totalAmountFormatted" name="totalAmountFormatted" class="form-control text-padding-update"
                           style="text-align:right;" [(ngModel)]="propertyUtilityModel.totalAmountFormatted"
                           [fieldKey]="'property.totalAmountFormatted'"
                           statusBarHelp
                           (keydown.f9)="openUtilityModal()"
                           [dp-read-only]="true">
                    <button type="button"
                            id="propertyUtilityBtn"
                            (click)="openUtilityModal()"
                            class="button-glyph" tabIndex="-1">
                        <span class="glyphicon glyphicon-edit"></span>
                    </button>
                </ng-container>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #legalDescriptionAB>
    <div *ngIf="propertyModel.isCondominium  !== 'YES' && !matter.isMatterProvinceBC">
        <dp-accordion [expanded]="openLegalDescription" *dpShowByProvince="'matter.subjectProperty.legalDescription'">
            <div class="dp-accordion-header">
                <div class="row  row-group">
                    <label class="control-label col-lg-2">Legal Description</label>
                    <div class="col-lg-10">
                        <input type="text" name="pContact" [dp-read-only]="true" class="form-control"
                               [ngModel]="legalDescriptionPlanBlockLot"
                        />
                    </div>
                </div>
            </div>
            <div class="dp-accordion-content">
                <div *dpShowByProvince="'matter.subjectProperty.legalDescription.pinDetails'">
                    <ng-container *ngTemplateOutlet="pinDetails"></ng-container>
                </div>
                <div class="form-group" *ngIf="!matter.isMatterProvinceBC">
                    <div class="radio col-lg-12" style="padding-left: 80px">
                                 <span class="radio display-inline" (click)="onPlanBlockLotSelection()">
                                  <input type="radio" [checked]="propertyModel.isPlanBlockLot()" >
                                  <label
                                      dp-province-field-label="matter.subjectProperty.planBlockLot">Plan/Block/Lot</label>
                                 </span>
                    </div>
                </div>
                <ng-container *ngTemplateOutlet="lincNumber"></ng-container>

                <div class="form-group" *dpShowByProvince="'matter.subjectProperty.legalDescription.planNumber'">
                    <div>
                        <label class="control-label col-lg-3">Plan Number</label>
                        <div class="col-lg-4">
                            <input type="text" name="planNumber" id="planNumber" class="form-control"
                                   [(ngModel)]="propertyModel.plan"
                                   maxlength="100" [dp-read-only]="!propertyModel.isPlanBlockLot() && !matter.isMatterProvinceBC"
                            />
                        </div>
                    </div>
                </div>
                <div class="form-group" *ngIf="matter.isMatterProvinceAB">
                    <div>
                        <label class="control-label col-lg-3">Block Number</label>
                        <div class="col-lg-4">
                            <input type="text" name="blockNumber" id="blockNumber" class="form-control"
                                   [(ngModel)]="propertyModel.block"
                                   maxlength="80" [dp-read-only]="!propertyModel.isPlanBlockLot()"
                            />
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div>
                        <label class="control-label col-lg-3">Lot Number</label>
                        <div class="col-lg-4">
                            <input type="text" name="lotNumber" id="lotNumber" class="form-control"
                                   [(ngModel)]="propertyModel.lot"
                                   maxlength="80" [dp-read-only]="!propertyModel.isPlanBlockLot() && !matter.isMatterProvinceBC"
                            />
                        </div>
                    </div>
                </div>
                <div class="form-group" *dpShowByProvince="'matter.subjectProperty.legalDescription.exceptionWording'">
                    <label class="control-label col-lg-3">Exception Wording?</label>
                    <div class="col-lg-2">
                        <select class="form-control" id="exceptionType"
                                name="exceptionType" [(ngModel)]="propertyModel.exceptionType"
                                (ngModelChange)="onChangeExceptionWording($event)"
                                [dp-read-only]="!propertyModel.isPlanBlockLot()">
                            <option value=""></option>
                            <option *ngFor="let exceptingType of exceptionTypeOptions" [value]="exceptingType.value">
                                {{exceptingType.label}}
                            </option>
                        </select>
                    </div>
                    <div class="col-lg-4" *ngIf="propertyModel.exceptionType == 'YES'">
                        <p-autoComplete
                            [(ngModel)]="propertyModel.exceptionTypeDescription"
                            tabIndexIgnore
                            name="exceptionDescription"
                            [suggestions]="filteredExceptionTypeOptions"
                            (ngModelChange)="onChange($event)"
                            (completeMethod)="filterExceptionTypeDescription($event)"
                            [minLength]="1"
                            [dropdown]="true"
                            (keydown.space)="utils.handleSpaceKeyDropdown($event, propertyModel.exceptionTypeDescription, handleDropdownClick)"
                            (keydown.enter)="utils.handleEnterKeyDropdown($event,  propertyModel.exceptionTypeDescription, handleDropdownClick)"
                            (onDropdownClick)="handleDropdownClickExceptionTypeDescription()">
                            <ng-template let-paymentFrequencyLabel pTemplate="item">
                                <li class="ui-autocomplete-list-item ui-helper-clearfix">
                                                    <span class="omnibarLi100">
                                                        {{paymentFrequencyLabel}}
                                                    </span>
                                </li>
                            </ng-template>
                        </p-autoComplete>
                    </div>
                </div>
                <div class="form-group" *ngIf="!matter.isMatterProvinceBC">
                    <div class="radio col-lg-12" style="padding-left: 80px">
                                 <span class="radio display-inline" (click)="onMetisAndBoundSelection()">
                                  <input type="radio" [checked]="propertyModel.isMetesAndBound() && !matter.isMatterProvinceBC">
                                  <label>Metes and Bounds</label>
                                 </span>
                    </div>
                </div>
                <div class="form-group">
                    <div>
                        <label class="control-label col-lg-3">Short legal description</label>
                        <div class="col-lg-9">
                            <input type="text" trimmed id="shortLegalDescription" name="shortLegalDescription"
                                   class="form-control"
                                   maxlength="255"
                                   [(ngModel)]="propertyModel.shortLegalDescription"
                                   [dp-read-only]="!propertyModel.isMetesAndBound() && !matter.isMatterProvinceBC"/>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div>
                        <label class="control-label col-lg-3">Full legal description</label>
                        <div class="col-lg-9">
                                        <textarea type="textarea" id="fullLegalDescription" name="fullLegalDescription"
                                                  class="form-control" rows="5"
                                                  [(ngModel)]="propertyModel.fullLegalDescription"
                                                  [dp-read-only]="!propertyModel.isMetesAndBound() && !matter.isMatterProvinceBC"
                                        ></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </dp-accordion>
    </div>
</ng-template>

<ng-template #titleRollNumberLegalDescription>
    <div class="form-group padding-top-10" *dpShowByProvince="'matter.subjectProperty.titleRollNumberLegalDescription'">
        <label class="control-label col-lg-2" for="parcel">Legal Description</label>
        <div class="col-lg-10">
                            <textarea [dp-read-only]="!propertyModel.overrideDescription"
                                      type="textarea"
                                      id="titleRollNumberLegalDescription"
                                      name="titleRollNumberLegalDescription"
                                      class="form-control"
                                      rows="5"
                                      [(ngModel)]="legalDescriptionSummaryMB">
                            </textarea>
        </div>
    </div>
</ng-template>
<ng-template #condomoniumTypePlanMBSK>
    <div *dpShowByProvince="'matter.subjectProperty.condominiumTypePlan'">
        <div class="form-group">
            <label *dpShowByProvince="'matter.subjectProperty.totalValueOfParcels'" class="control-label col-lg-2"
                   for="totalValueOfParcels">Total Value of Parcels</label>
            <div *dpShowByProvince="'matter.subjectProperty.totalValueOfParcels'" class="col-lg-4">
                <input type="text" id="totalValueOfParcels" name="totalValueOfParcels" class="form-control"
                       [(ngModel)]="propertyModel.totalValueOfParcels" dp-read-only="true" dp-currency
                       dp-default-currency
                />
            </div>
            <div *ngIf="propertyModel.isCondominium  === 'YES'">
                <label class="control-label col-lg-2" for="condominiumFees"
                       dp-province-field-label="matter.subjectProperty.condominiumFees">Condominium Fees</label>
                <div class="col-lg-4">
                    <input type="text" id="condominiumFees" name="condominiumFees" class="form-control"
                           [(ngModel)]="propertyModel.condominiumFees" dp-currency dp-read-only="true"
                           dp-default-currency
                    />
                    <ng-container *dpShowByProvince="'matter.subjectProperty.editAmount'">
                        <button type="button" *ngIf="matter.isEditCommonElementFeesBtnVisible()"
                                id="editCommonElementFeesBtn"
                                tabIndex="-1"
                                (click)="clickCommonElementFeesEditBtn()" class="button-glyph">
                            <span class="glyphicon glyphicon-edit"></span>
                        </button>
                    </ng-container>

                </div>
                <ng-container *dpShowByProvince="'matter.subjectProperty.separateCommonElementFeeAdj'">
                    <div class="padding-top-7"
                         *ngIf="propertyModel.parcelLegalDescriptions && propertyModel.parcelLegalDescriptions.length>1">
                        <dp-checkbox fieldId="separateCommonElementFeeAdj">
                            <input type="checkbox"
                                   id="separateCommonElementFeeAdj"
                                   name="separateCommonElementFeeAdj"
                                   [(ngModel)]="propertyModel.separateCommonElementFeeAdj"
                                   (ngModelChange)="onSeparateCommonElementFeeAdjChange($event)"
                            />
                        </dp-checkbox>
                        <label for="separateCommonElementFeeAdj"
                               class="control-label vertical-align-label display-inline">Create separate Common Element
                            Fee adjustments for each Title</label>
                    </div>

                </ng-container>

            </div>
        </div>
    </div>
</ng-template>
<ng-template #thumbnailDesc>
    <div class="form-group" *ngIf="propertyModel.propertyTaxesSummary">
        <label class="control-label col-lg-2 vertical-align-label" for="propertyTaxesSummary"
               [dp-province-field-label]="'teranet.subjectProerty.thumbnailDescription'">Thumbnail
            description from
            Teranet Connect</label>
        <div class="col-lg-10">
                            <textarea [dp-read-only]="true" type="textarea" id="propertyTaxesSummary"
                                      name="propertyTaxesSummary"
                                      class="form-control" [(ngModel)]="propertyModel.propertyTaxesSummary" rows="3"
                            ></textarea>
        </div>
    </div>
</ng-template>

<ng-template #legalDescriptionBC let-property="property" let-j="j">
    <dp-accordion [expanded]="openLegalDescription">
        <div class="dp-accordion-header">
            <div class="row  row-group">
                <label class="control-label col-lg-2">Legal Description</label>
                <div class="col-lg-10">
                    <input
                        type="text"
                        name="partlot_{{property.identifier}}"
                        [dp-read-only]="true"
                        class="form-control"
                        [(ngModel)]="property.partLot"
                    />
                </div>
            </div>
        </div>
        <div class="dp-accordion-content">
            <div class="form-group">
                <label class="control-label col-lg-2">{{getLincLabel()}} {{j === -1 ? 1 : j+2}}</label>
                <div class="col-lg-4">
                    <input
                        type="text" id="bclincNumber_{{property.identifier}}"
                        name="bclincNumber_{{property.identifier}}"
                        [fieldKey]="'lincNumber_'+ property.identifier"
                        uppercased trimmed
                        dp-error-validator
                        dp-pidnumber
                        (blur)="checkDuplicationOfLINC()"
                        [(ngModel)]="property.lincNumber"
                        maxlength="{{LINC_NUMBER_MAX_LENGTH_BC}}"
                        class="form-control"/>
                    <div class="actionbtns" *ngIf="j !== -1">
                        <div class="width-20">
                            <span>
                                <i class="fa fa-trash"
                                   (click)="removeMatterPropertyByLincNumber(property, 'lincNumber_'+property.identifier)"
                                   aria-hidden="true">
                                </i>
                            </span>
                        </div>
                    </div>
                </div>
                <label class="control-label col-lg-2">Roll Number {{j === -1 ? 1 : j+2}}</label>
                <div class="col-lg-4">
                    <input
                        type="text"
                        class="form-control"
                        trimmed
                        uppercased
                        maxlength="20"
                        id="rollnumber_{{property.identifier}}"
                        name="rollnumber_{{property.identifier}}"
                        [fieldKey]="'rollnumber_'+ property.identifier"
                        dp-error-validator
                        (blur)="removeMatterTaxOnBlurEvent(property)"
                        [(ngModel)]="property.rollNumber.city"
                    />
                </div>
            </div>
            <div class="form-group">
                <label class="control-label col-lg-2">Short Legal Description</label>
                <div class="col-lg-10">
                    <input
                        type="text"
                        class="form-control"
                        trimmed
                        maxlength="255"
                        id="shortLegalDescription_{{property.identifier}}"
                        name="shortLegalDescription_{{property.identifier}}"
                        [fieldKey]="'shortLegalDescription_'+ property.identifier"
                        [(ngModel)]="property.shortLegalDescription"
                        (keyup)="getlegalDescriptionForBC(property)"
                    />
                </div>
            </div>
            <div class="form-group">
                <label class="control-label col-lg-2" for="propertyTaxesSummary">Full Legal Description</label>
                <div class="col-lg-10">
                    <textarea
                        rows="3"
                        id="fullLegalDescription_{{property.identifier}}"
                        name="fullLegalDescription_{{property.identifier}}"
                        [(ngModel)]="property.fullLegalDescription"
                        class="form-control"
                        (keyup)="getlegalDescriptionForBC(property)"
                    ></textarea>
                </div>
            </div>
            <div class="form-group">
                <ng-container *ngIf="propertyModel.isCondominium === 'YES'">
                    <label class="control-label col-lg-2">Parking Stall(s)</label>
                    <div class="col-lg-4">
                        <input
                            type="text"
                            class="form-control"
                            trimmed
                            maxlength="15"
                            id="parkingStall_{{property.identifier}}"
                            name="parkingStall_{{property.identifier}}"
                            [fieldKey]="'parkingStall_'+ property.identifier"
                            [(ngModel)]="property.parkingStalls"
                            [dpPlaceholder]="'Separate items with commas'"
                        />
                    </div>
                    <label class="control-label col-lg-2">Locker(s)</label>
                    <div class="col-lg-4">
                        <input
                            type="text"
                            class="form-control"
                            trimmed
                            maxlength="15"
                            id="lockers_{{property.identifier}}"
                            name="lockers_{{property.identifier}}"
                            [fieldKey]="'lockers_'+ property.identifier"
                            [(ngModel)]="property.lockers"
                            [dpPlaceholder]="'Separate items with commas'"
                        />
                    </div>
                </ng-container>
            </div>
            <div class="form-group">
                <ng-container *ngIf="j !== -1">
                    <div
                        class="col-lg-3 margin-left-40 text-right"
                        *dpShowByProvince="'matter.subjectProperty.addressOption'"
                    >
                        <dp-checkbox fieldId="pinnumber_address_{{j + 1 + currentTimeStamp}}">
                            <input type="checkbox"
                                id="pinnumber_address_{{j + 1 + currentTimeStamp}}"
                                [checked]="property.addressOption"
                                (change)="updateAddressyCheckbox(property)"/>
                        </dp-checkbox>
                        <label for="pinnumber_address_{{j + 1 + currentTimeStamp}}">Property Address</label>
                    </div>
                    <div class="col-lg-12" *ngIf="property.addressOption">
                        <dp-address-form
                            [address]="property.address"
                            [fieldPrefix]="'pin.address'"
                            [page]="page"
                            [primaryShutter]="false"
                            [secondaryShutter]="true"
                            addressSummaryLabel="Property Address"
                        ></dp-address-form>
                    </div>
                </ng-container>
            </div>
        </div>
    </dp-accordion>
</ng-template>
<ng-template #titleSearchPerformedInput>
    <div class="form-group">
        <label class="control-label col-lg-2" for="titleSearch">Title Search Performed?</label>
        <div class="col-lg-1">
            <select class="form-control" (ngModelChange)="onChange($event)" id="titleSearch"
                    name="titleSearch" [(ngModel)]="propertyModel.isTitleSearchPerformed ">
                <option *ngFor="let title of titleSearchModel" [value]="title.value">{{title.label}}
                </option>
            </select>
        </div>
    </div>
</ng-template>
<ng-template #interestOfPropertyInput>
    <div class="form-group">
        <label class="control-label col-lg-2" for="commonExpenses"
            >% Interest of Property</label
        >
        <div class="col-lg-4">
            <input
                type="text"
                id="percentInterestOfProperty"
                name="percentInterestOfProperty"
                [(ngModel)]="propertyModel.percentInterestOfProperty"
                class="form-control text-right"
                dp-percentage
            />
        </div>
    </div>
</ng-template>
<ng-template #addAnotherPidInput>
    <div class="form-group">
        <label class="control-label col-lg-2"></label>
        <div class="col-lg-3">
            <span class="actionlink">
                <span>
                    <a
                        href="javascript:void(0);"
                        (click)="addNewLincAndRollNumber()"
                    >
                        Add Another PID
                    </a>
                </span>
                <span class="selector fa fa-plus-square-o"></span>
            </span>
        </div>
    </div>
</ng-template>
<ng-template #interestEstateInput>
    <div class="form-group">
        <ng-container *ngIf="matter.isMatterProvinceBC">
            <label
            class="control-label col-lg-2"
            for="interestEstate"
            dp-province-field-label="matter.subjectProperty.interestEstate.label">Interest/Estate</label>
            <div class="col-lg-4">
                <select
                    class="form-control"
                    (ngModelChange)="onChangeInterestEstate($event)"
                    id="interestEstate"
                    name="interestEstate"
                    [(ngModel)]="propertyModel.interestEstate">
                    <option
                        *ngFor="let interestEstate of interestEstates"
                        [value]="interestEstate.value">
                        {{ interestEstate.label }}
                    </option>
                </select>
            </div>
        </ng-container>
        <div *ngIf="isSpecifyApplicable()">
            <label class="control-label col-lg-2" for="interestEstate">Specify</label>
            <div class="col-lg-4">
                <input
                    type="text"
                    id="specifyOther"
                    name="specifyOther"
                    class="form-control"
                    [(ngModel)]="propertyModel.specify"
                    maxlength="50"
                />
            </div>
        </div>
        <div
            *ngIf="
                matter.isMatterProvinceON &&
                (matter.isPurchase || matter.isSale || matter.isProjectSale)
            "
        >
            <div class="col-lg-2 vertical-align-label">
                <label
                    class="control-label"
                    for="occupancyStatusDeclarationRequired"
                    >Is Vacant Occupancy Declaration required?</label
                >
            </div>
            <div class="col-lg-1">
                <select
                    class="form-control"
                    (ngModelChange)="onChange($event)"
                    id="occupancyStatusDeclarationRequired"
                    name="occupancyStatusDeclarationRequired"
                    [(ngModel)]="
                        propertyModel.occupancyStatusDeclarationRequired
                    "
                >
                    <option
                        *ngFor="let noYesOption of noYesOptions"
                        [value]="noYesOption.value"
                    >
                        {{ noYesOption.label }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #strataMaintenanceFeesInput>
    <div
        class="form-group"
        *dpShowByProvince="'matter.property.strataMaintenanceFee'"
    >
        <div
            class="row"
            *ngIf="propertyModel.isCondominium === DpBooleanValueTypes.YES"
        >
            <div class="col-lg-2 vertical-align-label">
                <label
                    class="control-label"
                    dp-province-field-label="strataMaintenanceFees"
                ></label>
            </div>
            <div class="col-lg-4">
                <input
                    type="text"
                    id="strataMaintenanceFees"
                    name="strataMaintenanceFees"
                    class="form-control text-right padding-right-30"
                    [(ngModel)]="
                        propertyModel.propertyStrataMaintenanceFee
                            .strataMaintenanceAmount
                    "
                    dp-currency
                    statusBarHelp
                    (keydown.f9)="openStrataFeeModal()"
                    [dp-read-only]="true"
                />
                <button
                    type="button"
                    tabIndex="-1"
                    id="strataMaintenanceFeesBtn"
                    (click)="openStrataFeeModal()"
                    class="button-glyph"
                >
                    <span class="glyphicon glyphicon-edit"></span>
                </button>
            </div>
        </div>
    </div>
</ng-template>
