import {Component, HostListener, OnInit, Renderer2, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {UtilsService} from './main/utils.service';
import {LockScreenService} from './core/lock-screen.service';
import {AppLoaderService} from './core/app-loader-service';
import {AuthNService, HttpClient, TokenRefreshAction} from './core';
import {ConfirmPasswordModalComponent} from './main/confirm-password-modal/confirm-password.modal.component';
import {DialogService} from './shared/dialog/dialog.service';
import {Subject} from 'rxjs/Subject';
import {FeatureService} from './feature-service';
import {AuthZService} from './core/authz/auth-z.service';

// this component will be loaded when user hit the dppmapp url.
// this component is the entry point to the ddpm app.
// it is the master page where all other page will be inculded.
@Component({
  selector: 'dppm-app',
  encapsulation: ViewEncapsulation.None,
  templateUrl: 'app.component.html',
  providers: [UtilsService]
})
export class AppComponent implements OnInit {
  redirectTokenId: string;
  redirectToken: string;

  passwordRefreshRequester = new Subject<TokenRefreshAction>();

  constructor(
    private authNService: AuthNService,
    public router: Router,
    public utilsService: UtilsService,
    public lockScreenService: LockScreenService,
    public renderer: Renderer2,
    public httpClient: HttpClient,
    public dialogService: DialogService,
    public featuresService: FeatureService,
    public appLoaderService: AppLoaderService,
    public authZService: AuthZService,
    public route: ActivatedRoute
  ) {
    httpClient.passwordRefreshRequester = this.passwordRefreshRequester;
    this.dialogService.rendererRef = this.renderer;
  }

  ngOnInit() {
    let dndBgImagePathStyle = sessionStorage.getItem('dndBgImagePathStyle');
    if (dndBgImagePathStyle) {
      this.utilsService.dndBgImagePathStyle = dndBgImagePathStyle;
    }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        (<any>window).Appcues && (<any>window).Appcues.page();
      }
    });

    const resetPasswordPath = window.location.href.match(
      '\\/reset-password\\/[a-f0-9]{8}(?:-[a-f0-9]{4}){4}[a-f0-9]{8}$'
    );
    if (resetPasswordPath) {
      this.router.navigate([resetPasswordPath[1]]);
    }

    const firstLoginPath = window.location.href.match(
      '\\/first-login\\/register\\/[a-f0-9]{8}(?:-[a-f0-9]{4}){4}[a-f0-9]{8}$'
    );
    if (firstLoginPath) {
      this.router.navigate([firstLoginPath[1]]);
    }

    if (this.featuresService.isSSOEnabled && !this.authNService.isSSOTokenActive()) {
      this.router.navigate(['']);
    }

    if (!this.featuresService.isSSOEnabled) {
      this.registerForPasswordRefresh();
    }

    if (!this.featuresService.isSSOEnabled && !this.authNService.isUPMTokenActive()) {
      this.router.navigate(['/login/home']);
    }

    if (this.authNService.isSSOTokenActive() || this.authNService.isUPMTokenActive()) {
      this.authZService.getRedirectUrl().then((redirectUrl) => {
        this.router.navigate([redirectUrl]);
      });
    }
  }

  registerForPasswordRefresh(): void {
    this.passwordRefreshRequester.subscribe((tokenRefreshAction: TokenRefreshAction) => {
      //saves the lockscreen status
      let lockScreen = this.lockScreenService.lockForUpdate;
      if (lockScreen) {
        //Unlock the screen
        this.lockScreenService.lockForUpdate = false;
      }
      this.dialogService.matDialogContent({
        content: ConfirmPasswordModalComponent,
        context: {
          oldToken: tokenRefreshAction.oldToken,
          fullLoginFlag: tokenRefreshAction.fullLoginFlag
        },
        modalGrid: 5,
        passwordModal: true,
        onFulfillment: async (res) => {
          if (res) {
            if (res.fullLoginFlag) {
              // Reacquire locks for all the opened tabs
              await this.httpClient.postReloginHandler();
            }
            tokenRefreshAction.newTokenSuccessAction(res.newToken);
            if (lockScreen) {
              //Lock the screen back
              this.lockScreenService.lockForUpdate = true;
            }
          }
        },
        onRejection: () => {
          this.httpClient.sessionExpired();
        }
      });
    });
  }

  get percentageLoading(): number {
    return this.appLoaderService
      ? this.appLoaderService.percentageLoading > 5
        ? this.appLoaderService.percentageLoading
        : 10
      : 10;
  }

  get progressBarLoading(): number {
    return Number(100 - this.percentageLoading);
  }

  get isLoaderInitialize(): boolean {
    return true;
  }

  get isAllModuleLoaded(): boolean {
    return true;
  }

  // Lock screen doesn't prevent submit buttons from being clicked by pressing the enter key on the keyboard
  // The following prevents key press when the lock screen is on.
  @HostListener('window:keydown', ['$event'])
  @HostListener('window:keyPress', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (this.lockScreenService.lockForUpdate) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
}
