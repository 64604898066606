import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FocusFirstElementDecorator} from '../../../../shared-main/focus-first-element-decorator';
import {Matter} from '../../../shared/matter';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {
  DdDispositionType,
  DdlTypeForCorrespondence,
  DdlTypeForYesNo,
  DdMortgageeType,
  Mortgage
} from '../../../shared/mortgage';
import {
  correspondenceOptions,
  dropDowns,
  mortgageDispositionTypeOptions,
  mortgageeTypeOptions,
  noYesOptions,
  yesNoOptions,
  dischargeRegisteredByOptionsBCMortgage
} from '../dropdown-options';
import {MortgageTerm} from '../../../shared/mortgage-term';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {MortgagePayout} from '../mortgage-payout/mortgage-payout';
import {MortgagePayoutModal} from '../mortgage-payout/mortgage-payout.modal.component';
import {CurrencyPipe} from '@angular/common';
import {MortgageeComponent} from '../mortgagee/mortgagee.component';
import {MatterParticipant} from '../../../shared/matter-participant';
import Utils from '../../../../shared-main/utils';
import SharedUtils from '../../../../shared-main/utils';

import {MatterTab} from '../../../matter-tab';
import {TabsService} from '../../../../core/tabs.service';

import {
  Constants, MortgageDischargeRegisteredByType,
  MortgageDispositionType, IS_TO_BE_REMOVED_BY, IS_TO
} from '../../../../shared-main/constants';
import {EmailFieldService} from '../../../../shared-main/email-field/email-field-service';
import {UserDefinedField} from '../../../../shared-main/user-defined-field/user-defined-field';
import {UserDefinedFieldService} from '../../../../shared-main/user-defined-field/user-defined-field-service';
import * as _ from 'lodash';
import {SelectItem} from 'primeng/api';
import moment from 'moment';
import {MatterUndertaking} from '../../../shared/matter-undertaking';
import {MatterTypesValue, matterUndertakingStatusDropdowns, matterUndertakingStatusDropdownsForMassUpdate} from '../../../shared';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {UndertakingsConfigService} from '../../../../admin/shared/undertaking-config.service';
import {
  correspondence,
  dischargeRegisteredBy, dischargeRegisteredByOptions, instrumentType, mortgageeType,
  provinceBasedFinancingTypeDropDown
} from '../../../../shared-main/province-based-dropdowns';
import {MortgageInstrument} from '../../../shared/mortgage-instrument';
import {MatterUserDefinedFieldsUtil} from '../../../shared/matter-utils/matter-user-defined-fields-util';
import {PayoutStatement} from '../../../../shared-main/telus/payout-statement';
import {TelusService} from '../../../../shared-main/telus/telus-service';
import {MatterService} from '../../../matter.service';
import {MatterParticipantRoleTypes} from '../../../shared/matter-participant-role-types';
import {DocumentProductionService} from '../../../document-production/document-production.service';
import {ContactQueryService} from '../../../../contact/contact-query.service';
import {provinceBasedFieldLabels} from '../../../../shared-main/province-based-field-labels';
import {AutoComplete} from 'primeng/autocomplete';

declare var jQuery: any;

/**
 *  RelatedInstrumentWrapper  includes autoComplete ngModel value and mortgage relatedInstruments information
 */
export class RelatedInstrumentWrapper {
  dataModel: any; //data model refers to the Instrument Type selected in auto-search
  selectedMortgageInstrument: MortgageInstrument;
}

@Component({
  selector: 'dp-existing-mortgage',
  templateUrl: 'existing-mortgage.component.html',
  styleUrls: [ './existing-mortgage.component.scss' ],
  providers: [ CurrencyPipe ]
})
@FocusFirstElementDecorator()
export class ExistingMortgageComponent implements OnInit {
  @Output() onChange = new EventEmitter();
  @ViewChild('mortgageeComponent') mortgageeComponent: MortgageeComponent;
  @ViewChild('registeredOnDate') registeredOnDate;
  @Input() showWizardFields: boolean = false; //Used only when opened from Wizard
  @Input() dpReadOnlyBlock: boolean = false; //Used only when opened from Wizard

  id: number;
  yesNoItems: DdlTypeForYesNo[] = yesNoOptions;
  noYesItems: DdlTypeForYesNo[] = noYesOptions;
  correspondenceItems: SelectItem[];
  pattern = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;
  mortgagePriority: any[] = [];
  mortgageIndexOrdinal: string = '';
  assignedMortgagePriority: number;

  financingType: SelectItem[];
  dischargeRegisteredByItems: SelectItem[];
  financingTypeLoading: boolean = false;
  financingTypeSuggestions: SelectItem[] = [];
  matterUndertakingStatusOptions: SelectItem[];
  mortgageeTypes: SelectItem[];
  // Now we need to support manual input, so we nee a local variable  to record manual input (it is string) and the selected item by autoComplete
  // So we use any for type
  selectedFinancingType: any;
  associatedUnderTaking: MatterUndertaking;

  relatedInstrumentWrappers: RelatedInstrumentWrapper[] = [];
  instrumentTypeLoading: boolean = false;
  relatedInstrumentSuggestions: SelectItem[];
  payoutStatement: PayoutStatement;
  IS_TO_BE_REMOVED_BY: string = IS_TO_BE_REMOVED_BY;
  IS_TO: string = IS_TO;

  constructor(public activatedRoute: ActivatedRoute,
              public dialogService: DialogService,
              public userDefinedFieldService: UserDefinedFieldService,
              public currencyPipe: CurrencyPipe,
              public router: Router,
              public tabService: TabsService,
              public errorService: ErrorService,
              public emailFieldService: EmailFieldService,
              public telusService: TelusService,
              public undertakingsConfigService: UndertakingsConfigService, public elementRef: ElementRef,
              public documentProductionService: DocumentProductionService,
              public contactQueryService: ContactQueryService,
              public matterService: MatterService) {
  }

  ngOnInit(): void {
    this.activatedRoute
    .params
    .subscribe((params: Params) => {
      this.id = +params[ 'mortgageId' ];
      this.initializeState();

    });
  }

  _matter: Matter;
  @Input() set matter(matter: Matter) {
    if (matter != this._matter) {
      // Because there are some clean up after saving, put initRelatedInstrumentWrappers here for updating relatedInstrumentWrappers
      // If this this._matter is undefined, it means it is not save matter. It is adding new mortgage.
      // For now we don't finish to create the current mortgage. So we can't call initRelatedInstrumentWrappers().
      this._matter = matter;
      if (this._matter) {
        this.initRelatedInstrumentWrappers();
      }

    }
  }

  get matter(): Matter {
    return this._matter;
  }

  ngOnDestroy(): void {
    //Need to update undertakings when leaving existing mortgage component (or saving).
    //todo resolve why this gets called twice
    if (this.matter !== undefined) {
      this.matter.updateUndertakings(this.undertakingsConfigService);
    }
  }

  get userDefinedFieldsMortgageId(): number {
    return this.mortgage && this.mortgage.id >= 0 ? this.mortgage.id : undefined;
  }

  get lawFirmName(): String {
    let matterParticipant: MatterParticipant = this.matter.getMatterParticipantByRoleAndMortgage('MORTGAGE_LEGAL_FIRM', this.mortgage);
    if (matterParticipant && matterParticipant.contact) {
      return matterParticipant.contact.organizationName;
    }
    return null;
  }

  addMortgageUDFsTopic(mortgageId, customerUDFs: UserDefinedField[], topicName: string) {
    let udfsConfigTopic = _.filter(customerUDFs, (udf) => {
      return (udf.topicName === topicName && udf.active);
    });
    //console.log("udfsConfigTopic: ", udfsConfigTopic);

    let udfsMatterMortgageTopic = _.filter(this.matter.matterUserDefinedFields, (udf) => {
      return (udf.topicName === topicName && udf.mortgageId === mortgageId && (udf.active || (!udf.active && udf.fieldValue != null && udf.fieldValue != '')));
    });
    //console.log("udfsMatterMortgageTopic: ", udfsMatterMortgageTopic);
    MatterUserDefinedFieldsUtil.addNewMortgageUDFsToMatter(this.matter, udfsConfigTopic, udfsMatterMortgageTopic, mortgageId, topicName);
  }

  get mortgage(): Mortgage {
    this.emailFieldService.mortgage = !this.id || (this.id >= this.matter.existingMortgages.length) ? this.matter.existingMortgages[ 0 ] : this.matter.existingMortgages[ this.id ];
    return !this.id || (this.id >= this.matter.existingMortgages.length) ? this.matter.existingMortgages[ 0 ] : this.matter.existingMortgages[ this.id ];
  }

  get mortgageIndex(): number {
    let index = this.matter.existingMortgages.findIndex(item => item.id == this.mortgage.id);
    return index >= 0 ? (Number(index) + 1) : index;
  }

  get mortgageTerm() {
    if (!this.mortgage.mortgageTerm) {
      this.mortgage.mortgageTerm = new MortgageTerm();
    }
    return this.mortgage.mortgageTerm;
  }

  get isRequestPostponementVisible(): boolean {
    // this function is see whether the existing mortgage requires postponement label
    // first check if both existing mortgages exist and new mortgage exists
    if (this.matter.existingMortgages.length > 0 && this.matter.newOrEmpMortgages.length > 0) {
      // we for loop through each new mortgage and then compare the mortgagePriority value to compare it with the current
      for (let j = 0; j < this.matter.newOrEmpMortgages.length; j++) {
        // In the event the existing Mortgage is greater than the priority set for any of the New Mortgage then '(req postponement)' will
        // be displayed
        let existingMortgagePriority = this.matter.existingMortgages[ this.mortgageIndex - 1 ].mortgagePriority;
        let newMortgagePriority = this.matter.newOrEmpMortgages[ j ].mortgagePriority;
        // we compare the new mortgage priorty with the existing mortgage priorty
        if (this.isExistingMortgagePriorityGreaterThenNewMortgagePriority(newMortgagePriority, existingMortgagePriority)) {
          return true;
        }
      }
      return false;
    } else {
      // if no mortgage for either existing or new we return false
      return false;
    }
  }

  isExistingMortgagePriorityGreaterThenNewMortgagePriority(newMortgage, existingMortgage): boolean {
    // we check if the current existing mortgage priority is greater than the new mortgage priority field
    let isNum: boolean = !isNaN(newMortgage) && !isNaN(existingMortgage);
    if (isNum) {
      return (Number(existingMortgage) > Number(newMortgage));
    } else {
      return false;
    }
  }

  public generatePriorityForMortgage(): void {
    if (this.mortgage && this.mortgage.isMortgageDispositionRemain()) {
      this.mortgagePriority = this.matter.availableMortgagePriority(this.mortgage ? this.mortgage.mortgagePriority : undefined);
      if (this.mortgage.mortgagePriority == undefined) {
        this.mortgage.mortgagePriority = Math.min(...this.mortgagePriority.map(item => {
          return Number(item.value);
        }));
        this.assignedMortgagePriority = this.mortgage.mortgagePriority;
      }
    }
  }

  //for Sale (including PS), Mortgage
  showUndertakingRelatedFields(): boolean {
    return (this.matter && (this.matter.isSale || this.matter.isMortgage) && (this.mortgage && this.mortgage.isMortgageDispositionDischarged()));
  }

  get mortgageDispositionTypeItems(): DdDispositionType[] {
    if (this.matter && this.matter.isProjectSale) {
      if (this.matter.isMatterProvinceON || this.matter.isMatterProvinceAB) {
        return _.filter(mortgageDispositionTypeOptions, item => item.value != MortgageDispositionType.REMAIN && item.value != MortgageDispositionType.BRIDGE_FINANCING);
      }
    }

    if (this.matter && this.matter.isMortgage) {
      return mortgageDispositionTypeOptions.filter(item => item.value != MortgageDispositionType.ASSUMED && item.value != MortgageDispositionType.BRIDGE_FINANCING);
    } else {
      return mortgageDispositionTypeOptions.filter(item => item.value != MortgageDispositionType.REMAIN);
    }
  }

  initializeState(): void {

    this.matterUndertakingStatusOptions = matterUndertakingStatusDropdowns;
    if (this.matter.isTemplateMatterForMassUpdate) {
      this.matterUndertakingStatusOptions = matterUndertakingStatusDropdownsForMassUpdate;
    }
    if (this.mortgage && this.mortgage.financingType) {
      this.selectedFinancingType = this.mortgage.financingType;
    }

    this.mortgageIndexOrdinal = Utils.getOrdinal(this.mortgageIndex);
    this.generatePriorityForMortgage();
    this.financingType = provinceBasedFinancingTypeDropDown[ this.matter.provinceCode ];
    this.dischargeRegisteredByItems = dischargeRegisteredBy[ this.matter.provinceCode ];
    this.correspondenceItems = correspondence[ this.matter.provinceCode ];
    this.mortgageeTypes = mortgageeType[ this.matter.provinceCode ];

    /*this.financingType = dropDowns.financingTypeOptions;
    if(this.matter.isMatterProvinceNBorNS){
        this.financingType = this.matter.isMatterProvinceNB ? dropDowns.financingTypeOptionsNB : dropDowns.financingTypeOptionsNS;
    }*/
    //Set selectedFinancingType with the get property
    this.selectedFinancingType = this.selectedFinancingTypeForSearch;

    if (this.matter && this.matter.isMatterProvinceBC) {
      this.initializeForBC();
    }

    this.associatedUnderTaking = this.getAssociatedUndertaking();

    setTimeout(() => {
      if (this.registeredOnDate) {
        this.registeredOnDate.verifyData();
      }
    }, 100);

    this.addUDF();
    this.matter.updateBalanceOfFundsPayableTo();
    //Put initRelatedInstrumentWrappers in  initializeState after creating mortgage
    this.initRelatedInstrumentWrappers();
    this.getLatestPayoutStatement();
    this.getDischargeRegisteredByItemsByType();
  }

  getDischargeRegisteredByItemsByType() {
    if (
      this.matter?.isMortgageBC
    ) {
      this.dischargeRegisteredByItems =
        dischargeRegisteredByOptions.dischargeRegisteredByOptionsBC;
      this.mortgage.dischargeRegisteredBy = MortgageDischargeRegisteredByType.MORTGAGEE;
    }
  }

  getLatestPayoutStatement() {
    this.telusService.fetchLatestPayoutStatement(this.matter.id, this.mortgage.id).subscribe((payoutStatement: PayoutStatement) => {
      if (payoutStatement) {
        this.payoutStatement = payoutStatement;
        this.mortgage.payoutStatement = payoutStatement;
      }
    });
  }

  initRelatedInstrumentWrappers() {
    this.relatedInstrumentSuggestions = instrumentType[ this.matter.provinceCode ];
    if (!Array.isArray(this.mortgage.relatedInstruments)) {
      this.mortgage.relatedInstruments = [];
    }
    if (this.mortgage.relatedInstruments.length === 0) {
      //Assignment
      let mortgageInstrument = new MortgageInstrument();
      mortgageInstrument.instrumentType = '';
      this.mortgage.relatedInstruments.push(mortgageInstrument);
    }

    this.relatedInstrumentWrappers = [];
    this.mortgage.relatedInstruments.forEach((relatedInstrument: MortgageInstrument) => {
      let relatedInstrumentWrapper: RelatedInstrumentWrapper = new RelatedInstrumentWrapper();
      let dropDownItem = this.relatedInstrumentSuggestions.find(item => item.value === relatedInstrument.instrumentType);
      relatedInstrumentWrapper.dataModel = dropDownItem ? dropDownItem : {
        label: SharedUtils.capitalizeWord(relatedInstrument.instrumentType),
        value: relatedInstrument.instrumentType
      };
      relatedInstrumentWrapper.selectedMortgageInstrument = relatedInstrument;
      this.relatedInstrumentWrappers.push(relatedInstrumentWrapper);
    });
  }

  initializeForBC() {
    if (this.matter.isSale) {
      if (!this.selectedFinancingType) {
        this.selectedFinancingType = this.findFinancingType('Mortgage');
      }
      this.mortgage.mortgageDispositionType = MortgageDispositionType.DISCHARGED;
      if (!this.mortgage.dischargeRegisteredBy) {
        this.mortgage.dischargeRegisteredBy = MortgageDischargeRegisteredByType.OUR_FIRM;
      }
    }
  }

  addUDF(): void {
    if (this.matter && this.matter.existingMortgages && this.matter.existingMortgages.length > 0 && this.matter.existingMortgages.length > this.id) {
      let mortgageId = this.matter.existingMortgages[ this.id ].id;
      console.log(`Mortgage changed: id=${ this.id } |  mortgageId=${ mortgageId }`);
      this.userDefinedFieldService
      .getUserDefinedFieldsForLoggedInCustomerAccount(this.isDischargeMatter() ? MatterTypesValue.DISCHARGE : this.matter.matterType, this.matter.provinceCode)
      .subscribe((customerUDFs: UserDefinedField[]) => {
        if (Array.isArray(customerUDFs)) {
          this.addMortgageUDFsTopic(mortgageId, customerUDFs, 'EXISTING_MORTGAGE');
        }
      });
    }
  }

  isDischargeMatter(): boolean {
    return this.matter && !!this.matter.isMatterTypeDischarge;
  }

  onDischargeRegisteredByChange(): void {
    this.notifyChange();
    // update related TrustLedger or SOA items
    if (this.matter.soaTrustLedgerCollection) {
      this.matter.soaTrustLedgerCollection.updateERegAndRegisterCharges();
    }
  }

  notifyChange(event?: any): void {
    this.onChange.emit();
    if (event && event.dataUpdateAttribute == 'PayoutUpdated') {
      this.updateTrustLedgerAndMortgageDataOnPayoutChange(this.mortgage.mortgagePayout);
    }
  };

  isAmountPayableApplicable(): boolean {
    if (this.matter.provinceCode === 'AB') {
      if (this.mortgage.isMortgageFinancingTypeCaveat()) {
        return false;
      } else {
        if (this.mortgage.isMortgageDispositionBridgeFinancing() || this.mortgage.isMortgageDispositionDischarged()) {
          return true;
        } else {
          return false;
        }
      }
      //Default case for Other Provinces
    } else {
      if (this.mortgage.isMortgageDispositionBridgeFinancing() || this.mortgage.isMortgageDispositionDischarged()) {
        return true;
      } else {
        return false;
      }
    }
  }

  get mortgagePriorities(): any[] {
    let mortgagePriority = this.mortgage ? this.mortgage.mortgagePriority : undefined;
    if (this.assignedMortgagePriority != mortgagePriority) {
      this.mortgagePriority = this.matter.availableMortgagePriority(mortgagePriority);
      if (this.mortgage) {
        let mortgagePriorityFound = this.mortgagePriority.find(item => item.value == mortgagePriority);
        if (mortgagePriorityFound) {
          this.mortgage.mortgagePriority = mortgagePriorityFound.value;
        }

      }
      this.assignedMortgagePriority = this.mortgage ? this.mortgage.mortgagePriority : undefined;
    }
    return this.mortgagePriority;
  }

  onDateChangeMortgageRegDate(event): void {
    this.mortgage.mortgageRequestDate = event.rawDate;
    this.notifyChange();
  }

  getStatementForInformationPartLabelText() {
    let partLabelText = 'information purposes';
    switch (this.mortgage.mortgageDispositionType) {
      case MortgageDispositionType.DISCHARGED :
        partLabelText = 'discharge purposes';
        break;
      case MortgageDispositionType.ASSUMED :
        partLabelText = 'assumption purposes';
        break;
      case MortgageDispositionType.BRIDGE_FINANCING:
        partLabelText = 'bridge financing';
        break;
      default :
        break;
    }

    return partLabelText;
  }

  openMortgagePayout(): void {
    this.dialogService.matDialogContent({
      content: MortgagePayoutModal,
      context: {
        mortgagePayoutPassed: this.mortgage.mortgagePayout,
        matterCloseDate: this.matter.matterCloseDate,
        lawFirmName: this.lawFirmName,
        mortgageCorrespondenceType: this.mortgage.mortgageCorrespondenceType,
        mortgageDispositionType: this.mortgage.mortgageDispositionType,
        mortgage: this.mortgage,
        matter: this.matter
      },
      onFulfillment: (result) => {
        if (result && result.mortgagePayout) {
          this.mortgage.mortgagePayout = result.mortgagePayout;
          this.updateTrustLedgerAndMortgageDataOnPayoutChange(this.mortgage.mortgagePayout);
          if (result.isUpdated) {
            this.notifyChange();
          }
        }
      }
    });
  }

  updateTrustLedgerAndMortgageDataOnPayoutChange(mortgagePayout: MortgagePayout) {
    if (this.matter.isMatterProvinceAB && !this.mortgage.mortgagePayout.payoutEqualsTrustBalance && this.matter.soaTrustLedgerCollection) {
      this.matter.soaTrustLedgerCollection.makePayToYouTLEditable();
    }
    if (mortgagePayout.useMortgagePayoutCalculator) {
      this.mortgage.amountPayableToDischarge = mortgagePayout.total;
    } else {
      this.mortgage.amountPayableToDischarge = mortgagePayout.amountPayableToDischarge;
    }
    //the amountPayableToDischarge need to sync with F9 value of the corresponding TL entry, in case 'Auto Insert F9' is checked
    if (this.matter.soaTrustLedgerCollection) {
      this.matter.soaTrustLedgerCollection.addRemoveTrustLedgerPaidDischargeFee(this.mortgage);
      this.matter.soaTrustLedgerCollection.updateF9ValueForCorrespondingTL(this.mortgage, this.matter.autoInsertAllF9Values);
    }
    this.updateAmountPayableToDischargeInMatterTrustLedger();
  }

  updateAmountPayableToDischargeInMatterTrustLedger() {

    if (this.matter && this.matter.soaTrustLedgerCollection) {
      this.matter.soaTrustLedgerCollection.updateF9ForPaidToYouAndOtherTrustLedgers();
    }

  }

  formattedDate(dateStr: string): string {
    return dateStr && this.pattern.test(dateStr) ? moment(dateStr).format('MMM DD, YYYY') : '';
  }

  mortgagePayOutLabelON(time: string): string {
    if (this.pattern.test(time)) {
      return 'Incl. interest to ' + this.formattedDate(time)
        + (this.mortgage.mortgagePayout.isDischargeFeeApplied()
          ? (' separate discharge fee of ' + this.getFormattedCurrencyValue(this.mortgage.mortgagePayout.dischargeAmount))
          : ' including discharge fee');
    } else {
      return this.mortgage.mortgagePayout.isDischargeFeeApplied() ? (' separate discharge fee of ' + this.getFormattedCurrencyValue(this.mortgage.mortgagePayout.dischargeAmount)) : '';
    }
  }

  get mortgagePayOutLabel(): string {
    if (this.matter.isMatterProvinceON) {
      let retString = '';
      if (this.mortgage.mortgagePayout) {
        if (this.mortgage.mortgagePayout.useMortgagePayoutCalculator) {
          retString = this.mortgagePayOutLabelON(this.mortgage.mortgagePayout.perDiemCalculationPeriod);
        } else {
          retString = this.mortgagePayOutLabelON(this.mortgage.mortgagePayout.inclusiveOfInterestToDate);
        }
      }
      return retString;
    } else {
      if (this.mortgage.mortgagePayout && !this.mortgage.mortgagePayout.useMortgagePayoutCalculator && this.pattern.test(this.mortgage.mortgagePayout.inclusiveOfInterestToDate)) {
        return 'Incl. interest to ' + this.formattedDate(this.mortgage.mortgagePayout.inclusiveOfInterestToDate) + (this.mortgage.mortgagePayout.isDischargeFeeApplied() ?
          ' separate discharge fee of ' + this.getFormattedCurrencyValue(this.mortgage.mortgagePayout.dischargeAmount) : this.mortgagePayoutSubLabel());
      } else if (this.mortgage.mortgagePayout && this.mortgage.mortgagePayout.useMortgagePayoutCalculator && this.pattern.test(this.mortgage.mortgagePayout.principalDate) &&
        this.pattern.test(this.mortgage.mortgagePayout.perDiemCalculationPeriod)) {
        return 'Incl. interest to ' + this.formattedDate(this.mortgage.mortgagePayout.perDiemCalculationPeriod) + (this.mortgage.mortgagePayout.isDischargeFeeApplied() ?
          ' separate discharge fee of ' + this.getFormattedCurrencyValue(this.mortgage.mortgagePayout.dischargeAmount) : this.mortgagePayoutSubLabel());
      } else {
        return '';
      }
    }
  }

  mortgagePayoutSubLabel() {
    if (this.matter.isMortgageBC) {
      return '';
    } else {
      return ' including discharge fee.';
    }
  }

  getFormattedCurrencyValue(currencyValue: number): string {
    if (currencyValue != undefined && !isNaN(currencyValue)
      && currencyValue != null && currencyValue.toString().trim() != '') {
      return this.currencyPipe.transform(currencyValue, 'CAD', 'symbol-narrow', '1.2-2').replace('CA', '');
    } else {
      return this.currencyPipe.transform('0.00', 'CAD', 'symbol-narrow', '1.2-2').replace('CA', '');
    }
  }

  markUndertakingDirty(): void {
    this.mortgage.undertakingDirty = true;
  }

  isMortgageRegNoApplicable(): boolean {
    return (((this.matter.matterType === 'SALE' && !this.mortgage.isMortgageDispositionBridgeFinancing()) || this.matter.matterType === 'MORTGAGE') && this.matter.provinceCode === 'AB');
  }

  burgerMenuItems(): string[] {
    let burgerMenuItems: string[] = [];
    burgerMenuItems.push('Delete');
    return burgerMenuItems;

  }

  burgerMenuClicked(mortgage: Mortgage, clickedMenuOption: string): void {
    if (clickedMenuOption === 'Delete') {
      if (this.payoutStatement && !this.payoutStatement.isCancelled()) {
        this.dialogService.confirm('ERROR', 'There is an active Payout Request associated with this Mortgage, to delete, first cancel the Payout Request.', true);
      } else {
        this.deleteExistingMortgage(mortgage);
      }
    }
  }

  deleteExistingMortgage(mortgage: Mortgage): void {
    let existingMortgageTitle = provinceBasedFieldLabels.get('provinceBasedExistingMortgage', this.matter.provinceCode);
    this.dialogService.confirm('Confirmation', `Are you sure you would like to delete this ${ existingMortgageTitle }?`, false, 'Delete').subscribe(res => {
      if (res) {
        if (this.documentProductionService && this.contactQueryService && this.matter && this.mortgage) {
          this.documentProductionService.revokeAssociatedPackages(this.matter, mortgage, this.contactQueryService);
        }
        let deletedIndex: number = this.matter.existingMortgageIndex(mortgage);
        let mortgageIndex = -1;
        if (deletedIndex > -1) {
          // if(this.matter && this.matter.isMatterProvinceAB && mortgage.isMortgageDispositionDischarged() && mortgage.mortgagePayout && mortgage.mortgagePayout.payoutEqualsTrustBalance && this.matter.soaTrustLedgerCollection){
          //     //make PayToYouTL editable
          //     this.matter.soaTrustLedgerCollection.makePayToYouTLEditable();
          // }
          // this.matter.deleteExistingMortgage(mortgage, this.errorService);
          // this.matter.removeEregistrationFormByMortgage(mortgage.id);
          // if(this.matter.soaTrustLedgerCollection) {
          //     this.matter.soaTrustLedgerCollection.updateExistingMortgageIndex(deletedIndex);
          //     this.matter.soaTrustLedgerCollection.updateERegAndRegisterCharges();
          // }
          this.matter.deleteExistingMortgageWithCleanup(deletedIndex, mortgage, this.errorService);
          if (this.matter.existingMortgages.length > 0) {
            let modifiedDeletedIndex = Number(deletedIndex) + 1;
            if (modifiedDeletedIndex < this.matter.existingMortgages.length) {
              mortgageIndex = deletedIndex;
              this.initializeState();
            } else {
              if (modifiedDeletedIndex == this.matter.existingMortgages.length) {
                this.initializeState();
              }
              mortgageIndex = Number(this.matter.existingMortgages.length - 1);
            }
            //mortgageIndex = modifiedDeletedIndex < this.matter.existingMortgages.length  ? deletedIndex : Number(this.matter.existingMortgages.length - 1);
          }

          if (mortgageIndex >= 0) {
            this.openExistingMortgage(mortgageIndex);
            if (this.mortgageeComponent) {
              this.mortgageeComponent.initializeState();
            }
          }
        }
        MatterUserDefinedFieldsUtil.deleteMortgageUserDefinedFields(this.matter, mortgage.id);
        this.matter.dirty = true;
      }
    });
  }

  openExistingMortgage(mortgageIndex: number): void {
    if (this.router.url && this.router.url.indexOf('existingMortgages/mortgage/') > -1) {
      this.router.navigate([ this.router.url.substr(0, this.router.url.indexOf('existingMortgages/mortgage/')) + 'existingMortgages/mortgage/' + mortgageIndex ]);
      this.activeMatterTab.section = 'existingMortgages/mortgage/' + mortgageIndex;
    } else {
      this.router.navigate([ this.router.url + '/mortgage/' + mortgageIndex ]);
      this.activeMatterTab.section = 'existingMortgages/mortgage/' + mortgageIndex;
    }
  }

  get activeMatterTab(): MatterTab {
    return this.tabService.activeTab as MatterTab;
  }

  onMortgageDispositionTypeChange(mortgage: Mortgage, isCalledFromMassUpdate: boolean = false) {
    if (this.mortgage.mortgageDispositionType != MortgageDispositionType.REMAIN) {
      this.mortgage.mortgagePriority = undefined;
    }
    if (this.mortgage.mortgageDispositionType == MortgageDispositionType.BLANK) {
      this.errorService.removeErrorsInGroup(this.mortgage.notBlankDispositionTypeGroupId);
    }

    if (!mortgage.isMortgageDispositionDischarged()) {

      this.errorService.removeErrorsInGroup(mortgage.getDischargedMortgageGroupId());
      if (Array.isArray(this.matter.matterUndertakings)) {
        const undertaking: MatterUndertaking = this.matter.matterUndertakings.find(undertaking => undertaking.id == mortgage.undertakingId);
        if (undertaking) {
          this.errorService.removeErrorsInGroup(undertaking.groupId);
        }
      }

      if (mortgage.mortgagePayout && mortgage.mortgagePayout.payoutEqualsTrustBalance) {
        //this field is only applicable for existing mortgage that is toBeDischarged,
        //and only one existing mortgage (with Discharged) can have this field set to true
        //so we need to clear this field to allow other existing mortgage (to be discharged) to select this field
        //TC has bug which didn't clear this field which lead to disabled checkbox in other existing mortgage(Discharged)
        mortgage.mortgagePayout.payoutEqualsTrustBalance = false;
        mortgage.mortgagePayout.amountPayableToDischarge = 0;
        mortgage.amountPayableToDischarge = 0;

        if (this.matter.soaTrustLedgerCollection) {
          this.matter.soaTrustLedgerCollection.makePayToYouTLEditable();
        }
      }
    }
    this.associatedUnderTaking = this.getAssociatedUndertaking();

    this.notifyChange();
    this.generatePriorityForMortgage();
    this.matter.updateStatementOfAccountForMortgage(mortgage);
    if (!this.matter.isMatterProvinceON) {
      this.onMortgageReferenceTypeChange(mortgage);
    }
    this.matter.createERegChargeForms(mortgage);

    //Set the default value for the calculator and payoutEqualsBalance
    MortgagePayoutModal.setDefaultValues(this.mortgage, this.matter, isCalledFromMassUpdate);
  }

  onMortgageReferenceTypeChange(mortgage: Mortgage) {
    if (this.mortgage.mortgageDispositionType === MortgageDispositionType.BRIDGE_FINANCING && this.mortgage.financingType.toUpperCase() != 'MORTGAGE') {
      this.dialogService.confirm('Error - Invalid Selection',
        'This encumbrance cannot be set to Bridge Financing.\n If you wish to proceed, change Bridge Financing to Discharged or Assumed then select Caveat.',
        true, 'OK').subscribe(res => {
        this.mortgage.financingType = 'Mortgage';
        this.selectedFinancingType = this.selectedFinancingTypeForSearch;
        this.markUndertakingDirty();
        this.notifyChange();
      });
    }
  }

  getRegisteredDate() {
    return this.associatedUnderTaking && this.associatedUnderTaking.matterUndertakingStatus == 'FULFILLED' ? this.associatedUnderTaking.registeredDate : '';
  }

  onRegisteredDate(event): void {
    if (this.associatedUnderTaking && event) {
      this.associatedUnderTaking.registeredDate = event.rawDate;
    }
  }

  getAssociatedUndertaking(): MatterUndertaking {
    if (this.mortgage.isMortgageDispositionDischarged()) {
      if (this.matter && Array.isArray(this.matter.matterUndertakings)) {
        let undertaking: MatterUndertaking = this.matter && this.matter.matterUndertakings.find(undertaking => undertaking.id == this.mortgage.undertakingId);
        if (!undertaking) {
          undertaking = this.createUndertakingFromMortgage();
          this.mortgage.undertakingDirty = false;
        }
        return undertaking;
      }
    }
    return null;
  }

  createUndertakingFromMortgage(): MatterUndertaking {
    let undertaking: MatterUndertaking = new MatterUndertaking(); //create new undertaking if necessary
    undertaking.updateUndertakingFromMortgage(this.matter, this.mortgage, this.undertakingsConfigService);
    if (!this.mortgage.undertakingId) {
      this.mortgage.undertakingId = undertaking.id;
      this.matter.matterUndertakings.push(undertaking);
    }
    if (this.matter.isTemplateMatterForMassUpdate) {
      undertaking.matterUndertakingStatus = '';
    }
    return undertaking;
  }

  onMortgageeTypeChange(mortgage: Mortgage) {
    if (this.matter.soaTrustLedgerCollection) {
      if (this.matter.isSale) {
        this.matter.soaTrustLedgerCollection.addTrustLedgerExistingMortgageRow(this.mortgageIndex - 1,
          this.matter.existingMortgages[ this.mortgageIndex - 1 ].isMortgageDispositionTypeBridgeFinancing(),
          this.matter.existingMortgages[ this.mortgageIndex - 1 ].isMortgageDispositionTypeDischarged(),
          this.matter.existingMortgages[ this.mortgageIndex - 1 ]);
      }

      if (this.matter.isMortgage) {
        this.matter.soaTrustLedgerCollection.addTrustLedgerExistingMortgageRow(this.mortgageIndex - 1,
          false,
          this.matter.existingMortgages[ this.mortgageIndex - 1 ].isMortgageDispositionTypeDischarged(),
          this.matter.existingMortgages[ this.mortgageIndex - 1 ]);
      }
    }
    mortgage.isMortgageePrivateLender() ?
      this.matter.reCalculateTrustLedgerPrivateLenderLineByMortgage(this.mortgage) :
      this.matter.reCalculateTrustLedgerMortgageeLineByMortgage(this.mortgage);
    this.matter.reCalculateLenderReLine(this.mortgage);
    this.mortgage.iscClientNumber = null;
    this.notifyChange();
  }

  omniSearchFinancingType(event) {
    console.log('omniSearchFinancingType : ', event);
    const query: string = event.query.trim();
    this.financingTypeSuggestions = !query
      ? this.financingType.slice()
      : this.financingType.filter(x => x.label.toLowerCase().indexOf(query.toLowerCase()) > -1);
    if (this.financingTypeSuggestions.length === 0) {
      this.financingTypeSuggestions.push({label: Constants.NO_RESULTS_FOUND, value: Constants.NO_RESULTS_FOUND});
    }
  }

  handleDropdownClickSearchFinancingType() {
    setTimeout(() => {
      this.financingTypeSuggestions = this.financingType.slice();
    }, 0);

  }

  updateValueOnFocusOut(event, mortgage: Mortgage) {
    if (event.srcElement.value != undefined && event.srcElement.value != '') {
      this.mortgage.financingType = event.srcElement.value;
      this.onMortgageReferenceTypeChange(mortgage);
      this.matter.updateStatementOfAccountForMortgage(mortgage);
    }

  }

  private findFinancingType(financingTypeLabel: string): SelectItem {
    let ftype;
    if (this.financingType) {
      ftype = this.financingType.find(item => item.label == financingTypeLabel);
    }
    return ftype ? ftype : null;
  }

  dataSelectedFinancingType(event) {
    console.log('onSelect : ', event);
    if (event.label === Constants.NO_RESULTS_FOUND) {
      //If select 'No records available', clean data
      this.mortgage.financingType = null;
      this.selectedFinancingType = null;
    } else {
      this.mortgage.financingType = event.label;
      this.selectedFinancingType = this.selectedFinancingTypeForSearch;
      if (this.matter.soaTrustLedgerCollection) {
        this.matter.soaTrustLedgerCollection.updateERegAndRegisterCharges();
      }
    }
    this.notifyChange();
    this.markUndertakingDirty();
    //this.onMortgageReferenceTypeChange(mortgage)
  }

  updateFinancingType(event) {
    //Add manual input function
    if (typeof event == 'string') {
      this.mortgage.financingType = event;
    }
    this.notifyChange();
    this.markUndertakingDirty();

  }

  ngAfterViewInit() {
  }

  setLocalInstancesForMassUpdate(matter: Matter, mortgageIndex: number) {
    this.matter = matter;
    this.id = mortgageIndex;
  }

  get selectedFinancingTypeForSearch(): SelectItem {
    if (this.mortgage && this.mortgage.financingType) {
      let findFinancingType = this.findFinancingType(this.mortgage.financingType);
      // DPPMP-42285 support manual input. If it is not in drop down options, it means it is manual input.
      return findFinancingType ? findFinancingType : {label: this.mortgage.financingType, value: this.mortgage.financingType};
    } else {
      return null;
    }
  }

  isMortgageRequestNumberVisible(): boolean {
    return (this.mortgage.isMortgageDispositionBridgeFinancing() && this.matter.isSale && !this.matter.isMatterProvinceAB)
      || this.mortgage.isMortgageDispositionDischarged()
      || this.mortgage.isMortgageDispositionRemain()
      || this.isMortgageRegNoApplicable();
  }

  mortgageRequestNoLineLabelCss(): string {
    return this.isMortgageRequestNumberVisible() && this.matter.isMatterProvinceNBorNS ? 'col-md-1' : 'col-md-2';
  }

  mortgageRequestNoLineInputCss(): string {
    return this.isMortgageRequestNumberVisible() && this.matter.isMatterProvinceNBorNS ? 'col-md-2' : 'col-md-4';
  }

  isMortgageRequestDateVisible(): boolean {
    return (this.mortgage.isMortgageDispositionBridgeFinancing() && this.matter.isSale && !this.matter.isMatterProvinceAB)
      || this.mortgage.isMortgageDispositionDischarged()
      || this.mortgage.isMortgageDispositionAssumed()
      || this.mortgage.isMortgageDispositionRemain();
  }

  isAssignmentRequestNumberVisible(): boolean {
    return (this.mortgage.isMortgageDispositionBridgeFinancing() && this.matter.isSale && !this.matter.isMatterProvinceAB)
      || this.mortgage.isMortgageDispositionDischarged();
  }

  updateRelatedInstrument(event, selectedRelatedInstrument: RelatedInstrumentWrapper) {
    if (typeof event == 'string') {
      if (selectedRelatedInstrument) {
        if (selectedRelatedInstrument.selectedMortgageInstrument) {
          selectedRelatedInstrument.selectedMortgageInstrument.instrumentType = event;
        }
      }
    }

  }

  omniSearchRelatedInstrument(event) {
    const query: string = event.query.trim();
    this.relatedInstrumentSuggestions = !query
      ? this.relatedInstrumentSuggestions.slice()
      : this.relatedInstrumentSuggestions.filter(x => x.label.toLowerCase().indexOf(query.toLowerCase()) > -1);
    if (this.relatedInstrumentSuggestions.length === 0) {
      this.relatedInstrumentSuggestions.push({label: Constants.NO_RESULTS_FOUND, value: Constants.NO_RESULTS_FOUND});
    }
  }

  dataselectedRelatedInstrumentWrapper(event, selectedRelatedInstrumentWrapper: RelatedInstrumentWrapper) {
    if (selectedRelatedInstrumentWrapper) {
      if (event.label === Constants.NO_RESULTS_FOUND) {
        setTimeout(() => {
          selectedRelatedInstrumentWrapper.dataModel = null;
        }, 0);
        if (selectedRelatedInstrumentWrapper.selectedMortgageInstrument) {
          selectedRelatedInstrumentWrapper.selectedMortgageInstrument.instrumentType = null;
        }
      } else {
        selectedRelatedInstrumentWrapper.selectedMortgageInstrument.instrumentType = event.value;
      }
    }
    this.notifyChange();
  }

  handleDropdownClickSearchRelatedInstrument() {
    // setTimeout( () =>{
    this.relatedInstrumentSuggestions = this.relatedInstrumentSuggestions.slice();
    // },0)
  }

  onDateChangeRegistrationDate(event, selectedRelatedInstrumentWrapper: RelatedInstrumentWrapper) {
    if (selectedRelatedInstrumentWrapper.selectedMortgageInstrument) {
      selectedRelatedInstrumentWrapper.selectedMortgageInstrument.registrationDate = event.rawDate;
    }

    this.notifyChange();
  }

  updateRelatedInstrumentValueOnFocusOut(event, selectedRelatedInstrumentWrapper: RelatedInstrumentWrapper) {
    // if()
  }

  removeRelatedInstrument(selectedRelatedInstrumentWrapper: RelatedInstrumentWrapper) {
    let relatedInstTitle = provinceBasedFieldLabels.get('provinceBasedRelatedInstrumentsShort', this.matter.provinceCode);
    this.dialogService.confirm('Confirmation', `Are you sure you would like to delete this ${ relatedInstTitle }?`, false, 'Delete').subscribe(res => {
      if (res) {
        const removeItemIndex = this.relatedInstrumentWrappers.findIndex(item => item == selectedRelatedInstrumentWrapper);
        if (removeItemIndex > -1) {
          this.notifyChange();
          if (selectedRelatedInstrumentWrapper.selectedMortgageInstrument && Array.isArray(this.mortgage.relatedInstruments)) {
            const removeMortgageInstrumentIndex = this.mortgage.relatedInstruments.findIndex(item => item == selectedRelatedInstrumentWrapper.selectedMortgageInstrument);
            this.mortgage.relatedInstruments.splice(removeMortgageInstrumentIndex, 1);
          }
          this.relatedInstrumentWrappers.splice(removeItemIndex, 1);
        }
      }
    });

  }

  addRelatedInstrument() {
    if (!Array.isArray(this.mortgage.relatedInstruments)) {
      this.mortgage.relatedInstruments = [];
    }

    if (!Array.isArray(this.relatedInstrumentWrappers)) {
      this.relatedInstrumentWrappers = [];
    }

    let relatedInstrumentWrapper: RelatedInstrumentWrapper = new RelatedInstrumentWrapper();
    relatedInstrumentWrapper.selectedMortgageInstrument = new MortgageInstrument();
    relatedInstrumentWrapper.selectedMortgageInstrument.instrumentType = '';
    this.relatedInstrumentWrappers.push(relatedInstrumentWrapper);
    this.mortgage.relatedInstruments.push(relatedInstrumentWrapper.selectedMortgageInstrument);
  }

  isRelatedInstrumentsVisible(): boolean {
    return (this.mortgage.mortgageDispositionType === MortgageDispositionType.DISCHARGED)
      || (this.mortgage.mortgageDispositionType === MortgageDispositionType.BRIDGE_FINANCING);
  }

  showRollBookNumberAndFramePageNumber(): boolean {
    return this.isMortgageRequestNumberVisible() && this.matter && this.matter.isMatterProvinceNBorNS;
  }

  isDischargeByValueChanged(value: string): boolean {
    return value != MatterParticipantRoleTypes.MORTGAGEE;
  }

  //ToDo - This needs to be handled from outside (Disabling Google Search for readonly inputs)
  isOpenedFromWizardBeforeMatterSave(): boolean {
    return !!this.showWizardFields && !!this.dpReadOnlyBlock;
  }

  payoutStatementRequested(event): void {
    if (event && event.payoutStatement) {
      this.payoutStatement = event.payoutStatement;
      if (this.payoutStatement.isPayoutRequestSent()) {
        if (this.matter.isMatterProvinceNBorNS && this.isDischargeByValueChanged(this.mortgage.dischargePreparedBy)) {
          this.mortgage.dischargePreparedBy = MatterParticipantRoleTypes.MORTGAGEE;
          this.notifyChange();
        }
        if (this.isDischargeByValueChanged(this.mortgage.dischargeRegisteredBy)) {
          this.mortgage.dischargeRegisteredBy = MatterParticipantRoleTypes.MORTGAGEE;
          this.onDischargeRegisteredByChange();
        }
        if (this.matter.isDirty) {
          this.matterService.saveMatterWithoutConfirmationDialog();
        }
      }
    }
  }

  get getMortgageeLabel(): string {
    return provinceBasedFieldLabels.get('provinceBasedMortgagee', this.matter.provinceCode);
  }

  protected readonly provinceBasedFieldLabels = provinceBasedFieldLabels;
}
