import {accountApi} from './shared/account-api';
import {HttpClient} from '../../core';
import {Account} from './shared/account';
import {accountResponseKey} from './shared/account-response-key';
import {Injectable} from '@angular/core';
import {Logger} from '@nsalaun/ng-logger';
import {Observable} from 'rxjs';
import {Utils} from '../../matters/shared/utils';
import {DocumentProfileTemplateFolder} from '../document-profile/document-profile-edit/template-folder/document-profile-template-folder';
import {JournalNote, NotesList} from '../account-notes/account-notes';
import {AccountProvince, ProvinceCode} from './shared/account-province';
import {SESSION_STORAGE_KEYS} from '../../shared/session-storage-keys';
import {MatterTypeInfo} from '../shared/matter-type-info';
import {DateCalculationConfig, DateTypes} from './shared/date-calculation-config';
import {AppConfig} from '../../shared-main/app-configuration';
import {AuthZService} from '../../core/authz/auth-z.service';
import {Permissions} from "../../core/authz/permissions";
import {Roles} from "../../core/authz/roles";

/** Account service defines all api calls for account modules
 */
@Injectable()
export class AccountService {

  private cachedMatterTypes: MatterTypeInfo[] = [];
  private cachedShallowAccount: Account;

  constructor(private http: HttpClient,
              private logger: Logger,
              private appConfig: AppConfig,
              public authZService: AuthZService) {
  }

  // get All the accounts for account list
  getAccounts(query?: string, filterMatterStatus?: string) {
    let url: string;
    let urlQuery = query;
    urlQuery = Utils.escapeSearchText(urlQuery);
    let filter: string = '';
    let matterStatus: string = filterMatterStatus ? filterMatterStatus.toUpperCase() : null;
    let filterFlag: boolean = false;
    if (matterStatus != 'ALL') {
      filterFlag = true;
      if (matterStatus === 'INACTIVE') {
        filter += 'customerAccountStatus_EQ_INACTIVE';
      } else {
        filter += 'customerAccountStatus_EQ_ACTIVE';
      }
    }
    if (urlQuery && urlQuery != '') {
      filterFlag = true;
      filter = Utils.addCommaToFilter(filter);
      filter += 'ANYpublicAccountId_EQ_*' + urlQuery + '*|name_EQ_*' + urlQuery + '*|firmName_EQ_*' + urlQuery + '*';
      //  filter += 'matterRecordNumber_EQ_' + urlQuery + '*';
    }
    url = filterFlag ?
      `${ accountApi.accounts }?sort=createdTimeStamp|DESC&filter=${ filter }&filterType=ALL`
      : `${ accountApi.accounts }?sort=createdTimeStamp|DESC`;

    return this.http.get(url)
    .map((res) => {
      return res[ accountResponseKey.accounts ];
    });
  }

  // get All the accounts which accountTypeCode is LAW_FIRM
  getLawFirmAccounts(query: string) {
    let url: string;
    let urlQuery = query;
    urlQuery = Utils.escapeSearchText(urlQuery);
    let filter: string = '';
    let accountTypeCodeValue = Utils.escapeSearchText('LAW_FIRM');

    filter += 'accountTypeCode_EQ_' + accountTypeCodeValue;
    if (urlQuery && urlQuery != '') {
      filter = Utils.addCommaToFilter(filter);
      filter += 'ANYpublicAccountId_EQ_*' + urlQuery + '*|name_EQ_*' + urlQuery + '*|firmName_EQ_*' + urlQuery + '*';
      //  filter += 'matterRecordNumber_EQ_' + urlQuery + '*';
    }
    url = `${ accountApi.accounts }?sort=createdTimeStamp|DESC&filter=${ filter }&filterType=ALL`;

    return this.http.get(url)
    .map((res) => {
      return res[ accountResponseKey.accounts ];
    });
  }

  // Api to add a new account
  // accepts a account type object and return newly created account with id
  // DPPM23980: skipUnityBilling == true: web api should skip calling UnityBillingService, only create account in DPPM
  addAccount(account, skipUnityBilling = false) {
    return this.http.post(accountApi.accountAdd + (skipUnityBilling ? '?skipUnityBilling=true' : ''), JSON.stringify(account))
    .map((res) => {
      return new Account(res[ 'CUSTOMERACCOUNT' ]);
    });
  }

  updateAccount(account: Account): Observable<any> {
    return this.http.put(`${ accountApi.accounts }/${ account.id }`, JSON.stringify(account))
    .map((res) => {
      return new Account(res[ 'CUSTOMERACCOUNT' ]);
    });
  }

  getAccount(id: string) {
    // use this only for admin users // if regular non-admin user needs to get account info use getShallowAccount()
    return this.http.get(`${ accountApi.accounts }/${ id }`)
    .map((res) => {
      return new Account(res[ 'CUSTOMERACCOUNT' ]);
    });
  }

  getCachedShallowAccount(id: string): Observable<Account> {
    if (!this.cachedShallowAccount) {
      return this.getShallowAccount(id);
    }
    return Observable.of(this.cachedShallowAccount);
  }

  getShallowAccount(id: string): Observable<Account> {

    return this.http.get(`${ accountApi.accounts }/${ id }/shallowAccount`)
    .map((res) => {
      this.cachedShallowAccount = new Account(res[ 'CUSTOMERACCOUNT' ]);
      return this.cachedShallowAccount;
    });
  }

  getPublicCustomerAccount(accountId: string): Observable<Account> {

    return this.http.get(accountApi.publicCustomerAccount(accountId))
    .map((res) => {
      return new Account(res[ 'CUSTOMERACCOUNT' ]);
    });
  }

  checkAccountId(id: string) {
    return this.http.get(`${ accountApi.accounts }?filter=publicAccountId_EQ_${ id }`)
    .map((res) => {
      return res[ 'CustomerAccounts' ].length > 0;
    });
  }

  getAccessProfiles(accountId: string, type?: string) {
    let url: string = accountApi.accessProfiles.replace('{accountId}', accountId);
    if (type) {
      url += `?profileType=${ type }`;
    }
    return this.http.get(url)
    .map((res) => {
      return res[ 'AccessProfiles' ];
    });
  }

  addTemplateFolder(accountId: string, documentProfileTemplateFolder: DocumentProfileTemplateFolder) {
    return this.http.post(accountApi.accountFileFolders(accountId), JSON.stringify(documentProfileTemplateFolder))
    .map((res) => {
      return new DocumentProfileTemplateFolder(res[ 'AccountFileFolder' ]);
    });
  }

  updateTemplateFolder(accountId: string, documentProfileTemplateFolder: DocumentProfileTemplateFolder): Observable<any> {
    return this.http.put(accountApi.accountFileFolders(accountId), JSON.stringify(documentProfileTemplateFolder))
    .map((res) => {
      return new DocumentProfileTemplateFolder(res[ 'AccountFileFolder' ]);
    });
  }

  getTemplateFolders(accountId: string, provinceCode?: ProvinceCode, includeSystemDefaultTemplateFolders?: boolean): Observable<DocumentProfileTemplateFolder[]> {
    let url: string = accountApi.accountFileFolders(accountId);
    if (provinceCode) {
      url += '?provinceCode=' + provinceCode;
    }
    if (includeSystemDefaultTemplateFolders) {
      url += '&includeSystemDefaultTemplateFolders=' + includeSystemDefaultTemplateFolders;
    }
    return this.http.get(url)
    .map((res) => {
      const serverSideData: DocumentProfileTemplateFolder[] = res[ 'AccountFileFolders' ];
      const data: DocumentProfileTemplateFolder[] = [];
      if (Array.isArray(serverSideData)) {
        serverSideData.forEach(item => {
          data.push(new DocumentProfileTemplateFolder(item));
        });
      }
      return data;
    });
  }

  getSystemTemplateFolder(provinceCode: ProvinceCode, processorType: string): Observable<DocumentProfileTemplateFolder> {
    let url: string = accountApi.systemAccountFileFolder + '?provinceCode=' + provinceCode + '&templateProcessorType=' + processorType;
    /* if(provinceCode) {
         url += '?provinceCode='+provinceCode;
     }
     if(processorType){
         url += '&templateProcessorType='+processorType;
     }*/
    return this.http.get(url)
    .map((res) => {
      let systemDocumentProfileTemplateFolder: DocumentProfileTemplateFolder = res[ 'AccountFileFolder' ];
      return new DocumentProfileTemplateFolder(systemDocumentProfileTemplateFolder);
    });
  }

  getJournalNotes(accountId: string): Observable<NotesList> {
    let url: string = `${ accountApi.accounts }` + '/' + accountId + '/notes';

    return this.http.get(url)
    .map((res) => {
      let noteList = res[ 'NotesList' ];
      return new NotesList(noteList);

      //  return res['NotesList'];
    });
  }

  addNewJournalNote(accountId: string, journalNote: JournalNote) {
    return this.http.post(`${ accountApi.accounts }/${ accountId }/notes`, JSON.stringify(journalNote))
    .map((res) => {
      let noteList = res[ 'NotesList' ];
      return new NotesList(noteList);
    });
  }

  getAccountProvince(accountId: string, provinceCode: ProvinceCode): Observable<AccountProvince> {
    let url: string = accountApi.getAccountProvince(accountId, provinceCode);

    return this.http.get(url)
    .map((res) => {
      return new AccountProvince(res[ 'ACCOUNT_PROVINCE' ]);
    });
  }

  saveAccountProvince(accountId: string, accountProvince: AccountProvince): Observable<AccountProvince> {
    let url: string = accountApi.putAccountProvince(accountId, accountProvince.id);
    return this.http.post(url, JSON.stringify(accountProvince))
    .map((res) => {
      let noteList = res[ 'NotesList' ];
      return new AccountProvince(res[ 'ACCOUNT_PROVINCE' ]);
    });
  }

  getDateCalculationConfig(accountId: string, provinceCode: ProvinceCode): Observable<DateCalculationConfig> {
    let url: string = accountApi.getDateCalculationConfig(accountId) + '?provinceCode=' + provinceCode + '&sourceDateType=' + DateTypes.REQUISITION_DATE;

    return this.http.get(url)
    .map((res: Response) => {
      return new DateCalculationConfig(res[ 'DateCalculationConfig' ]);
    });
  }

  saveDateCalculationConfig(accountId: string, dateCalculationConfig: DateCalculationConfig): Observable<DateCalculationConfig> {
    let url: string = accountApi.updateDateCalculationConfig(accountId);
    return this.http.post(url, JSON.stringify(dateCalculationConfig))
    .map((res: Response) => {
      return new DateCalculationConfig(res[ 'DateCalculationConfig' ]);
    });
  }

  loadMatterTypesDetails(accountId: string, account?: Account): Observable<MatterTypeInfo[]> {
    return this.isWillSubscriptionActive(accountId, account).switchMap(bool => {
      return this.http.get(accountApi.matterTypes(accountId))
      .map((res) => {
        let data = res[ 'MATTERTYPES' ];
        this.cachedMatterTypes = [];
        if (data && Array.isArray(data)) {
          this.cachedMatterTypes.push(...data.map(item => {
            return new MatterTypeInfo(item);
          }));
        }
        // this.cachedMatterTypes = this.cachedMatterTypes.filter(item => item.matterTypeCode != MatterTypesValue.DISCHARGE)
        if (!this.isDpAdminWithFullAccess()) {
          this.cachedMatterTypes = this.cachedMatterTypes.filter(item => {
              return item.matterTypeCode !== 'WILL' ||
                (item.matterTypeCode === 'WILL' && this.appConfig.isWillMatterEnabled && bool);
            }
          );
        }
        return this.cachedMatterTypes;
      });
    });
  }

  isDpAdminWithFullAccess(): boolean {
    return this.authZService.hasRole(Roles.ROLE_SYSTEM_ADMINISTRATOR) &&
      this.authZService.hasAccess(Permissions.DOPROCESS_ACCOUNT_MANAGEMENT);
  }


    private isWillSubscriptionActive(accountId: string, account?: Account): Observable<boolean> {
        if (account) {
            return Observable.of(account.applications?.filter(app => app.applicationName == 'UNITY_WILL' && app.active)?.length > 0);
    } else {
      return this.getCachedShallowAccount(accountId).map(acct => {
        return acct.applications?.filter(app => app.applicationName == 'UNITY_WILL' && app.active)?.length > 0;
            });
    }
  }

  getMatterTypesDetails(account?: Account, ignoreCache= false): Observable<MatterTypeInfo[]> {
        let accountId: string =  sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);

    if (account) {
      let providedAccountId = String(account.id);
      if (accountId != providedAccountId) {
        ignoreCache = true;
        accountId = String(account.id);
      }
    }

    if (!ignoreCache && (this.cachedMatterTypes && this.cachedMatterTypes.length > 0)) {
      return Observable.of(this.cachedMatterTypes);
    } else {
      return this.loadMatterTypesDetails(accountId, account);
    }
  }

  clearMatterTypesCache(): void {
    this.cachedMatterTypes = [];
  }

  getBilledMattersForThisMonth(): Observable<number> {
    let accountId: string = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    return this.http.get(accountApi.billedMattersForThisMonth(accountId))
    .map((res) => {
      return res[ 'BilledMattersForThisMonth' ];
    });
  }

  async getBilledMattersForThisMonthText(): Promise<string> {
    const month = (new Date()).toLocaleString('default', {month: 'short'});
    const count = await this.getBilledMattersForThisMonth().toPromise();
    return month + ' Txn: ' + count;
  }
}
