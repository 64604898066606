// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import {Injectable} from '@angular/core';
import {AuDevEnvironment} from './au/dev-environment';
import {AuStagingEnvironment} from './au/staging-environment';
import {AuProdEnvironment} from './au/prod-environment';
import {CaDevEnvironment} from './ca/dev-environment';
import {CaQaEnvironment} from './ca/qa-environment';
import {CaProdEnvironment} from './ca/prod-environment';
import {CaTrainingEnvironment} from './ca/training-environment';

declare let __config: EnvironmentConfig;

export abstract class EnvironmentConfig {
  public appMode: AppMode;
  public tcolUrl: string;
  public keycloak: KeycloakConfig;
  public goCardlessApiUrl: string;
  public goCardlessAuthRedirectUri: string;
  public goCardlessClientId: string;
  public ltsaWebBaseUrl: string;
  public landingPageUrl: string;
}

export const environment: { production: boolean; } = {
  production: false
};

export enum AppMode {
  CA = 'CA',
  AU = 'AU',
}

export class KeycloakConfig {
  public clientId: string;
  public domain: string;
  public realm: string;
  public logoutRedirectUri: string;
}

@Injectable({
  providedIn: 'root'
})
export class Environment {
  public config: EnvironmentConfig;

  constructor() {
    const globalConfig = typeof __config !== 'undefined' ? __config : {} as EnvironmentConfig;
    let environments = new Map<string, EnvironmentConfig>([
      [ 'AU_DEV', new AuDevEnvironment() ],
      [ 'AU_STAGING', new AuStagingEnvironment() ],
      [ 'AU_PROD', new AuProdEnvironment() ],
      [ 'Dev', new CaDevEnvironment() ],
      [ 'QA', new CaQaEnvironment() ],
      [ 'Prod', new CaProdEnvironment() ],
      [ 'Training', new CaTrainingEnvironment() ]
    ]);
    let currentEnvironment = environments.get(this.environment);
    this.config = {
      ...currentEnvironment,
      ...globalConfig,
      keycloak: {
        ...currentEnvironment?.keycloak,
        ...globalConfig?.keycloak
      }
    };
  }

  get environment(): string {
    return window['env']?.ENVIRONMENT ?? 'Dev';
  }

  get isProd(): boolean {
    return this.environment.toLowerCase() === 'prod';
  }

  get isDev(): boolean {
    return this.environment.toLowerCase() === 'dev';
  }

  get isQA(): boolean {
    return this.environment.toLowerCase() === 'qa';
  }

  get isTraining(): boolean {
    return this.environment.toLowerCase() === 'training';
  }

}
