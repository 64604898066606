import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class AppLoaderService {

  private moduleCounter: number = 0;
  isLoaderInitialize: boolean = true;
  isAllModuleLoaded: boolean = true;
  currentStateRoute: string;
  isAotOn: boolean = false;
  private loaderUpdated?: Subject<void>;

  get loadedModuleCount(): number {
    return this.moduleCounter;
  }

  set loadedModuleCount(count: number) {
    this.moduleCounter = count;

    this.loaderUpdated?.next();
  }

  setLoaderUpdatedSubject(subject: Subject<void>) {
    this.loaderUpdated = subject;
  }

  get percentageLoading(): number {
    // return this.moduleCounter;
    return 100;
  }

}
