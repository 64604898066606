import {ApplicationError, FieldError} from './application-error';
import {Injectable} from '@angular/core';
import {HttpClient as Http, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import '../common/rxjs-operators';
import {SESSION_STORAGE_KEYS} from '../shared/session-storage-keys';
import {Router} from '@angular/router';
import {LockScreenService} from './lock-screen.service';
import {DPError} from '../shared/error-handling/dp-error';
import {ErrorService} from '../shared/error-handling/error-service';
import {Subject} from 'rxjs/Subject';
import {userAccountProfilesApi} from '../admin/shared/user-account-profiles-api';
import {accountApi} from '../admin/accounts/shared/account-api';
import * as pako from 'pako';
import {UUID} from 'angular2-uuid';
import {MatDialog} from '@angular/material/dialog';
import {FeatureService} from '../feature-service';
import {OAuthService} from 'angular-oauth2-oidc';

// this service works like an interceptor all the api call will pass through this service.

export interface TokenRefreshAction {
  oldToken: string; // Old expired token
  newTokenSuccessAction: Function; // The action to be performed after acquiring the new token
  fullLoginFlag: boolean; // Full Login flag is indicating that the user session has expired and a full login is required
}

@Injectable()
export class HttpClient {
  validTokenRequested: boolean = false;
  linkedMatterBackEndCallsCounter: number = 0;

  /** This is invoked every time the http client needs to ask the user to refresh their password */
  //passwordRefreshRequester = new Subject<TokenRefreshAction>();
  passwordRefreshRequester = null;
  tokenSubjectsArray = [];

  // Triggered when the user server session is renewed
  // It will be injected as needed by other components to specify behaviour.
  postReloginHandler: Function = async () => {};

  //This cache should be enabled only in rare scenarios like mass update, it caches the response of get calls. Useful in mass update where we are making
  // so many duplicate http calls.
  private massUpdateModeEnabled: boolean;
  //Cache for getApi responses by URL
  private cachedResponses: any = {};

  pollingUrlPatterns = [
    '/v2/matters/\\d*/documents',
    '/v2/matters/\\d*/documents/metadata',
    '/v2/accounts/\\d*/connect/opportunities/count'
  ];

  constructor(
    private http: Http,
    private router: Router,
    private lockScreenService: LockScreenService,
    private errorService: ErrorService,
    public dialog: MatDialog,
    private featureService: FeatureService,
    private oauthService: OAuthService
  ) {}

  getRequestOptions(compressIfNeeded?: boolean): Observable<any> {
    //Instantiate the headers every time, rather than keeping a common value for everybody. Some requests can change headers options
    //and we don't want subsequent requests to inadvertently inherit settings they're not aware of
    const requestOptions: any = {};
    requestOptions.headers = new HttpHeaders();
    requestOptions.headers = requestOptions.headers.append('Accept', 'application/json');
    if (compressIfNeeded) {
      requestOptions.headers = requestOptions.headers.append('Content-Type', 'application/octet-stream');
      requestOptions.headers = requestOptions.headers.append('Content-Encoding', 'gzip');
    } else {
      requestOptions.headers = requestOptions.headers.append('Content-Type', 'application/json');
    }
    if (localStorage.getItem('selected-account-id') !== null) {
      requestOptions.headers = requestOptions.headers.append(
        'Selected-Customer-Account',
        localStorage.getItem('selected-account-id')
      );
    }
    if (localStorage.getItem('public-account-id') !== null) {
      requestOptions.headers = requestOptions.headers.append(
        'Selected-Public-Account-Id',
        localStorage.getItem('public-account-id')
      );
    }
    requestOptions.headers = requestOptions.headers.append('UI-Request-UUID', UUID.UUID());
    requestOptions.observe = 'response';
    let getTokenSubject = new Subject<any>();

    this.getToken().subscribe((token: string) => {
      requestOptions.headers = requestOptions.headers.append('Authorization', token);
      getTokenSubject.next(requestOptions);
    });
    return getTokenSubject;
  }

  getRequestPDFOptions(): Observable<any> {
    const requestOptions: any = {};
    requestOptions.headers = new HttpHeaders();
    requestOptions.headers = requestOptions.headers.append('Accept', 'application/octet-stream');
    requestOptions.headers = requestOptions.headers.append('Content-Type', 'application/json');
    requestOptions.observe = 'response';
    let getTokenSubject = new Subject<any>();
    this.getToken().subscribe((token: string) => {
      requestOptions.headers = requestOptions.headers.append('Authorization', token);
      requestOptions.responseType = 'blob';
      getTokenSubject.next(requestOptions);
    });
    return getTokenSubject;
  }

  getUploadRequestOptions(): Observable<any> {
    const requestOptions: any = {};
    requestOptions.headers = new HttpHeaders();
    requestOptions.headers = requestOptions.headers.append('Accept', 'application/json');
    requestOptions.observe = 'response';
    let getTokenSubject = new Subject<any>();
    this.getToken().subscribe((token: string) => {
      requestOptions.headers = requestOptions.headers.append('Authorization', token);
      getTokenSubject.next(requestOptions);
    });
    return getTokenSubject;
  }

  public getToken = (): Observable<string> => {
    let tokenSubject = new Subject<string>();
    let tokens: any = sessionStorage.getItem(SESSION_STORAGE_KEYS.tokens);
    if (tokens && tokens.length) {
      tokens = JSON.parse(tokens);
      let activeTokens = this.removeExpiredTokens(tokens);
      if (activeTokens && activeTokens.length) {
        sessionStorage.setItem(SESSION_STORAGE_KEYS.tokens, JSON.stringify(activeTokens));
        //Put "tokenSubject.next" at the end of the stack so it will get invoked after "return tokenSubject"
        setTimeout(() => {
          tokenSubject.next(activeTokens[activeTokens.length - 1]);
        }, 0);
      } else {
        //Handle forkJoin API calls - tokenSubjectsArray contains all the forkJoin getToken requests
        this.tokenSubjectsArray.push(tokenSubject);
        if (!this.validTokenRequested) {
          this.getValidToken(tokens).subscribe((token) => {
            this.tokenSubjectsArray.forEach((obs) => {
              obs.next(token);
            });
            this.tokenSubjectsArray = [];
          });
        }
      }
    } else {
      //Put "tokenSubject.next" at the end of the stack so it will get invoked after "return tokenSubject"
      setTimeout(() => {
        tokenSubject.next('');
      }, 0);
    }
    return tokenSubject;
  };

  // This method is used to reauthenticate the user after his server session expired due to being idle for long time.
  // Full login is required
  handleSessionExpired(): Observable<string> {
    let tokenSubject = new Subject<string>();
    if (!this.validTokenRequested) {
      this.validTokenRequested = true;
      sessionStorage.removeItem(SESSION_STORAGE_KEYS.tokens);
      this.retrieveNewToken(null, true).subscribe(
        (token) => {
          this.validTokenRequested = false;
          tokenSubject.next(token);
        },
        (error) => {
          this.sessionExpired();
        }
      );
    }

    return tokenSubject;
  }

  getValidToken = (tokens: string[]): Observable<string> => {
    this.validTokenRequested = true;
    let validTokenSubject = new Subject<string>();
    this.getServerTimeRemaining(tokens[tokens.length - 1]).subscribe(
      (res) => {
        console.log(res);
        if (res['SUCCESS'] > 2) {
          //Server session reaminaing time is more than 2 minutes
          this.retrieveNewToken(tokens[tokens.length - 1]).subscribe(
            (token) => {
              this.validTokenRequested = false;
              validTokenSubject.next(token);
            },
            (error) => {
              this.sessionExpired();
            }
          );
        } else {
          //Server session has expired so we need to relogin the user again
          this.validTokenRequested = false;
          this.handleSessionExpired().subscribe((token) => {
            this.validTokenRequested = false;
            validTokenSubject.next(token);
          });
        }
      },
      (error) => {
        this.sessionExpired();
      }
    );
    return validTokenSubject;
  };

  private getServerTimeRemaining = (token: string): Observable<any> => {
    let options = this.getCustomRequestOptions(token);
    return this.http
      .get(this.normalizeUrl(`${userAccountProfilesApi.getTimeRemaining}`), options)
      .map(this.extractData)
      .catch((e) => {
        return this.handleError(e);
      })
      .finally(() => {});
  };

  private retrieveNewToken(expiredToken: string, fullLoginFlag: boolean = false): Observable<string> {
    let confirmPasswordSubject = new Subject<string>();
    //Using a function pointer here for slightly simpler logic, we need to pass the action to execute once the new password is retrieved
    if (this.passwordRefreshRequester) {
      this.passwordRefreshRequester.next({
        oldToken: expiredToken,
        fullLoginFlag: fullLoginFlag,
        newTokenSuccessAction: (newToken) => {
          confirmPasswordSubject.next(newToken);
        }
      } as TokenRefreshAction);
    } else {
      this.sessionExpired();
    }

    return confirmPasswordSubject;
  }

  public relogin(url: string, data, token): Observable<any> {
    let returnSubject: Subject<any> = new Subject<any>();
    let options = this.getCustomRequestOptions(token);
    this.http
      .post(this.normalizeUrl(url), data, options)
      .catch((e) => {
        returnSubject.error(this.formatError(e));
        return this.handleError(e);
      })
      .map(this.extractConfirmPasswordData)
      .subscribe((data) => {
        returnSubject.next(data);
        returnSubject.complete();
      });

    return returnSubject;
  }

  private extractConfirmPasswordData = (res: any) => {
    let token: string = res.headers.get('authorization');
    this.checkForNewToken(res);
    this.checkForSessionId(res);
    return token;
  };

  private removeExpiredTokens(tokens: string[]): string[] {
    return tokens.filter((token) => this.isActiveToken(token));
  }

  private isActiveToken(token: string): boolean {
    return this.parseJwt(token).exp > Date.now() / 1000;
  }

  private parseJwt(token) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }

  isPollingUrlPattern(url: string): boolean {
    for (let i = 0; i < this.pollingUrlPatterns.length; i++) {
      if (url.match(RegExp(this.pollingUrlPatterns[i]))) {
        return true;
      }
    }
    return false;
  }

  get(url: string, lockScreen?: boolean, responsePreChecker = (resp: any) => resp): Observable<any> {
    if (this.validTokenRequested && this.isPollingUrlPattern(url)) {
      return Observable.of(null);
    }

    if (this.massUpdateModeEnabled && this.cachedResponses[url]) {
      //Adding delay of 10 ms to stimulate asynchronus behaviour as happens in normal backend calls
      return Observable.of(this.cachedResponses[url]).delay(10);
    }

    let returnSubject: Subject<any> = new Subject<any>();

    this.getRequestOptions().subscribe((requestOptions: any) => {
      if (lockScreen) {
        this.lockScreenService.lockForUpdate = true;
      }
      this.linkedMatterBackEndCallsCounter++;

      this.http
        .get(this.encodePipe(this.normalizeUrl(url)), requestOptions)
        .catch((e) => {
          returnSubject.error(this.formatError(e));
          return this.handleError(e);
        })
        .finally(() => {
          if (lockScreen) {
            this.lockScreenService.lockForUpdate = false;
          }
          this.linkedMatterBackEndCallsCounter--;
        })
        .map(responsePreChecker) // insert pre-processing of the response, default is simply return the response object
        .map(this.extractData)
        .subscribe((data) => {
          if (this.massUpdateModeEnabled) {
            this.cachedResponses[url] = data;
          }
          returnSubject.next(data);
          returnSubject.complete();
        });
    });
    return returnSubject;
  }

  // getWithRequestOption(url: string, requestOption: RequestOptions): Observable<any> {
  //     return this.http.get(this.encodePipe(this.normalizeUrl(url)), requestOption).map(this.extractData).catch(e => {return this.handleError(e)});
  // }

  //As pipe character is not accepted by some versions of tomcat therefore encoding it if exists in the get URL
  encodePipe(url: string): string {
    return url.split('|').join('%7C');
  }

  /**
   * "+" sign in URL is treated as Space on server side, use this function to ensure it is still treated as "+" character.
   */
  encodePlusSign(url: string): string {
    return url.split('+').join('%2B');
  }

  //As pipe character is not accepted by some versions of tomcat therefore encoding it if exists in the get URL
  normalizeUrl(url: string): string {
    return url.replace('//', '/');
  }

  postWithPromise(url: string, data): Promise<any> {
    //return this.http.post(this.normalizeUrl(url), data, this.getRequestOptions()).toPromise().then(this.extractData).catch(e => {return
    // this.handleError(e)});
    let returnSubject: Subject<any> = new Subject<any>();
    this.getRequestOptions().subscribe((requestOptions: any) => {
      this.http
        .post(this.normalizeUrl(url), data, requestOptions)
        .catch((e) => {
          returnSubject.error(this.formatError(e));
          return this.handleError(e);
        })
        .map(this.extractData)
        .subscribe((data) => {
          returnSubject.next(data);
          returnSubject.complete();
        });
    });
    return returnSubject.asObservable().toPromise();
  }

  post(url: string, data, excludeLockScreen?: boolean, skipFieldsDataError: boolean = false): Observable<any> {
    let returnSubject: Subject<any> = new Subject<any>();
    this.getRequestOptions().subscribe((requestOptions: any) => {
      if (!excludeLockScreen) {
        this.lockScreenService.lockForUpdate = true;
      }
      this.http
        .post(this.normalizeUrl(url), data, requestOptions)
        .catch((e) => {
          returnSubject.error(this.formatError(e));
          return this.handleError(e, skipFieldsDataError);
        })
        .finally(() => {
          if (!excludeLockScreen) {
            this.lockScreenService.lockForUpdate = false;
          }
        })
        .map(this.extractData)
        .subscribe((data) => {
          returnSubject.next(data);
          returnSubject.complete();
        });
    });

    return returnSubject;
  }

  getPdf(url: string): Observable<any> {
    let returnSubject: Subject<any> = new Subject<any>();
    this.getRequestPDFOptions().subscribe((requestOptions: any) => {
      this.http
        .get(this.encodePipe(this.normalizeUrl(url)), requestOptions)
        .catch((e) => {
          returnSubject.error(this.formatError(e));
          return this.handleError(e);
        })
        .map(this.extractData)
        .subscribe((data) => {
          returnSubject.next(data);
          returnSubject.complete();
        });
    });
    return returnSubject;
  }

  formatError(e): any {
    if (e.error) {
      let errorBody = e.error;
      if (errorBody['Error']) {
        let applicationError = new ApplicationError(errorBody['Error']);
        if (e.statusText) {
          applicationError.statusText = e.statusText;
        }
        return applicationError;
      } else {
        return errorBody;
      }
    } else {
      return e;
    }
  }

  postPdf(url: string, data): Observable<any> {
    return this.downloadThroughPost(url, data);
  }

  public downloadThroughPost(url: string, data) {
    console.log(url);
    let returnSubject: Subject<any> = new Subject<any>();
    this.getRequestPDFOptions().subscribe((requestOptions: any) => {
      this.lockScreenService.lockForUpdate = true;
      this.http
        .post(this.normalizeUrl(url), data, requestOptions)
        .catch((e) => {
          returnSubject.error(this.formatError(e));
          return this.handleError(e);
        })
        .finally(() => {
          this.lockScreenService.lockForUpdate = false;
        })
        .subscribe((data) => {
          returnSubject.next(data);
          returnSubject.complete();
        });
    });
    return returnSubject;
  }

  postWithNoResponse(url: string, data): Observable<any> {
    let returnSubject: Subject<any> = new Subject<any>();
    this.getRequestOptions().subscribe((requestOptions: any) => {
      this.lockScreenService.lockForUpdate = true;
      this.http
        .post(this.normalizeUrl(url), data, requestOptions)
        .catch((e) => {
          returnSubject.error(this.formatError(e));
          return this.handleError(e);
        })
        .finally(() => {
          this.lockScreenService.lockForUpdate = false;
        })
        .subscribe((data) => {
          returnSubject.next(data);
          returnSubject.complete();
        });
    });
    return returnSubject;
  }

  postWithNoCatch(url: string, data): Observable<any> {
    return this.postWithNoResponse(this.normalizeUrl(url), data)
      .map(this.extractData)
      .catch((e) => {
        return this.handleError(e);
      })

      .finally(() => {});
  }

  put(
    url: string,
    data: any,
    excludeLockScreen?: boolean,
    compressIfNeeded?: boolean,
    skipFieldsDataError: boolean = false
  ): Observable<any> {
    let returnSubject: Subject<any> = new Subject<any>();
    this.getRequestOptions(compressIfNeeded).subscribe((requestOptions: any) => {
      if (!excludeLockScreen) {
        this.lockScreenService.lockForUpdate = true;
      }
      if (compressIfNeeded) {
        let jsonPayload: string = JSON.stringify(data);
        if (jsonPayload.length > 1024) {
          let gzippedJson = pako.gzip(jsonPayload);
          data = gzippedJson.buffer;
        }
      }

      this.http
        .put(this.normalizeUrl(url), data, requestOptions)
        .catch((e) => {
          returnSubject.error(this.formatError(e));
          return this.handleError(e, skipFieldsDataError);
        })
        .finally(() => {
          if (!excludeLockScreen) {
            this.lockScreenService.lockForUpdate = false;
          }
        })
        .map(this.extractData)
        .subscribe((data) => {
          returnSubject.next(data);
          returnSubject.complete();
        });
    });
    return returnSubject;
  }

  /**
   * @param {string} url
   * @param data
   * @return {Observable<any>}
   */
  uploadFiles(url: string, data: any): Observable<any> {
    let returnSubject: Subject<any> = new Subject<any>();
    this.getUploadRequestOptions().subscribe((requestOptions: any) => {
      this.http
        .post(this.normalizeUrl(url), data, requestOptions)
        .catch((e) => {
          returnSubject.error(this.formatError(e));
          return this.handleError(e);
        })
        .map(this.extractData)
        .subscribe((data) => {
          returnSubject.next(data);
          returnSubject.complete();
        });
    });
    return returnSubject;
  }

  delete(url: string): Observable<any> {
    let returnSubject: Subject<any> = new Subject<any>();
    this.getRequestOptions().subscribe((requestOptions: any) => {
      this.lockScreenService.lockForUpdate = true;
      this.http
        .delete(this.normalizeUrl(url), requestOptions)
        .catch((e) => {
          returnSubject.error(this.formatError(e));
          return this.handleError(e);
        })
        .finally(() => {
          this.lockScreenService.lockForUpdate = false;
        })
        .subscribe((data) => {
          returnSubject.next(data);
          returnSubject.complete();
        });
    });
    return returnSubject;
  }

  // Returns the data when http status code is 200
  private extractData = (res: any) => {
    this.checkForNewToken(res);
    this.checkForSessionId(res);
    let body = res.body;
    return body || {};
  };

  private checkForNewToken(res: any): void {
    if (res.headers) {
      let token: string = res.headers.get('authorization');
      if (token) {
        let tokens: any = sessionStorage.getItem(SESSION_STORAGE_KEYS.tokens);
        if (tokens && tokens.length) {
          tokens = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.tokens));
          if (token != tokens[tokens.length - 1]) {
            //Prevent adding a repeated last token
            tokens.push(token);
          }
        } else {
          tokens = [token];
        }
        sessionStorage.setItem(SESSION_STORAGE_KEYS.tokens, JSON.stringify(tokens));
      }
    }
  }

  private checkForSessionId(res: any): void {
    let sessionId: any;

    if (this.featureService.isSSOEnabled) {
      if (
        sessionStorage.getItem(SESSION_STORAGE_KEYS.sessionState) !==
        sessionStorage.getItem(SESSION_STORAGE_KEYS.sessionId)
      ) {
        sessionId = sessionStorage.getItem(SESSION_STORAGE_KEYS.sessionState);
        this.setSessionId(sessionId);
      }
    } else {
      if (res.headers) {
        sessionId = res.headers.get(SESSION_STORAGE_KEYS.sessionId);
        this.setSessionId(sessionId);
      }
    }
  }

  private setSessionId(sessionId: any) {
    if (sessionId) {
      sessionStorage.setItem(SESSION_STORAGE_KEYS.sessionId, sessionId);
    }
  }

  public logout(): void {
    if (this.featureService.isSSOEnabled) {
      this.ssoLogout()
        .then(() => {
          console.log('SSO logout finished');
          this.oauthService.logOut();
          this.cleanUpStorageAndCloseAllDialogs(); // After the logout from Oauth is finished it will jump to another domain and the rest of sessions or local storages are all gone
        })
        .catch((err) => {
          console.error('SSO logout failed', err);
        });
    } else {
      let options = this.getCustomRequestOptions(this.getLastToken());
      this.http.post(this.normalizeUrl(accountApi.logout), '', options).subscribe(
        () => {
          this.cleanUpStorageAndCloseAllDialogs();
        },
        (err) => {
          console.error('Logout failed', err);
        }
      );
    }
  }

  public async ssoLogout(): Promise<void> {
    try {
      await this.post(this.normalizeUrl(accountApi.ssoLogout), {}).toPromise();
    } catch (err) {
      console.error('SSO logout failed', err);
      throw err; // Re-throw the error to ensure it gets caught in the calling method
    }
  }

  private cleanUpStorageAndCloseAllDialogs() {
    this.validTokenRequested = false;
    this.checkForModels();
    sessionStorage.clear();
    localStorage.clear();
  }

  public getLastToken(): string {
    let tokens: any = sessionStorage.getItem(SESSION_STORAGE_KEYS.tokens);
    if (tokens && tokens.length) {
      tokens = JSON.parse(tokens);
      return tokens && tokens.length ? tokens[tokens.length - 1] : null;
    }
  }

  private getCustomRequestOptions(token: string): any {
    const requestOptions: any = {};
    requestOptions.headers = new HttpHeaders();
    requestOptions.headers = requestOptions.headers.append('Accept', 'application/json');
    requestOptions.headers = requestOptions.headers.append('Content-Type', 'application/json');
    requestOptions.headers = requestOptions.headers.append('Authorization', token);
    requestOptions.observe = 'response';
    return requestOptions;
  }

  public sessionExpired(): void {
    this.logout();
    this.router.navigateByUrl('login/home/2');
  }

  public accountLockedOut(): void {
    this.logout();
    this.router.navigateByUrl('login/home/3');
  }

  public handleError(e: any, skipFieldsDataError: boolean = false): Observable<any> {
    if (e.status === 401) {
      if (e.error == 'ANOTHER_SESSION') {
        this.logout();
        this.router.navigateByUrl('login/home/1');
      } else if (e.error == 'SESSION_EXPIRED') {
        this.handleSessionExpired();
      } else if (!e.error) {
        this.sessionExpired();
      }
      return Observable.throw('Unauthorized');
    }

    if (e.status === 400 && e.error) {
      const bodyError = e.error['Error'];
      if (bodyError) {
        let errorCode = bodyError.errorCode;
        const fieldErrors: any[] = bodyError.fieldErrors;
        if (errorCode == 'app.accountLockedOut') {
          this.accountLockedOut();
        }
        if (
          errorCode === 'app.invalidFieldsDataError' &&
          Array.isArray(fieldErrors) &&
          fieldErrors.length > 0 &&
          !skipFieldsDataError
        ) {
          fieldErrors.forEach((fieldError: FieldError) => {
            this.errorService.addDpSaveError(
              DPError.createCustomDPError(
                errorCode + '.' + fieldError.field + '.' + fieldError.message,
                this.errorService.getErrorMessageForSaveMatter(fieldError),
                null,
                'ERROR'
              )
            );
          });
        }
      }
    }

    if (e.status === 409 && e.error == 'app.cannotSaveDataWithCreditCardNumbers') {
      let appErr: ApplicationError = new ApplicationError();
      appErr.errorCode = e.error;
      appErr.message = 'Data with credit card numbers is not accepted.';
      this.errorService.addDpSaveError(DPError.createCustomDPError('', appErr.errorMessage, null, 'ERROR'));

      return Observable.throw(appErr);
    }

    try {
      if (e.error) {
        let applicationError: ApplicationError = new ApplicationError(e.error['Error']);

        // In a real world app, we might use a remote logging infrastructure
        // We'd also dig deeper into the error to get a better message
        //Legacy summary message for the error
        applicationError.summary = e.message ? e.message : e.status ? `${e.status} - ${e.statusText}` : 'Server error';
        return Observable.throw(applicationError);
      }
    } catch (ignore) {
      console.log('Http response error cannot be converted to Json.');
    }

    //If error is not already handled above then in the end it is re-thrown so it can be handled by global exception handler
    throw e;
  }

  checkForModels(): void {
    let closeBtns = document.body.querySelectorAll('.mat-modal-dialog button.close.pull-right');
    for (let i = 0; i < closeBtns.length; i++) {
      console.log('model found #' + i);
      if (closeBtns[i].id != 'bypassClickBtn') {
        //To prevent confirm password modal close btn to keep calling itself
        closeBtns[i].dispatchEvent(new CustomEvent('click'));
      }
    }
    let doNotSaveBtns = document.body.querySelectorAll('#btnDontSave');
    for (let i = 0; i < doNotSaveBtns.length; i++) {
      doNotSaveBtns[i].dispatchEvent(new CustomEvent('click'));
    }
  }

  enableMassUpdateMode(): void {
    this.massUpdateModeEnabled = true;
  }

  disableMassUpdateMode(): void {
    this.massUpdateModeEnabled = false;
    this.cachedResponses = {};
  }

  isMassUpdateModeEnabled(): boolean {
    return this.massUpdateModeEnabled;
  }
}
