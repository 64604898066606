import {Component, Inject, ViewChild} from '@angular/core';
import moment from 'moment';
import {SelectItem} from 'primeng/api';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {ModalErrorComponent} from '../../../shared/error-handling/modal-error/modal-error.component';
import {
  OtherFixedCreditTypes,
  OtherFixedDebitTypes,
  SoAdjOtherFixed,
  SoAdjOtherFixedItem
} from '../model/so-adj-other-fixed';
import {ErrorService} from '../../../shared/error-handling/error-service';
import {StatementOfAdjustmentHeading} from '../model/statement-adjustment-heading';
import {booleanYesNoDropDowns} from '../../../shared-main/constants';
import {AppConfig} from '../../../shared-main/app-configuration';
import Utils from '../../../shared-main/utils';
import {ModalResult} from '../../../shared-main/enums';
import {amountAdditionalConsiderationToVendorDropDownOptions} from '../../../shared-main/province-based-dropdowns';
import {Jurisdiction, Matter, MatterProperty} from '../../shared';
import {Project} from '../../../projects/shared/project';
import {OtherFixedModalConstants, StatementAdjustmentKey} from '../statement-adjustment-constants';
import {ConsiderationTaxes} from '../../consideration-ltt/consideration-taxes';
import {StatementAdjustment} from '../statement-adjustment';
import {UnitLevelPlanModalComponent} from '../../property-teranet/unit-level-plan/unit-level-plan.component';
import {AdjustmentBaseModalComponent} from '../modals/adjustment-base.modal.component';
import {StatementAdjustmentDisplayBuilder} from '../builders/statement-adjustment-display-builder';
import {AdjustmentTemplate} from '../model/adjustment-template';
import {DpBooleanValueTypes} from '../../shared/dp-boolean';
import {TabsService} from '../../../core';
import {MatterService} from '../../matter.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

declare var jQuery: any;

export class SoaOtherFixedModalContext {
  closingDate: string;
  otherFixedSoa: SoAdjOtherFixed;
  statementAdjustmentHeading: StatementOfAdjustmentHeading;
  defaultHstRate: number;
  defaultPstRate: number;
  defaultGstRate: number;
  purchaseIsOfANewHomeFromABuilder: boolean;
  salePriceAdjustmentFactorsInHstRebate: boolean;
  provinceCode: string;
  isOtherFixedAdjAvailable: boolean;
  aTarionWarrantyWithConsiderationAlreadyExists: boolean;
  aHCRAWithConsiderationAlreadyExists: boolean;
  additionalConsiderationsInclHst: number;
  matter: Matter;
  project: Project;
  statementAdjustmentKey: string;
  soaConsiderationTaxes: ConsiderationTaxes;
  statementAdjustmentDisplayUtil: StatementAdjustmentDisplayBuilder;
  sourceFromProject: boolean;
}

@Component({
  selector: 'dp-other-fixed-soa-modal-content',
  templateUrl: 'other-fixed.modal.component.html',
  providers: [ErrorService],
  styleUrls: ['./other-fixed.modal.component.scss']
})
export class SoaOtherFixedModal extends AdjustmentBaseModalComponent<SoAdjOtherFixed, SoaOtherFixedModalContext> {
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  context: SoaOtherFixedModalContext;
  creditTargetTypes: SelectItem[];
  debitTargetTypes: SelectItem[];
  itemHeadingTypes: SelectItem[];
  taxTypes: SelectItem[];
  yesNoOptions: SelectItem[] = [
    {label: 'Yes', value: true},
    {label: 'No', value: false}
  ];
  noYesOptions: SelectItem[] = booleanYesNoDropDowns.NoYes_DefaultNo;
  maxItems: number = 2;
  minNumberOfItems: number = 2;
  closingDateLabel: string = '';
  deleteIndex: number = 0;
  amountAdditionalConsiderationToVendorOptions: SelectItem[] = [];
  purchaserPortionBasedOnPercentageInterest: string = '0.00';
  selectedJurisdiction: Jurisdiction;
  newAdj: boolean = false;

  constructor(
    public dialog: MatDialogRef<SoaOtherFixedModal>,
    public dialogService: DialogService,
    public matterService: MatterService,
    public tabsService: TabsService,
    public appConfig: AppConfig,
    @Inject(MAT_DIALOG_DATA) context?: SoaOtherFixedModalContext
  ) {
    super(dialog, dialogService, context);
    this.statementAdjustmentDisplayUtil = this.context.statementAdjustmentDisplayUtil;
  }

  ngOnInit() {
    this.taxTypes = Utils.loadTaxSelectItem(
      this.context.soaConsiderationTaxes.rateTypeLabel,
      this.context.defaultPstRate
    );

    this.amountAdditionalConsiderationToVendorOptions =
      amountAdditionalConsiderationToVendorDropDownOptions.getDropDownOptions(
        this.context.soaConsiderationTaxes.rateTypeLabel,
        this.context.provinceCode
      );

    if (this.isOtherProratedOnPercentageInterest()) {
      this.amountAdditionalConsiderationToVendorOptions = this.amountAdditionalConsiderationToVendorOptions.filter(
        (itm) => itm.value !== 'YES_NOT_ELIGIBLE_FOR_REBATE_PLUS_TAX'
      );
    }

    this.populateTargetTypes();

    if (!this.context.otherFixedSoa) {
      this.loadDefaultOtherFixedSoa();
      this.newAdj = true;
    } else {
      this.loadFromOtherFixedSoa(this.context.otherFixedSoa);
    }

    this.statementAdjustmentKey = this.context.statementAdjustmentKey;
    this.populateAmountHeadingTypes();

    this.maxItems = this.appConfig.maxNumberOfAmountItemsOnAdjustmentsOtherFixed;
    this.closingDateLabel = Utils.formatDateString(this.context.closingDate, true, '');

    this.checkHSTWarning();

    if (this.isOtherProratedOnPercentageInterest()) {
      const percentage = this.context.matter.isMatterProvinceAB
        ? Number(this.context.matter.matterPropertyWithCondo.percentageShareOfTotalRealtyTaxes)
        : Number(this.context.matter.matterPropertyWithCondo.condominiumTotalSharePercentage);
      this.purchaserPortionBasedOnPercentageInterest = Utils.formatWithDecimals(percentage, 9);
      this.selectedJurisdiction = this.context.matter.matterPropertyWithCondo.jurisdiction;
    } else {
      this.purchaserPortionBasedOnPercentageInterest =
        this.context.matter.matterPropertyWithCondo.percentageShareOfTotalRealtyTaxes;
    }
  }

  loadFromOtherFixedSoa(otherFixedSoa: SoAdjOtherFixed): void {
    this.localAdjustment = new SoAdjOtherFixed(otherFixedSoa);
    if (this.localAdjustment.items.length < this.minNumberOfItems) {
      this.fillInEmptyItems(this.minNumberOfItems - this.localAdjustment.items.length);
    }
  }

  loadDefaultOtherFixedSoa(): void {
    this.localAdjustment = new SoAdjOtherFixed();
    if (this.context.matter.isPurchaseBC) {
      this.localAdjustment.debitType = OtherFixedDebitTypes.BLANK;
      this.localAdjustment.creditType = OtherFixedCreditTypes.BLANK;
    } else {
      this.localAdjustment.creditType = OtherFixedCreditTypes.VENDOR;
    }
    this.localAdjustment.amountHeading = 'Amount';
    this.localAdjustment.adjustmentHeading = '';
    this.localAdjustment.amount = 0;
    this.localAdjustment.taxType = this.context.soaConsiderationTaxes.provinceDefaultTaxRate;
    this.localAdjustment.applyTax = false;
    this.localAdjustment.applyPst = false;
    this.localAdjustment.hstRate = this.context.defaultHstRate;
    this.localAdjustment.gstRate = this.context.defaultGstRate;
    this.localAdjustment.pstRate = this.context.defaultPstRate;
    this.localAdjustment.amountAdditionalConsiderationToVendor = 'NO';
    this.localAdjustment.adjustmentPaidOnInterimClosing = 'N_y';
    // always have at least two empty ones
    this.fillInEmptyItems(this.minNumberOfItems);
  }

  get adjustmentType(): string {
    if (this.isOtherFixedPayableOnOccupancy()) {
      return OtherFixedModalConstants.OTHER_FIXED_PAYABLE_ON_OCCUPANCY;
    }
    if (this.isOtherProratedOnPercentageInterest()) {
      return OtherFixedModalConstants.OTHER_PRORATED_ON_PERCENTAGE_INTEREST;
    }
    return OtherFixedModalConstants.OTHER_FIXED_AMOUNT;
  }

  isOtherFixed(): boolean {
    return this.context.statementAdjustmentKey === StatementAdjustmentKey.OTHER_FIXED;
  }

  isOtherFixedPayableOnOccupancy(): boolean {
    return this.context.statementAdjustmentKey === StatementAdjustmentKey.OTHER_FIXED_PAYABLE_ON_OCCUPANCY;
  }

  populateTargetTypes() {
    this.populateCreditTargetTypes();
    this.populateDebitTargetTypes();
  }

  private populateCreditTargetTypes(): void {
    if (this.context.matter.isPurchaseBC) {
      this.creditTargetTypes = [
        {label: OtherFixedModalConstants.TARGET_TYPE_BLANK, value: OtherFixedCreditTypes.BLANK},
        {label: this.vendorLabel, value: OtherFixedCreditTypes.VENDOR},
        {label: this.purchaserLabel, value: OtherFixedCreditTypes.PURCHASER},
        {
          label: OtherFixedModalConstants.TARGET_TYPE_TRUST_RECONCILIATION,
          value: OtherFixedCreditTypes.TRUST_RECONCILIATION
        }
      ];
    } else {
      this.creditTargetTypes = [
        {label: this.purchaserLabel, value: OtherFixedCreditTypes.PURCHASER},
        {label: this.vendorLabel, value: OtherFixedCreditTypes.VENDOR},
        {label: OtherFixedModalConstants.TARGET_TYPE_NO_ADJUSTMENT, value: OtherFixedCreditTypes.NO_ADJUSTMENT},
        {label: OtherFixedModalConstants.TARGET_TYPE_SILENT, value: OtherFixedCreditTypes.SILENT}
      ];
    }
  }

  private populateDebitTargetTypes(): void {
    if (this.context.matter.isPurchaseBC) {
      this.debitTargetTypes = [
        {label: OtherFixedModalConstants.TARGET_TYPE_BLANK, value: OtherFixedDebitTypes.BLANK},
        {label: this.vendorLabel, value: OtherFixedDebitTypes.VENDOR},
        {label: this.purchaserLabel, value: OtherFixedDebitTypes.PURCHASER},
        {
          label: OtherFixedModalConstants.TARGET_TYPE_TRUST_RECONCILIATION,
          value: OtherFixedDebitTypes.TRUST_RECONCILIATION
        }
      ];
    }
  }

  populateAmountHeadingTypes(): void {
    this.itemHeadingTypes = [
      {label: 'Cost', value: 'Cost'},
      {label: 'Amount', value: 'Amount'},
      {
        label: 'Fee',
        value: 'Fee'
      },
      {label: 'Total', value: 'Total'},
      {label: 'Charge', value: 'Charge'}
    ];
    if (this.localAdjustment && this.localAdjustment.adjustmentHeading) {
      this.itemHeadingTypes.push({
        label: this.localAdjustment.adjustmentHeading,
        value: this.localAdjustment.adjustmentHeading
      });
    } else {
      this.itemHeadingTypes.push({label: 'heading', value: 'heading'});
    }
  }

  onChangeAdjustmentHeading(event): void {
    let staticValues: string[] = ['Cost', 'Amount', 'Fee', 'Total', 'Charge'];
    if (this.localAdjustment.amountHeading) {
      this.itemHeadingTypes.pop();
      this.itemHeadingTypes.push({label: event, value: event});
      if (staticValues.indexOf(this.localAdjustment.amountHeading) == -1) {
        this.localAdjustment.amountHeading = event;
      }
    } else {
      this.itemHeadingTypes.pop();
      this.itemHeadingTypes.push({label: 'heading', value: 'heading'});
      this.localAdjustment.amountHeading = 'Amount';
    }
  }

  get purchaserLabel(): string {
    return this.context.statementAdjustmentHeading
      ? this.context.statementAdjustmentHeading.leftColumnListsCreditsTo
      : '';
  }

  get vendorLabel(): string {
    return this.context.statementAdjustmentHeading
      ? this.context.statementAdjustmentHeading.rightColumnListsCreditsTo
      : '';
  }

  get isCreditPurchaser(): boolean {
    return this.localAdjustment && this.localAdjustment.creditType === OtherFixedCreditTypes.PURCHASER;
  }

  get isCreditVendor(): boolean {
    return this.localAdjustment && this.localAdjustment.creditType === OtherFixedCreditTypes.VENDOR;
  }

  get isCreditPurchaserOrVendor(): boolean {
    return this.isCreditPurchaser || this.isCreditVendor;
  }

  get creditToLabel(): string {
    //Credit XXXXXX, YYYYYY or No Adjustment?
    if (this.context.matter.isPurchaseBC) {
      return OtherFixedModalConstants.TARGET_TYPE_CREDIT;
    }
    return `${OtherFixedModalConstants.TARGET_TYPE_CREDIT} ${this.purchaserLabel}, ${this.vendorLabel} or ${OtherFixedModalConstants.TARGET_TYPE_NO_ADJUSTMENT}?`;
  }

  removeEmptyItems() {
    this.localAdjustment.items = this.localAdjustment.items.filter(
      (itm) => (itm.heading && itm.heading.length > 0) || (itm.amount && itm.amount > 0)
    );
  }

  cleanAdjustmentPaidOnInterimClosing() {
    if (
      !this.isAdjustmentPaidOnInterimClosingVisibleWithProject() &&
      !this.isAdjustmentPaidOnInterimClosingVisibleWithProject()
    ) {
      this.localAdjustment.adjustmentPaidOnInterimClosing = DpBooleanValueTypes.N_y;
    }
  }

  ok(): void {
    this.modalErrorComponent.removeAllDpSaveError();

    this.checkForErrors();
    if (!this.modalErrorComponent.anyErrorExist()) {
      this.removeEmptyItems();
      this.checkAmountConsiderationVendor();
      this.cleanAdjustmentPaidOnInterimClosing();
      this.dialog.close(this.localAdjustment);
    }
  }

  close(): void {
    this.dialog.close(ModalResult.Cancel);
  }

  delete(): void {
    this.dialogService
      .confirm('Confirmation', 'Do you wish to delete this item ?', false, 'Delete')
      .subscribe((res) => {
        if (res) {
          this.dialog.close(ModalResult.Delete);
        }
      });
  }

  get allowDelete(): boolean {
    return !this.context.sourceFromProject && !this.newAdj;
  }

  formattedDate(dateStr: string): string {
    return dateStr ? moment(dateStr).format('MMM DD, YYYY') : '';
  }

  addNewItem() {
    if (this.localAdjustment.items.length < this.maxItems) {
      this.localAdjustment.items.push(this.getNewItem());

      setTimeout(() => {
        jQuery(`#item-heading-${this.localAdjustment.items.length - 1}-${this.deleteIndex}`).focus();
      }, 100);
    }
  }

  removeItem(item: SoAdjOtherFixedItem) {
    //if (this.otherFixedSoa && this.otherFixedSoa.items) {
    let index: number = this.localAdjustment.items.indexOf(item);
    if (index > -1) {
      this.localAdjustment.items.splice(index, 1);
      this.deleteIndex++;
      //always at least 2 empty items
      if (this.localAdjustment.items.length < this.minNumberOfItems) {
        this.fillInEmptyItems(1);
      }

      setTimeout(() => {
        jQuery(`#item-heading-${this.localAdjustment.items.length - 1}-${this.deleteIndex}`).focus();
      }, 100);
    }
    // }
  }

  fillInEmptyItems(howMany: number) {
    for (let i: number = 0; i < howMany; i++) {
      this.localAdjustment.items.push(this.getNewItem());
    }
  }

  getNewItem(): SoAdjOtherFixedItem {
    let newItem = new SoAdjOtherFixedItem();
    newItem.amount = Number(0);
    newItem.heading = '';
    return newItem;
  }

  /**
   * used for hiding/showing the note on calculations (only if the amount in any items is greater than 0)
   * @returns {boolean}
   */
  displayNote(): boolean {
    if (this.localAdjustment && Array.isArray(this.localAdjustment.items) && this.localAdjustment.items.length > 0) {
      return this.localAdjustment.items.some((itm) => itm.amount > 0);
    }
    return false;
  }

  noteText(): string {
    if (this.context.matter.isPurchaseBC) {
      return OtherFixedModalConstants.PURCHASE_BC_NOTE;
    }
    return OtherFixedModalConstants.DEFAULT_NOTE.replace('{purchaserLabel}', this.purchaserLabel).replace(
      '{vendorLabel}',
      this.vendorLabel
    );
  }

  isProjectTab(): boolean {
    return this.tabsService && this.tabsService.activeTab && this.tabsService.activeTab.isProject();
  }

  checkForErrors() {
    this.modalErrorComponent.removeAllDpFieldError();
    if (!this.localAdjustment.adjustmentHeading || this.localAdjustment.adjustmentHeading.length == 0) {
      this.modalErrorComponent.createDPFieldError('matter.soadj.other.fixed.description');
    }
    //Relax the validation to allow zero amount for project adjustments
    if (
      !this.isProjectTab() &&
      this.localAdjustment.amount <= 0 &&
      this.localAdjustment.creditType != OtherFixedCreditTypes.SILENT &&
      this.localAdjustment.creditType != OtherFixedCreditTypes.NO_ADJUSTMENT
    ) {
      this.modalErrorComponent.createDPFieldError('matter.soadj.other.fixed.adj.amount');
    }
    if (
      !this.context.salePriceAdjustmentFactorsInHstRebate &&
      this.localAdjustment.amountAdditionalConsiderationToVendor == 'YES_ELIGIBLE_FOR_REBATE'
    ) {
      //this.modalErrorComponent.createDPFieldError('matter.soadj.other.fixed.amount.additional.consideration.to.vendor.sale.price');
      this.modalErrorComponent.createCustomDPFieldError(
        'matter.soadj.other.fixed.amount.additional.consideration.to.vendor.sale.price',
        `The 'Yes (eligible for ${this.context.soaConsiderationTaxes.rateTypeLabel} Rebate)' response in the 'Additional Consideration' field is not permitted unless the Sale Price adjustment is set to 'Factor in ${this.context.soaConsiderationTaxes.rateTypeLabel} Rebate'`,
        'ERROR',
        'ERROR'
      );
    }

    if (
      !this.context.isOtherFixedAdjAvailable &&
      !this.context.aTarionWarrantyWithConsiderationAlreadyExists &&
      !this.context.aHCRAWithConsiderationAlreadyExists &&
      this.context.additionalConsiderationsInclHst > 0 &&
      this.localAdjustment.amountAdditionalConsiderationToVendor != 'NO'
    ) {
      this.modalErrorComponent.createDPSaveError(
        'matter.soadj.other.fixed.amount.additional.consideration.to.vendor.include.hst'
      );
    }
  }

  displayTaxFields(): boolean {
    return (
      this.localAdjustment &&
      this.isCreditPurchaserOrVendor &&
      (this.localAdjustment.applyTaxBoolean || this.localAdjustment.applyPstBoolean)
    );
  }

  displayPstPercentage(): boolean {
    // applyTax = true/false at initialization and "false"/"true" after it gets changed in UI
    if (this.context.provinceCode == 'MB' || this.context.provinceCode == 'SK') {
      return (
        this.localAdjustment && this.isCreditPurchaserOrVendor && Utils.convertToBoolean(this.localAdjustment.applyPst)
      );
    } else {
      return (
        this.localAdjustment &&
        this.isCreditPurchaserOrVendor &&
        Utils.convertToBoolean(this.localAdjustment.applyTax) &&
        Utils.convertToBoolean(this.localAdjustment.applyPst)
      );
    }
  }

  checkHSTWarning(): void {
    if (this.modalErrorComponent) {
      this.modalErrorComponent.removeDpFieldError('matter.soadj.other.fixed.adj.hst.rebate');
      let isAbWithProject: boolean =
        (this.context.matter.isProjectSale || this.context.matter.templateForProject) &&
        this.context.matter.isMatterProvinceAB;
      // For project or project matter with AB, condition is <Sale Price Selection> = 'Yes - factor in HST rebate(s)'
      // If it is not project or project matter, condition is <Sale Price Selection> is 'Yes - factor in HST rebate(s)' or  "Yes - divide sale price"
      if (
        !this.localAdjustment.applyTaxBoolean &&
        this.localAdjustment.amountAdditionalConsiderationToVendor == 'YES_ELIGIBLE_FOR_REBATE' &&
        ((!isAbWithProject && this.context.salePriceAdjustmentFactorsInHstRebate) ||
          this.context.matter.considerationLtt.salePriceAdjustment.isFactorInHstRebate())
      ) {
        let warningMsg: string = `If the amount specified of ${Utils.formattedCurrencyValue(
          this.localAdjustment.amount
        )} does not already include ${this.context.soaConsiderationTaxes.rateTypeLabel}, then if you proceed, ${
          this.context.soaConsiderationTaxes.rateTypeLabel
        } and ${
          this.context.soaConsiderationTaxes.rateTypeLabel
        } Rebate calculations will be incorrect.  Amounts comprising "Additional Consideration to Vendor" become part of the consideration on which these calculations are based.  Since you have responded that this adjustment is not subject to ${
          this.context.soaConsiderationTaxes.rateTypeLabel
        }, these calculations will be incorrect.`;

        this.modalErrorComponent.createCustomDPFieldError(
          'matter.soadj.other.fixed.adj.hst.rebate',
          warningMsg,
          null,
          'WARNING'
        );
      }
    }
  }

  updateHSTWarning(): void {
    if (this.modalErrorComponent) {
      let warningMsg: string = `If the amount specified of ${Utils.formattedCurrencyValue(
        this.localAdjustment.amount
      )} does not already include ${this.context.soaConsiderationTaxes.rateTypeLabel}, then if you proceed, ${
        this.context.soaConsiderationTaxes.rateTypeLabel
      } and ${
        this.context.soaConsiderationTaxes.rateTypeLabel
      } Rebate calculations will be incorrect.  Amounts comprising "Additional Consideration to Vendor" become part of the consideration on which these calculations are based.  Since you have responded that this adjustment is not subject to ${
        this.context.soaConsiderationTaxes.rateTypeLabel
      }, these calculations will be incorrect.`;
      this.modalErrorComponent.updateErrorMsg('matter.soadj.other.fixed.adj.hst.rebate', warningMsg);
    }
  }

  // if credit is not to vendor reset amountAdditionalConsiderationToVendor
  // TC does not reset this field while editing adjustment only on exit
  checkAmountConsiderationVendor(): void {
    if (!this.isCreditVendor) {
      this.localAdjustment.amountAdditionalConsiderationToVendor = 'NO';
    }
  }

  isAdjustForPstVisible(): boolean {
    if (this.context.provinceCode == 'MB' || this.context.provinceCode == 'SK') {
      return true;
    } else {
      return this.localAdjustment.taxType == 'GST' && this.displayTaxFields() && this.context.defaultPstRate > 0;
    }
  }

  itemAdjAmtLabel(): string {
    if (this.isOtherProratedOnPercentageInterest()) {
      return OtherFixedModalConstants.GROSS_AMOUNT_FOR_PROJECT;
    }
    if (this.context.matter.isPurchaseBC) {
      return OtherFixedModalConstants.AMOUNT;
    }
    return '';
  }

  isOtherProratedOnPercentageInterest(): boolean {
    return (
      this.context &&
      this.context.statementAdjustmentKey == StatementAdjustmentKey.OTHER_PRORATED_ON_PERCENTAGE_INTEREST
    );
  }

  showItemAdjAmountSelect(): boolean {
    return !this.isOtherProratedOnPercentageInterest() && !this.context.matter.isPurchaseBC;
  }

  selectUnitLevelPlan(): void {
    let modelWidthXl: boolean =
      (this.context.matter.isProjectSale || this.context.matter.templateForProject) &&
      this.context.matter.isMatterProvinceON;
    let cea: StatementAdjustment = this.context.matter.statementOfAdjustments.find((item) =>
      item.isCommonExpenseAdjustmentFromProperty()
    );
    this.dialogService.matDialogContent({
      content: UnitLevelPlanModalComponent,
      widthXl: modelWidthXl,
      context: {
        propertyJurisdiction: this.selectedJurisdiction,
        unitLevelPlanOrg: this.propertyModel,
        commonExpenseAdj: cea,
        currentMatter: this.context.matter,
        isReadOnly: false,
        isUnitLevelPinReadOnly: true
      },
      onFulfillment: (result) => {
        if (result) {
          if (result.plan) {
            // this.context.matter.updateUnitLevelPlanCreated(result.plan);
            // this.context.matter.calculateStatementAdjustment();
            this.matterService.updateMatterWithUnitLevelPlanChange(this.context.matter, result);

            if (
              (this.context.matter.isProjectSale || this.context.matter.templateForProject) &&
              this.context.matter.isMatterProvinceAB
            ) {
              this.purchaserPortionBasedOnPercentageInterest =
                this.context.matter.matterPropertyWithCondo.percentageShareOfTotalRealtyTaxes;
            } else {
              this.purchaserPortionBasedOnPercentageInterest = Utils.formatWithDecimals(
                Number(this.context.matter.matterPropertyWithCondo.condominiumTotalSharePercentage),
                9
              );
            }
            this.context.matter.dirty = true;
            setTimeout(() => {
              this.cachedDetails = this.statementAdjustmentDisplayUtil.getDisplayItems(
                this.localAdjustment,
                '',
                true,
                this.statementAdjustmentKey
              );
              jQuery('#purchaserPortionBasedOnPercentageInterest').focus();
            }, 100);
          }
        } else {
          setTimeout(() => {
            jQuery('#unitLevelPlanButton').focus();
          }, 0);
        }
      }
    });
  }

  get propertyModel(): MatterProperty {
    if (this.context.matter && !this.context.matter.matterPropertyWithCondo) {
      this.context.matter.matterProperties.push(MatterProperty.createDefaultMatterProperty());
    }
    return this.context.matter.matterPropertyWithCondo;
  }

  savePredefined() {
    let sa = new StatementAdjustment(this.context.matter.adjustmentStatusMode, this.context.matter.provinceCode);
    sa.itemKey = this.context.statementAdjustmentKey;
    sa.soAdjOtherFixed = new SoAdjOtherFixed(this.localAdjustment);
    sa.soAdjOtherFixed.clearAllIds();
    this.saveAdjustmentTemplate(sa, OtherFixedModalConstants.SAVE_PREDEFINED_ADJUSTMENT);
  }

  copyFrom(adjustmentTemplate: AdjustmentTemplate): void {
    if (
      adjustmentTemplate &&
      adjustmentTemplate.statementOfAdjustment &&
      adjustmentTemplate.statementOfAdjustment.soAdjOtherFixed
    ) {
      const soaAdjOthFix = new SoAdjOtherFixed(adjustmentTemplate.statementOfAdjustment.soAdjOtherFixed);
      this.localAdjustment.copyFrom(soaAdjOthFix);
      this.localAdjustment.items = soaAdjOthFix.items.map((itm: SoAdjOtherFixedItem) => {
        const newSoaFixItm = new SoAdjOtherFixedItem(itm);
        newSoaFixItm.clearAllIds();
        return newSoaFixItm;
      });
      // for the case when the template was created for amountAdditionalConsiderationToVendor !== 'NO' on a matter with purchaseIsOfANewHomeFromABuilder
      // but current matter does not have purchaseIsOfANewHomeFromABuilder
      if (!this.context.purchaseIsOfANewHomeFromABuilder) {
        this.localAdjustment.amountAdditionalConsiderationToVendor = 'NO';
      }
      // rebuild adj heading options as per template
      this.onChangeAdjustmentHeading(this.localAdjustment.adjustmentHeading);
      this.checkHSTWarning();
      this.clearCachedDetails();
    }
  }

  /**
   *  Only for there is a Project situation
   */
  isAdjustmentPaidOnInterimClosingVisibleWithProject(): boolean {
    let ret: boolean = false;
    if (this.context.project) {
      if (this.context.project.isProjectProvinceON) {
        ret = this.isAdjustmentPaidOnInterimClosingVisibleWithProjectON();
      } else if (this.context.project.isProjectProvinceAB) {
        ret = this.isAdjustmentPaidOnInterimClosingVisibleWithProjectAB();
      }
    }
    return ret;
  }

  /**
   *  Only for there is a Project situation with ON
   *  IF 	(<Is amount additional consideration to vendor> IS VISIBLE AND <Is amount additional consideration to vendor> <>'No' )
   *      AND ((<Is this is a condo?> = 'Yes')
   *          OR (<Is this is a condo?> = 'No' and<Is there a POTL> = 'Yes')
   *          OR (<Is this is a condo?> = 'No' and <Is an occupancy date required for each transaction in this project> = 'Yes'))
   *  THEN
   *      I am able to see a field labeled 'Adjustment to be paid by purchaser on interim closing'
   *  ELSE
   *      I am NOT able to see a field labeled 'Adjustment to be paid by purchaser on interim closing'
   *  END IF
   *  isAdjustmentPaidOnInterimClosingVisibleExternalCondition only implement check the following condition
   *   ((<Is this is a condo?> = 'Yes')
   *          OR (<Is this is a condo?> = 'No' and<Is there a POTL> = 'Yes')
   *          OR (<Is this is a condo?> = 'No' and <Is an occupancy date required for each transaction in this project> = 'Yes'))
   */
  isAdjustmentPaidOnInterimClosingVisibleWithProjectON(): boolean {
    return (
      this.context.purchaseIsOfANewHomeFromABuilder &&
      this.isCreditVendor &&
      this.localAdjustment.amountAdditionalConsiderationToVendor != 'NO' &&
      this.context.project &&
      this.context.project.isAdjustmentPaidOnInterimClosingVisibleExternalCondition()
    );
  }

  /**
   *  Only for there is a Project situation with AB
   *  IF
   *      <Is amount additional consideration to vendor> IS VISIBLE
   *      AND <Is amount additional consideration to vendor> <>'No' )
   *      AND (<Is this is a condo?> = 'Yes')
   *  THEN
   *      I am able to see a field labeled 'Adjustment to be paid by purchaser on interim closing'
   *  ELSE
   *      I am NOT able to see a field labeled 'Adjustment to be paid by purchaser on interim closing'
   *  END IF
   *
   */
  isAdjustmentPaidOnInterimClosingVisibleWithProjectAB(): boolean {
    return (
      this.context.purchaseIsOfANewHomeFromABuilder &&
      this.isCreditVendor &&
      this.localAdjustment.amountAdditionalConsiderationToVendor != 'NO' &&
      this.context.project &&
      this.context.project.isCondo
    );
  }
}
