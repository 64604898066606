import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-ai-card',
  templateUrl: './app-AI.component.html',
  styleUrls: [ './app-AI.component.scss' ]
})
export class AICardComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }
}
