import {ErrorService} from '../../shared/error-handling/error-service';
import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';

class AccountingModalContext {}

@Component({
  selector: 'dp-accounting-modal-content',
  templateUrl: 'accounting.modal.component.html',
  styleUrls: ['./accounting.modal.scss'],
  providers: [ErrorService]
})
export class AccountingModalComponent extends ModalComponent<AccountingModalContext> implements AfterViewInit {
  @ViewChild('accountingModalClose') accountingModalClose!: ElementRef<HTMLButtonElement>;

  constructor(
    public dialog: MatDialogRef<AccountingModalComponent>,
    @Inject(MAT_DIALOG_DATA) context?: AccountingModalContext
  ) {
    super(dialog, context);
  }

  ngAfterViewInit() {
    this.accountingModalClose.nativeElement.focus();
  }

  goToLink(url: string) {
    this.dialog.close();
    window.open(url, '_blank');
  }

    close(): void {
        this.dialog.close();

    }

  beforeDismiss(): boolean {
    return true;
  }

  beforeClose(): boolean {
    return false;
  }
}
