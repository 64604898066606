import {Component, Inject, OnInit} from '@angular/core';
import {Precedent} from '../../../app/matters/shared/precedent';
import {PrecedentService} from '../../../app/matters/mortgages/mortgage/precedent/precedent.service';
import {PrecedentTypes} from '../../../app/matters/shared/mortgage-precedent-type';
import {ErrorService} from '../../shared/error-handling/error-service';
import {UnityMessage} from '../../messages/unity-message';
import {MatterWorkItemTask} from '../shared/matter-work-item';
import {MatterParticipant} from '../shared';
import {Matter} from '../shared/matter';
import * as _ from 'lodash';
import {StaffProfiles} from '../../admin/staff-profiles/staff-profiles';
import {SESSION_STORAGE_KEYS} from '../../shared';
import moment from 'moment';
import {DPError} from '../../shared/error-handling/dp-error';
import {MatterParticipantInfo, MatterParticipantUtil} from '../shared/matter-utils/matter-participant-util';
import Utils from '../../shared-main/utils';
import {EmailFieldService} from '../../shared-main/email-field/email-field-service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';

export class AssignedTaskModalContext {
  matter: Matter;
  matterWorkItemtasks: MatterWorkItemTask[];
  subject: string;
  staffProfiles: StaffProfiles[];
  assignedToStaffName: string;
  multipleTasks: boolean;
}

@Component({
  selector: 'dp-assigned-task-modal',
  templateUrl: 'assigned-task-modal.component.html',
  providers: [ErrorService]
})
export class AssignedTaskModalComponent extends ModalComponent<AssignedTaskModalContext> implements OnInit {
  messagePrecedents: Precedent[] = [];
  clients: MatterParticipantInfo[] = [];
  selectedPrecedent: any;
  selectedClientId: any;
  message: string;

  assignedTo: string = 'STAFF';
  unityMessage: UnityMessage;
  staffOptions: any[] = [];
  selectedStaffId: number;
  isPrimaryClientIssue: boolean = false;

  constructor(
    public dialog: MatDialogRef<AssignedTaskModalComponent>,
    public messagePrecedentService: PrecedentService,
    public emailFieldService: EmailFieldService,
    public errorService: ErrorService,
    @Inject(MAT_DIALOG_DATA) context?: AssignedTaskModalContext
  ) {
    super(dialog, context);
    emailFieldService.matter = context.matter;
  }

  close() {
    this.dialog.close();
  }

  ngOnInit() {
    this.loadStaffList();
    this.getMessagePrecedents();
    this.updateEditorMessage();
    this.createUnityMessage();
    this.getClients();
    // this.isPrimaryClientIssue = !this.primaryClient() || (this.primaryClient().contact && (!this.primaryContactEmail() || !ErrorValidator.areEmailAddressesValid(this.primaryContactEmail(), true)));
  }

  createUnityMessage(): void {
    const id = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    this.unityMessage = new UnityMessage();
    this.unityMessage.category = 'ASSIGNMENT';
    this.unityMessage.sentDate = moment().toDate().getTime();
    let defaultMessage = '';
    let taskName = '';
    let dueDate;

    if (this.context.matterWorkItemtasks.length > 0) {
      taskName = this.context.matterWorkItemtasks[0].name;
      dueDate = this.context.matterWorkItemtasks[0].dueDate;
    }

    if (
      this.context.assignedToStaffName &&
      this.context.matterWorkItemtasks &&
      this.context.matterWorkItemtasks.length
    ) {
      defaultMessage = `Re: ${this.context.matterWorkItemtasks[0].name}`;
      this.unityMessage.recipientUserId = this.getStaffUserIdFromContactId(
        this.context.matterWorkItemtasks[0].assignedToParticipantId
      );
    } else {
      defaultMessage = 'We are notifying you that a task, or tasks, for this transaction have been assigned to you.';
      if (this.context.matterWorkItemtasks) {
        this.context.matterWorkItemtasks.forEach((item) => {
          defaultMessage = defaultMessage + '\n\n' + item.name;
        });
      }
    }
    this.unityMessage.message = defaultMessage;
    this.unityMessage.messageTarget = 'USER';
    this.unityMessage.origin = 'TK';
    this.unityMessage.recipientCustomerAccountId = Number(id);
    this.unityMessage.effectiveDate = moment().toDate().getTime();

    let reLine = this.emailFieldService.createSubjectLineForAssign();
    let subjectAddress = '';
    if (
      this.context.matter &&
      this.context.matter.matterPropertyWithCondo &&
      this.context.matter.matterPropertyWithCondo &&
      this.context.matter.matterPropertyWithCondo.address.addressTextWithoutCountryAndPostalCode
    ) {
      subjectAddress =
        '; ' + this.context.matter.matterPropertyWithCondo.address.addressTextWithoutCountryAndPostalCode;
    }
    if (this.context.matter && this.context.matter.isOpportunityMatter()) {
      let opportunityNumber = this.context.matter.matterRecordNumber
        ? 'Opportunity ID ' + this.context.matter.matterRecordNumber
        : '';
      this.unityMessage.messageSubject =
        'Task Assignment: ' +
        (this.context.multipleTasks ? 'Multiple tasks - ' : '' + taskName) +
        (this.context.multipleTasks ? '' : ' - ') +
        reLine +
        subjectAddress +
        '; ' +
        opportunityNumber +
        (Utils.isNotValidDate(dueDate) || this.context.multipleTasks
          ? ''
          : '; ' + 'Due Date: ' + moment(dueDate, 'YYYY/MM/DD').format('ddd MMM DD YYYY'));
      this.unityMessage.additionalMessageData = JSON.stringify({
        opportunityMatterId: this.context.matter.id,
        taskAssignment: 'YES'
      });
    } else {
      let fileNumber = this.context.matter ? '; Our File No. ' + this.context.matter.fileNumber : '';
      this.unityMessage.messageSubject =
        'Task Assignment: ' +
        (this.context.multipleTasks ? 'Multiple tasks - ' : '' + taskName) +
        (this.context.multipleTasks ? '' : ' - ') +
        reLine +
        subjectAddress +
        fileNumber +
        (Utils.isNotValidDate(dueDate)
          ? ''
          : '; ' + 'Due Date: ' + moment(dueDate, 'YYYY/MM/DD').format('ddd MMM DD YYYY'));
      this.unityMessage.additionalMessageData = JSON.stringify({
        matterId: this.context.matter.id,
        taskAssignment: 'YES'
      });
    }
  }

  getStaffUserIdFromContactId(contactId: number): number {
    let selectedStaff = this.context.staffProfiles.find((item) => item.contactId == contactId);
    return selectedStaff ? selectedStaff.dppmUserId : null;
  }

  loadStaffList(): void {
    this.staffOptions = [];
    if (this.context.staffProfiles) {
      let staffProfilesList = this.context.staffProfiles.filter((item) => !!item.dppmUserId);
      if (this.context.matter.selectedSolicitorId) {
        let contactIndex = staffProfilesList.findIndex((sf) => sf.contactId == this.context.matter.selectedSolicitorId);
        if (contactIndex > -1) {
          this.staffOptions.push({
            label: staffProfilesList[contactIndex].surnameLastFullName,
            value: staffProfilesList[contactIndex].dppmUserId,
            contactId: staffProfilesList[contactIndex].contactId
          });
          staffProfilesList.splice(contactIndex, 1);
        }
      }
      if (this.context.matter.selectedLawClerkId) {
        let contactIndex = staffProfilesList.findIndex((sf) => sf.contactId == this.context.matter.selectedLawClerkId);
        if (contactIndex > -1) {
          this.staffOptions.push({
            label: staffProfilesList[contactIndex].surnameLastFullName,
            value: staffProfilesList[contactIndex].dppmUserId,
            contactId: staffProfilesList[contactIndex].contactId
          });
          staffProfilesList.splice(contactIndex, 1);
        }
      }
      if (this.context.matter.selectedOpportunityAssignedTo) {
        let contactIndex = staffProfilesList.findIndex(
          (sf) => sf.contactId == this.context.matter.selectedOpportunityAssignedTo
        );
        if (contactIndex > -1) {
          this.staffOptions.push({
            label: staffProfilesList[contactIndex].surnameLastFullName,
            value: staffProfilesList[contactIndex].dppmUserId,
            contactId: staffProfilesList[contactIndex].contactId
          });
          staffProfilesList.splice(contactIndex, 1);
        }
      }

      let otherClerkOptions: any = [];
      let filteredList = staffProfilesList ? staffProfilesList.filter((staff) => staff.contactStatus != 'NO') : [];
      for (let i = 0; i < filteredList.length; i++) {
        otherClerkOptions.push({
          label: filteredList[i].surnameLastFullName,
          value: filteredList[i].dppmUserId,
          contactId: filteredList[i].contactId
        });
      }
      this.staffOptions.push(..._.orderBy(otherClerkOptions, [(obj) => obj.label && obj.label.toLowerCase()]));
    }
  }

  async getMessagePrecedents(): Promise<void> {
    //Get precedents from backend
    let precedents: Precedent[] = await this.messagePrecedentService.getPrecedents().toPromise();
    if (precedents) {
      this.messagePrecedents = precedents.filter((precedent) => precedent.precedentType === PrecedentTypes.MESSAGE);
    }
  }

  getClients() {
    let clients: MatterParticipant[];
    clients = this.context.matter ? this.context.matter.mainClients : [];
    if (clients && clients.length > 0) {
      this.clients = clients.map((item) => MatterParticipantUtil.contactFieldWithEmailInfo(item, this.context.matter));
      this.clients = _.orderBy(this.clients, ['label'], ['asc']);
      let primaryClient: MatterParticipantInfo = MatterParticipantUtil.contactFieldWithEmailInfo(
        this.primaryClient(),
        this.context.matter
      );
      if (primaryClient) {
        let client = this.clients.find((item) => item.id == primaryClient.id);
        this.selectedClientId = client ? client.id : null;
      }
    }
  }

  updateEditorMessage(): void {
    this.message = '';
    if (this.selectedPrecedent) {
      let messagePrecedent = this.getSelectedPrecedent();
      if (messagePrecedent) {
        this.message += messagePrecedent.description;
      }
    }
  }

  getSelectedPrecedent() {
    return this.messagePrecedents.find((item) => item.id == this.selectedPrecedent);
  }

  checkMissingEmail() {
    if (this.selectedClientId) {
      let client = this.getSelectedClient();
      if (client && !Utils.validateEmail(client.email)) {
        this.errorService.addDpSaveError(DPError.createDPError('task.assignment.miss.email'));
      }
    }
  }

  getSelectedClient() {
    return this.clients.find((item) => item.id == this.selectedClientId);
  }

  getContactIdFromStaffProfile(dppmUserId: number): number {
    let staff = this.staffOptions.find((so) => so.value == dppmUserId);
    return staff ? staff.contactId : undefined;
  }

  send() {
    this.errorService.clearAllSaveErrors();
    if (this.assignedToStaff()) {
      if (!this.unityMessage.recipientUserId) {
        this.errorService.addDpSaveError(DPError.createDPError('matter.workItem.taskAssignment.userNotSelected'));
      } else {
        this.unityMessage.message =
          this.unityMessage.message +
          '\n\n' +
          ('Select Button Below to open the ' +
            (this.context.matter && this.context.matter.isOpportunityMatter() ? 'Opportunity ' : 'Matter '));
        this.unityMessage.message = this.context.matter
          ? this.unityMessage.message + this.context.matter.matterRecordNumber
          : '';
        this.dialog.close({
          message: this.message,
          assignedTo: this.assignedTo,
          unityMessage: this.unityMessage,
          assignedContactId: this.getContactIdFromStaffProfile(this.unityMessage.recipientUserId)
        });
      }
    } else {
      this.checkMissingEmail();
      if (!this.errorService.hasErrors()) {
        this.dialog.close({
          message: this.message,
          assignedTo: this.assignedTo,
          unityMessage: this.unityMessage,
          selectedClientId: this.selectedClientId
        });
      }
    }
  }

  assignedToStaff(): boolean {
    return this.assignedTo == 'STAFF';
  }

  primaryClient(): MatterParticipant {
    return (
      this.context.matter &&
      this.context.matter.mainClients &&
      this.context.matter.mainClients.find((item) => !!item.primary && !!item.contact)
    );
  }

  primaryContactEmail(): string {
    return MatterParticipantUtil.primaryContactEmail(this.context.matter);
  }
}
