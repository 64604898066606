import {Address} from './../shared/address';
import {MatterParticipant} from './../shared/matter-participant';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {Logger} from '@nsalaun/ng-logger';
import '../../common/rxjs-operators';
import {Subject} from 'rxjs/Subject';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/switchMap';
import {PartialDateComponent} from '../../shared/partial-date';
import {SelectItem} from 'primeng/api';
import {AutoComplete} from 'primeng/autocomplete';
import {
  constValues,
  Contact,
  ContactService,
  currentMatter,
  dropDowns,
  errorMessage,
  GetGlobalSaveModelService,
  Jurisdiction,
  MatterProperty,
  MatterUtil,
  RegistryOffice,
  Utils
} from '../shared';
import CommonUtils from '../../shared-main/utils';
import {ContactQueryService} from '../../contact/contact-query.service';
import {JurisdictionService} from './jurisdiction.service';
import {UnitLevelPlanModalComponent} from './unit-level-plan/unit-level-plan.component';
import {ContentDialogConfig, DialogService} from '../../shared/dialog/dialog.service';
import {ConsiderationModal} from '../consideration-ltt/consideration.modal.component';
import {DepositModal, DepositModalContextValue} from './deposit/deposit.modal.component';
import {ConsiderationTaxes} from '../consideration-ltt/consideration-taxes';
import {MatterService} from '../matter.service';
import {DocumentProfile} from '../../admin/document-profile/document-profile';
import {MatterTax} from '../shared/property-taxes';
import {CurrencyPipe, DecimalPipe, Location, PercentPipe} from '@angular/common';
import {CondominiumPlan} from './unit-level-plan/condominium-plan';
import {CommonContactDialogComponent} from '../matter-opening/common-contact-dialog/common-contact-dialog.component';
import * as _ from 'lodash';
import {ErrorService} from '../../shared/error-handling/error-service';
import {ActivatedRoute, Router} from '@angular/router';
import {TabsService} from '../../core/tabs.service';
import {
  booleanYesNoDropDowns,
  BurgerMenuPropertyTeranetParcel,
  Combined_Hydro_Water_Department,
  Constants,
  Water_Department,
  ZERO_INTEREST
} from '../../shared-main/constants';
import {DocumentProfileService} from '../../admin/document-profile/document-profile-edit/document-profile.service';
import {RegistryOfficeDialogComponent} from './registry-office/registry-office.component';
import {TaxRateService} from '../consideration-ltt/tax-rate.service';
import {FocusFirstElementDecorator} from '../../shared-main/focus-first-element-decorator';
import {JurisdictionDepartmentsService} from '../../admin/jurisdiction-departments/jurisdiction-departments.service';
import {JurisdictionDepartment} from '../../admin/jurisdiction-departments/jurisdiction-departments';
import {Subscription} from 'rxjs/Subscription';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {Compliance} from '../compliance/compliance';
import {DocumentProfileCache} from '../../shared-main/document-profile-cache.service';
import {UnitLevelPlanUtil} from './unit-level-plan/unit-level-plan-util';
import {ProgressionStatus, StatementAdjustment} from '../statement-adjustment/statement-adjustment';
import {Insurer} from '../matter-opening/insurer/insurer';
import {InsurerService} from '../matter-opening/insurer/insurer.service';
import {Matter} from '../shared/matter';
import {StatementAdjustmentUtil} from '../statement-adjustment/statement-adjustment-util';
import {DPError} from '../../shared/error-handling/dp-error';
import {RollNumber} from '../shared/roll-number';
import {
  depositHeldByDropdown,
  depositHeldByDropdownOptions,
  ProvinceBasedInterestEstateOptions,
  Tax_RATE
} from '../../shared-main/province-based-dropdowns';
import {DpBooleanValueTypes} from '../shared/dp-boolean';
import {RegistryOfficeService} from './registry-office/registry-office.service';
import {ModalResult} from '../../shared-main/enums';
import {SoAdjOtherProrated} from '../statement-adjustment/model';
import {
  StatementAdjustmentExpenseTypes,
  StatementAdjustmentKey
} from '../statement-adjustment/statement-adjustment-constants';
import {OtherProratedModalComponent} from '../statement-adjustment/other/other-prorated.modal.component';
import {RollNumberPropertyTaxesModalComponent} from './property-taxes/roll-number-property-taxes.modal.component';
import {BrokerCommissionComponent} from '../broker-commission/broker-commission.component';

import moment from 'moment';
import {BurgerMenuExtendedItem} from '../shared/burger-menu-extended-item';
import {TitleRollNumberModal} from './title-roll-number/title-roll-number.modal.component';
import {ParcelLegalDescription} from '../shared/parcel-legal-description';
import {SoaCommonExpense} from '../statement-adjustment/model/common-expense-soa';
import {ParcelLegalDescriptionModalComponent} from './parcel/parcel-legal-description.modal.component';
import {SoaCommonExpenseModal} from '../statement-adjustment/modals/common-expense/common-expenses-soa.modal.component';
import {ManitobaLttTierUtil} from '../../matters/consideration-ltt/manitoba-ltt-tier-util';
import {ManitobaLTTTier} from '../../matters/consideration-ltt/manitoba-ltt-tier';
import {RentInterestRate} from '../../matters/statement-adjustment/model/rent-interest-rate';
import {TarionWarrantyEnrolmentPeriod} from '../../matters/statement-adjustment/model/tarion-warranty-enrolment-period';
import {StatementAdjustmentDisplayBuilder} from '../../matters/statement-adjustment/builders/statement-adjustment-display-builder';
import {AffidavitTobeSignedByOptions, ROLL_NUMBER_ASSIGNED} from '../shared/matter-drop-downs';
import {Project} from '../../projects/shared/project';
import {ProjectTab} from '../../projects/shared/project-tab';
import {AuthZService} from '../../core/authz/auth-z.service';
import {SelectUnitLevelPlanResult} from '../shared/select-unit-level-plan-result';
import {SoaExpenseAdjustmentUtil} from '../statement-adjustment/modals/reserve-fund/soa-expense-adjustment-util';
import {MatterTab} from '../matter-tab';
import {NavigateToMatterService} from '../navigate-to-matter.service';
import {LandTransferTaxRate} from '../shared/land-transfer-tax-rate';
import {RollNumberPropertyTaxesResult} from './property-taxes/roll-number-property-taxes-result';
import {Form54ModalComponent} from './form54/form-54.modal.component';
import {Form54} from '../shared/form54';
import SharedUtils from '../../shared-main/utils';
import {UndertakingsConfigService} from '../../admin/shared/undertaking-config.service';
import {PropertyPinHelper} from '../shared/property-pin-helper';
import {JurisdictionTab} from '../../contact/jurisdiction-tab';
import {JurisdictionModalComponent} from '../jurisdiction-modal/jurisdiction-modal.component';
import {ProcessJurisdictionActions} from '../shared/jurisdiction';
import {MatterParticipantRoleTypes} from '../shared/matter-participant-role-types';
import {ContactChangesListener} from '../../contact/contact-changes-listener';
import {MatterPropertyUtil} from '../shared/matter-utils/matter-property-util';
import {TitleDetailsUtil} from '../shared/title-details-util';
import {SESSION_STORAGE_KEYS} from '../../shared';
import {PropertyStrataMaintenanceFeeModal} from './propery-strata-maintenance-fee/propery-strata-maintenance-fee.modal.component';
import {PropertyStrataMaintenanceFee} from '../shared/property-strata-maintenance-fee';
import {PttService} from '../property-transfer-tax/ptt.service';
import {LINC_NUMBER_MAX_LENGTH_BC} from '../../shared-main/constants';
import {PropertyUtilitiesModal} from './property-utilities/property-utilities.modal.component';
import {MatterUtility} from '../shared/matter-utility';
import {ApplicationError} from '../../core/application-error';

@Component({
  selector: 'dp-property-teranet',
  styleUrls: ['./property-teranet.scss'],
  templateUrl: 'property-teranet.component.html',
  providers: [
    JurisdictionService,
    CurrencyPipe,
    BrokerCommissionComponent,
    DecimalPipe,
    CurrencyPipe,
    PercentPipe,
    NavigateToMatterService,
  ]
})
@AutoUnsubscribe({blackList: ['jurisdictionSubscription']})
@FocusFirstElementDecorator()
export class PropertyTeranetComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('propertyTeranetForm') form;
  @ViewChild('partialSurveyorDate') partialSurveyorDateComponent: PartialDateComponent;
  @ViewChild('noRollNumberFocusable') noRollNumberFocusable;
  @ViewChild('rollNumberFocusable') rollNumberFocusable;
  @ViewChild('jurisdictionOmnibar') jurisdictionOmnibar: AutoComplete;
  @ViewChild('surveyorOmnibar') surveyorOmnibar: AutoComplete;
  @ViewChild('residentAssociationOmnibar') residentAssociationOmnibar: AutoComplete;
  @Input() showWizardFields: boolean = false;
  @Input() dpReadOnlyBlock: boolean = false;

  surveyDateSamplingMessage: string;
  matterProperties: any[];
  subscription: any;
  jurisdictionSubscription: Subscription;
  _propertyModel: any;
  address: Address;
  currentTimeStamp = Date.now();
  page = 'property';
  utils = new Utils();
  propertyPinHelper = new PropertyPinHelper();
  rollNumberType: string;
  rollNumber: RollNumber;

  allowMaxLength: boolean = true;
  ontarioTaxRateSlab: ConsiderationTaxes;
  torontoTaxRateSlab: ConsiderationTaxes;
  provinceHstRateSlab: ConsiderationTaxes;
  considerationTaxes: ConsiderationTaxes[] = [];
  // Jurisdiction
  jurisdictions: any;
  jurisdictionsLoading: boolean = false;
  searchTermJurisdiction = new Subject<string>();
  jurisdictionReadOnly: any;
  selectedJurisdiction: any;
  jurisdictionAutoCompleteEmptyFlag: boolean = false;
  jurisdictionDisplayName: string;

  residentAssociations: any;
  residentAssociationLoading: boolean = false;
  searchTermResidentAssociation = new Subject<string>();
  residentAssociationReadOnly: boolean;
  selectedResidentAssociation: Contact;
  residentAssociationAutoCompleteEmptyFlag: boolean = false;

  // Surveyor
  surveyorsLoading: boolean = false;
  public surveyorSearchSubject: Subject<string> = new Subject<string>();
  surveyorContacts: any[];
  // autoCompleteEmptyFlagSurveyor : boolean = false;
  selectedSurveyorContact: any;
  selectedSurveyorContactAttention: any;
  purchaseTypes: SelectItem[];
  interestEstates: SelectItem[];
  propertyTypes: SelectItem[];
  titleInsurancePropertyTypes: SelectItem[];
  registryLt: SelectItem[];
  farmlandEvidenceOptions: SelectItem[];
  titleSearchModel: SelectItem[];
  condoOptions: SelectItem[];
  nRDTTaxOptions: SelectItem[];
  vendorToSupplyRPROptions: SelectItem[];
  exceptionTypeOptions: SelectItem[];
  exceptionDescriptionOptions: SelectItem[];
  filteredExceptionTypeOptions: any[];
  landTitleOfficeLocationOptions: String[];
  landTitleOfficeOptions: String[];
  registryOffices: RegistryOffice[] = [];
  estTaxIncrease: string;
  attentionList: Contact[];
  insurers: Insurer[];
  parcelOfTiedLandOptions: SelectItem[];
  soaConsiderationTaxes: ConsiderationTaxes;

  heatTypeOptions: SelectItem[];
  hasPropertyMigratedOptions: SelectItem[];

  condominiumTypeOptions: SelectItem[];
  occupancyOptions: SelectItem[];

  isRollNumberFieldsVisible: boolean = true;
  disableRegistryOrLt: boolean = false;
  disableParcel: boolean = false;
  disableSection: boolean = false;
  titleInsuranceNoOfUnits: string[];
  isOtherFixedAdjAvailableAndWithAdditionalConsiderationAmount: boolean = false;
  openLegalDescription: boolean = true;
  disableLandTitleFields: boolean = false;
  outOfRange: boolean = true;
  soAdjOtherProrated: SoAdjOtherProrated;
  rollNumbersActions: BurgerMenuExtendedItem[] = [];
  emptyRollNumbersActions: BurgerMenuExtendedItem[] = [];
  addNewTitleRollNumberRequest: boolean = false;

  _matter: Matter;
  manitobaLTTTiers: ManitobaLTTTier[] = [];
  taxRateServiceSub: Subscription;
  tarionRatesSub: Subscription;
  statementAdjustmentDisplayUtil: StatementAdjustmentDisplayBuilder;
  commonExpensesCopiedValue: Number;
  propertyAddressFlag: boolean;

  teraviewLegalDescOverriddenTypes: SelectItem[];

  surveyInCurrentOwnerNameOptions: SelectItem[];
  changeInPropertyAfterSurveyOptions: SelectItem[];
  landTransferTaxRateOptions: SelectItem[] = [];
  landTransferTaxRate: LandTransferTaxRate[] = [];

  depositHeldByOptions: SelectItem[] = [];

  interestToBenefitParcelOptions: SelectItem[] = dropDowns.interestToBenefitParcelOptions;
  interestToBurdenParcelOptions: SelectItem[] = dropDowns.interestToBurdenParcelOptions;
  subjectToCovenantsAndConditionsOptions: SelectItem[] = dropDowns.subjectToCovenantsAndConditionsOptions;

  noYesOptions: SelectItem[] = booleanYesNoDropDowns.NoYes_DefaultNo;
  LINC_TEXT: string = 'LINC';
  PID_TEXT: string = 'PID';
  lincOrPIDSText: string = this.matter?.isMatterProvinceBC ? this.PID_TEXT : this.LINC_TEXT;

  readonly LINC_NUMBER_MAX_LENGTH_BC = LINC_NUMBER_MAX_LENGTH_BC;

  matterUtility: MatterUtility;

  constructor(
    public logger: Logger,
    public contactService: ContactService,
    public contactQueryService: ContactQueryService,
    public childGetsFromParent: GetGlobalSaveModelService,
    public jurisdictionService: JurisdictionService,
    public dialogService: DialogService,
    public matterService: MatterService,
    public errorService: ErrorService,
    public currencyPipe: CurrencyPipe,
    public route: ActivatedRoute,
    public tabsService: TabsService,
    public taxRateService: TaxRateService,
    public documentProfileService: DocumentProfileService,
    public viewContainerRef: ViewContainerRef,
    public documentProfileCache: DocumentProfileCache,
    public insurerService: InsurerService,
    public registryOfficeService: RegistryOfficeService,
    public decimalPipe: DecimalPipe,
    public percentPipe: PercentPipe,
    public authZService: AuthZService,
    public jurisdictionDepartmentsService: JurisdictionDepartmentsService,
    public brokerCommissionComponent: BrokerCommissionComponent,
    public navigateToMatterService: NavigateToMatterService,
    public router: Router,
    public location: Location,
    public contactChangesListener: ContactChangesListener,
    public undertakingsConfigService: UndertakingsConfigService,
    public elementRef: ElementRef,
    private pttService: PttService
  ) {}

  ngOnInit() {
    this.depositHeldByOptions = this.matter
      ? depositHeldByDropdown[this.matter.provinceCode]
      : depositHeldByDropdownOptions.depositHeldByDropdownOptionsDefault;
    this.matter.isPurchaseTypeValueChanged = false;
    this.isOtherFixedAdjAvailableAndWithAdditionalConsiderationAmount =
      StatementAdjustmentUtil.isOtherFixedAdjAvailableAndWithAdditionalConsiderationAmount(
        this.matter.statementOfAdjustments
      );
    // TODO : Fixing Temporray but section should not be update in component.
    // Need to remove this code once Section Component is refactored
    //this.tabService.activeTab.section = 'propertyTeranet/'+this.route.routeConfig.path;
    this.insurers = [];
    this.insurerService
      .getInsurer(false) //supportFullIntegration
      .subscribe((data: Insurer[]) => {
        this.insurers = data['Organizations'];
        if (data && data['Organizations']) {
          this.insurers = data['Organizations'];
        }
      });

    // This is an Observable which will notify the changes in javascript object.
    if (!this.subscription && !this.matter.isCopyInProgress) {
      this.subscription = this.childGetsFromParent.getItem$.subscribe(() => {
        this.openProperty();
        this.openUtility();
        this.loadHomeOwnersResidentFeeSOA();
      });
    }

    // autocomplete
    // Jurisdictions

    this.searchTermJurisdiction = this.refreshSubject(this.searchTermJurisdiction);
    this.searchTermJurisdiction
      .switchMap((term: string) => {
        this.jurisdictionsLoading = true;
        this.jurisdictionAutoCompleteEmptyFlag = false;
        this.jurisdictionDisplayName = term ? term.trim() : null;
        if (term.trim() === '') {
          this.jurisdictionAutoCompleteEmptyFlag = true;
          let observable = Observable.create((observer) => {
            setTimeout(() => {
              observer.next();
            }, 10);
          });
          return observable;
        } else {
          return this.jurisdictionService.getJurisdictions(
            term,
            this.jurisdictionService.page,
            this.jurisdictionService.limit,
            this.jurisdictionService.sortQuery,
            this.jurisdictionService.sortingType,
            undefined,
            this.matter.provinceCode,
            true
          );
        }
      })
      .subscribe(
        (jurisdictions: any[]) => {
          this.jurisdictionsLoading = false;
          this.jurisdictions = jurisdictions;
          if (Array.isArray(this.jurisdictions)) {
            this.jurisdictions.unshift({
              jurisdictionName: this.jurisdictionDisplayName
                ? Constants.ADD_NEW_RECORD + `"${this.jurisdictionDisplayName}"`
                : Constants.ADD_NEW_RECORD
            });
          }
        },
        (error) => {
          this.jurisdictionsLoading = false;
          this.logger.error('jurisdictions search error: ', error);
        }
      );
    this.taxRateService
      .cachedConsiderationTaxRate(this.matter.provinceCode)
      .subscribe((considerationTaxes: ConsiderationTaxes[]) => {
        if (considerationTaxes && considerationTaxes.length > 0) {
          this.considerationTaxes = considerationTaxes;
          this.ontarioTaxRateSlab =
            TaxRateService.getLatestOntarioTaxRateSlabFromConsiderationTaxes(considerationTaxes);
          this.torontoTaxRateSlab =
            TaxRateService.getLatestTorontoTaxRateSlabFromConsiderationTaxes(considerationTaxes);
          this.provinceHstRateSlab = considerationTaxes.find((item) => item.instanceType == Tax_RATE.HST_RATE);
          if (this.matter.soaHst) {
            this.soaConsiderationTaxes = new ConsiderationTaxes();
            if (
              this.matter.isMatterProvinceAB &&
              this.matter.isProjectOrProjectSale &&
              this.matter.isStatementAdjustmentInterimAndFinal()
            ) {
              this.soaConsiderationTaxes.hstRate = this.matter.getSoaHstInSelectedSheet();
              this.soaConsiderationTaxes.hstFederalPortion = this.matter.getSoaFederalHstInSelectedSheet();
              this.soaConsiderationTaxes.hstProvincialPortion = this.matter.getSoaProvincialHstInSelectedSheet();
            } else {
              this.soaConsiderationTaxes.hstRate = this.matter.soaHst;
              this.soaConsiderationTaxes.hstFederalPortion = this.matter.soaFederalHst;
              this.soaConsiderationTaxes.hstProvincialPortion = this.matter.soaProvincialHst;
            }
            this.soaConsiderationTaxes.rateType = this.matter.matterTaxType
              ? this.matter.matterTaxType
              : this.provinceHstRateSlab.rateType;
          } else {
            this.soaConsiderationTaxes = this.provinceHstRateSlab;
          }
        }
      });

    // if Jurisdiction field is already populated, make the Jurisdiction field read-only when matter is initialized
    this.makeJurisdictionReadOnly();

    // Surveyor Auto-complete
    this.surveyorSearchSubject = this.refreshSubject(this.surveyorSearchSubject);
    this.surveyorSearchSubject
      .switchMap((entered: string) => {
        this.surveyorsLoading = true;
        if (!entered || entered.trim() === '') {
          return Observable.create((observer) => {
            setTimeout(() => {
              observer.next();
            }, 10);
          });
        } else {
          return this.contactService.getContactsType(entered.trim(), 'SURVEYOR');
        }
      })
      .subscribe(
        (surveyors: Contact[]) => {
          this.surveyorsLoading = false;
          this.surveyorContacts = surveyors;
        },
        (error) => {
          this.surveyorsLoading = false;
          this.logger.error('surveyors search error: ', error);
        }
      );

    //
    let defaultDocProfile: Observable<DocumentProfile>;
    if (this.matter?.isMatterProvinceBC) {
      let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
      defaultDocProfile = this.documentProfileService.getDefaultProfileForAccountFromCache(
        accountId,
        this.matter.provinceCode
      );
    } else {
      defaultDocProfile = this.documentProfileService.getDefault();
    }

    defaultDocProfile.subscribe((documentProfile: DocumentProfile) => {
      if (
        documentProfile &&
        documentProfile.statementOfAdjustmentsProfile &&
        documentProfile.statementOfAdjustmentsProfile.estTaxIncrease
      ) {
        this.estTaxIncrease = documentProfile.statementOfAdjustmentsProfile.estTaxIncrease;
      }
    });

    this.loadResidentAssociations();
    this.loadRollNumbersActionsBurgerMenu();

    // ngOnInit does not get called on injected components
    this.brokerCommissionComponent.initCommissionPaidToFields();
    this.brokerCommissionComponent.initCommissionBasedSalePrice();
    // SK
    if (
      this.matter.isMatterProvinceSK &&
      this.matter.matterPropertyWithCondo &&
      (!this.matter.matterPropertyWithCondo.parcelLegalDescriptions ||
        this.matter.matterPropertyWithCondo.parcelLegalDescriptions.length == 0)
    ) {
      this.addParcel();
    }

    if (this.propertyModel.isCondominium !== 'YES' && this.legalDescriptionPlanBlockLot) {
      this.openLegalDescription = false;
    }

    this.taxRateService.getManitobaLTTTiers().subscribe((res) => {
      this.manitobaLTTTiers = res;
    });

    this.commonExpensesCopiedValue = this.propertyModel.commonExpenses ? this.propertyModel.commonExpenses : null;

    //Init Project Sale matter related fields
    this.initProjectSale();
    if (this.matter.isMatterProvinceNS) {
      this.calculateLandTransferTax();
      this.calculateRecordings();
      this.propertyModel.calculateNRDTTax();
    }

    if (this.matter.isMatterProvinceNB && this.matter.isPurchase && !this.propertyModel.form54) {
      this.propertyModel.form54 = new Form54();
      this.propertyModel.form54.setDefaults();
    }
    if (!this.propertyModel.address || (this.propertyModel.address && this.propertyModel.address.isEmpty)) {
      this.propertyAddressFlag = true;
    }

    if (!this.propertyModel.propertyStrataMaintenanceFee) {
      this.propertyModel.propertyStrataMaintenanceFee =
        PropertyStrataMaintenanceFee.createDefaultPropertyStrataMaintenanceFee();
    }
  }

  openUtility() {
    const id = this.matter.id;
    this.matterService.getUtility(id).subscribe(
      (res: MatterUtility) => {
        this.matterUtility = res;
      },
      (error: ApplicationError) => {
        return false;
      }
    );
  }

  propagateSoaPurchasePrice() {
    return (
      this.matter &&
      this.matter.considerationLtt &&
      this.matter.considerationLtt.salePriceAdjustment &&
      this.matter.considerationLtt.salePriceAdjustment.getSalePrice(this.provinceHstRateSlab)
    );
  }

  initProjectSale() {
    if (this.matter.isProjectSale) {
      if (this.matter.isMatterProvinceON) {
        this.teraviewLegalDescOverriddenTypes = dropDowns.teraviewLegalDescOverriddenTypes;
        this.disableRegistryOrLt = true;
        if (this.propertyModel.autoCalculateCommonExpense == null) {
          this.propertyModel.autoCalculateCommonExpense = false;
        }
      }
    }
  }

  onAutoCalculateCommonExpense(event) {
    if (event && this.propertyModel.autoCalculateCommonExpense) {
      this.propertyModel.commonExpenses =
        (Number(this.matter.project.commonExpensesForEntireCondo) * Number(this.propertyModel.percentageInterest)) /
        100;
    } else {
      this.propertyModel.commonExpenses = this.commonExpensesCopiedValue;
    }
    this.matter.updatePOTLAdjustmentsWithCommonExpense();
    this.enableSave();
  }

  get matter(): Matter {
    return this._matter ? this._matter : currentMatter.value;
  }

  setLocalInstanceMatter(matter: Matter) {
    this._matter = matter;
  }

  ngOnDestroy(): void {
    //The UFFI Warranty should be set to None if the Purchase is of Vacant Land
    if (
      this.matter &&
      this.matter.matterPropertyWithCondo &&
      this.matter.matterPropertyWithCondo.isPurchaseTypeVacantLand() &&
      this.matter.isPurchaseTypeValueChanged
    ) {
      this.matter.uffiWarrantyCode = 'NONE';
    }
  }

  isInsurerStewartChicagoFCT(): boolean {
    return this.matter && this.matter.isMainTitleInsurer();
  }

  getDefaultQuery(type: string): any {
    return {query: type};
  }

  loadResidentAssociations(): void {
    this.searchTermResidentAssociation = this.refreshSubject(this.searchTermResidentAssociation);
    this.searchTermResidentAssociation
      .switchMap((term: string) => {
        this.residentAssociationLoading = true;
        this.residentAssociationAutoCompleteEmptyFlag = false;
        if (term.trim() === '') {
          this.residentAssociationAutoCompleteEmptyFlag = true;
          let observable = Observable.create((observer) => {
            setTimeout(() => {
              observer.next();
            }, 10);
          });
          return observable;
        } else {
          return this.contactService.getContactsType(term.trim(), 'RESIDENCE_ASSOCIATION', null, null, true);
        }
      })
      .subscribe(
        (residentAssociations: any[]) => {
          this.residentAssociationLoading = false;
          return (this.residentAssociations = residentAssociations);
        },
        (error) => {
          this.residentAssociationLoading = false;
          this.logger.error('resident association search error: ', error);
        }
      );
  }

  // After the view is initialized it will detect the from changes of the form.
  //  after the form change this will class the change method to check the global validation.
  ngAfterViewInit(): void {
    if (this.form) {
      this.form.control.valueChanges.subscribe((values) => this.onValueChanged(values));
    }
    // if(this.selectedSurveyorContact){
    //     this.surveyorAttention = this.selectedSurveyorContact.attention;
    // }
    //console.log("ngAfterViewInit - this.selectedSurveyorContact:", this.selectedSurveyorContact);

    //the pin maybe partial, need to report warning, this apples to ON PS matter - nonCondo
    if (this.matter && this.matter.isMatterProvinceON && this.matter.isProjectSale && !this.matter.isPropertyCondo()) {
      this.verifyPinValues();
    }
  }

  // Todo Error message will be handel for each page.
  onValueChanged(values): void {
    errorMessage.errorMessage = '';
    // for (let prop in this.form.form.controls) {
    //     if (this.form.form.controls[prop]._errors !== null) {
    //         errorMessage.errorMessage = errorMessage.errorMessage + errorProperty['pin'];
    //         matterValidation.propertyValid = false;
    //     }
    // }
  }

  // this method will update the select box values.
  onChange(event): void {
    this.formChangeAfterInit();
  }

  onChangeExceptionWording(event) {
    if (!(this.propertyModel.exceptionType === 'YES')) {
      this.propertyModel.exceptionTypeDescription = '';
    }
    this.onChange(event);
  }

  onRPRChange(event) {
    this.propertyModel.rprApplicable = this.propertyModel.rprApplicable ? false : true;
    this.onChange(event);
  }

  onRegistryOrLTChange(value: string): void {
    this.setParcelSection(value);
    this.updateF9DefaultSoaForNB();
    this.onChange(value);
  }

  onPurchaseTypeChange(event): void {
    let previousCode = this.propertyModel.purchaseIsOfCode;
    this.propertyModel.purchaseIsOfCode = event.target.value;
    this.matter.isPurchaseTypeValueChanged = true;
    this.formChangeAfterInit();
    this.matter.updateDefaultUndertakingForNB(
      previousCode,
      this.undertakingsConfigService.getCachedConfig(this.matter.provinceCode)
    );
    this.checkPurchaseTypeDependencyOnOtherFixedAdjustment();

    // //The UFFI Warranty should be set to None if the Purchase is of Vacant Land
    // if (this.matter.matterPropertyWithCondo && this.matter.matterPropertyWithCondo.isPurchaseTypeVacantLand()) {
    //     this.matter.uffiWarrantyCode='NONE';
    // }

    if (
      this.matter.matterPropertyWithCondo &&
      (this.matter.matterPropertyWithCondo.isPurchaseCommercial() ||
        (this.matter.provinceCode == 'AB' && this.matter.matterPropertyWithCondo.isPurchaseTypeFarmLand())) &&
      this.matter.isTitleInsured() &&
      !this.matter.isOtherTitleInsurer()
    ) {
      let message = '';
      if (this.insurers) {
        // let insurer = this.insurers.find(item => item.organizationName == this.matter.insurer);
        let insurer = _.find(this.insurers, (insuer) => {
          // console.log(">> insuer's organizationName", insuer.organizationName);
          // console.log(">> currentMatterValueInsurer", this.matter.insurer);
          // console.log(">> matched ?", insuer.organizationName == this.matter.insurer);
          return insuer.organizationName == this.matter.insurer;
        });
        if (insurer) {
          if (this.matter.isInsurerFCT()) {
            message =
              'Contact Us<br><br>' +
              'Your Commercial Solutions Team is here to help <br>Monday to Friday 8:00 am ET – 8:00 pm ET.' +
              '<br><br> Phone: 905.287.3112 / 1.866.804.3112' +
              '<br><br>Email: <a href="mailto:commercialsolutions@fct.ca" target="_blank">commercialsolutions@fct.ca</a>' +
              '<br><br><a href="http://www.FCT.ca" target="_blank">FCT.ca</a>';
          } else if (this.matter.isInsurerTitlePlus()) {
            message = new Insurer(insurer).insurerTitlePlusHtmlText;
          } else {
            message = new Insurer(insurer).insurerContactHtmlText;
            message = 'Contact Info<br>' + message;
          }
        }
      }
      setTimeout(() => {
        this.propertyModel.purchaseIsOfCode = previousCode;
        this.formChangeAfterInit();
      }, 10);

      let confirmMsg: string = this.matter.isInsurerTitlePlus()
        ? message
        : 'Commercial title insurance order submission is not currently supported in Unity.  If you wish to proceed' +
          ' with title insuring a commercial property then please select the “Other Title Insurer” option under the Matter' +
          ' Opening topic. <br><br> You can contact ' +
          this.matter.insurer +
          '<br><br>' +
          message;

      this.dialogService.confirm('Warning', confirmMsg, true, null, null, true).subscribe((res) => {
        if (res) {
          // Do Nothing
        }
      });
    }
    if (this.matter.isMatterProvinceSK) {
      this.matter.checkHSTRebateToDisplayWarningMessage(this.errorService);
    }
    if (this.matter.isMatterProvinceNB) {
      this.verifyPinValues();
    }
    if (this.activeMatterTab && this.activeMatterTab.matterComponent) {
      this.activeMatterTab.matterComponent.initializeMatterTopicClosingStatuses();
    }
  }

  checkPurchaseTypeDependencyOnOtherFixedAdjustment(): boolean {
    this.errorService.removeDpFieldError('matter.propertyteranet.soAdj.other.fixed');
    if (
      this.isOtherFixedAdjAvailableAndWithAdditionalConsiderationAmount &&
      this.propertyModel.purchaseIsOfCode != 'NEW_BUILDER_HOME'
    ) {
      this.errorService.addDpFieldError(DPError.createDPError('matter.propertyteranet.soAdj.other.fixed'));
      return true;
    }
    return false;
  }

  // this will put the updated address in the matter model
  // emit  form child address component.modalErrorComponent
  updateAddressFormChild(event): void {
    if (!this.propertyAddressFlag) {
      this.enableSave();
    }
    this.propertyAddressFlag = false;
    this.propertyModel.address = event.value;
    if (this.matter.matterProperties && this.matter.matterPropertyWithCondo) {
      this.matter.matterPropertyWithCondo.address = event.value;
    }
    // Update Title details on puchaser when Address is updated.
    this.matter.generateAndUpdateTitleDetailsAB();
    TitleDetailsUtil.getAndUpdateTitleDetails(this.matter);
  }

  // after any change in property teranet tab values will be set in
  // matter save model
  formChangeAfterInit(): void {
    this.enableSave();

    // this.propertyModel.pin = this.utils.returnValidString(this.pin0) + this.utils.returnValidString(this.pin1);
    // if(this.matter.matterProperties &&
    //     this.matter.matterPropertyWithCondo) {
    //     this.matter.updateMatterPropertyWithCondo(this.propertyModel);
    // } else {
    //     this.matter.matterProperties.push(this.propertyModel);
    // }
  }

  get disablePOTL(): boolean {
    return this.matter && this.matter.project && this.matter.project.potlPlanApplied;
  }

  public updatePropertyTypeList(): void {
    if (this.matter.isMatterProvinceNBorNS) {
      this.propertyTypes = dropDowns.propertyTypeNB;
      if (!this.propertyModel.propertyType) {
        this.propertyModel.propertyType = 'RESIDENTIAL';
      }
    }
    if (this.matter.isInsurerStewartTitle()) {
      if (this.matter.matterPropertyWithCondo && this.matter.matterPropertyWithCondo.isPropertyCondominium()) {
        this.titleInsurancePropertyTypes = dropDowns.titleInsurancePropertyTypeStewart
          .filter((item) => item.value.indexOf('CONDO') > -1 || item.value.indexOf('QUESTION') > -1)
          .slice(0);
      } else {
        this.titleInsurancePropertyTypes = dropDowns.titleInsurancePropertyTypeStewart
          .filter((item) => item.value.indexOf('RESIDENTIAL_CONDO') < 0)
          .slice(0);
      }
      this.titleInsuranceNoOfUnits = ['', '2', '3', '4', '5', '6'];
    } else if (this.matter.isInsurerChicagoTitle()) {
      this.titleInsurancePropertyTypes = dropDowns.titleInsurancePropertyTypeChicago;
      this.titleInsuranceNoOfUnits = ['', '2', '3', '4', '5', '6'];
    } else if (this.matter.isInsurerFCT()) {
      this.titleInsurancePropertyTypes = dropDowns.titleInsurancePropertyTypeFCT;
      this.titleInsuranceNoOfUnits = ['', '2', '3', '4', '5', '6'];
    } else if (this.matter.isInsurerTitlePlus()) {
      this.titleInsurancePropertyTypes = dropDowns.titleInsurancePropertyTypeTitlePlus;
      this.titleInsuranceNoOfUnits = ['', '2', '3', '4', '5', '6'];
    }
    if (!this.propertyModel.titleInsurancePropertyType) {
      this.propertyModel.titleInsurancePropertyType = 'QUESTION';
    }
  }

  setDefaultNoOfUnitsOnPropertyChange() {
    if (
      this.propertyModel.propertyType == 'RESIDENTIAL' ||
      this.propertyModel.propertyType == 'COMMERCIAL_RESIDENTIAL'
    ) {
      this.propertyModel.numOfUnits = '1';
    }
    this.formChangeAfterInit();
  }

  isPropertyTypeMultiUnit(): boolean {
    return this.propertyModel && this.propertyModel.isPropertyTypeMultiUnit();
  }

  isTitleInsurancePropertyTypeMultiUnit(): boolean {
    return this.propertyModel && this.propertyModel.isTitleInsurancePropertyTypeMultiUnit();
  }

  get noOfUnitsLabel(): string {
    return MatterUtil.getNoOfUnitsLabel(this.matter);
  }

  initPropertyDataForAB = (): void => {
    this.landTitleOfficeLocationOptions = [];
    this.landTitleOfficeOptions = [];

    this.registryOfficeService.getAllRegistryOffices(this.matter.provinceCode).subscribe((res: any) => {
      res.forEach((ro) => {
        this.registryOffices.push(ro);
        if (ro.region && this.landTitleOfficeLocationOptions.indexOf(ro.region) === -1) {
          this.landTitleOfficeLocationOptions.push(ro.region);
        }
        if (ro.officeName && this.landTitleOfficeOptions.indexOf(ro.officeName) === -1) {
          this.landTitleOfficeOptions.push(ro.officeName);
        }
      });
      if (this.selectedJurisdiction && this.selectedJurisdiction.registryOffice) {
        this.propertyModel.landTitleOfficeLocation = this.selectedJurisdiction.registryOffice.region; //this.selectedJurisdiction.
        this.propertyModel.registryOfficeName = this.selectedJurisdiction.registryOffice.shortOfficeName;
        if (
          this.landTitleOfficeLocationOptions.indexOf(this.propertyModel.landTitleOfficeLocation) != -1 &&
          this.landTitleOfficeOptions.indexOf(this.propertyModel.registryOfficeName) != -1
        ) {
          this.disableLandTitleFields = true;
        }
      }
    });
  };

  updateLandTitleOfficeName() {
    if (this.matter.isMatterProvinceAB) {
      let registryOfficeSelected = this.registryOffices.find(
        (item) => item.officeName == this.propertyModel.registryOfficeName
      );
      if (registryOfficeSelected) {
        if (!this.propertyModel.registryOffice) {
          this.propertyModel.registryOffice = registryOfficeSelected;
        }
      } else if (!this.propertyModel.landTitleOfficeLocation && this.propertyModel.registryOffice) {
        this.propertyModel.registryOffice = null;
      }
    }
    this.enableSave();
  }

  updateLandTitleOffice() {
    if (this.matter.isMatterProvinceMB) {
      if (this.propertyModel.landTitleOfficeLocation) {
        this.propertyModel.registryOfficeName = this.propertyModel.landTitleOfficeLocation + ' Land Titles Office';
      } else {
        this.propertyModel.registryOfficeName = '';
      }
    } else {
      let registryOfficeSelected = this.registryOffices.find(
        (item) => item.region == this.propertyModel.landTitleOfficeLocation
      );
      if (registryOfficeSelected) {
        this.propertyModel.registryOfficeName = registryOfficeSelected.officeName;
        if (this.matter.isMatterProvinceAB && !this.propertyModel.registryOffice) {
          this.propertyModel.registryOffice = registryOfficeSelected;
        }
      } else {
        this.propertyModel.registryOfficeName = '';
      }
    }
    this.enableSave();
  }

  // TODO: Need to use matter rather than property model
  get propertyModel(): MatterProperty {
    //Move them to matter
    // if (this.matter && !this.matter.matterPropertyWithCondo) {
    //     this.matter.matterProperties.push(MatterProperty.createDefaultMatterProperty());
    // }
    return this.matter && this.matter.matterPropertyWithCondo;
  }

  get propertyUtilityModel(): MatterUtility {
    return this.matter && this.matter.matterUtility;
  }
  // it will check if matter is present then the it will call the set property
  openProperty(): void {
    // Before matter NO inputting, Registration Method has already given "ELETRONIC"
    // setRegistryLt() put outside setPropertyData.
    this.purchaseTypes = dropDowns.purchaseType;
    this.interestEstates = ProvinceBasedInterestEstateOptions[this.matter.provinceCode];
    this.condominiumTypeOptions = dropDowns.condominiumTypeOptions;
    this.occupancyOptions = dropDowns.occupancyOptions;
    // If it is Project Sale matter and ON, keep registryLt same as DPPMP-17776 - T022-E010-U020-080 - Project Record - Documents & Registration
    this.registryLt =
      this.matter.isMatterProvinceNB || (this.matter.isProjectSale && this.matter.isMatterProvinceON)
        ? dropDowns.registryLt.defaultLandTitle
        : dropDowns.registryLt.default;
    this.farmlandEvidenceOptions = dropDowns.farmlandEvidenceOptions;
    this.titleSearchModel = dropDowns.yesNo;
    this.condoOptions = dropDowns.isCondoOptions;
    this.nRDTTaxOptions = dropDowns.isNRDTTaxOptions;
    this.vendorToSupplyRPROptions = dropDowns.isVendorToSupplyRPROptions;
    this.exceptionTypeOptions = dropDowns.isExceptionTypeOptions;
    this.exceptionDescriptionOptions = dropDowns.isExceptionDescriptionOptions;

    this.heatTypeOptions = dropDowns.heatTypesNS;
    this.hasPropertyMigratedOptions = dropDowns.hasPropertyMigratedOptions;

    //do some extra data population which are province specific
    let provinceBasedInitFunc = this.provinceBasedInitTask[this.matter.provinceCode];
    if (provinceBasedInitFunc) {
      provinceBasedInitFunc();
    }

    this.parcelOfTiedLandOptions = dropDowns.isParcelOfTiedLandOptions;
    this.surveyInCurrentOwnerNameOptions = dropDowns.yesNoBlankOptions;
    this.changeInPropertyAfterSurveyOptions = dropDowns.yesNoBlankOptions;

    this.rollNumber = RollNumber.createDefaultRollNumber();

    if (!this.matter.considerationLtt) {
      this.matter.createNewConsiderationLtt();
    }
    if (this.matter.matterPropertyWithCondo) {
      this.setPropertyData();
    } else {
      // this.propertyModel = MatterProperty.createDefaultMatterProperty();
      this.setRegistryLt();
    }
    this.updatePropertyTypeList();
    this.setDefaultValuesForProvince();
  }

  async loadLandTransferTaxRates(provinceCode: string): Promise<void> {
    this.landTransferTaxRate = await this.taxRateService.getLandTransferTaxRates(provinceCode).toPromise();
    this.populateLandTransferTaxOptions();
  }

  populateLandTransferTaxOptions() {
    this.landTransferTaxRate.forEach((item) => {
      let landTransferTaxRateExists = this.landTransferTaxRateOptions.find((option) => option.value == item.id);
      if (!landTransferTaxRateExists) {
        this.landTransferTaxRateOptions.push({label: this.getLandTransferTaxRateTypeLabel(item), value: item.id});
      }
    });
  }

  getLandTransferTaxRateTypeLabel(landTransferTaxRate: LandTransferTaxRate): string {
    return (
      'On/After ' +
      moment(landTransferTaxRate.effectiveDate).format('MMM DD YYYY') +
      ' @ ' +
      landTransferTaxRate.taxRate +
      ' %'
    );
  }

  calculateLandTransferTax(): void {
    if (this.matter.isMatterProvinceNS) {
      this.matter.calculateLandTransferTaxNS();
    } else {
      if (this.propertyModel.landTransferTaxRateId) {
        let landTransferTaxRate = this.landTransferTaxRate.find(
          (item) => item.id == this.propertyModel.landTransferTaxRateId
        );
        if (landTransferTaxRate) {
          this.matter.propertyModel.landTransferTaxRate = landTransferTaxRate;
          this.matter.calculateLandTransferTaxNB();
        }
        if (this.matter && this.matter.soaTrustLedgerCollection && this.matter.isMatterProvinceNB) {
          this.matter.soaTrustLedgerCollection.updateLttNB();
        }
      }
    }
  }

  calculateRecordings(): void {
    if (this.matter) {
      this.matter.calculateRecordings();
    }
  }

  handleDropdownClickExceptionTypeDescription = () =>
    (this.filteredExceptionTypeOptions = this.exceptionDescriptionOptions.map((item) => item.label));

  filterExceptionTypeDescription(event) {
    this.filteredExceptionTypeOptions = this.exceptionDescriptionOptions
      .map((item) => item.label)
      .filter((label) => label && label.toLowerCase().indexOf(event.query.toLowerCase()) == 0);
  }

  onPlanBlockLotSelection() {
    this.propertyModel.propertyDescriptionType = 'PLAN_BLOCK_LOT';
    this.propertyModel.shortLegalDescription = '';
    this.propertyModel.fullLegalDescription = '';
    this.propertyModel.exceptionTypeDescription = '';
    this.enableSave();
  }

  onMetisAndBoundSelection() {
    this.propertyModel.propertyDescriptionType = 'METES_AND_BOUNDS';
    this.propertyModel.plan = '';
    this.propertyModel.lot = '';
    this.propertyModel.block = '';
    this.propertyModel.exceptionType = '';
    this.propertyModel.exceptionTypeDescription = '';
    this.enableSave();
  }

  setDefaultValuesForProvince() {
    this.setDefaultValuesForAB();
    this.setDefaultValuesForSK();
    this.setDefaultValuesForMB();
    this.setDefaultValuesForNB();
    this.setDefaultValuesForNS();
  }

  setDefaultValuesForAB(): void {
    if (this.matter.provinceCode == 'AB') {
      // Do not use double equals ("==") to check if a value is undefined:
      //  It creates a bug when inserting lender EMP lender data to a new matter - "==" causes the "if" condition to be true for new matter
      //  and hence clears the exceptionTypeDescription even if user has entered a value in the "Insert Lender Data" dialog.
      // TODO do we need a better condition for this check?
      if (this.propertyModel.isVendorToSupplyRPR === undefined) {
        this.propertyModel.isVendorToSupplyRPR = 'N_y';
        if (!this.matter.isProjectSale) {
          //wrap the following line into PS matter condition, since for Project Sale matter, the exceptionTypeDescription value comes from project
          this.propertyModel.exceptionTypeDescription = '';
        }
      }
      this.propertyModel.landTitleOfficeLocation = this.propertyModel.landTitleOfficeLocation
        ? this.propertyModel.landTitleOfficeLocation
        : '';
      this.propertyModel.registryOfficeName = this.propertyModel.registryOfficeName
        ? this.propertyModel.registryOfficeName
        : '';
      this.propertyModel.isParcelOfTiedLand = 'N_y';
    }
  }

  setDefaultValuesForSK(): void {
    if (this.matter.isMatterProvinceSK) {
      if (this.propertyModel.occupancy == undefined) {
        this.propertyModel.occupancy = 'CLIENTS';
      }
      if (this.propertyModel.isVendorToSupplyRPR == undefined) {
        this.propertyModel.isVendorToSupplyRPR = 'N_y';
      }
      if (this.propertyModel.condominiumType == undefined) {
        this.propertyModel.condominiumType = 'CONVENTIONAL_CONDOMINIUM_PLAN';
      }
    }
  }

  setDefaultValuesForMB(): void {
    if (this.matter.isMatterProvinceMB) {
      if (!this.propertyModel.farmlandEvidence) {
        this.propertyModel.farmlandEvidence = 'NOT_FARM_LAND';
      }
      this.landTitleOfficeLocationOptions = [
        'Winnipeg',
        'Portage la Prairie',
        'Morden',
        'Brandon',
        'Dauphin',
        'Neepawa'
      ];
      if (!this.propertyModel.landTitleOfficeLocation) {
        this.propertyModel.landTitleOfficeLocation = '';
      }
    }
  }

  setDefaultValuesForNB(): void {
    if (this.matter.isMatterProvinceNB) {
      if (!this.propertyModel.surveyInCurrentOwnersName) {
        this.propertyModel.surveyInCurrentOwnersName = 'BLANK';
      }
      if (!this.propertyModel.changeInPropertyAfterSurvey) {
        this.propertyModel.changeInPropertyAfterSurvey = 'BLANK';
      }
      if (!this.propertyModel.assessedValue) {
        this.propertyModel.assessedValue = 0;
      }
      if (!this.isPropertyTypeSelected()) {
        this.propertyModel.propertyType = 'RESIDENTIAL';
        this.propertyModel.numOfUnits = '1';
      }

      this.loadLandTransferTaxRates(this.matter.provinceCode);
    }
  }

  setDefaultValuesForNS(): void {
    if (this.matter.isMatterProvinceNS) {
      if (!this.isPropertyTypeSelected()) {
        this.propertyModel.propertyType = 'RESIDENTIAL';
        this.propertyModel.numOfUnits = '1';
      }
    }
  }

  isPropertyTypeSelected(): boolean {
    return (
      this.propertyModel &&
      (this.propertyModel.propertyType === 'RESIDENTIAL' ||
        this.propertyModel.propertyType === 'COMMERCIAL' ||
        this.propertyModel.propertyType === 'COMMERCIAL_RESIDENTIAL')
    );
  }

  // at the time of edit matter this method will populate the values in form fields.
  setPropertyData(): void {
    //   this.enableSave();

    if (this.matter.matterProperties && this.matter.matterPropertyWithCondo) {
      let selectedProperty = this.propertyModel;
      if (selectedProperty.soaPurchasePrice) {
        this.propertyModel.soaPurchasePrice = selectedProperty.soaPurchasePrice;
      } else {
        this.propertyModel.soaPurchasePrice = 0;
      }
      if (selectedProperty.purchasePriceType) {
        this.propertyModel.purchasePriceType = selectedProperty.purchasePriceType;
        if (this.propertyModel.purchasePriceType == 'SALE_PRICE_AS_IN_SOA') {
          if (selectedProperty.purchasePrice) {
            this.propertyModel.purchasePrice = selectedProperty.soaPurchasePrice;
          } else {
            this.propertyModel.purchasePrice = 0;
          }
        }
      } else {
        this.propertyModel.purchasePriceType = undefined;
      }
      // Todo validation. Jira ticket is DPPMP-2228
      // this.propertyModel.purchasePrice = this.utils.removeCurrencySymbol(this.propertyModel.purchasePrice);

      if (isNaN(this.propertyModel.depositAmount)) {
        this.propertyModel.depositAmount = 0;
      }

      if (!this.propertyModel.percentageInterest || this.propertyModel.percentageInterest == null) {
        this.propertyModel.percentageInterest = ZERO_INTEREST;
      }

      // let propertyTax = new MatterTax();
      // propertyTax.taxType = this.propertyModel.taxType;
      // propertyTax.baseTaxAdjustmentTaxBillType = this.propertyModel.baseTaxAdjustmentTaxBillType;
      // propertyTax.estimatedPercentageIncrease = this.propertyModel.estimatedPercentageIncrease;
      // propertyTax.totalTaxes = this.propertyModel.totalTaxes;
      // propertyTax.vendorPaidFullAmount = this.propertyModel.vendorPaidFullAmount;
      // propertyTax.vendorWillHavePaid = this.propertyModel.vendorWillHavePaid;
      // propertyTax.vendorShareAmount = this.propertyModel.vendorShareAmount;
      // propertyTax.tippInstallment = this.propertyModel.tippInstallment;
      // this.propertyModel.propertyTaxes = propertyTax;

      // this.utils.removeCurrencySymbol(this.propertyModel.purchasePrice);
      // this.propertyModel.purchasePrice = this.utils.addedCurrencySymbol(this.propertyModel.purchasePrice);

      // after the view is loaded this function is called and set the partial date.
      setTimeout(() => {
        this.setPartialDatesEditMatter(selectedProperty.surveyDate);
        //this.setPartialDatesEditMatter(selectedProperty.fiscalYearEndDate); removed for DPPMP-24409 by MN
      }, 10);

      // DPPMP-2180
      this.selectedJurisdiction = this.matter.matterPropertyWithCondo.jurisdiction;

      if (selectedProperty.rollNumber == null) {
        this.rollNumber = RollNumber.createDefaultRollNumber();
      }

      if (selectedProperty.rollNumberType && selectedProperty.rollNumberType != ROLL_NUMBER_ASSIGNED) {
        this.propertyModel.rollNumberType = selectedProperty.rollNumberType;
        let rollNumberTypeIndex: number = _.findIndex(
          dropDowns.rollNumberType,
          (opt) => opt.value === this.propertyModel.rollNumberType
        );
        this.rollNumberType = dropDowns.rollNumberType[rollNumberTypeIndex].label;
        this.isRollNumberFieldsVisible = false;
      }

      if (!this.utils.isEmptyString(selectedProperty.pin)) {
        this.propertyModel.pin = selectedProperty.pin.replace('-', '');
      } else {
        this.propertyModel.pin = '';
      }

      this.address = selectedProperty.address;
      // this.address.province = this.address.provinceName;
      this.propertyModel.address = selectedProperty.address;

      // this code will removed when api works as expected.
      // TODO now we are putting nameOnDocuments as organizationName this will be changes in future.
      // if (selectedProperty.surveyor != null && selectedProperty.surveyor.contact != null) {
      //     if (selectedProperty.surveyor.contact.contactType === 'SURVEYOR') {
      //         if (this.selectedSurveyorContact) {
      //             this.selectedSurveyorContact.organizationName = selectedProperty.surveyor.contact.organizationName;
      //         }
      //     }
      //
      //     if (selectedProperty.surveyor.contact.contactType === 'PERSON') {
      //         this.setOrganizationNameForSelectedSurveyor(selectedProperty.surveyor.contact);
      //     }
      // }
      this.matter.updateMatterPropertyFields();

      if (this.matter.matterParticipants) {
        for (let i = 0; i < this.matter.matterParticipants.length; i++) {
          if (this.matter.matterParticipants[i].matterParticipantRole == 'SURVEYOR') {
            let item = this.matter.matterParticipants[i].contact as any; //Disable typing here temporarily, as the contact model
            // does not have all the properties defined (on the client side structure) TODO: look into fixing this
            if (item.subContacts.length > 0) {
              let fName = Utils.returnValidString(item.subContacts[0].contactName.firstName);
              let mName = Utils.returnValidString(item.subContacts[0].contactName.middleName);
              item.selectedName = `${fName}${mName ? ' ' + mName : ''} ${Utils.returnValidString(
                item.subContacts[0].contactName.lastName
              )} (${item.organizationName})`;
            } else {
              item.selectedName = `${item.organizationName}`;
            }
            this.selectedSurveyorContact = item;
            this.selectedSurveyorContactAttention = item.attention;
            break;
          }

          if (this.matter.matterParticipants[i].matterParticipantRole == 'RESIDENCE_ASSOCIATION') {
            let item = this.matter.matterParticipants[i].contact as any; //Disable typing here temporarily, as the contact model
            this.selectedResidentAssociation = item;
            break;
          }
        }
        if (!this.matter.getMatterParticipantByRole('SURVEYOR')) {
          this.selectedSurveyorContact = null;
        }
        if (!this.matter.getMatterParticipantByRole('RESIDENCE_ASSOCIATION')) {
          this.selectedResidentAssociation = null;
        }
      }

      //this.matter.updateUnitLevelPlanCreated(this.matter.matterPropertyWithCondo);
    }
    //Becase teh reference of this.propertyModel is changed, setRegistryLt()  should be call again.
    this.setRegistryLt();
  }

  setParcelSection(value: string): void {
    switch (value) {
      case constValues.registryLt.registry:
        this.propertyModel.parcel = null;
        this.propertyModel.section = null;
        this.disableParcel = true;
        this.disableSection = true;
        break;
      default:
        this.disableParcel = false;
        this.disableSection = false;
        break;
    }
  }

  setRegistryLt(): void {
    let localMatter = currentMatter;
    if (localMatter && localMatter.value) {
      if (localMatter.value.isProjectSale && localMatter.value.isMatterProvinceON) {
        this.disableRegistryOrLt = true;
        this.propertyModel.registryOrLt =
          this.matter.project &&
          this.matter.project.docRegistration &&
          this.matter.project.docRegistration.registryOrLt;
      } else if (localMatter.value.isMatterProvinceNS) {
        this.disableRegistryOrLt = false;
        this.registryLt = dropDowns.registryLt.default;
      } else if (localMatter.value.isMatterProvinceNB) {
        this.disableRegistryOrLt = false;
        this.registryLt = dropDowns.registryLt.defaultLandTitle;
      } else {
        this.disableRegistryOrLt = false;
        switch (localMatter.value.registrationMethodCode) {
          case constValues.registrationMethodsType.electronic:
            this.disableRegistryOrLt = true;
            this.propertyModel.registryOrLt = constValues.registryLt.landTitles;
            this.registryLt = dropDowns.registryLt.electronic;
            break;
          case constValues.registrationMethodsType.manual:
            this.registryLt = dropDowns.registryLt.manual;
            break;
          default:
            this.registryLt = dropDowns.registryLt.default;
            break;
        }
      }
    }
    this.setParcelSection(this.propertyModel.registryOrLt);
  }

  get registryOfficeLabel() {
    return this.matter
      ? this.matter.isMatterProvinceON
        ? 'Registry Office'
        : this.matter.isMatterProvinceNBorNS && this.propertyModel.registryOrLt == 'REGISTRY'
          ? 'Registry Office'
          : 'Land Titles Office'
      : '';
  }

  setOrganizationNameForSelectedSurveyor(person: Contact): void {
    let completeAddress: string = '';
    let personName: string = '';

    /*
         //Unclear why this is here, the contact does not have an address (yet??)
         if (person.address.length > 0) {

         let address: any = person.address;
         let addressArray: string[] = [];

         completeAddress = this.utils.returnValidString(address[0].addressLine1) +
         this.utils.getNameWithComma(address[0].addressLine2) +
         this.utils.getNameWithComma(address[0].city) +
         this.utils.getNameWithComma(address[0].postalCode) +
         this.utils.getNameWithComma(address[0].provinceName);
         }*/

    if (person.contactName) {
      personName = ` (${person.getFullNameForContact(true, true)}) `;
    }

    // Surveyor Name (Contact’s Name in the format Last Name,
    // First Name Middle Name if present) - Address.
    this.selectedSurveyorContact.organizationName = person.organizationName + personName + completeAddress;
  }

  // PartialDate
  // SurveyDate partialDate set the date
  setPartialDatesEditMatter(date): void {
    if (this.partialSurveyorDateComponent) {
      if (!this.utils.isEmptyString(date)) {
        let values: string[] = date.split('/');
        this.partialSurveyorDateComponent.verifyDateInput(values[2], 'day');
        this.partialSurveyorDateComponent.verifyDateInput(values[1], 'month');
        this.partialSurveyorDateComponent.verifyDateInput(values[0], 'year');
      } else {
        this.partialSurveyorDateComponent.setValues('', '', '');
      }
    }
  }

  onDateChangeSurveyDate(event): void {
    const tmpDate = event.rawDate;
    if (this.propertyModel.surveyDate !== tmpDate) {
      this.propertyModel.surveyDate = tmpDate;
      this.surveyDateSamplingMessage = event.message;
      this.enableSave();
    }
  }

  onDateChangeZoningMemorandumDate(event): void {
    const tmpDate = event.rawDate;
    if (this.propertyModel.zoningMemorandum !== tmpDate) {
      this.propertyModel.zoningMemorandum = tmpDate;
      this.enableSave();
    }
  }

  onDateChangeFiscalYearEndDate(event): void {
    const tmpDate = event.rawDate;
    if (this.propertyModel.fiscalYearEndDate !== tmpDate) {
      this.enableSave();
      this.propertyModel.fiscalYearEndDate = event.rawDate;
      this.onFiscalYearEndDateUpdate();
    }
  }

  onFiscalYearEndDateUpdate(): void {
    if (this.soAdjOtherProrated) {
      this.soAdjOtherProrated.expiryDate = this.propertyModel.fiscalYearEndDate;
      this.soAdjOtherProrated.commencementDate = this.calculateCommencementDate();
    }

    this.loadHomeOwnersResidentFeeSOA(true);
  }

  changeAnnualFee(): void {
    if (this.soAdjOtherProrated) {
      this.soAdjOtherProrated.amountToBeProrated = this.propertyModel.annualFee;
    }
    this.loadHomeOwnersResidentFeeSOA(true);
  }

  // CREATE  Surveyor Object
  // createMatterSurveyor(matterParticipantId, id: number): void {
  //
  //     let surveyor = {
  //         matterParticipantId: matterParticipantId,
  //         contact: {
  //             id: id
  //         }
  //     };
  //
  //     if (this.matter.matterProperties
  //         && this.matter.matterPropertyWithCondo) {
  //         this.matter.matterPropertyWithCondo.surveyor = <Surveyor>surveyor;
  //     } else {
  //         this.propertyModel.surveyor = surveyor;
  //         this.matter.matterProperties.push(this.propertyModel);
  //     }
  // }

  // autocomplete Jurisdiction
  jurisdictionHelpText(): void {
    if (
      this.selectedJurisdiction === undefined ||
      this.selectedJurisdiction === null ||
      this.selectedJurisdiction.toString() === ''
    ) {
      this.searchTermJurisdiction.next('');
    }
  }

  isNotCondo(): boolean {
    return MatterPropertyUtil.isNotCondo(this.propertyModel);
  }

  isAttentionApplicable(): boolean {
    return (
      (this.matter.isMatterProvinceON && this.isNotCondo()) ||
      ((this.matter.isMatterProvinceAB || this.matter.isMatterProvinceSK || this.matter.isMatterProvinceMB) &&
        this.propertyModel &&
        !this.isNotCondo() &&
        this.propertyModel.rprApplicable) ||
      ((this.matter.isMatterProvinceAB || this.matter.isMatterProvinceSK || this.matter.isMatterProvinceMB) &&
        this.propertyModel &&
        this.isNotCondo())
    );
  }

  isSurveyDateAndSurveyorApplicableForProvince(): boolean {
    return (
      (this.matter.isMatterProvinceON &&
        this.propertyModel &&
        !this.matter.isMatterTypeDischarge &&
        this.propertyModel.isCondominium !== 'YES') ||
      ((this.matter.isMatterProvinceAB || this.matter.isMatterProvinceSK || this.matter.isMatterProvinceMB) &&
        this.propertyModel &&
        !this.isNotCondo() &&
        this.propertyModel.rprApplicable) ||
      ((this.matter.isMatterProvinceAB || this.matter.isMatterProvinceSK || this.matter.isMatterProvinceMB) &&
        this.propertyModel &&
        !this.matter.isMatterTypeDischarge &&
        this.isNotCondo()) ||
      (this.matter.isMatterProvinceNS && !this.matter.isMatterTypeDischarge) ||
      (this.matter.isMatterProvinceNB && !this.matter.isMatterTypeDischarge)
    );
  }

  // Code for omnibar search jurisdiction
  searchJurisdiction(event): void {
    let entered: string = event.query;
    this.searchTermJurisdiction.next(entered);
  }

  searchResidentAssociation(event): void {
    let entered: string = event.query;
    this.searchTermResidentAssociation.next(entered);
  }

  dataFromSelectedJurisdictionForAB = (): void => {
    if (this.selectedJurisdiction && this.selectedJurisdiction.registryOffice) {
      //US17761, if AB, and selected Jurisdiction contains RO data, then prepopulate the data in matter property and disable the relevant fields
      this.propertyModel.landTitleOfficeLocation = this.selectedJurisdiction.registryOffice.region; //this.selectedJurisdiction.
      this.propertyModel.registryOfficeName = this.selectedJurisdiction.registryOffice.shortOfficeName;
      if (
        this.landTitleOfficeLocationOptions.indexOf(this.propertyModel.landTitleOfficeLocation) != -1 &&
        this.landTitleOfficeOptions.indexOf(this.propertyModel.registryOfficeName) != -1
      ) {
        this.disableLandTitleFields = true;
      }
    }
  };

  dataSelectedResidentAssociation(): void {
    let fieldKey = this.residentAssociationOmnibar.el.nativeElement.getAttribute('fieldKey');
    this.errorService.removeDpFieldError(fieldKey);
    if (this.selectedResidentAssociation.id === undefined) {
      if (
        this.selectedResidentAssociation.displayName &&
        this.selectedResidentAssociation.displayName.indexOf(Constants.NO_RESULTS_FOUND) > -1
      ) {
        this.selectedResidentAssociation = null;
      } else {
        let residentAssociation = new Contact();
        residentAssociation.description = this.selectedResidentAssociation.displayName;
        this.dialogService.matDialogContent({
          content: CommonContactDialogComponent,
          context: {
            matter: this.matter,
            contactType: 'RESIDENCE_ASSOCIATION',
            contactName: this.selectedResidentAssociation.displayName
          },
          onFulfillment: (result: any) => {
            this.errorService.removeDpFieldError(fieldKey);
            if (result) {
              if (result.action === 'save') {
                //if contact modal is closed after save
                this.selectedResidentAssociation = new Contact(result.contact);
                this.setMatterParticipantResidenceAssociation();
              } else {
                //if contact modal is closed after cancel
                this.selectedSurveyorContact = null;
              }
            }
          }
        });
      }
    } else {
      this.setMatterParticipantResidenceAssociation();
    }
    this.dialogService.setFocusOnAutocomplete('autocomplete-surveyor', true);
  }

  cleanJurisdiction() {
    this.selectedJurisdiction = undefined;
    this.propertyModel.registryOfficeName = '';
    this.propertyModel.registryOffice = undefined;
    this.propertyModel.city = '';
    this.propertyModel.municipality = '';
  }

  updateJurisdiction() {
    this.errorService.removeDpFieldError('matter.propertyteranet.jurisdiction');
    this.jurisdictionReadOnly = true;
    // if jurisdiction is selected, populate these fields from here
    if (this.selectedJurisdiction.registryOffice) {
      this.propertyModel.registryOfficeName = this.selectedJurisdiction.registryOffice.officeName;
      this.propertyModel.landTitleOfficeLocation = this.selectedJurisdiction.registryOffice.region;
    }
    this.propertyModel.registryOffice = this.selectedJurisdiction.registryOffice;
    if (this.selectedJurisdiction.city) {
      this.propertyModel.city = this.selectedJurisdiction.city.name;
      if (this.selectedJurisdiction.city.municipality) {
        this.propertyModel.municipality = this.selectedJurisdiction.city.municipality.name;
      }
    }
    //call the province-specific function
    let provinceBasedDataPopulationFunc = this.provinceBasedDataPopulationFunc[this.matter.provinceCode];
    if (provinceBasedDataPopulationFunc) {
      provinceBasedDataPopulationFunc();
    }
    this.updatePropertyTaxPaidTrustLedger();
  }

  // On jurisdiction input data select event
  async dataSelectedJurisdictionAction(): Promise<void> {
    // DPPMP-2180
    try {
      if (this.matter.matterProperties.length > 0) {
        this.matter.matterPropertyWithCondo.jurisdiction = this.selectedJurisdiction;
      }
      this.matter.updateMLTTAndLTTOnJurisdictionChange(this.ontarioTaxRateSlab, this.torontoTaxRateSlab);
    } catch (error) {
      console.error('Error: property-teranet : ', error);
    }
    await this.updateCompliancesAfterJurisdictionChange();
    this.dialogService.setFocusOnAutocomplete('autocomplete-jurisdiction', true);

    if (this.matter.isMatterProvinceNS) {
      this.calculateLandTransferTax();
      if (this.matter.soaTrustLedgerCollection) {
        this.matter.soaTrustLedgerCollection.updateLttNS();
      }
    }
    this.enableSave();
  }

  public openJurisdictionModal(jurisdictionName: string) {
    let jurisdiction = new Jurisdiction();
    jurisdiction.jurisdictionName = jurisdictionName && jurisdictionName.trim();

    const routeBase: string = 'main/contacts';
    // jurisdiction.departments = [];
    // if(privateJurisdiction.departments && privateJurisdiction.departments.length > 0)
    // {
    //     privateJurisdiction.departments.forEach( item =>{
    //         let department = new Department();
    //         Department.copyDepartmentData(item, department);
    //         jurisdiction.departments.push(department);
    //     })
    // }
    jurisdiction.private = true;
    jurisdiction.provinceCode = this.matter.provinceCode;
    const jurisdictionTab = JurisdictionTab.createJurisdictionTab(jurisdiction, routeBase + '/' + 'jurisdiction');
    jurisdictionTab.isCreatedForModal = true;
    this.dialogService.matDialogContent({
      fullScreen: true,
      content: JurisdictionModalComponent,
      context: {
        jurisdictionTab: jurisdictionTab,
        defaultProvinceCode: this.matter.provinceCode //launch within the matter, use the provinceCode of the matter
        //if launch outside the matter, then would use the account's defaultProvinceCode
      },
      onFulfillment: (result) => {
        if (result === ProcessJurisdictionActions.SAVE) {
          this.selectedJurisdiction = new Jurisdiction(jurisdictionTab.jurisdiction);
          this.updateJurisdiction();
        } else {
          this.cleanJurisdiction();
        }
        this.dataSelectedJurisdictionAction();
      }
    });
  }

  // On jurisdiction input data select event
  async dataSelectedJurisdiction(): Promise<void> {
    if (this.selectedJurisdiction.id === undefined) {
      if (
        this.selectedJurisdiction.jurisdictionName &&
        this.selectedJurisdiction.jurisdictionName.indexOf(Constants.ADD_NEW_RECORD) != -1
      ) {
        this.openJurisdictionModal(this.jurisdictionDisplayName);
      } else {
        this.cleanJurisdiction();
        this.dataSelectedJurisdictionAction();
      }
    } else {
      //loading full Jurisdiction here
      this.selectedJurisdiction = await this.jurisdictionService
        .getReadonlyJurisdiction(this.selectedJurisdiction.id)
        .toPromise();
      this.updateJurisdictionAndAction();
    }
  }

  updateJurisdictionAndAction() {
    this.updateJurisdiction();
    this.dataSelectedJurisdictionAction();
  }

  public async updateCompliancesAfterJurisdictionChange(): Promise<void> {
    //Getting both, the new jurisdiction and default configs from the back end which are needed to update compliances.
    let result = await Observable.forkJoin(
      this.jurisdictionDepartmentsService.getDepartmentPairs(null, this.selectedJurisdiction.provinceCode),
      this.jurisdictionService.getReadonlyJurisdiction(this.selectedJurisdiction.id)
    ).toPromise();

    if (result && result.length == 2) {
      let jurisdictionDepartments: JurisdictionDepartment[] = result[0];
      let newJurisdiction: Jurisdiction = result[1];
      //No change to compliances if new jurisdiction has no associated departments
      if (newJurisdiction.departments && newJurisdiction.departments.length) {
        this.matter.jurisdictionChange(jurisdictionDepartments, newJurisdiction);
      }
      if (this.matter.soaTrustLedgerCollection) {
        this.matter.soaTrustLedgerCollection.addOrUpdateCompliance();
        if (this.matter.secondarySoaSheetsCollection) {
          this.matter.secondarySoaSheetsCollection.forEach((collection) => {
            collection.addOrUpdateCompliance();
          });
        }
      }
    }
  }

  //Handle a jurisdiction being removed to update the compliances
  public jurisdictionDeleted(jurisdictionDepartments: JurisdictionDepartment[]): void {
    let configCompliances = Compliance.jurisdictionDepartmentsToCompliances(
      jurisdictionDepartments,
      this.matter.matterType
    );

    //If Jurisdiction removed, must fall back to default compliances (keeping some edited values)
    for (let configCompliance of configCompliances) {
      let matterCompliance: Compliance = this.matter.matterCompliances.find(
        (compliance) => compliance.departmentPriority === configCompliance.departmentPriority
      );

      // Existing compliance
      if (matterCompliance) {
        matterCompliance.writeTo = configCompliance.writeTo;
        //Once jurisdiction is deleted, Hydro/Water no longer combined.
        if (matterCompliance.departmentName == Combined_Hydro_Water_Department) {
          matterCompliance.departmentName = Water_Department;
        }
      } else {
        // New Compliance
        this.matter.matterCompliances.push(new Compliance(configCompliance));
      }
    }

    //Remove compliances that are not in the default compliances
    this.removeCompliances(configCompliances);
    this.matter.matterCompliances = _.sortBy(this.matter.matterCompliances, ['departmentPriority']);
  }

  //Remove compliances that are not needed (i.e. not in new jurisidiction or not in default configs)
  public removeCompliances(keepDepartments: any[]): void {
    this.matter.matterCompliances = this.matter.matterCompliances.filter((compliance) =>
      keepDepartments.find((keepDepartment) => keepDepartment.departmentPriority === compliance.departmentPriority)
    );
  }

  deleteResidentAssociation(): void {
    this.dialogService
      .confirm('Confirmation', "Proceed to remove this Resident's Association from this matter?", false, 'Delete')
      .subscribe((res) => {
        if (res) {
          this.clearResidentAssocaitionFromMatterProperty();
        }
      });
  }

  clearResidentAssocaitionFromMatterProperty(): void {
    this.enableSave();
    const tmpResidentAssociationMatterParticipant = this.matter.residentAssociationMatterParticipant;
    if (tmpResidentAssociationMatterParticipant) {
      this.matter.removeMatterParticipant(tmpResidentAssociationMatterParticipant);
    }
    this.selectedResidentAssociation = null;
    this.residentAssociationReadOnly = false;
    this.propertyModel.annualFee = 0;
    this.propertyModel.instrumentNumber = '';
    this.propertyModel.fiscalYearEndDate = '//';
    this.propertyModel.purchaserShareAmount = 0;
    this.dialogService.setFocusOnAutocomplete('autocomplete-residentAssociation');
    this.removeAdjHomeOwnersResidentFee();
    this.soAdjOtherProrated = null;
  }

  removeAdjHomeOwnersResidentFee(statementAdjustment?: StatementAdjustment): void {
    let statementAdjustmentToBeRemoved: StatementAdjustment;
    if (statementAdjustment) {
      statementAdjustmentToBeRemoved = statementAdjustment;
    } else {
      statementAdjustmentToBeRemoved =
        this.matter.finalStatementAdjustments &&
        this.matter.finalStatementAdjustments.find((item) => {
          return item.itemKey == StatementAdjustmentKey.HOME_OWNERS_RESIDENT_FEE && !item.addedFlag;
        });
    }
    if (statementAdjustmentToBeRemoved) {
      let statementAdjustmentIndex: number = this.matter.finalStatementAdjustments.findIndex(
        (item) => item == statementAdjustmentToBeRemoved || item.id == statementAdjustmentToBeRemoved.id
      );
      this.matter.finalStatementAdjustments.splice(statementAdjustmentIndex, 1);
    }
  }

  deleteJurisdiction(): void {
    this.dialogService
      .confirm(
        'Confirmation',
        'Proceed to remove the Jurisdiction and related fields from this matter?',
        false,
        'Delete'
      )
      .subscribe((res) => {
        if (res == true) {
          this.removeJurisdictionRelatedFields();
          if (this.matter.isMatterProvinceNS) {
            this.calculateLandTransferTax();
            if (this.matter.soaTrustLedgerCollection) {
              this.matter.soaTrustLedgerCollection.updateLttNS();
            }
          }
        }
      });
  }

  removeJurisdictionRelatedFields(): void {
    try {
      this.jurisdictionReadOnly = false;
      this.disableLandTitleFields = false;
      this.propertyModel.registryOfficeName = '';
      this.propertyModel.landTitleOfficeLocation = '';
      this.propertyModel.registryOffice = undefined;
      this.propertyModel.city = '';
      this.propertyModel.municipality = '';
      if (this.matter.matterProperties.length > 0) {
        this.matter.matterPropertyWithCondo.jurisdiction = undefined;
      }
      this.selectedJurisdiction = null;
      this.matter.updateApplyTorontoTaxFlagForToronto();
      if (this.matter.soaTrustLedgerCollection) {
        this.matter.soaTrustLedgerCollection.removeMLTT();
        if (this.matter.secondarySoaSheetsCollection) {
          this.matter.secondarySoaSheetsCollection.forEach((item) => {
            item.removeMLTT();
          });
        }
        this.matter.soaTrustLedgerCollection.removeAllComplianceItems();
        if (this.matter.secondarySoaSheetsCollection) {
          this.matter.secondarySoaSheetsCollection.forEach((item) => {
            item.removeAllComplianceItems();
          });
        }
      }
      this.matter.updateLTTOnStatementOfAccount();
      this.updateCompliancesAfterJurisdictionDelete();
    } catch (error) {
      console.error('deleteJurisdiction: ', error);
    }
    this.enableSave();
    this.updatePropertyTaxPaidTrustLedger();
    this.dialogService.setFocusOnAutocomplete('autocomplete-jurisdiction');
  }

  public updateCompliancesAfterJurisdictionDelete(): void {
    this.jurisdictionDepartmentsService.getDepartmentPairs(null, this.matter.provinceCode).subscribe(
      (jurisdictionDepartments: JurisdictionDepartment[]) => {
        this.jurisdictionDeleted(jurisdictionDepartments);
      },
      () => {}
    );
  }

  // Jurisdiction end
  // Surveyor omnibar data select event
  dataSelectedSurveyor(): void {
    let fieldKey = this.surveyorOmnibar ? this.surveyorOmnibar.el.nativeElement.getAttribute('fieldKey') : '';
    this.errorService.removeDpFieldError(fieldKey);
    if (this.selectedSurveyorContact.id === undefined) {
      if (
        this.selectedSurveyorContact.organizationName &&
        this.selectedSurveyorContact.organizationName.indexOf(Constants.NO_RESULTS_FOUND) > -1
      ) {
        this.selectedSurveyorContact = null;
      } else {
        this.dialogService.matDialogContent({
          content: CommonContactDialogComponent,
          context: {
            matter: this.matter,
            contactType: 'SURVEYOR',
            contactName: this.selectedSurveyorContact.displayName
          },
          onFulfillment: (result: any) => {
            this.errorService.removeDpFieldError(fieldKey);
            if (result) {
              if (result.action === 'save') {
                //if contact modal is closed after save
                this.selectedSurveyorContact = new Contact(result.contact);
                this.setMatterParticipantSurveyor();
              } else {
                //if contact modal is closed after cancel
                this.selectedSurveyorContact = '';
              }
            }
          }
        });
      }
    } else {
      this.setMatterParticipantSurveyor();
    }
    this.dialogService.setFocusOnAutocomplete('autocomplete-surveyor', true);
  }

  onAttentionDropdownClick = (event) => {
    //load attentionList
    this.getAttentionList();
  };

  getAttentionList() {
    if (this.selectedSurveyorContact) {
      if (this.selectedSurveyorContact.sourceContactId) {
        this.contactQueryService
          .getContactForMatter(this.selectedSurveyorContact.sourceContactId)
          .subscribe((res: Contact) => {
            this.attentionList = res.privateContacts ? res.privateContacts.persons : [];
          });
      }
    }
  }

  get surveyorMatterParticipant(): MatterParticipant {
    let surveyorMatterParticipant: MatterParticipant;
    if (this.matter && Array.isArray(this.matter.matterParticipants)) {
      surveyorMatterParticipant = this.matter.matterParticipants.find(
        (item) => item.matterParticipantRole == 'SURVEYOR'
      );
    }
    return surveyorMatterParticipant;
  }

  populateAttention(event) {
    let surveyorMatterParticipant: MatterParticipant = this.surveyorMatterParticipant;
    if (surveyorMatterParticipant && surveyorMatterParticipant.contact) {
      // Set timeout to make sure "this.selectedSurveyorContactAttention = event.subContactDisplayName" is acted
      // after  "onSelect" of p-autoComplete select an object
      setTimeout(() => {
        surveyorMatterParticipant.contact.attention = event.subContactDisplayName;
        this.selectedSurveyorContactAttention = event.subContactDisplayName;
      }, 50);
    } else {
      console.log('Fail to onAttentionSelect');
    }
  }

  public setMatterParticipantSurveyor() {
    // Remove existing Surveyor(s) first
    if (Array.isArray(this.matter.matterParticipants)) {
      for (let i = 0; i < this.matter.matterParticipants.length; i++) {
        if (this.matter.matterParticipants[i].matterParticipantRole == 'SURVEYOR') {
          this.matter.matterParticipants.splice(i, 1);
        }
      }
    }
    if (this.selectedSurveyorContact) {
      // create a proper object instance
      let newMatterParticipant = this.matter.addMatterParticipant(this.selectedSurveyorContact, true, 'SURVEYOR');
      this.selectedSurveyorContact = newMatterParticipant.contact;

      this.getAttentionList();
    }
  }

  public setMatterParticipantResidenceAssociation() {
    // Remove existing Residence Association first
    if (Array.isArray(this.matter.matterParticipants)) {
      for (let i = 0; i < this.matter.matterParticipants.length; i++) {
        if (this.matter.matterParticipants[i].matterParticipantRole == 'RESIDENCE_ASSOCIATION') {
          this.matter.matterParticipants.splice(i, 1);
        }
      }
    }

    // create a proper object instance
    let newMatterParticipant = this.matter.addMatterParticipant(
      this.selectedResidentAssociation,
      true,
      'RESIDENCE_ASSOCIATION'
    );
    this.selectedResidentAssociation = newMatterParticipant.contact;
    this.propertyModel.annualFee = this.selectedResidentAssociation.feeAmount;
    this.propertyModel.instrumentNumber = this.selectedResidentAssociation.instrumentNo;
    this.calculateFiscalYear();
    this.loadHomeOwnersResidentFeeSOA();
    this.changeAnnualFee();
    this.onFiscalYearEndDateUpdate();
    this.residentAssociationReadOnly = true;
  }

  calculateFiscalYear(): void {
    console.log(this.selectedResidentAssociation.fiscalYearEnd);
    console.log(this.matter.getClosingDate());
    if (this.selectedResidentAssociation.fiscalYearEnd && this.selectedResidentAssociation.fiscalYearEnd != '//') {
      let currentYear = new Date().getFullYear();
      let fiscalYear = currentYear.toString() + this.selectedResidentAssociation.fiscalYearEnd;
      if (this.getDateDiff(this.matter.getClosingDate(), fiscalYear) < 0) {
        //FiscalYear is after adjustmentDate
        fiscalYear = (currentYear + 1).toString() + this.selectedResidentAssociation.fiscalYearEnd;
      }
      this.propertyModel.fiscalYearEndDate = fiscalYear;
    }
  }

  searchSurveyor(event): void {
    let entered: string = event.query;
    if (entered === Constants.SURVEYOR && this.selectedSurveyorContact) {
      return;
    }
    this.surveyorSearchSubject.next(event.query);
  }

  getSurveyorDisplayNameFromContact(surveyor: Contact): string {
    let surveyorDisplayName: string;
    if (!surveyor) {
      surveyorDisplayName = 'Unknown';
    } else {
      surveyorDisplayName = surveyor.displayName ? surveyor.displayName : surveyor.organizationName;
    }
    return surveyorDisplayName;
  }

  getContactDisplayMailingAddress(surveyor: Contact): string {
    if (!surveyor || !surveyor.id || !surveyor.mailingAddress) {
      return '';
    }

    return surveyor.mailingAddress.addressTextWithoutCountryAndPostalCode;
  }

  isMatterReadOnly(): boolean {
    return (
      this.tabsService.isLinkedMatterDirty() ||
      this.tabsService.isMatterProjectLocked(this.matter) ||
      this.authZService.isMatterReadOnlyAccess()
    );
  }

  enableSave(): void {
    this.matter.dirty = !this.isMatterReadOnly();

    // if Jurisdiction field is populated, make the Jurisdiction field read-only on matter save
    this.makeJurisdictionReadOnly();
  }

  // DPPMP-2437 & DPPMP-3051 - Make jurisdiction field read-only on save + Allow more than 1 character in jurisdiction search
  makeJurisdictionReadOnly(): void {
    if (
      this.matter.matterProperties.length > 0 &&
      this.matter.matterPropertyWithCondo &&
      this.matter.matterPropertyWithCondo.jurisdiction
    ) {
      if (this.matter.matterPropertyWithCondo.jurisdiction.jurisdictionName !== null) {
        this.jurisdictionReadOnly = true;
      } else {
        // has to be null (not false), it's a bug in PrimeNG's AutoComplete [readonly]
        this.jurisdictionReadOnly = null;
      }
    }
  }

  checkIfJurisdictionEmpty(selectedJurisdiction: string): void {
    if (!selectedJurisdiction || selectedJurisdiction.length === 0) {
      if (this.matter.matterPropertyWithCondo) {
        this.matter.matterPropertyWithCondo.jurisdiction = null;
      }
      // this.matter.purchaserExecDocsAt = null;
    }
  }

  isCommonExpenseZeroTotalForNonProject() {
    if (this.isMatterPropertyCondoForNonProject()) {
      let condominiumTotalExpenses: Number = !this.matter.matterPropertyWithCondo.condominiumTotalExpenses
        ? 0
        : this.matter.matterPropertyWithCondo.condominiumTotalExpenses;
      return condominiumTotalExpenses == 0;
    }
    return false;
  }

  isMatterPropertyCondoForNonProject(): boolean {
    return (
      this.matter.matterPropertyWithCondo &&
      this.matter.matterPropertyWithCondo.isPropertyCondominium() &&
      !this.matter.templateForProject &&
      !this.matter.isProjectSale
    );
  }

  isCommonExpenseAdjustmentFromPropertyRequired() {
    return this.isMatterPropertyCondoForNonProject() ? this.matter.commonExpenseAdjustmentFromProperty == null : false;
  }

  get projectMatters(): Matter[] {
    return this.navigateToMatterService.projectMatters;
  }

  isPreviousMatterBtnVisible(): boolean {
    return this.navigateToMatterService.isPreviousMatterBtnVisible(this.matter);
  }

  isNextMatterBtnVisible(): boolean {
    return this.navigateToMatterService.isNextMatterBtnVisible(this.matter);
  }

  selectUnitLevelPlan(focusOnUnitNumber?: boolean): void {
    let modelWidthXl = this.matter.isProjectSale && this.matter.isMatterProvinceON;

    let cea: StatementAdjustment = this.matter.statementOfAdjustments.find((item) =>
      item.isCommonExpenseAdjustmentFromProperty()
    );
    this.dialogService.matDialogContent({
      content: UnitLevelPlanModalComponent,
      widthXl: modelWidthXl,
      context: {
        propertyJurisdiction: this.selectedJurisdiction,
        unitLevelPlanOrg: this.propertyModel,
        commonExpenseAdj: cea,
        currentMatter: this.matter,
        isReadOnly: false,
        label: this.getUnitLevelPlanFieldLabelForPS(),
        isPreviousMatterBtnVisible: this.isPreviousMatterBtnVisible(),
        isNextMatterBtnVisible: this.isNextMatterBtnVisible(),
        focusOnUnitNumber: focusOnUnitNumber
      },
      onFulfillment: (selectUnitLevelPlanResult: SelectUnitLevelPlanResult) => {
        if (selectUnitLevelPlanResult && selectUnitLevelPlanResult.isDirty) {
          this.enableSave();
        }
        //all the related changes will be done in the following method call
        this.matterService.updateMatterWithUnitLevelPlanChange(this.matter, selectUnitLevelPlanResult);
        if (this.isCommonExpenseAdjustmentFromPropertyRequired()) {
          this.matter.createUpdateAdjustmentCommonExp();
          if (!cea) {
            cea = this.matter.statementOfAdjustments.find((item) => item.isCommonExpenseAdjustmentFromProperty());
            if (cea) {
              cea.updateFromCommonExpense(this.matter.getClosingDate(), this.matter.isPaysForDateOfClosingVendor);
            }
          }
        }

        //the following calls need to be integrated into the above method
        if (
          this.matter.isProjectSale &&
          SoaExpenseAdjustmentUtil.updateReserveFundAdjustments(
            this.matter,
            this.matter.matterPropertyWithCondo.condominiumTotalExpenses.valueOf()
          )
        ) {
          this.matterService.updateStatementAdjustmentDisplayItems(this.matter);
        }
        if (selectUnitLevelPlanResult) {
          if (selectUnitLevelPlanResult.navigateToPreviousMatter) {
            this.goToPreviousMatter();
          } else if (selectUnitLevelPlanResult.navigateToNextMatter) {
            this.goToNextMatter();
          }
        }
      }
    });
  }

  async goToPreviousMatter() {
    let targetMatterId: number = await this.navigateToMatterService.goToPreviousMatter(
      this.matter,
      this.isMatterReadOnly(),
      true
    );
    if (targetMatterId) {
      this.navigateToMatter();
    }
  }

  async goToNextMatter(): Promise<void> {
    let targetMatterId: number = await this.navigateToMatterService.goToNextMatter(
      this.matter,
      this.isMatterReadOnly(),
      true
    );
    if (targetMatterId) {
      this.navigateToMatter();
    }
  }

  initializeUnitPlanWhenProjectAvailable = () => {
    if (this.matter.project) {
      this.selectUnitLevelPlan(true);
    } else {
      //project not loaded yet, wait a bit an try again
      setTimeout(this.initializeUnitPlanWhenProjectAvailable, 100);
    }
  };

  async navigateToMatter() {
    let url = this.router.createUrlTree(this.activeMatterTab.routeParams).toString();
    this.location.go(url);
    if (!this.activeMatterTab.isLocked() && !this.isMatterReadOnly()) {
      this.initializeUnitPlanWhenProjectAvailable();
    }
  }

  get activeMatterTab(): MatterTab {
    return this.tabsService && (this.tabsService.activeTab as MatterTab);
  }

  // updateMatterWithUnitLevelPlanChange(matter : Matter, selectUnitLevelPlanResult: SelectUnitLevelPlanResult){
  //     if (selectUnitLevelPlanResult) {
  //         if (selectUnitLevelPlanResult.matterProperty) {
  //             this.updateUnitLevelPlan(selectUnitLevelPlanResult.matterProperty);
  //             this.enableSave();
  //         }
  //         if (selectUnitLevelPlanResult.editCommonExpenseCommand) {
  //             this.matter.updateInterimOccupanyFeeAdjOnUnitLevelChanges(selectUnitLevelPlanResult.editCommonExpenseCommand);
  //             this.matterService.updateStatementAdjustmentDisplayItems(this.matter);
  //         }
  //         SoaRealtyTaxAdjustmentUtil.updateRealtyTaxAdjustmentOnDateChange(this.matter);
  //     }
  // }

  // updateUnitLevelPlan(matterProperty: MatterProperty): void {
  //     this.matter.updateUnitLevelPlanCreated(matterProperty);
  //     this.matter.calculateStatementAdjustment();
  //     this.removeDeprecatedMatterTaxAndAdjustmentForCondo();
  // }

  generateUnitLevelPlan(unitLevelPlan: MatterProperty): string {
    return UnitLevelPlanUtil.generateUnitLevelPlan(unitLevelPlan, this.matter.provinceCode);
  }

  generatePinNumber(unitLevelPlan: MatterProperty): string {
    return UnitLevelPlanUtil.generatePinNumber(unitLevelPlan);
  }

  onExceptionTypeValueChange() {
    this.enableSave();
  }

  onCondoValueChange(event, delayVerifyPinValues?: boolean) {
    if (this.propertyModel.isCondominium == 'YES') {
      //Change from 'YES' to other values
      this.propertyModel.condoPinNumber = this.propertyModel.pin;
      this.propertyModel.condoAssessmentAccountNumberSummary = this.propertyModel.assessmentAccountNumberSummary;
    } else {
      this.propertyModel.nonCondoPinNumber = this.propertyModel.pin;
      this.propertyModel.nonCondoAssessmentAccountNumberSummary = this.propertyModel.assessmentAccountNumberSummary;
    }
    this.resetMatterTaxesByRollNumberOnCondoChange();
    if (this.matter.isMatterProvinceSK) {
      this.resetParcelLegalDescriptions();
    }

    if (event == 'YES') {
      //Change 'Is this a condo?' to 'YES'
      this.propertyModel.pin = this.propertyModel.condoPinNumber;
      this.propertyModel.assessmentAccountNumberSummary = this.propertyModel.condoAssessmentAccountNumberSummary;
      //remove legal description from non condo
      if (
        this.matter.isMatterProvinceMB &&
        this.propertyModel.parcelLegalDescriptions &&
        this.propertyModel.parcelLegalDescriptions.length > 0
      ) {
        this.propertyModel.parcelLegalDescriptions.forEach((titleRollNumber) => {
          titleRollNumber.memo = '';
        });
      }
      this.removePinErrors();
      if (this.matter.isMatterProvinceAB) {
        this.propertyModel.nonCondoLincNumber = this.propertyModel.lincNumber;
        this.propertyModel.exceptionType = '';
        this.propertyModel.exceptionTypeDescription = '';
        this.propertyModel.lincNumber = UnitLevelPlanUtil.generateLincNumber(this.propertyModel);
        if (this.propertyModel.rollNumber) {
          this.propertyModel.nonCondoRollNumber = this.propertyModel.rollNumber.city;
          this.propertyModel.rollNumber.city = UnitLevelPlanUtil.generateRollNumber(this.propertyModel);
        }
      }
    } else {
      this.propertyModel.pin = this.propertyModel.nonCondoPinNumber;
      this.propertyModel.assessmentAccountNumberSummary = this.propertyModel.nonCondoAssessmentAccountNumberSummary;
      //the property-> pin field is displayed, may contains invalid data
      if (!delayVerifyPinValues) {
        this.verifyPinValues();
      }
      //remove the existing errors related to condo Corp if Applicable
      this.errorService.removeDpSaveError('condo_corp.management_company_required');
      if (this.matter.isMatterProvinceAB) {
        this.propertyModel.exceptionType = '';
        this.propertyModel.exceptionTypeDescription = '';
        this.propertyModel.lincNumber = this.propertyModel.nonCondoLincNumber
          ? this.propertyModel.nonCondoLincNumber
          : '';
        if (this.propertyModel.rollNumber) {
          this.propertyModel.rollNumber.city = this.propertyModel.nonCondoRollNumber
            ? this.propertyModel.nonCondoRollNumber
            : '';
        }
      }
    }
  }

  resetParcelLegalDescriptions() {
    this.propertyModel.parcelLegalDescriptions = [];
    this.addParcel();
    this.updateParcelValues();
  }

  resetMatterTaxesByRollNumberOnCondoChange() {
    if (this.matter.isMatterProvinceABorMB && this.propertyModel.separateTaxAdjustmentByRollNumber) {
      this.propertyModel.matterTaxesByRollNumber = [];
      this.propertyModel.matterTax = undefined;
      this.propertyModel.separateTaxAdjustmentByRollNumber = false;
      this.propertyModel.propertyTax = '';
      this.clearSoaPropertyTaxesOnCondoChange();
      this.propertyModel.resetPropertyTaxesByRollNumber();
      if (this.matter.soaTrustLedgerCollection) {
        this.matter.soaTrustLedgerCollection.clearPropertyTaxPaidFromTrustAccountMatterTax();
      }
    }
  }

  clearSoaPropertyTaxesOnCondoChange() {
    if (this.matter.statementOfAdjustments && this.matter.statementOfAdjustments.length > 0) {
      let elementToBeRemoved: StatementAdjustment[] = [];
      this.matter.statementOfAdjustments.forEach((item, index) => {
        if (item.isOriginPropertyTaxes()) {
          elementToBeRemoved.push(item);
        }
      });
      elementToBeRemoved.forEach((soaAdj) => this.matter.removeStatementAdjustment(soaAdj));
    }
  }

  condominiumChange(doNotCreateAdjustment?: boolean): void {
    this.formChangeAfterInit();
    this.resetMBCommonExpenseSoa();
    this.resetSTGPinLegalDescriptions();
    this.matter.onCondominumChange(doNotCreateAdjustment);
    this.updateCondoFeeInSoa();
    this.updatePropertyTypeList();
    this.propertyModel.rprApplicable = false;
    if (this.activeMatterTab && this.activeMatterTab.matterComponent) {
      this.activeMatterTab.matterComponent.initializeMatterTopicClosingStatuses();
    }
    this.resetBCCondoFields();
  }

  resetBCCondoFields(): void {
    if (!this.matter.isMatterProvinceBC && this.propertyModel.isCondominium === 'YES') {
      return;
    }

    this.matter?.matterProperties.forEach((mp) => {
      mp.parkingStalls = '';
      mp.lockers = '';
    });
  }

  resetMBCommonExpenseSoa(): void {
    if (this.matter.isMatterProvinceMB) {
      this.propertyModel.separateCommonElementFeeAdj = false;
      let statementOfAdjs = this.matter.statementOfAdjustments.filter((item) =>
        item.isCommonExpenseAdjustmentFromProperty()
      );
      if (statementOfAdjs && statementOfAdjs.length) {
        statementOfAdjs.forEach((soa) => {
          (<any>this.matter.statementOfAdjustments).remove(soa);
        });
      }
    }
  }

  resetSTGPinLegalDescriptions(): void {
    if (this.matter.isMatterProvinceMB && this.matter.isInsurerStewartTitle && this.matter.matterTitleInsurance) {
      this.matter.matterTitleInsurance.pinLegalDescriptions = [];
    }
  }

  // Miltiple Pin Logic

  addNewPIN(): void {
    this.formChangeAfterInit();
    this.currentTimeStamp = Date.now();
    let matterProperty = new MatterProperty();
    matterProperty.address = new Address();
    matterProperty.matterPropertyCode = 'QUESTION';
    matterProperty.isPinValid = true;
    this.matter.matterProperties.push(matterProperty);
    MatterPropertyUtil.updateNBStatementOfAccount(this.matter);
  }

  addNewLincAndRollNumber(): void {
    this.formChangeAfterInit();
    this.currentTimeStamp = Date.now();
    let matterProperty = new MatterProperty();
    matterProperty.address = new Address();
    matterProperty.matterPropertyCode = 'QUESTION';
    this.matter.matterProperties.push(matterProperty);
  }

  getPinLabel(): string {
    return this.matter.isMatterProvinceBCorNBorNS ? 'PID Number' : 'PIN';
  }

  getAANLabel(): string {
    return this.matter.isMatterProvinceNB ? 'PAN' : 'AAN';
  }

  updatePropertyTaxSummaryCheckbox(matterProperties: MatterProperty): void {
    matterProperties.propertyTaxesSummary = '';
    matterProperties.propertyTaxesSummaryOption = matterProperties.propertyTaxesSummaryOption ? false : true;
  }

  updateAddressyCheckbox(matterProperties: MatterProperty): void {
    this.enableSave();
    matterProperties.address = new Address();
    matterProperties.addressOption = matterProperties.addressOption ? false : true;
  }

  removeMatterProperty(matterProperties: MatterProperty, fieldId: string): void {
    let errorElementKey = 'matter.propertyteranet.pin';
    let pinName = this.matter.isMatterProvinceNS || this.matter.isMatterProvinceBC ? 'PID' : 'PIN';

    this.dialogService
      .confirm('Confirmation', `Are you sure you would like to delete this ${pinName}?`, false, 'Delete')
      .subscribe((res) => {
        if (res) {
          this.errorService.removeDpFieldError('pinnumber_' + String(matterProperties.identifier));
          this.errorService.removeDpFieldError('pan' + String(matterProperties.identifier));
          this.enableSave();
          this.matter.deleteMatterProperty(matterProperties);
          this.errorService.removeDpFieldError(errorElementKey, fieldId);
          MatterPropertyUtil.updateNBStatementOfAccount(this.matter);
        }
      });
  }

  removeMatterPropertyByLincNumber(selectedMatterProperty: MatterProperty): void {
    const pinLabel: string = this.matter?.isMatterProvinceBC ? 'PID' : 'LINC';
    this.dialogService
      .confirm('Confirmation', `Are you sure you would like to delete this ${pinLabel} Number?`, false, 'Delete')
      .subscribe((res) => {
        if (res) {
          this.enableSave();
          this.removeMatterPropertyAndMatterTax(selectedMatterProperty);
          this.updatePropertyTaxPaidTrustLedger();
          this.errorService.removeDpFieldError(`rollnumber_${selectedMatterProperty.identifier}`);
          this.errorService.removeDpFieldError(`lincNumber_${selectedMatterProperty.identifier}`);
        }
      });
  }

  removeMatterPropertyAndMatterTax(selectedMatterProperty: MatterProperty): void {
    let matterPropertyIndex: number = _.findIndex(
      this.matter.matterProperties,
      (matterProperty) => matterProperty.identifier === selectedMatterProperty.identifier
    );
    this.matter.matterProperties.splice(matterPropertyIndex, 1);
    this.removeMatterTaxByRollNumber(matterPropertyIndex, true);
  }

  removeMatterTaxOnBlurEvent(selectedMatterProperty: MatterProperty) {
    if (selectedMatterProperty && selectedMatterProperty.rollNumber.city == '') {
      let selectedMatterPropertyIndex = this.matter.matterProperties.findIndex(
        (matterProperty) => matterProperty.identifier === selectedMatterProperty.identifier
      );
      this.removeMatterTaxByRollNumber(selectedMatterPropertyIndex, false);
    }
    if (this.matter.isMatterProvinceAB && this.matter.matterPropertyWithCondo) {
      let fieldId: string = `rollnumber_${selectedMatterProperty.identifier}`;
      this.errorService.removeDpFieldError(fieldId);
      [
        this.matter.matterPropertyWithCondo,
        ...this.matter.additionalMatterProperty.filter(
          (matterProperty) => matterProperty.identifier !== selectedMatterProperty.identifier
        )
      ]
        .filter((matterProperty) => matterProperty.rollNumber.city === selectedMatterProperty.rollNumber.city)
        .forEach((matterProperty) => {
          this.errorService.addDpFieldError(
            DPError.createCustomDPError(
              fieldId,
              `Roll Number "${matterProperty.rollNumber.city}" already added on LINC ${
                matterProperty.lincNumber ? matterProperty.lincNumber : ''
              }.`,
              'Property',
              'ERROR'
            )
          );
        });
    }
  }

  checkDuplicationOfLINC() {
    this.matter.matterProperties.forEach((matterProperty, index) => {
      let findIndex = this.matter.matterProperties.findIndex(
        (mp) => mp.lincNumber == matterProperty.lincNumber && matterProperty.identifier != mp.identifier
      );
      if (findIndex > -1 && index > findIndex) {
        if (matterProperty.mortgageId) {
          let fieldIdBM: string = `lincNumberBM_${matterProperty.identifier}`;
          if (!this.errorService.isAnyDpFieldError(fieldIdBM)) {
            let mortgage = this.matter.mortgages.find((m) => m.id == matterProperty.mortgageId);
            this.errorService.addDpFieldError(
              DPError.createCustomDPError(
                fieldIdBM,
                `The list of properties included on a blanket mortgage should be unique. LINC Number "${matterProperty.lincNumber}" is a duplicate.`,
                (mortgage ? SharedUtils.getOrdinal(mortgage.mortgagePriority) : '') + ' Mortgage',
                'ERROR'
              )
            );
          }
        } else {
          let fieldId: string = `lincNumber_${matterProperty.identifier}`;
          if (!this.errorService.isAnyDpFieldError(fieldId)) {
            this.errorService.addDpFieldError(
              DPError.createCustomDPError(
                fieldId,
                `${this.lincOrPIDSText} Number "${matterProperty.lincNumber}" already added.`,
                'Property',
                'ERROR'
              )
            );
          }
        }
      } else {
        if (matterProperty.mortgageId) {
          let fieldIdBM: string = `lincNumberBM_${matterProperty.identifier}`;
          if (this.errorService.isAnyDpFieldError(fieldIdBM)) {
            this.errorService.removeDpFieldError(fieldIdBM);
          }
        } else {
          let fieldId: string = `lincNumber_${matterProperty.identifier}`;
          if (this.errorService.isAnyDpFieldError(fieldId)) {
            this.errorService.removeDpFieldError(fieldId);
          }
        }
      }
    });
  }

  removeMatterTaxByRollNumber(matterPropertyIndex: number, updateRollNumberOrder: boolean) {
    if (this.matter.matterProperties) {
      this.matter.resetSeparateTaxAdjustmentByRollNumber();
      let matterTaxToBeRemovedIndex = this.propertyModel.matterTaxesByRollNumber.findIndex(
        (matterTax) => matterTax.rollNumberOrder == matterPropertyIndex
      );
      if (matterTaxToBeRemovedIndex > -1) {
        this.propertyModel.matterTaxesByRollNumber.splice(matterTaxToBeRemovedIndex, 1);
      }
      if (updateRollNumberOrder) {
        this.updateRollNumberOrderOnMatterTax(matterPropertyIndex, 1);
      }
      this.matter.removeDeprecatedAdjustmentsPropertyTaxByRollNumber();
    }
  }

  // resetSeparateTaxAdjustmentByRollNumber() {
  //     if (this.propertyModel.isCondominium === DpBooleanValueTypes.YES) {
  //         let condoExpensesWithRollNumber = this.propertyModel.condominiumExpenses.filter(item => (item.rollNumber != undefined && item.rollNumber != ''));
  //         if (condoExpensesWithRollNumber.length < 2) {
  //             this.propertyModel.separateTaxAdjustmentByRollNumber = false;
  //             this.propertyModel.matterTax = this.propertyModel.matterTaxesByRollNumber[0];
  //             this.propertyModel.updatePropertyTaxes(this.propertyModel.matterTax, this.formattedAllTaxesAmount(this.propertyModel.matterTax), this.formattedTrustAmount(this.propertyModel.matterTax), this.matter.provinceCode);
  //         }
  //     } else {
  //         let matterPropertiesWithRollNumber = this.matter.matterProperties.filter(item => item.rollNumber.city != '');
  //         if (matterPropertiesWithRollNumber.length < 2) {
  //             this.propertyModel.separateTaxAdjustmentByRollNumber = false;
  //             this.propertyModel.matterTax = this.propertyModel.matterTaxesByRollNumber[0];
  //             this.propertyModel.updatePropertyTaxes(this.propertyModel.matterTax, this.formattedAllTaxesAmount(this.propertyModel.matterTax), this.formattedTrustAmount(this.propertyModel.matterTax), this.matter.provinceCode);
  //         }
  //     }
  //
  // }

  updateRollNumberOrderOnMatterTax(propertyIndex: number, numberOfItemsRemoved: number) {
    let matterTax: MatterTax[] = this.propertyModel.matterTaxesByRollNumber.filter(
      (item) => item.rollNumberOrder > propertyIndex
    );
    if (matterTax && matterTax.length > 0) {
      matterTax.forEach(
        (matterPropertyTax) =>
          (matterPropertyTax.rollNumberOrder = matterPropertyTax.rollNumberOrder - numberOfItemsRemoved)
      );
    }
  }

  getPinFieldKey(isPin1: boolean): string {
    if (this.matter && this.matter.isPartialPinAllowed) {
      return isPin1 ? 'matter.propertyteranet.pin1.warningOnly' : 'matter.propertyteranet.pin.warningOnly';
    } else {
      return isPin1 ? 'matter.propertyteranet.pin1' : 'matter.propertyteranet.pin';
    }
  }

  removePinErrors(): void {
    //the property->pin field is hidden
    if (this.matter.isMatterProvinceNBorNS) {
      this.errorService.removeDpfieldErrorsByPartialKey('pinnumber_*');
      this.errorService.removeDpfieldErrorsByPartialKey('pan*');
    } else {
      this.errorService.removeDpfieldErrorsByPartialKey('matter.propertyteranet.pin*');
    }
  }

  verifyPinValues(): void {
    if (
      this.matter &&
      this.matter.matterPropertyWithCondo &&
      !this.checkPinFormat(this.matter.matterPropertyWithCondo.identifier)
    ) {
      if (!this.matter.isMatterProvinceNBorNS) {
        this.errorService.addDpFieldError(
          DPError.createDPError(
            this.getPinFieldKey(true),
            'pinnumber_' + this.matter.matterPropertyWithCondo.identifier
          )
        );
      }
    }
    if (this.matter && this.matter.matterPropertyWithCondo && this.matter.isMatterProvinceNBorNS) {
      this.propertyPinHelper.validatePinWithPan(
        this.errorService,
        this.matter,
        this.matter.matterPropertyWithCondo,
        this.matter.matterPropertyWithCondo.identifier,
        1,
        true
      );
    }
    if (this.matter && Array.isArray(this.matter.additionalMatterProperty)) {
      this.matter.additionalMatterProperty.forEach((property, index) => {
        if (this.matter.isMatterProvinceNBorNS) {
          this.propertyPinHelper.validatePinWithPan(
            this.errorService,
            this.matter,
            property,
            property.identifier,
            index + 2,
            false
          );
        } else {
          if (!this.checkPinFormat(property.identifier)) {
            this.errorService.addDpFieldError(
              DPError.createDPError(this.getPinFieldKey(false), 'pinnumber_' + property.identifier)
            );
          }
        }
      });
    }
  }

  checkPinFormat = (identifier: number): boolean => {
    let matterProperty: MatterProperty = _.find(
      this.matter.additionalMatterProperty,
      function (property: MatterProperty) {
        return property.identifier === identifier;
      }
    );

    if (!matterProperty) {
      matterProperty = this.propertyModel;
    }

    matterProperty.isPinValid = Utils.isValidPin(matterProperty.pin, this.getPinMaxLength());
    return matterProperty.isPinValid;
  };

  getPinMaxLength(): number {
    return 9;
  }

  getPid(): number {
    if (this.matter.isMatterProvinceNB) {
      return 10;
    }
    return 20;
  }

  getPANMaxLength(): number {
    if (this.matter.isMatterProvinceNB) {
      return 8;
    }
    return 20;
  }

  // End
  /*updateSalePriceOnParcelUpdate() : void {
        if(this.matter.considerationLtt && this.matter.considerationLtt.salePriceAdjustment){
            // Initializing purchasePriceType if it is not defined , Since it is defaulted to Sale Price in Soa on Creation
            if(!this.matter.matterPropertyWithCondo.purchasePriceType){
                this.matter.matterPropertyWithCondo.purchasePriceType = 'SALE_PRICE_AS_IN_SOA';
            }
            this.matter.considerationLtt.salePriceAdjustment.agreementSalePrice = this.matter.matterPropertyWithCondo.totalValueOfParcels;
            let newSalePrice = this.matter.considerationLtt.salePriceAdjustment.getSalePrice(this.provinceHstRateSlab);
            this.onSalePriceUpdate({
                salePrice           : this.propertyModel.purchasePrice,
                salePriceSoa        : newSalePrice,
                priceType           : this.propertyModel.purchasePriceType,
                salePriceAdjustment : this.matter.considerationLtt.salePriceAdjustment,
                autoInsertHst       : this.matter.autoInsertHst,
                affidavitTobeSignedBy                : this.propertyModel.affidavitTobeSignedBy,
                statementAdjustmentDisplayUtil : this.statementAdjustmentDisplayUtil,
                overrideValueOfParcels         : this.propertyModel.overrideValueOfParcels
            });
        }
    }*/

  openSalePriceModal(): void {
    const contentModalConfig: ContentDialogConfig = {
      content: ConsiderationModal,
      context: {
        salePrice: this.propertyModel.purchasePrice,
        salePriceSoa: this.propertyModel.soaPurchasePrice,
        priceType: this.propertyModel.purchasePriceType,
        statementOfAdjustments: this.matter.statementOfAdjustments,
        closingDate: this.matter.matterCloseDate,
        viewContainerRef: this.viewContainerRef,
        provinceHstRateSlab: this.soaConsiderationTaxes,
        salePriceAdjustment: this.matter.considerationLtt.salePriceAdjustment,
        statementOfAdjustmentHeading: this.matter.statementOfAdjustmentHeading,
        purchaseIsOfCode: this.propertyModel.purchaseIsOfCode,
        autoInsertHst: this.matter.autoInsertHst,
        txnType: this.txnType,
        considerationLtt: this.matter.considerationLtt,
        matter: this.matter,
        overrideValueOfParcels: this.propertyModel.overrideValueOfParcels
      },
      onFulfillment: (result: any) => {
        if (result) {
          this.onSalePriceUpdate(result);
          this.propertyModel.calculateNRDTTax();
        } else if (this.matter.isMatterProvinceMB && this.matter.isPurchase && this.propertyModel) {
          this.propertyModel.landTransferTax = ManitobaLttTierUtil.calculateLttTax(
            this.propertyModel.purchasePrice,
            this.manitobaLTTTiers
          );
        }
        this.matter.checkHSTRebateToDisplayWarningMessage(this.errorService);
        this.matter.updateChattelsConsiderationLTTFromSalePriceAdjustment();
      }
    };
    this.dialogService.matDialogContent(contentModalConfig);
  }

  onSalePriceUpdate(result: any): void {
    try {
      MatterPropertyUtil.onSalePriceUpdate(
        result,
        this.matter,
        this.considerationTaxes,
        this.soaConsiderationTaxes,
        this.provinceHstRateSlab,
        this.manitobaLTTTiers,
        this.brokerCommissionComponent,
        this.matterService
      );
    } catch (error) {
      console.error('Error updating sale price ', error);
    }
    this.pttService.updatePropertyTransferTax(this.matter);
    this.enableSave();
  }

  updateCommissionPayableToVendorBroker(): void {
    if (this.matter.isSale && this.brokerCommissionComponent) {
      // TODO : Need to clean up this and move updateCommissionPayableToVendorBroker to matter.ts
      this.brokerCommissionComponent.updateCommissionPayableToVendorBroker();
    }
  }

  openDepositModal(): void {
    let closingDate = this.matter.isSaleBC ? this.matter.adjustAsAtClosingDate : this.matter.getClosingDate();
    const project = this.project;
    const projectCondo = project ? project.projectCondo : null;

    this.dialogService.matDialogContent({
      content: DepositModal,
      widthXl: true,
      context: {
        depositAmount: this.propertyModel.depositAmount,
        multipleDeposit: this.propertyModel.multipleDeposit,
        payDepositOutOfTrust: this.propertyModel.payDepositOutOfTrust,
        closingDate: closingDate,
        adjustmentFormat: this.propertyModel.adjustmentFormat,
        deposits: this.propertyModel.deposits,
        matter: this.matter,
        isCondo: this.isCondo(),
        isProjectRelated: this.isProjectRelated(),
        projectOccupancyFeesCalBasedOn: projectCondo ? projectCondo.occupancyFeesCalculatedBasedOn : null
      },
      onFulfillment: (result: DepositModalContextValue) => {
        if (result) {
          //when user hit enter in the modal page(on the currency input field), it close the modal
          //(Karthyk confirms that this is the expected/wanted behavior)
          //but there are some LATE valueChanged calls triggered by Currency directive (onBlur, onKeyUp)
          //even after the modal closed, and reach here
          //and the following method needs to be called at the very last
          setTimeout(() => this.onDepositUpdate(result), 100);
        }
      }
    });
  }

  private isProjectRelated() {
    return this.isProjectSale || this.isProjectConfigTab;
  }

  private isCondo(): boolean {
    const project = this.project;
    return project ? project.condominiumFlag : this.matter.isPropertyCondo();
  }

  get isProjectSale(): boolean {
    return this.matter && this.matter.isProjectSale;
  }

  get isProjectConfigTab(): boolean {
    return this.tabsService && this.tabsService.activeTab && this.tabsService.activeTab.isProject();
  }

  get project(): Project {
    if (this.isProjectConfigTab) {
      return (this.tabsService.activeTab as ProjectTab).project;
    } else {
      return this.matter && this.matter.isProjectSale ? this.matter.project : null;
    }
  }

  onDepositUpdate(result: DepositModalContextValue): void {
    this.enableSave();
    this.matter.updateMatterDepositAdjustment(result);

    if (result.depositStatementAdj) {
      const index = this.matter.statementOfAdjustments.findIndex(
        (adj) => adj.itemKey == StatementAdjustmentKey.DEPOSIT
      );
      if (index == -1) {
        // don't think we'll be in here but just in case...
        this.matter.statementOfAdjustments.push(result.depositStatementAdj);
      } else {
        // the deposit SOA in 'result' is copied from this.matter when deposit modal is opened,
        // the modal may have updated its values so we just set it back
        this.matter.statementOfAdjustments[index] = result.depositStatementAdj;
      }
    }
    if (this.project && this.project.isStatementOfAdjustmentInterim()) {
      let interimDepositAdj = StatementAdjustmentUtil.findByKey(
        this.matter.interimStatementAdjustments,
        StatementAdjustmentKey.DEPOSIT
      );
      let finalDepositAdj = StatementAdjustmentUtil.findByKey(
        this.matter.finalStatementAdjustments,
        StatementAdjustmentKey.DEPOSIT
      );

      if (this.matter.isAdjustmentStatusModeFinal) {
        StatementAdjustmentUtil.copyDepositAdjustmentData(finalDepositAdj, interimDepositAdj);
      } else {
        StatementAdjustmentUtil.copyDepositAdjustmentData(interimDepositAdj, finalDepositAdj);
      }
    }
    this.formChangeAfterInit();
    this.matter.createUpdateDepositAdjustment(result.infoOnly);
    this.matter.calculateStatementAdjustment();
    this.updateCommissionPayableToVendorBroker();
    this.matter.updateBalanceOfFundsPayableTo();
  }

  updatePropertyTaxPaidTrustLedger(): void {
    this.updateTeraviewLegalDescs();
    if (this.matter.soaTrustLedgerCollection) {
      this.matter.soaTrustLedgerCollection.updatePropertyTaxPaidTrustLedger();
    }
  }

  openPropertyTaxModal(): void {
    let closingDate = this.matter.getClosingDate();
    let soa: StatementAdjustment = this.matter.getPropertyTaxSoa();
    this.dialogService.matDialogContent({
      content: RollNumberPropertyTaxesModalComponent,
      context: {
        propertyTax: this.propertyModel.matterTax,
        closingDate: closingDate,
        estTaxIncrease: this.estTaxIncrease,
        statementAdjustmentHeading: this.matter.statementOfAdjustmentHeading,
        matterProvinceCode: this.matter.provinceCode,
        separateTaxAdjustmentByRollNumber: this.propertyModel.separateTaxAdjustmentByRollNumber,
        matterTaxesByRollNumber: this.matter.matterTaxByRollNumbers(),
        currentMatter: this.matter,
        hideDeleteFromModal: false,
        disableCheckBoxFromSoa: false,
        condominiumExpenses: this.propertyModel.condominiumExpenses,
        parcelLegalDescriptions: this.propertyModel.parcelLegalDescriptions,
        isCondo: this.propertyModel.isCondominium,
        statementAdjustmentKey: StatementAdjustmentKey.PROPERTY_TAX,
        infoOnly: soa && soa.infoOnly,
        autoInsertTaxAdjustment: this.propertyModel.autoInsertTaxAdjustment,
        hideAutoInsertFromModal: false
      },
      onFulfillment: (result: any) => {
        if (result) {
          this.enableSave();
          this.propagatePropertyTaxChanges(result);
        }
      }
    });
  }

  propagatePropertyTaxChanges(result: RollNumberPropertyTaxesResult): void {
    if (result.propertyTax) {
      this.propertyModel.matterTaxesByRollNumber = [];
      if (this.matter.statementOfAdjustments && this.matter.statementOfAdjustments.length > 0) {
        let elementToBeRemoved: StatementAdjustment[] = [];
        this.matter.statementOfAdjustments.forEach((item, index) => {
          if (item.isOriginPropertyTaxes()) {
            elementToBeRemoved.push(item);
          }
        });
        elementToBeRemoved.forEach((soaAdj) => this.matter.removeStatementAdjustment(soaAdj));
      }
      this.propertyModel.updatePropertyTaxes(
        result.propertyTax,
        this.matter.formattedAllTaxesAmount(result.propertyTax),
        this.matter.formattedTrustAmount(result.propertyTax),
        this.matter.provinceCode
      );

      this.propertyModel.matterTaxesByRollNumber.push(result.propertyTax);
    } else if (result.propertyTaxesByRollNumber) {
      this.propertyModel.propertyTax = 'Multiple Taxes...';
      if (result.propertyTaxesByRollNumber.length > 0) {
        this.propertyModel.matterTaxesByRollNumber = [];
        this.propertyModel.matterTax = undefined;
        result.propertyTaxesByRollNumber.forEach((item) => {
          if (item.matterTax) {
            this.propertyModel.matterTaxesByRollNumber.push(item.matterTax);
          }
        });
      }
      this.matter.removeDeprecatedAdjustmentsPropertyTaxByRollNumber();
    } else {
      this.removePropertyTaxes(result.separateTaxAdjustmentByRollNumber);
    }
    this.updatePropertyTaxPaidTrustLedger();
    this.formChangeAfterInit();
    this.propertyModel.separateTaxAdjustmentByRollNumber = result.separateTaxAdjustmentByRollNumber;
    this.propertyModel.autoInsertTaxAdjustment = result.autoInsertTaxAdjustment;
    if (this.matter && this.matter.isMatterProvinceNS) {
      if (result.autoInsertTaxAdjustment) {
        this.reCalculateStatementofAdjustment(result.infoOnly);
      }
    } else {
      this.reCalculateStatementofAdjustment(result.infoOnly);
    }
  }

  reCalculateStatementofAdjustment(infoOnly?: boolean): void {
    if (this.propertyModel.separateTaxAdjustmentByRollNumber) {
      this.matter.createUpdateAdjustmentPropertyTaxesByRollNumber(infoOnly);
    } else {
      this.matter.createUpdateAdjustmentPropertyTaxes(null, null, null, infoOnly);
    }
    this.matter.calculateStatementAdjustment();
  }

  removePropertyTaxes(separateTaxAdjustmentByRollNumber: any): void {
    if (!separateTaxAdjustmentByRollNumber) {
      if (this.propertyModel.matterTaxesByRollNumber) {
        this.propertyModel.matterTaxesByRollNumber.forEach((matterTaxByRollNumber) => {
          if (this.matter.statementOfAdjustments && this.matter.statementOfAdjustments.length > 0) {
            let statementAdjustment = this.matter.statementOfAdjustments.find(
              (item) => item.isOriginPropertyTaxes() && item.propertyMatterTaxId === matterTaxByRollNumber.id
            );
            this.matter.removeStatementAdjustment(statementAdjustment);
          }
        });
        this.propertyModel.resetPropertyTaxesByRollNumber();
      }
    } else {
      if (this.matter.statementOfAdjustments && this.matter.statementOfAdjustments.length > 0) {
        let statementAdjustment = this.matter.statementOfAdjustments.find(
          (item) => item.isOriginPropertyTaxes() && item.propertyMatterTaxId === this.propertyModel.matterTax.id
        );
        if (statementAdjustment) {
          this.matter.removeStatementAdjustment(statementAdjustment);
        }
      }
      this.propertyModel.resetPropertyTaxesByRollNumber();
    }
  }

  //  formattedAllTaxesAmount(propertyTax: MatterTax): string {
  //     return propertyTax ? propertyTax.formattedAllTaxesAmount(this.matter, this.interimTaxMultiplier) : '';
  // }

  // formattedTrustAmount(propertyTax: MatterTax): string {
  //     return propertyTax ? propertyTax.formattedTrustAmount() : '';
  // }

  formattedVendorPaidAmount(propertyTax: MatterTax): string {
    let vendorPaidAmountFormatted: string;
    if (propertyTax.vendorWillHavePaid != undefined && propertyTax.vendorWillHavePaid != null) {
      let newValue: string = this.currencyPipe.transform(propertyTax.vendorWillHavePaid, 'CAD', 'symbol', '1.2-2');
      if (newValue) {
        vendorPaidAmountFormatted = newValue.replace('CA', '') + ' paid';
      }
    }
    return vendorPaidAmountFormatted;
  }

  //Removing adjustment for ID's not found
  // removeDeprecatedAdjustmentsPropertyTaxByRollNumber() {
  //     if (this.matter.statementOfAdjustments && this.matter.statementOfAdjustments.length > 0) {
  //         this.matter.statementOfAdjustments.forEach(adj => {
  //             if (adj.isOriginPropertyTaxes()) {
  //                 let matterTaxByRollNumber = this.propertyModel.matterTaxesByRollNumber.find(item => item && item.id === adj.propertyMatterTaxId);
  //                 if (matterTaxByRollNumber == undefined) {
  //                     this.matter.removeAdjustmentPropertyTaxes(adj);
  //                 }
  //             }
  //         })
  //     }
  // }

  //Removing MatterTaxes for ID's not found
  // removeDeprecatedMatterTaxAndAdjustmentForCondo() {
  //     if (this.matter.isMatterProvinceAB && this.propertyModel.separateTaxAdjustmentByRollNumber &&
  //         this.propertyModel.isCondominium === DpBooleanValueTypes.YES && this.propertyModel.condominiumExpenses) {
  //         this.propertyModel.condominiumExpenses.forEach((condoExpense, index) => {
  //             if (condoExpense.rollNumber === undefined || !condoExpense.rollNumber) {
  //                 if (this.propertyModel.matterTaxesByRollNumber && this.propertyModel.matterTaxesByRollNumber.length > 0) {
  //                     let matterTaxByRollRollNumberIndex = this.propertyModel.matterTaxesByRollNumber.findIndex(item => item.rollNumberOrder === index);
  //                     if (matterTaxByRollRollNumberIndex > -1) {
  //                         this.propertyModel.matterTaxesByRollNumber.splice(matterTaxByRollRollNumberIndex, 1);
  //                     }
  //                 }
  //             }
  //         })
  //         this.removeDeprecatedAdjustmentsPropertyTaxByRollNumber();
  //         this.resetSeparateTaxAdjustmentByRollNumber();
  //     }
  // }

  handleF9OnPartLot(): void {
    let inputValue: string = this.propertyModel.partLot ? this.propertyModel.partLot.trim() : '';
    let index: number = _.findIndex(dropDowns.partLotOptions, (opt) => opt.label.startsWith(inputValue));

    index++;

    this.propertyModel.partLot = dropDowns.partLotOptions[index % dropDowns.partLotOptions.length].label + ' ';
    this.updateTeraviewLegalDescs();
  }

  handleF9OnPlan(): void {
    let inputValue = this.propertyModel.plan ? this.propertyModel.plan.trim() : '';

    if (
      this.propertyModel.registryOrLt === constValues.registryLt.defaultType ||
      this.propertyModel.registryOrLt === constValues.registryLt.registry
    ) {
      let index: number = _.findIndex(dropDowns.planOptions.blankOrRegistry, (opt) => opt.label.startsWith(inputValue));

      index++;

      this.propertyModel.plan =
        dropDowns.planOptions.blankOrRegistry[index % dropDowns.planOptions.blankOrRegistry.length].label;
    } else if (
      this.propertyModel.jurisdiction &&
      ~this.propertyModel.jurisdiction.city.name.toLowerCase().indexOf('toronto') &&
      this.propertyModel.registryOrLt === constValues.registryLt.landTitles
    ) {
      let index: number = _.findIndex(dropDowns.planOptions.torontoAndLandTitles, (opt) =>
        opt.label.startsWith(inputValue)
      );

      index++;

      this.propertyModel.plan =
        dropDowns.planOptions.torontoAndLandTitles[index % dropDowns.planOptions.torontoAndLandTitles.length].label;
    } else if (this.propertyModel.registryOrLt === constValues.registryLt.landTitles) {
      let newInputValue: string = 'Plan XXM-';

      if (
        this.propertyModel.jurisdiction &&
        this.propertyModel.jurisdiction.registryOffice &&
        this.propertyModel.jurisdiction.registryOffice.officeNumber
      ) {
        newInputValue = newInputValue.replace('XX', this.propertyModel.jurisdiction.registryOffice.officeNumber);
      } else {
        newInputValue = newInputValue.replace('XX', '');
      }

      this.propertyModel.plan = newInputValue;
    }
    this.updateTeraviewLegalDescs();
  }

  generateF9OnPlan(): string {
    if (
      this.propertyModel.registryOrLt === constValues.registryLt.defaultType ||
      this.propertyModel.registryOrLt === constValues.registryLt.registry
    ) {
      if (dropDowns.planOptions.blankOrRegistry && dropDowns.planOptions.blankOrRegistry.length > 0) {
        let filteredOptions = dropDowns.planOptions.blankOrRegistry.filter((dropDown) => dropDown.label != '');
        return (
          'Press F9 to toggle ' +
          filteredOptions
            .map(function (elem) {
              return elem.label;
            })
            .join(', ')
            .replace(/,(?!.*,)/gim, ' or')
        );
      }
    } else if (
      this.propertyModel.jurisdiction &&
      this.propertyModel.jurisdiction.city &&
      ~this.propertyModel.jurisdiction.city.name.toLowerCase().indexOf('toronto') &&
      this.propertyModel.registryOrLt === constValues.registryLt.landTitles
    ) {
      if (dropDowns.planOptions.torontoAndLandTitles && dropDowns.planOptions.torontoAndLandTitles.length > 0) {
        let filteredOptions = dropDowns.planOptions.torontoAndLandTitles.filter((dropDown) => dropDown.label != '');
        return (
          'Press F9 to toggle ' +
          filteredOptions
            .map(function (elem) {
              return elem.label;
            })
            .join(', ')
            .replace(/,(?!.*,)/gim, ' or')
        );
      }
    } else if (this.propertyModel.registryOrLt === constValues.registryLt.landTitles) {
      let f9OnPlanString: string = 'F9 = Plan M-';
      if (
        this.propertyModel.jurisdiction &&
        this.propertyModel.jurisdiction.registryOffice &&
        this.propertyModel.jurisdiction.registryOffice.officeNumber
      ) {
        f9OnPlanString = 'F9 = Plan ' + this.propertyModel.jurisdiction.registryOffice.officeNumber + 'M-';
      }
      return f9OnPlanString;
    }
  }

  handleF9OnBeingPart(): void {
    let inputValue: string = this.propertyModel.beingPart ? this.propertyModel.beingPart.trim() : '';
    let index: number = _.findIndex(dropDowns.beingPartOptions, (opt) => opt.label.startsWith(inputValue));

    index++;

    this.propertyModel.beingPart = dropDowns.beingPartOptions[index % dropDowns.beingPartOptions.length].label + ' ';
    this.updateTeraviewLegalDescs();
  }

  handleF9OnOnPlan(): void {
    let inputValue: string = this.propertyModel.onPlan ? this.propertyModel.onPlan.trim() : '';

    if (
      this.propertyModel.jurisdiction &&
      ~this.propertyModel.jurisdiction.city.name.toLowerCase().indexOf('toronto')
    ) {
      let index: number = _.findIndex(dropDowns.onPlanOptions.inToronto, (opt) => opt.label.startsWith(inputValue));

      index++;

      this.propertyModel.onPlan =
        dropDowns.onPlanOptions.inToronto[index % dropDowns.onPlanOptions.inToronto.length].label;
    } else {
      let newInputValue: string = '';
      let registryOfficeNumber: string = '';

      if (
        this.propertyModel.jurisdiction &&
        this.propertyModel.jurisdiction.registryOffice &&
        this.propertyModel.jurisdiction.registryOffice.officeNumber
      ) {
        registryOfficeNumber = this.propertyModel.jurisdiction.registryOffice.officeNumber;
      }

      if (inputValue.toLowerCase().startsWith('on plan')) {
        newInputValue = 'on Reference Plan ' + registryOfficeNumber + 'R-';
      } else {
        newInputValue = 'on Plan ' + registryOfficeNumber + 'R-';
      }

      this.propertyModel.onPlan = newInputValue;
    }
    this.updateTeraviewLegalDescs();
  }

  generateF9OnOnPlan(): string {
    if (
      this.propertyModel.jurisdiction &&
      ~this.propertyModel.jurisdiction.city.name.toLowerCase().indexOf('toronto')
    ) {
      if (dropDowns.onPlanOptions.inToronto && dropDowns.onPlanOptions.inToronto.length > 0) {
        let filteredOptions = dropDowns.onPlanOptions.inToronto.filter((dropDown) => dropDown.label != '');
        return (
          'Press F9 to toggle ' +
          filteredOptions
            .map(function (elem) {
              return elem.label;
            })
            .join(', ')
            .replace(/,(?!.*,)/gim, ' or')
        );
      }
    } else {
      let f9OnPlanString: string = 'Press F9 to toggle "on Plan R-" or "on Reference Plan R-"';
      if (
        this.propertyModel.jurisdiction &&
        this.propertyModel.jurisdiction.registryOffice &&
        this.propertyModel.jurisdiction.registryOffice.officeNumber
      ) {
        f9OnPlanString =
          'Press F9 to toggle "on Plan ' +
          this.propertyModel.jurisdiction.registryOffice.officeNumber +
          'R-" or "on Reference Plan ' +
          this.propertyModel.jurisdiction.registryOffice.officeNumber +
          'R-"';
      }
      return f9OnPlanString;
    }
  }

  handleF9OnParcel(): void {
    if (this.propertyModel.isCondominium != 'YES' && this.propertyModel.partLot) {
      let firstDigitIndex: number = this.propertyModel.partLot.search(/\d/);

      if (firstDigitIndex >= 0) {
        this.propertyModel.parcel = 'Parcel ' + this.propertyModel.partLot.substr(firstDigitIndex) + '-1';
      } else {
        this.propertyModel.parcel = 'Parcel -1';
      }
    } else if (this.propertyModel.isCondominium != 'YES') {
      this.propertyModel.parcel = 'Parcel -1';
    } else if (this.propertyModel.isCondominium === 'YES') {
      this.propertyModel.parcel = 'Parcel ';
    }
    this.updateTeraviewLegalDescs();
  }

  generateF9OnParcel(): string {
    let f9OnParcelText: string = '';

    if (this.propertyModel.isCondominium != 'YES' && this.propertyModel.partLot) {
      let firstDigitIndex: number = this.propertyModel.partLot.search(/\d/);

      if (firstDigitIndex >= 0) {
        f9OnParcelText = 'F9 = Parcel ' + this.propertyModel.partLot.substr(firstDigitIndex) + '-1';
      } else {
        f9OnParcelText = 'F9 = Parcel -1';
      }
    } else if (this.propertyModel.isCondominium != 'YES') {
      f9OnParcelText = 'F9 = Parcel -1';
    } else if (this.propertyModel.isCondominium === 'YES') {
      f9OnParcelText = 'F9 = Parcel ';
    }

    return f9OnParcelText;
  }

  handleF9OnSection(): void {
    if (this.propertyModel.isCondominium != 'YES' && this.propertyModel.plan) {
      let plan = this.propertyModel.plan.trim();
      let planIndexSpace = plan.indexOf(' ');
      let planIndexDigit = plan.match(/\d/);
      if (planIndexDigit && planIndexDigit != null && planIndexDigit.index > -1) {
        this.propertyModel.section = 'Section ' + plan.substr(planIndexDigit.index, plan.length);
      } else if (planIndexSpace != -1) {
        this.propertyModel.section = 'Section ' + plan.substr(planIndexSpace, plan.length);
      }
    } else if (this.propertyModel.isCondominium != 'YES') {
      this.propertyModel.section = 'Section ';
    } else if (this.propertyModel.isCondominium === 'YES' && this.propertyModel.condominiumPlans) {
      let condoPlan: CondominiumPlan = this.propertyModel.condominiumPlans[0];

      this.propertyModel.section =
        'Section ' + (condoPlan && condoPlan.condominiumPlanNumber ? condoPlan.condominiumPlanNumber : '');
    }
    this.updateTeraviewLegalDescs();
  }

  handF9EasementRightOfWay() {
    if (this.matter.isProjectSale && this.matter.isMatterProvinceON) {
      this.propertyModel.easementRightOfWay = 'SUBJECT TO INSTRUMENT NO(S)';
      this.updateTeraviewLegalDescs();
    }
  }

  isHandF9EasementRightOfWayVisible(): boolean {
    return this.matter.isProjectSale && this.matter.isMatterProvinceON;
  }

  /**
   * Toggles the view and input values from multiple roll number input fields to a single readonly input field
   * @param event
   */
  handleF9OnRollNumber(event: KeyboardEvent): void {
    let target = event.target as Element;

    if (target.id.startsWith('rollnumber')) {
      this.controlRollNumberFields(false, dropDowns.rollNumberType[1]);
      setTimeout(() => this.noRollNumberFocusable.nativeElement.focus(), 0);
    } else if (target.id == 'noRollNumber' && this.propertyModel.rollNumberType === dropDowns.rollNumberType[1].value) {
      this.controlRollNumberFields(false, dropDowns.rollNumberType[2]);
      setTimeout(() => this.noRollNumberFocusable.nativeElement.focus(), 0);
    } else if (target.id == 'noRollNumber' && this.propertyModel.rollNumberType === dropDowns.rollNumberType[2].value) {
      this.controlRollNumberFields(true, dropDowns.rollNumberType[0]);
      setTimeout(() => this.rollNumberFocusable.nativeElement.focus(), 0);
    }
  }

  public controlRollNumberFields(showRollNumbers: boolean, rollNumberType): void {
    this.isRollNumberFieldsVisible = showRollNumbers;

    this.propertyModel.rollNumberType = rollNumberType.value;
    this.rollNumberType = rollNumberType.label;

    this.formChangeAfterInit();
  }

  isReadOnlyForSurveyor(): boolean {
    return !!this.matter.surveyorMatterParticipant;
  }

  get surveyorDisplayName(): string {
    const surveyorMatterParticipant: MatterParticipant = this.matter && this.matter.surveyorMatterParticipant;
    return surveyorMatterParticipant && surveyorMatterParticipant.contact
      ? surveyorMatterParticipant.contact.organizationName
      : '';
  }

  get residentAssociationDisplayName(): string {
    const surveyorMatterParticipant: MatterParticipant =
      this.matter && this.matter.residentAssociationMatterParticipant;
    if (surveyorMatterParticipant && surveyorMatterParticipant.contact) {
      this.residentAssociationReadOnly = true;
      return surveyorMatterParticipant.contact.organizationName;
    } else {
      this.residentAssociationReadOnly = false;
      return '';
    }
  }

  deleteSurveyor(): void {
    const tmpSurveyorMatterParticipant = this.matter.surveyorMatterParticipant;
    let deleteConfirmationMessage = 'Proceed to remove this Surveyor from this matter?';
    deleteConfirmationMessage = this.contactChangesListener.checkParticipantsEventsAndConstructDeleteMessage(
      deleteConfirmationMessage,
      this.matter,
      tmpSurveyorMatterParticipant,
      MatterParticipantRoleTypes.SURVEYOR
    );
    this.dialogService.confirm('Confirmation', deleteConfirmationMessage, false, 'Delete').subscribe((res) => {
      if (res) {
        this.enableSave();

        if (tmpSurveyorMatterParticipant) {
          this.matter.removeMatterParticipant(tmpSurveyorMatterParticipant);
        }
        this.selectedSurveyorContact = null;
        this.attentionList = [];
        this.dialogService.setFocusOnAutocomplete('autocomplete-surveyor');
        this.handleSurveyorContactChange(tmpSurveyorMatterParticipant, true);
      }
    });
  }

  selectRegistry(): void {
    this.dialogService.matDialogContent({
      content: RegistryOfficeDialogComponent,
      context: {matter: this.matter},
      onFulfillment: (result) => {
        if (result && result.selectedRegistryOffice && result.selectedRegistryOffice.length > 0) {
          this.propertyModel.registryOfficeName = result.selectedRegistryOffice[0].officeName;
          this.propertyModel.registryOffice = result.selectedRegistryOffice[0];
        }
      }
    });
    this.enableSave();
  }

  getIndexJurisdictionKey(jurisdictionKeyFound: any): number {
    return _.findIndex(
      jurisdictionKeyFound.f9Options,
      (f9OptionVal) =>
        f9OptionVal &&
        this.propertyModel.nameOfCondominiumPlan &&
        f9OptionVal.toString().trim() == this.propertyModel.nameOfCondominiumPlan.trim()
    );
  }

  handleF9NameOfCondominiumPlan() {
    if (this.selectedJurisdiction && this.selectedJurisdiction.jurisdictionName) {
      let jurisdictionKeyFound = dropDowns.f9HelpNameOfCondominiumPlan.find(
        (jurisdictionKey) => this.selectedJurisdiction.jurisdictionName == jurisdictionKey.jurisdictionKey
      );

      if (jurisdictionKeyFound) {
        let indexForOption: number = this.getIndexJurisdictionKey(jurisdictionKeyFound);
        if (indexForOption == jurisdictionKeyFound.f9Options.length - 1) {
          this.propertyModel.nameOfCondominiumPlan = jurisdictionKeyFound.f9Options[0];
        } else {
          this.propertyModel.nameOfCondominiumPlan = jurisdictionKeyFound.f9Options[indexForOption + 1];
        }
      } else {
        if (this.selectedJurisdiction.city && this.selectedJurisdiction.city.municipality) {
          let appendMunicipality = this.selectedJurisdiction.city.municipality.name.substr(
            this.selectedJurisdiction.city.municipality.name.indexOf('of') + 3
          );
          let jurisdictionKeyNotFound = dropDowns.f9HelpNameOfCondominiumPlan.find((jurisdictionKey) =>
            this.isReservedDefaultKey(jurisdictionKey.jurisdictionKey)
          );
          if (this.propertyModel.nameOfCondominiumPlan) {
            let nameOfCondoPlanToMatch = this.propertyModel.nameOfCondominiumPlan.substr(appendMunicipality.length + 1);
            let indexForOption: number = _.findIndex(
              jurisdictionKeyNotFound.f9Options,
              (f9OptionVal) =>
                f9OptionVal && nameOfCondoPlanToMatch && f9OptionVal.trim() == nameOfCondoPlanToMatch.trim()
            );
            if (indexForOption == jurisdictionKeyNotFound.f9Options.length - 1) {
              this.propertyModel.nameOfCondominiumPlan =
                appendMunicipality + ' ' + jurisdictionKeyNotFound.f9Options[0];
            } else {
              this.propertyModel.nameOfCondominiumPlan =
                appendMunicipality + ' ' + jurisdictionKeyNotFound.f9Options[indexForOption + 1];
            }
          } else {
            this.propertyModel.nameOfCondominiumPlan = appendMunicipality + ' ' + jurisdictionKeyNotFound.f9Options[0];
          }
        }
      }
    } else {
      let jurisdictionKeyNotFound = dropDowns.f9HelpNameOfCondominiumPlan.find((jurisdictionKey) =>
        this.isReservedDefaultKey(jurisdictionKey.jurisdictionKey)
      );
      let indexForOption: number = this.getIndexJurisdictionKey(jurisdictionKeyNotFound);
      if (indexForOption == jurisdictionKeyNotFound.f9Options.length - 1) {
        this.propertyModel.nameOfCondominiumPlan = jurisdictionKeyNotFound.f9Options[0];
      } else {
        this.propertyModel.nameOfCondominiumPlan = jurisdictionKeyNotFound.f9Options[indexForOption + 1];
      }
    }
  }

  isReservedDefaultKey(keyValue: string): boolean {
    return keyValue && '<<DEFAULT>>' == keyValue;
  }

  //DPPMP-767
  generateF9NameOfCondominiumPlanHelpText(): string {
    let f9HelpText: string = '';

    if (this.selectedJurisdiction) {
      if (this.selectedJurisdiction.jurisdictionName) {
        let selectedJurisdictionName = this.selectedJurisdiction.jurisdictionName.toUpperCase().trim();
        let foundedF9HelpEntry = dropDowns.f9HelpNameOfCondominiumPlan.find(
          (f9HelpEntry) =>
            selectedJurisdictionName == f9HelpEntry.jurisdictionKey &&
            !this.isReservedDefaultKey(selectedJurisdictionName)
        );
        if (foundedF9HelpEntry) {
          f9HelpText =
            'Press F9 to toggle on ' + foundedF9HelpEntry.f9Options[0] + ' or ' + foundedF9HelpEntry.f9Options[1];
          return f9HelpText;
        } else {
          //If above Jurisdiction Name not found, and if the Municipality field is not blank then go for Municipality field
          if (
            this.selectedJurisdiction.city &&
            this.selectedJurisdiction.city.municipality &&
            this.selectedJurisdiction.city.municipality.name
          ) {
            let nameOfMunicipality = this.selectedJurisdiction.city.municipality.name;
            let loc = nameOfMunicipality.indexOf('of');
            if (loc > -1) {
              //get the remaining value after 'of' from municipality name
              let nameAfterOf = nameOfMunicipality.substr(loc + 2).trim();
              if (nameAfterOf) {
                //find the word 'of' in the Municipality field and if found delete everything up to and including 'of'
                // then add the remaining to the beginning of the defaultHelpText
                let foundedDefaultF9HelpEntry = dropDowns.f9HelpNameOfCondominiumPlan.find((f9HelpEntry) =>
                  this.isReservedDefaultKey(f9HelpEntry.jurisdictionKey)
                );
                f9HelpText = 'Press F9 to toggle on ' + nameAfterOf + ' ';
                f9HelpText += foundedDefaultF9HelpEntry.f9Options[0] + ' or ';
                f9HelpText += nameAfterOf + ' ';
                f9HelpText += foundedDefaultF9HelpEntry.f9Options[1];
                return f9HelpText;
              }
            }
          }
        }
      }
    }
    //If Jurisdiction and/or Municipality field is blank, then F9 = 'Common Elements Condominium Plan No.' or 'Standard Common Elements Condominium Plan No.'.
    let foundedDefaultF9HelpEntry = dropDowns.f9HelpNameOfCondominiumPlan.find((f9HelpEntry) =>
      this.isReservedDefaultKey(f9HelpEntry.jurisdictionKey)
    );
    f9HelpText =
      'Press F9 to toggle on ' +
      foundedDefaultF9HelpEntry.f9Options[0] +
      ' or ' +
      foundedDefaultF9HelpEntry.f9Options[1];
    return f9HelpText;
  }

  onBlurResidentAssociation(event) {
    setTimeout(() => {
      //console.warn("onBlurJurisdiction | selectedJurisdiction=", this.selectedJurisdiction);
      //let fieldKey = this.jurisdictionOmnibar.el.nativeElement.getAttribute("fieldKey");
      if (this.selectedJurisdiction && typeof this.selectedJurisdiction === 'string') {
        this.selectedJurisdiction = null;
      }
    }, 200);
  }

  onBlurJurisdiction(event) {
    setTimeout(() => {
      //console.warn("onBlurJurisdiction | selectedJurisdiction=", this.selectedJurisdiction);
      //let fieldKey = this.jurisdictionOmnibar.el.nativeElement.getAttribute("fieldKey");
      if (this.selectedJurisdiction && typeof this.selectedJurisdiction === 'string') {
        this.selectedJurisdiction = null;
      }
    }, 200);
  }

  onBlurSurveyor(event) {
    setTimeout(() => {
      //console.warn("onBlurSurveyor | selectedSurveyor=", this.selectedSurveyorContact);
      let fieldKey = this.surveyorOmnibar ? this.surveyorOmnibar.el.nativeElement.getAttribute('fieldKey') : '';
      if (this.selectedSurveyorContact && typeof this.selectedSurveyorContact === 'string') {
        this.selectedSurveyorContact = null;
        this.surveyorsLoading = false;
      }
    }, 200);
  }

  isPotlExpanded() {
    if (this.propertyModel.isParcelOfTiedLand === 'YES') {
      return true;
    } else {
      return false;
    }
  }

  get txnType(): string {
    if (this.matter?.isSale) {
      return 'Sale';
    }
    if (this.matter?.isMortgageBC) {
      return 'Purchase';
    } else if (this.matter?.isMortgage) {
      return 'NA';
    } else {
      return 'Purchase';
    }
  }

  isAddNew(contact: Contact): boolean {
    return contact && contact.displayName && contact.displayName.indexOf(Constants.ADD_NEW_RECORD) != -1;
  }

  isAddNewJurisdiction(jurisdictionName: string): boolean {
    return jurisdictionName && jurisdictionName.indexOf(Constants.ADD_NEW_RECORD) != -1;
  }

  isSpecifyApplicable(): boolean {
    return (
      (this.matter.isMatterProvinceAB ||
        this.matter.isMatterProvinceNS ||
        this.matter.isMatterProvinceNB ||
        this.matter.isMatterProvinceBC) &&
      this.propertyModel.interestEstate == 'OTHER'
    );
  }

  provinceBasedDataPopulationFunc = {
    AB: this.dataFromSelectedJurisdictionForAB
  };

  provinceBasedInitTask = {
    AB: this.initPropertyDataForAB
  };

  get legalDescriptionPlanBlockLot(): string {
    return this.matter && this.matter.legalDescriptionPlanBlockLot;
  }

  setOutOfRange(): void {
    if (this.isNotValidDates()) {
      this.outOfRange = true;
    } else {
      let daysDiff = this.getDateDiff(this.matter.getClosingDate(), this.propertyModel.fiscalYearEndDate);
      if (this.matter.isPaysForDateOfClosingPurchaser) {
        daysDiff++;
      }
      this.outOfRange = daysDiff < 0 || daysDiff > 365;
    }
  }

  isNotValidDates(): boolean {
    return (
      !this.matter ||
      Utils.isNotValidDate(this.matter.getClosingDate()) ||
      Utils.isNotValidDate(this.propertyModel.fiscalYearEndDate)
    );
  }

  get purchaserShareInDays() {
    if (this.isNotValidDates()) {
      this.outOfRange = true;
      return 'Out of range';
    }
    let daysDiff = this.getDateDiff(this.matter.getClosingDate(), this.propertyModel.fiscalYearEndDate);
    if (this.matter.isPaysForDateOfClosingPurchaser) {
      daysDiff++;
    }
    if (daysDiff < 0 || daysDiff > 365) {
      this.outOfRange = true;
      return 'Out of range';
    } else {
      this.outOfRange = false;
      return daysDiff + ' day' + (daysDiff != 1 ? 's' : '');
    }
  }

  getDateDiff(from: string, to: string): number {
    return Utils.getDateDiff(from, to);
  }

  get isMatterEditingDisabledAfterPostClosingDate(): boolean {
    return MatterUtil.isMatterEditingDisabledAfterPostClosingDate(this.matter);
  }

  calculateCreditAmount(): number {
    if (this.outOfRange) {
      this.propertyModel.purchaserShareAmount = 0;
    } else {
      if (!this.soAdjOtherProrated) {
        this.initiSoAdjOtherProrated();
      }
      this.propertyModel.purchaserShareAmount = this.soAdjOtherProrated.getCreditAmount(
        this.matter.getClosingDate(),
        StatementAdjustmentKey.HOME_OWNERS_RESIDENT_FEE,
        this.matter.paysForDateOfClosing
      );
    }
    return this.propertyModel.purchaserShareAmount;
  }

  loadHomeOwnersResidentFeeSOA(newFlag?: boolean): void {
    this.setOutOfRange();
    //Fix the undefine issue
    let statementAdjustment =
      this.matter.finalStatementAdjustments &&
      this.matter.finalStatementAdjustments.find((item) => {
        return item.itemKey == StatementAdjustmentKey.HOME_OWNERS_RESIDENT_FEE && !item.addedFlag;
      });
    if (!this.outOfRange) {
      // DPPMP-24975 - T022-E070-U070-033 - Subject Property - Part 6 - Residents' Association Fee days and Out of Range - AB
      // IF the "Homeowners resident's Fee" Adjustment has already been created AND
      // The calculation of # of days as described in the story above, results in the number of days becoming 'out of range',
      // the automatically created "Homeowners resident's Fee" Adjustment should be set to out of range (as described in this story: DPPM-T022-E020-U180-61 – Out of range error on an Adjustment after the Adjustment date has been change) rather than deleted
      if (!this.soAdjOtherProrated) {
        this.initiSoAdjOtherProrated();
      }

      // let statementAdjustment = this.matter.statementOfAdjustments.find((item) => {
      //     return item.itemKey == StatementAdjustmentKey.HOME_OWNERS_RESIDENT_FEE && !item.addedFlag;
      // });
      if (statementAdjustment) {
        if (newFlag) {
          statementAdjustment.soAdjHomeOwnersResidentFee = new SoAdjOtherProrated(this.soAdjOtherProrated);
        } else {
          this.soAdjOtherProrated = statementAdjustment ? statementAdjustment.soAdjHomeOwnersResidentFee : undefined;
        }
      }
      this.createHomeOwnersResidentFeeSOA(statementAdjustment);
    }
    if (!this.propertyModel.fiscalYearEndDate) {
      this.removeAdjHomeOwnersResidentFee();
    }
    this.calculateCreditAmount();
  }

  createHomeOwnersResidentFeeSOA(statementAdjustment: StatementAdjustment): void {
    let previousIndex: number = this.matter.finalStatementAdjustments.length;
    if (statementAdjustment && statementAdjustment.soAdjHomeOwnersResidentFee) {
      previousIndex = this.matter.finalStatementAdjustments.indexOf(statementAdjustment);
      this.removeAdjHomeOwnersResidentFee(statementAdjustment);
      statementAdjustment.soAdjHomeOwnersResidentFee = new SoAdjOtherProrated(this.soAdjOtherProrated);
    } else {
      if (!statementAdjustment) {
        statementAdjustment = new StatementAdjustment(this.matter.adjustmentStatusMode, this.matter.provinceCode);
        statementAdjustment.adjustmentStatus = ProgressionStatus.FINAL;
        statementAdjustment.itemKey = StatementAdjustmentKey.HOME_OWNERS_RESIDENT_FEE;
        statementAdjustment.addedFlag = false;
      }
      statementAdjustment.soAdjHomeOwnersResidentFee = new SoAdjOtherProrated(this.soAdjOtherProrated);
    }

    statementAdjustment.amount = statementAdjustment.soAdjHomeOwnersResidentFee.getCreditAmount(
      this.matter.getClosingDate(),
      StatementAdjustmentKey.HOME_OWNERS_RESIDENT_FEE,
      this.matter.paysForDateOfClosing
    );
    statementAdjustment.description = statementAdjustment.soAdjHomeOwnersResidentFee.heading;
    statementAdjustment.statementOfAdjustmentCreditType = statementAdjustment.soAdjHomeOwnersResidentFee.getCreditType(
      this.matter.getClosingDate(),
      StatementAdjustmentKey.HOME_OWNERS_RESIDENT_FEE,
      this.matter.paysForDateOfClosing
    );
    this.matter.finalStatementAdjustments.splice(previousIndex, 0, statementAdjustment);
    this.calculateCreditAmount();
  }

  initiSoAdjOtherProrated(): void {
    this.soAdjOtherProrated = new SoAdjOtherProrated();
    this.soAdjOtherProrated.amountToBeProrated = this.propertyModel.annualFee;
    this.soAdjOtherProrated.commencementDate = this.calculateCommencementDate();
    this.soAdjOtherProrated.expiryDate = this.propertyModel.fiscalYearEndDate;
    this.soAdjOtherProrated.direction = 'PAYABLE';
    this.soAdjOtherProrated.vendorPaidFullAmount = true;
    this.soAdjOtherProrated.amountReceived = 0;
    this.soAdjOtherProrated.taxType = this.matter.matterTaxType;
    this.soAdjOtherProrated.applyTax = false;
    this.soAdjOtherProrated.applyPst = false;
    this.soAdjOtherProrated.hstRate = this.matter.matterHst;
    this.soAdjOtherProrated.gstRate = this.matter.matterFederalHst;
    this.soAdjOtherProrated.pstRate = this.matter.matterProvincialHst;
    this.soAdjOtherProrated.heading = "HOMEOWNERS RESIDENT'S FEE";
  }

  calculateCommencementDate(): string {
    //<Fiscal Year End – 1 Year> + 1 day
    let commencementDate = moment(this.propertyModel.fiscalYearEndDate, 'YYYY/MM/DD')
      .subtract(1, 'years')
      .add(1, 'days');
    return moment(commencementDate).format('YYYY/MM/DD');
  }

  get rprApplicableText(): string {
    return this.matter && (this.matter.isMatterProvinceSK || this.matter.isMatterProvinceMB)
      ? this.propertyModel && this.propertyModel.rprApplicable
        ? ''
        : 'Building Location Certificate Date and Surveyor Not Applicable'
      : this.propertyModel && this.propertyModel.rprApplicable
        ? ''
        : 'Real Property Report Date and Surveyor Not Applicable';
  }

  openHomeOwnersResidentFeeModal(): void {
    if (!this.outOfRange) {
      let statementAdjustment = this.matter.statementOfAdjustments.find((item) => {
        return item.itemKey == StatementAdjustmentKey.HOME_OWNERS_RESIDENT_FEE && !item.addedFlag;
      });
      this.soAdjOtherProrated = statementAdjustment ? statementAdjustment.soAdjHomeOwnersResidentFee : undefined;
      if (!this.soAdjOtherProrated) {
        this.initiSoAdjOtherProrated();
      }

      this.dialogService.matDialogContent({
        content: OtherProratedModalComponent,
        context: {
          closingDate: this.matter.getClosingDate(),
          otherProrated: this.soAdjOtherProrated,
          taxRateType: this.soaConsiderationTaxes.rateType,
          matter: this.matter,
          statementAdjustmentHeading: this.matter.statementOfAdjustmentHeading,
          statementAdjustmentKey: StatementAdjustmentKey.HOME_OWNERS_RESIDENT_FEE,
          addedFlag: false
        },
        onFulfillment: (result) => {
          if (result) {
            if (result != ModalResult.Cancel) {
              if (result == ModalResult.Delete) {
                if (statementAdjustment) {
                  this.matter.removeStatementAdjustment(statementAdjustment);
                }
              } else {
                this.onHomeOwnersResidentFeeModalUpdate(result, statementAdjustment);
              }
              this.enableSave();
            }
          }
        }
      });
    }
  }

  onHomeOwnersResidentFeeModalUpdate(result: SoAdjOtherProrated, statementAdjustment: StatementAdjustment): void {
    this.soAdjOtherProrated = new SoAdjOtherProrated(result);
    this.soAdjOtherProrated.id = undefined;
    this.createHomeOwnersResidentFeeSOA(statementAdjustment);
  }

  isFarmlandEvidenceOther(): boolean {
    return this.propertyModel.farmlandEvidence == 'OTHER';
  }

  farmlandEvidenceChange(event): void {
    this.onChange(event);
    if (event != 'OTHER') {
      this.propertyModel.farmlandSection = '';
    }
  }

  addToBurgerMenu(burgerMenuItems: BurgerMenuExtendedItem[], text: string, action?: any): void {
    let burgerMenuItem: BurgerMenuExtendedItem;
    burgerMenuItem = new BurgerMenuExtendedItem();
    burgerMenuItem.text = text;
    burgerMenuItem.action = action;
    burgerMenuItems.push(burgerMenuItem);
  }

  clickDropDownMenu(
    clickedMenuOption: BurgerMenuExtendedItem,
    titleRollNumber?: ParcelLegalDescription,
    index?: number
  ): void {
    if (clickedMenuOption.action && typeof clickedMenuOption.action === 'function') {
      clickedMenuOption.action(titleRollNumber, index);
    }
  }

  loadRollNumbersActionsBurgerMenu(): void {
    this.addToBurgerMenu(this.rollNumbersActions, 'Edit', this.editRollNumber);
    this.addToBurgerMenu(this.rollNumbersActions, 'Delete', this.deleteRollNumber);
    this.addToBurgerMenu(this.emptyRollNumbersActions, 'Edit', this.editRollNumber);
  }

  editRollNumber = (titleRollNumber?: ParcelLegalDescription, order?: number) => {
    let titleRollNumberOrder = order
      ? order
      : this.propertyModel.parcelLegalDescriptions && this.propertyModel.parcelLegalDescriptions.length
        ? this.propertyModel.parcelLegalDescriptions.length + 1
        : 1;
    let statementOfAdj = titleRollNumber
      ? this.matter.statementOfAdjustments.find((item) => item.parcelLegalDescriptionId == titleRollNumber.id)
      : null;
    this.dialogService.matDialogContent({
      content: TitleRollNumberModal,
      context: {
        titleRollNumber: titleRollNumber,
        order: titleRollNumberOrder,
        isCondominium: this.propertyModel.isCondominium,
        statementAdjustment: statementOfAdj,
        soaConsiderationTaxes: this.soaConsiderationTaxes,
        matter: this.matter
      },
      modalGrid: 7,
      onFulfillment: (result: any) => {
        if (result) {
          if (result.titleRollNumber) {
            this.addNewTitleRollNumberRequest = this.propertyModel.updateTitleRollNumber(
              this.matter,
              this.addNewTitleRollNumberRequest,
              titleRollNumber,
              result.titleRollNumber,
              result.linkedMatterTaxToBeRemoved,
              result.added
            );
            this.enableSave();
          }
        }
      }
    });
  };

  deleteRollNumber = (titleRollNumber: ParcelLegalDescription) => {
    if (titleRollNumber) {
      this.propertyModel.deleteRollNumber(titleRollNumber, this.matter);

      this.enableSave();
    }
  };

  onSeparateCommonElementFeeAdjChange(event): void {
    this.enableSave();
    let commonExpensestatementOfAdjustments = this.matter.statementOfAdjustments.filter((item) =>
      item.isCommonExpenseAdjustmentFromProperty()
    );
    commonExpensestatementOfAdjustments.forEach((adj) => {
      (<any>this.matter.statementOfAdjustments).remove(adj);
    });
    if (this.propertyModel.separateCommonElementFeeAdj) {
      this.propertyModel.parcelLegalDescriptions.forEach((item) => {
        this.matter.createUpdateAdjustmentCommonExp(item);
      });
    } else {
      this.matter.createUpdateAdjustmentCommonExp();
    }
  }

  // If change isNewTitleRollNumberVisible,
  // please also update isNewTitleRollNumberVisible method in lender-deal-data.component.ts
  isNewTitleRollNumberVisible(): boolean {
    return (
      this.addNewTitleRollNumberRequest ||
      !this.propertyModel.parcelLegalDescriptions ||
      (this.propertyModel.parcelLegalDescriptions && !this.propertyModel.parcelLegalDescriptions.length)
    );
  }

  isOverrideLegalDescriptionFromTPRVisible(): boolean {
    return (
      this.matter &&
      !this.matter.isCustomMatter() &&
      this.matter.isMatterProvinceMB &&
      this.propertyModel.parcelLegalDescriptions &&
      this.propertyModel.parcelLegalDescriptions.length > 0 &&
      this.matter.matterPropertyWithCondo.importedParcelRegisterIds &&
      this.matter.matterPropertyWithCondo.importedParcelRegisterIds.length > 0
    );
  }

  onOverrideLegalDescriptionFromTPR(event) {
    this.enableSave();
  }

  showNewTitleRollNumber(): void {
    if (this.propertyModel && this.propertyModel.isAllowedToAddParcelDescription()) {
      this.editRollNumber();
    }
  }

  getNewTitleRollNumberOrder(): number {
    return this.propertyModel.getNewTitleRollNumberOrder();
  }

  get legalDescriptionSummaryMB(): string {
    if (!this.propertyModel || (this.matter && this.matter.isCustomMatter())) {
      return '';
    }
    if (this.matter.isMatterProvinceMB) {
      this.propertyModel.formatLegalDescriptionSummary();
    }
    return this.propertyModel.legalDescriptionSummary;
  }

  set legalDescriptionSummaryMB(val: string) {
    this.propertyModel.legalDescriptionSummary = val;
  }

  getBurgerMenuItemsParcel(): string[] {
    return MatterPropertyUtil.getBurgerMenuItemsParcel();
  }

  clickParcelBurgerMenuItem(event, parcelLegalDescription: ParcelLegalDescription, index: number) {
    switch (event) {
      case BurgerMenuPropertyTeranetParcel.DELETE:
        this.deleteParcel(parcelLegalDescription);
        break;
      case BurgerMenuPropertyTeranetParcel.EDIT:
        this.openParcelModal(parcelLegalDescription, index);
        break;
    }
  }

  updateCondoFeeInSoa(): void {
    MatterPropertyUtil.updateCondoFeeInSoa(this.matter);
  }

  openParcelModal(parcelLegalDescription: ParcelLegalDescription, index) {
    this.dialogService.matDialogContent({
      content: ParcelLegalDescriptionModalComponent,
      context: {
        matter: this.matter,
        matterProperty: this.propertyModel,
        parcelLegalDescription: parcelLegalDescription
      },
      onFulfillment: (result) => {
        if (result && result.localParcelLegalDescription) {
          this.updateParcelFieldDescription(result.localParcelLegalDescription);
          if (
            this.matter.matterPropertyWithCondo.parcelLegalDescriptions.length > 0 &&
            index < this.matter.matterPropertyWithCondo.parcelLegalDescriptions.length
          ) {
            this.matter.matterPropertyWithCondo.parcelLegalDescriptions[index] = _.cloneDeep(
              result.localParcelLegalDescription
            );
            this.updateParcelValues();
            if (this.matter.isMatterProvinceSK) {
              this.matter.checkHSTRebateToDisplayWarningMessage(this.errorService);
            }
            this.enableSave();
          }
        } else {
          // user clicked cancel button in modal
          if (!this.isParcelCancel(this.matter.matterPropertyWithCondo.parcelLegalDescriptions[index])) {
            this.deleteParcel(this.matter.matterPropertyWithCondo.parcelLegalDescriptions[index]);
          }
        }
      }
    });
  }

  updateParcelFieldDescription(parcelLegalDescription: ParcelLegalDescription): string {
    return MatterPropertyUtil.updateParcelFieldDescription(
      parcelLegalDescription,
      this.propertyModel,
      this.matter.provinceCode
    );
  }

  updateParcelValues(): void {
    MatterPropertyUtil.updateParcelValues(
      this.matter,
      this.provinceHstRateSlab,
      this.soaConsiderationTaxes,
      this.considerationTaxes,
      this.manitobaLTTTiers,
      this.statementAdjustmentDisplayUtil,
      this.brokerCommissionComponent,
      this.matterService
    );
  }

  addTotalValueOfParcels(): number {
    return MatterPropertyUtil.addTotalValueOfParcels(this.matter);
  }

  calculateCondoFeesFromParcels(): number {
    return MatterPropertyUtil.calculateCondoFeesFromParcels(this.matter);
  }

  addParcel(): void {
    MatterPropertyUtil.addParcel(this.matter);
  }

  addParcelAndOpenModal() {
    this.addParcel();
    this.openParcelModal(
      this.matter.matterPropertyWithCondo.parcelLegalDescriptions[
        this.matter.matterPropertyWithCondo.parcelLegalDescriptions.length - 1
      ],
      this.matter.matterPropertyWithCondo.parcelLegalDescriptions.length - 1
    );
  }

  deleteParcel(parcelLegalDescription: ParcelLegalDescription): void {
    MatterPropertyUtil.deleteParcel(
      parcelLegalDescription,
      this.matter,
      this.errorService,
      this.provinceHstRateSlab,
      this.soaConsiderationTaxes,
      this.considerationTaxes,
      this.manitobaLTTTiers,
      this.statementAdjustmentDisplayUtil,
      this.brokerCommissionComponent,
      this.matterService
    );
    this.enableSave();
  }

  clickCommonElementFeesEditBtn(): void {
    let statementAdjustment = this.matter.statementOfAdjustments.find((soa) =>
      soa.isCommonExpenseAdjustmentFromProperty()
    );
    let soaCommonExpense = statementAdjustment ? statementAdjustment.soaCommonExpense : undefined;
    this.dialogService.matDialogContent({
      content: SoaCommonExpenseModal,
      context: {
        closingDate: this.matter.getClosingDate(),
        provinceHstRateSlab: this.soaConsiderationTaxes,
        soaCommonExpense: soaCommonExpense,
        expenseType: StatementAdjustmentExpenseTypes.COMMON_EXPENSE,
        statementAdjustmentHeading: this.matter.statementOfAdjustmentHeading,
        addedFlag: false,
        currentMatter: this.matter,
        isMBEditBtnVisible: false
      },
      onFulfillment: (result) => {
        if (result) {
          if (result.soaCommonExpense) {
            this.onUpdateCommonExpense(result.soaCommonExpense, statementAdjustment);
          }
        }
        this.enableSave();
      }
    });
  }

  onUpdateCommonExpense(soaCommonExpense: SoaCommonExpense, statementAdjustment: StatementAdjustment): void {
    statementAdjustment.soaCommonExpense = new SoaCommonExpense(soaCommonExpense);
    statementAdjustment.updateFromCommonExpense(this.matter.getClosingDate(), this.matter.isPaysForDateOfClosingVendor);
  }

  get isAddAnotherParcelLinkVisible(): boolean {
    return MatterPropertyUtil.isAddAnotherParcelLinkVisible(this.matter);
  }

  isParcelEmpty(parcelLegalDescription?: ParcelLegalDescription): boolean {
    return MatterPropertyUtil.isParcelEmpty(this.propertyModel, parcelLegalDescription);
  }

  validateParcelData(parcelLegalDescription: ParcelLegalDescription): boolean {
    return MatterPropertyUtil.validateParcelData(parcelLegalDescription, this.propertyModel);
  }

  isParcelCancel(parcelLegalDescription: ParcelLegalDescription): boolean {
    return MatterPropertyUtil.isParcelCancel(parcelLegalDescription);
  }

  initializeSoAdjUtil(defaultDocumentProfile?: DocumentProfile): void {
    this.statementAdjustmentDisplayUtil = new StatementAdjustmentDisplayBuilder(
      this.decimalPipe,
      this.currencyPipe,
      this.percentPipe
    );
    this.statementAdjustmentDisplayUtil.documentProfileCache = this.documentProfileCache;
    this.statementAdjustmentDisplayUtil.setMatter(this.matter);
    this.statementAdjustmentDisplayUtil.setSoaConsiderationTaxes(this.provinceHstRateSlab);
    this.statementAdjustmentDisplayUtil.matterDocumentProfile = defaultDocumentProfile
      ? defaultDocumentProfile
      : undefined;
    this.taxRateServiceSub = this.taxRateService
      .cachedRentInterestRates(this.statementAdjustmentDisplayUtil.matter.provinceCode)
      .subscribe((rirList: RentInterestRate[]) => {
        if (rirList && rirList.length > 0) {
          let rentInterestRates = rirList.map((r) => new RentInterestRate(r));
          let rentInterestRatesSorted = _.sortBy(rentInterestRates, ['index']);
          this.statementAdjustmentDisplayUtil.setRentInterestRates(rentInterestRatesSorted);
        }
      });

    this.tarionRatesSub = this.taxRateService
      .cachedTarionWarrantyEnrolmentPeriods()
      .subscribe((twepList: TarionWarrantyEnrolmentPeriod[]) => {
        if (twepList && twepList.length > 0) {
          this.statementAdjustmentDisplayUtil.setTarionWarrantyEnrolmentPeriods(twepList);
        }
      });
  }

  get affidavitTobeSignedByItemLable(): string {
    let label: string = 'Client';
    if (this.propertyModel && this.propertyModel.affidavitTobeSignedBy) {
      let affidavitTobeSignedByOption = AffidavitTobeSignedByOptions.find(
        (item) => item.value === this.propertyModel.affidavitTobeSignedBy
      );
      if (affidavitTobeSignedByOption) {
        label = affidavitTobeSignedByOption.label;
      }
    }
    return label;
  }

  getUnitLevelPlanFieldLabelForPS(): string {
    return this.matter.getUnitLevelPlanFieldLabelForPS();
  }

  overrideDescChange(matterProperty?: MatterProperty) {
    this.enableSave();
    if (matterProperty) {
      if (!matterProperty.overrideDescription) {
        matterProperty.descriptionOverriddenType = null;
        matterProperty.fullLegalDescription = null;
      } else if (matterProperty.overrideDescription && !matterProperty.descriptionOverriddenType) {
        matterProperty.descriptionOverriddenType = dropDowns.teraviewLegalDescOverriddenTypes[0].value;
      }
    } else {
      if (!this.propertyModel.overrideDescription) {
        this.propertyModel.descriptionOverriddenType = null;
        this.propertyModel.fullLegalDescription = null;
      } else if (this.propertyModel.overrideDescription && !this.propertyModel.descriptionOverriddenType) {
        this.propertyModel.descriptionOverriddenType = dropDowns.teraviewLegalDescOverriddenTypes[0].value;
      }
    }
  }

  updateTeraviewLegalDescs(matterProperty?: MatterProperty): void {
    this.enableSave();
    if (this.matter.isProjectSale && this.matter.isMatterProvinceON) {
      if (matterProperty) {
        matterProperty.fullLegalDescription = '';
      }
      this.matter.updateTeraviewLegalDescs();
    }
  }

  getMaxAddressLength(): number {
    return this.matter.isMatterProvinceON
      ? Address.ADDRESS_LINE_DEFAULT_MAX_LENGTH
      : Address.ADDRESS_LINE_INCREASED_MAX_LENGTH;
  }

  isOverrideDescVisible(): boolean {
    return this.matter.registrationMethodCode == constValues.registrationMethodsType.electronic;
  }

  isMassUpdateTab(): boolean {
    return this.tabsService && this.tabsService.activeTab && this.tabsService.activeTab.isMassUpdateSubType();
  }

  refreshSubject(subject: Subject<string>): Subject<string> {
    return Utils.refreshSubject(subject);
  }

  onDepositHeldByChange(event) {
    this.enableSave();
    if (this.matter.isSale && !this.matter.isProjectSale && this.brokerCommissionComponent) {
      this.brokerCommissionComponent.onDepositHeldByChange();
    }
    if (
      (this.matter.isPurchase && event == constValues.depositHeldBy.PURCHASER_LAWYER) ||
      (this.matter.isSale && event == constValues.depositHeldBy.VENDOR_LAWYER)
    ) {
      if (this.matter.matterPropertyWithCondo) {
        this.matter.matterPropertyWithCondo.payDepositOutOfTrust = DpBooleanValueTypes.YES;
      }
    }
    this.matter.reCalculateTrustLedgerReceivedDepositHeldInTrust();
  }

  //From matter-list switch to project tab, there is no matter, this component is injected.
  // so it need check if matter is null for getter
  /*get isAddAnotherPinDisplayed(): boolean{
        return this.matter && !this.matter.isTemplateMatterForMassUpdate
            && this.propertyModel.isCondominium  != 'YES'
            &&  this.matter.matterProperties.length < 99;
    }*/

  validatePinWithPan(matterProperty: MatterProperty, identifier: number, index: number, isPin1: boolean) {
    if (this.matter.isMatterProvinceNBorNS) {
      this.errorService.removeDpFieldError(
        this.getPinFieldKey(isPin1),
        'pinnumber_' + String(matterProperty.identifier)
      );
      this.errorService.removeDpFieldError('pan' + String(identifier));

      if (matterProperty.pin && !matterProperty.assessmentAccountNumberSummary) {
        let accountName = this.matter.isMatterProvinceNB ? 'PAN' : 'AAN';
        this.errorService.addDpFieldError(
          DPError.createCustomDPError(
            'pan' + String(identifier),
            accountName + `${index} is required`,
            'ERROR',
            'ERROR'
          )
        );
      }
      if (!matterProperty.pin && matterProperty.assessmentAccountNumberSummary) {
        this.errorService.addDpFieldError(
          DPError.createCustomDPError(
            this.getPinFieldKey(isPin1),
            `PID Number${index} is required`,
            'ERROR',
            'ERROR',
            null,
            'pinnumber_' + matterProperty.identifier
          )
        );
      }
    }
  }

  getROfWCovenantsPropertyTab(): string {
    /*const parts: string[] = [this.propertyModel.interestToBenefitParcel == DpBooleanValueTypes.YES ? 'Interest to Benefit' : null,
                                 this.propertyModel.interestToBurndenParcel == DpBooleanValueTypes.YES ? 'Interest to Burden' : null,
                                 this.propertyModel.subjectToCovenantsAndConditions == DpBooleanValueTypes.YES ? 'Covenants' : null];
        const retString = parts.filter(Boolean).join(" + ");*/
    const retString = this.propertyModel.getROfWCovenants();
    return retString ? retString : 'None';
  }

  getForm54DefaultText(): string {
    return this.propertyModel.form54.isDefaultValues() ? 'Default Responses' : 'NON-DEFAULT';
  }

  getForm54SignedByText(): string {
    if (this.matter.isMatterProvinceNS) {
      return 'to be signed by solicitor';
    } else if (this.matter.isMatterProvinceNB) {
      if (this.propertyModel.form54) {
        if (this.propertyModel.form54.form54SignedBy == 'CLIENT') {
          return 'to be signed by client';
        } else if (this.propertyModel.form54.form54SignedBy == 'SOLICITOR') {
          return 'to be signed by solicitor';
        }
      }
    }
    return '';
  }

  openForm54Modal(): void {
    let year: number = this.matter.matterCloseDate ? Number(this.matter.matterCloseDate.split('/')[0]) : null;
    if (!year) {
      year = Number(SharedUtils.getYear(this.matter.createdTimeStamp));
    }
    this.dialogService.matDialogContent({
      content: Form54ModalComponent,
      context: {
        form54: this.propertyModel.form54,
        purchasePrice: this.propertyModel.purchasePrice,
        year: year
      },
      onFulfillment: (result: any) => {
        if (result.action == 'OK' && result.updatedForm54) {
          this.propertyModel.form54 = result.updatedForm54;
          this.enableSave();
        }
      }
    });
  }

  isLinkedMatterProjectSale(): boolean {
    return (
      this.matter &&
      this.matter.matterLink &&
      this.activeMatterTab &&
      this.activeMatterTab.isMatter() &&
      this.activeMatterTab.isLinkedMatterProjectSale()
    );
  }

  isDischargeMatter(): boolean {
    return this.matter && !!this.matter.isMatterTypeDischarge;
  }

  generateF9BuilderNumberHelpText(): string {
    let f9HelpText: string = '';
    if (this.matter.isProjectSale && this.matter.project && this.matter.project.tarionNo) {
      f9HelpText = `F9 = Project Record Builder's Tarion (V/B) No. ${this.matter.project.tarionNo}`;
    }
    return f9HelpText;
  }

  handleF9BuilderNumber(): void {
    if (this.matter.isProjectSale && this.matter.project && this.matter.project.tarionNo) {
      this.propertyModel.builderNumber = this.matter.project.tarionNo;
    }
  }

  handleSurveyorContactChange(surveyorMatterParticipant: MatterParticipant, isDeleted?: boolean) {
    if (this.matter && surveyorMatterParticipant && surveyorMatterParticipant.contact) {
      this.contactChangesListener.handleContactChange(
        this.matter,
        surveyorMatterParticipant,
        MatterParticipantRoleTypes.SURVEYOR,
        this.tabsService,
        isDeleted
      );
    }
  }

  isLinkFieldNotDisable(): boolean {
    return !this.matter.locked && !this.matter.refunded && this.isLinkedMatterProjectSale();
  }

  isOpenedFromWizardBeforeMatterSave(): boolean {
    return !!this.showWizardFields && !!this.dpReadOnlyBlock;
  }

  updateF9DefaultSoaForNB(): void {
    if (this.matter && this.matter.isMatterProvinceNB && this.matter.soaTrustLedgerCollection) {
      this.matter.soaTrustLedgerCollection.updateF9DefaultSoa();
      this.matter.soaTrustLedgerCollection.updateF9DefaultTrustLedger();
      if (this.matter.secondarySoaSheetsCollection) {
        this.matter.secondarySoaSheetsCollection.forEach((collection) => {
          collection.updateF9DefaultSoa();
          collection.updateF9DefaultTrustLedger();
        });
      }
    }
  }

  onPurchasePriceChange(event): void {
    if (this.matter && this.matter.isMatterProvinceBC) {
      let salePrice: number = event.target.value;
      this.matter.matterPropertyWithCondo.purchasePriceType = StatementAdjustmentKey.SALE_PRICE;
      if (salePrice != undefined) {
        this.matter.matterPropertyWithCondo.purchasePrice = salePrice;
      }
      this.matterService.verifyPurchasePriceWithInstructionPurchasePrice(this.matter);
      this.enableSave();
    }
  }

  //Non-Resident Deed Transfer Tax
  resetNRDTTax() {
    this.propertyModel.nRDTTaxPercentage = this.propertyModel.isNRDTTax === 'YES' ? '100.00' : ZERO_INTEREST;

    this.propertyModel.nRDTTaxAssessedValue = 0;
    this.propertyModel.nRDTTax = 0;
  }

  onNRDTTaxValueChange() {
    this.resetNRDTTax();
    this.propertyModel.calculateNRDTTax();
    this.formChangeAfterInit();
  }

  onNRDTTaxPercentageChange() {
    this.propertyModel.calculateNRDTTax();
    this.enableSave();
  }

  onNRDTTaxAssessedValueChange(): void {
    this.propertyModel.calculateNRDTTax();
    this.enableSave();
  }

  getLincLabel() {
    return this.matter?.isMatterProvinceBC ? 'PID' : 'LINC';
  }

  getlegalDescriptionForBC(property: MatterProperty): void {
    if (property.shortLegalDescription) {
      property.partLot = property.shortLegalDescription;
    } else if (property.fullLegalDescription) {
      property.partLot = property.fullLegalDescription && property.fullLegalDescription.replace(/\n/g, ' ');
    } else {
      property.partLot = '';
    }
  }

  openUtilityModal(): void {
    this.dialogService.matDialogContent({
      content: PropertyUtilitiesModal,
      context: {
        propertyUtilitiesFee: {...this.matterUtility},
        matterType: this.matter.matterType
      },
      onFulfillment: (result) => {
        if (Number(result.propertyUtilitiesFee.estimatedPercentageIncrease)) {
          result.propertyUtilitiesFee.utilityAmount =
            Number(result.propertyUtilitiesFee.utilityAmount) +
            (Number(result.propertyUtilitiesFee.estimatedPercentageIncrease) / 100) *
              Number(result.propertyUtilitiesFee.utilityAmount);
        }
        if (this.propertyUtilityModel.utilitiesPaidInFull == 'YES') {
          result.propertyUtilitiesFee.prePaymentAmount = result.propertyUtilitiesFee.utilityAmount;
          this.propertyUtilityModel.prePaymentAmount = result.propertyUtilitiesFee.utilityAmount;
        } else if (
          this.propertyUtilityModel.utilitiesPaidInFull == 'NO' &&
          this.propertyUtilityModel.prePaymentAmount <= 0
        ) {
          result.propertyUtilitiesFee.prePaymentAmount = 0;
          this.propertyUtilityModel.prePaymentAmount = 0;
        }

        this.propertyUtilityModel.totalAmountFormatted = this.formattedTotalUtilityAmount(
          result.propertyUtilitiesFee.utilityAmount,
          result.propertyUtilitiesFee.prePaymentAmount,
          result.propertyUtilitiesFee.utilitiesPaidInFull
        );
        this.propertyModel.propertyUtilitiesFee=result.propertyUtilitiesFee;
      }
    });
  }

  formattedTotalUtilityAmount(utilityAmount: number, prePaymentAmount: number, utilitiesPaidInFull: string): string {
    let totalAmountFormatted: string = '';

    if (utilityAmount != undefined && utilityAmount != null) {
      totalAmountFormatted = 'Total ' + CommonUtils.formattedCurrencyValue(Number(utilityAmount));
    }
    if (prePaymentAmount != undefined || (prePaymentAmount != null && prePaymentAmount >= 0) ||utilitiesPaidInFull=="YES") {
      totalAmountFormatted = totalAmountFormatted + this.getTaxPartSeparator(totalAmountFormatted) + 'Paid ' + CommonUtils.formattedCurrencyValue(Number(prePaymentAmount));
    }
    return totalAmountFormatted;
  }

  getTaxPartSeparator(prefix: string) {
    return prefix ? '/' : '';
  }

  openStrataFeeModal(): void {
    this.dialogService.matDialogContent({
      content: PropertyStrataMaintenanceFeeModal,
      context: {
        strataMaintenanceFee: {...this.propertyModel.propertyStrataMaintenanceFee},
        matterCloseDate:
          this.matter.matterType == 'MORTGAGE' ? this.matter.matterCloseDate : this.matter.adjustAsAtClosingDate,
        matterType: this.matter.matterType
      },
      onFulfillment: (result) => {
        if (result && result.strataMaintenanceFee && result.isUpdated) {
          this.propertyModel.propertyStrataMaintenanceFee = result.strataMaintenanceFee;
          this.enableSave();
        }
      }
    });
  }

  onChangeInterestEstate(event: string) {
    if (this.matter.propertyTransferTax) {
      this.matter.propertyTransferTax.transferType = event;
    }
    this.onChange(event);
  }

  protected readonly DpBooleanValueTypes = DpBooleanValueTypes;
}
