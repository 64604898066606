import {Contact} from '../../shared/contact';
import {contactTypeMapping, ContactTypes} from '../../../contact/contact-type-mapping';
import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {Matter} from '../../shared/matter';
import {NewContactPrivateInfo} from '../../../contact/new-contact-private-info.component';
import {ApplicationError} from '../../../core/application-error';
import {ContactQueryService} from '../../../contact/contact-query.service';
import {ContactCommandService} from '../../../contact/contact-command.service';
import {ModalErrorComponent} from '../../../shared/error-handling/modal-error/modal-error.component';
import {ErrorService} from '../../../shared/error-handling/error-service';
import * as _ from 'lodash';
import {StatusBarService} from '../../../shared-main/status-bar.service';
import {StatusBarMessages} from '../../../shared-main/status-bar-messages';
import {DPError} from '../../../shared/error-handling/dp-error';
import {OtherContactInformation} from '../../../shared-main/other-contact-info-fields/other-contact-information';
import {DefaultFieldsService} from '../../../shared-main/default-fields.service';
import {SESSION_STORAGE_KEYS} from '../../../shared/session-storage-keys';
import {Address, SameAsAddressOption} from '../../shared/address';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {ModalF9HelpComponent} from '../../../shared/modal-f9-help/modal-f9-help.component';
import {DocumentProfileCache} from '../../../shared-main/document-profile-cache.service';
import {DocumentProfile} from '../../../admin/document-profile/document-profile';
import {DocumentProfileService} from '../../../admin/document-profile/document-profile-edit/document-profile.service';
import {FocusFirstElementModalDecorator} from '../../../shared-main/focus-first-element-modal-decorator';
import {MatterParticipant} from '../../shared/matter-participant';
import {FamilyLawAct, FlaData} from '../../shared/fla-data';
import {PurchaserFamilyLawAct} from '../../purchaser/family-law-act/purchaser-family-law-act';
import {Mortgage} from '../../shared/mortgage';
import {Subscription} from 'rxjs/Subscription';
import {LendingInstitution} from '../../../contact/lending-institution';
import {AddressDropdowns} from '../../../shared-main/address-Form/drop-downs';
import {AddressTypes} from '../../shared/address-types';
import {ContactService} from '../../../shared-main/contact.service';
import {dropDowns} from '../../../admin/accounts/shared/account-drop-downs';
import {SelectItem} from 'primeng/api';
import {ProvinceCode} from '../../../admin/accounts/shared/base-province';
import {provinceBasedFieldLabels} from '../../../shared-main/province-based-field-labels';
import Utils from '../../../shared-main/utils';
import {ContactNameUtil} from '../../shared/contact-name-util';
import {AddressUtil} from '../../shared/address-util';
import {GlobalLogger} from '../../../core/global-logger';
import {MatterParticipantService} from '../../matter-participant-service';
import {MatterParticipantRoleTypes} from '../../shared/matter-participant-role-types';
import {IdentificationRecord} from '../../shared/identification-record';
import {AppConfig} from '../../../shared-main/app-configuration';
import {provinceBasedContactTypeErrorMessages} from '../../../shared/error-handling/error-directive-mapping';
import {ContactTypeUtil} from '../../../contact/contact-type-util';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';
import {ContactChangesListener} from '../../../contact/contact-changes-listener';
import {PurchaserService} from '../../purchaser/purchaser.service';

declare var jQuery: any;

class CommonContactModalContext {
  public matter: Matter;
  public lender: LendingInstitution;
  public matterParticipant: MatterParticipant;
  public contact: Contact;
  public isLawFirm: boolean;
  public spouseNameOptions: any;
  public flaStatements: FlaData;
  public contactType: string;
  public contactName?: string;
  public referencePurchaser?: Contact;
  public  isRelatedPurchaser : boolean;
    public removeStatusCertificatePage : boolean;
    public isOtherParty : boolean;
    public matterParticipantRole : string;
    public mortgage : Mortgage;
    public isOnlySolicitor : boolean;
    public contactAddressType : string;
    public solicitorAddressDd : SameAsAddressOption[];
    public fieldPrefix : string;
    public mortgageeContact : Contact;
    public canAddNewManagementCompanyFromSearch: boolean;
    public parentContact : Contact;
    public brokerContact : Contact;
    public isVisibleWithoutSourceContact: boolean;
    public isPurchaserSideAgent: boolean;
    isVTBMortgagee: boolean;
    public selectCreateNewClient?: boolean;
    public isMainClient : boolean;
    public isSigningOfficer : boolean ;
    public reducedContactDetails :boolean;
    public gender:string;
    public showRelationshipGenders: boolean = false;
    public showMaleFemaleOnlyGenders: boolean = false;
    public cacheDocumentProfile: DocumentProfile;
    public onlyMatterLevelContact: boolean;
    public isSignerForOtherParty: boolean;
    public legacyContact : Contact;
    public legacyContactTitle : string;
    public isEdit : boolean = false;
}

@FocusFirstElementModalDecorator()
@Component({
  selector: 'dp-common-contact-dialog',
  templateUrl: 'common-contact-dialog.component.html',
  providers: [ErrorService, StatusBarService, DocumentProfileCache, DialogService, ContactChangesListener, PurchaserService]
})
export class CommonContactDialogComponent
  extends ModalComponent<CommonContactModalContext>
  implements OnInit, AfterViewInit
{
  public _brokerName: string;
  contact: Contact;
  subContact: Contact = null;
  contactUntouched: Contact;
  selectedContactType: any;
  selectedTab: string = 'Profile';
  isCommonContactFormDirty: boolean = false;
  isContactExist: boolean;
  isContactCheckRequestSent: boolean = false;
  hasFlaError: boolean = false;
  acted: boolean = false;
  cachedChangedValues: any;
  profileComponent: boolean;
  public defaultOtherContactInfo: OtherContactInformation[] = [];
  //This flag indicates whether source contact should be created or not. By default it's true except for public contact, other party and real estate agent.
  shouldCreateSourceContact: boolean = true;
  // insurerOrBroker : string;
  provinceReadOnly: boolean = false;
  solicitorAddressDdOptions: SameAsAddressOption[];
  signingOfficeText: string;

  myOwnAccountLawFirmErrorKey: string = 'CommonContactDialogComponent.isMyOwnAccountLawFirm';

  @ViewChild('newContactPrivateInfoComponent') newContactPrivateInfoComponent: NewContactPrivateInfo;
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  @ViewChild('modalF9HelpComponent') modalF9HelpComponent: ModalF9HelpComponent;

  constructor(
    public contactQueryService: ContactQueryService,
    public contactService: ContactService,
    public defaultFieldsService: DefaultFieldsService,
    public contactCommandService: ContactCommandService,
    public dialog: MatDialogRef<CommonContactDialogComponent>,
    public errorService: ErrorService,
    public statusBarService: StatusBarService,
    public globalLogger: GlobalLogger,
    public dialogService: DialogService,
    public matterParticipantService: MatterParticipantService,
    public documentProfileCache: DocumentProfileCache,
    public documentProfileService: DocumentProfileService,
    public appConfig: AppConfig,
    @Inject(MAT_DIALOG_DATA) context?: CommonContactModalContext
  ) {
    super(dialog, context);
  }

  get matter(): Matter {
    return this.context.matter;
  }

  getMatterProvince(): ProvinceCode {
    return this.matter ? this.matter.provinceCode : undefined;
  }

  updateSelectedContactTypeLabel(): void {
    if (this.isMatterParticipantRolePrivateLender()) {
      this.selectedContactType.label = 'Private Lender';
    }
  }

  async ngOnInit(): Promise<void> {
    if (
      this.context.matterParticipantRole == MatterParticipantRoleTypes.CONSENTING_SPOUSE &&
      this.context.onlyMatterLevelContact
    ) {
      this.shouldCreateSourceContact = false;
    }
    // For other side party link matter, we need show the Create Contact CheckBox.
    // The check box should be selected and disabled.
    if (this.isCreateSourceContactAutoEnabled()) {
      this.shouldCreateSourceContact = true;
    }
    if (this.context.cacheDocumentProfile) {
      //For client, in html we call dp-new-manage-contact, we can NOT wait setDocumentProfileCache to get cacheDocumentProfile
      // Avoid to set a empty default province address value with undefined cacheDocumentProfile, we pass it from outside.
      this.documentProfileCache.cacheDocumentProfile(this.context.cacheDocumentProfile);
    } else {
      if (this.context.matter && this.context.matter.documentProfileId) {
        await this.setDocumentProfileCache();
      } else {
        let documentProfile: DocumentProfile = await this.documentProfileService
          .getDefaultDocumentProfileOfLoggedInUserByDefaultProvince()
          .toPromise();
        this.documentProfileCache.cacheDocumentProfile(documentProfile);
      }
    }

    // TODO clean up because for solicitor( contactKey : 'LAWYER' and contactType : 'SOLICITOR')
    // So 'LAWYER' is passed
    if (this.context.matter?.isMatterProvinceBC) {
      this.selectedContactType = ContactTypeUtil.getLocalizedMapping(
        this.context.contactType,
        this.context.matter?.provinceCode
      );
    } else {
      this.selectedContactType = _.find(
        contactTypeMapping.CONTACTTYPES,
        (contactTypeObj) => contactTypeObj.contactKey === this.context.contactType
      );
    }

    this.updateSelectedContactTypeLabel();

    this.isCommonContactFormDirty = false;
    this.initializeContact(this.context);

    this.isContactExist = false;

    this.profileComponent =
      this.isOnlyProfileVisible() || this.isProfileIdDetailsVisible() || this.isProfileIdDetailsFlaCapacityVisible();
  }

  addNewLawFirm() {
    let subContact: Contact = new Contact(this.subContact);
    this.initializeContact(this.context);
    this.subContact = subContact;
    this.contact.subContacts = [];
    this.contact.subContacts.push(subContact);
    this.setSolicitorAddressDdOptions();
  }

  isMyOwnAccountLawFirm(): boolean {
    const accountId: number = Number(sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId));
    const legFirmId: number = Number(sessionStorage.getItem(SESSION_STORAGE_KEYS.legalFirmId));
    if (this.contact && this.contact.contactType == ContactTypes.LAW_FIRM) {
      return this.contact.customerAccountId == accountId && this.contact.sourceContactId == legFirmId;
    }

    return false;
  }

  selectedLawFirm() {
    if (!Array.isArray(this.contact.subContacts)) {
      this.contact.subContacts = [];
    }
    this.subContact.legalFirmId = this.contact.id;
    this.subContact.organizationId = this.contact.id;
    this.contact.subContacts.push(this.subContact);
    this.setSolicitorAddressDdOptions();
    let errorMsg: string = 'Adding a Solicitor to your Law Firm must be done through Staff Profile Maintenance';
    if (this.isMyOwnAccountLawFirm()) {
      this.errorService.addDpSaveError(
        DPError.createCustomDPError(this.myOwnAccountLawFirmErrorKey, errorMsg, 'ERROR', 'ERROR')
      );
    } else {
      this.errorService.removeDpSaveError(this.myOwnAccountLawFirmErrorKey);
    }
  }

  lawFirmChange(isAddNewLawFirm: boolean) {
    if (isAddNewLawFirm) {
      this.addNewLawFirm();
      this.errorService.removeDpSaveError(this.myOwnAccountLawFirmErrorKey);
    } else {
      this.selectedLawFirm();
    }
  }

  async setDocumentProfileCache(): Promise<void> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    if (this.context.matter && this.context.matter.documentProfileId) {
      let documentProfile: DocumentProfile = await this.documentProfileService
        .getById(this.context.matter.documentProfileId, accountId, false, this.context.matter)
        .toPromise();
      this.documentProfileCache.cacheDocumentProfile(documentProfile);
    }
  }

  ngAfterViewInit() {
    this.contactUntouched = new Contact(this.contact);
  }

  initializeContact(context: CommonContactModalContext): void {
    let contactName: string = null;

    //For following contact type shouldCreateSourceContact should be false by default
    if (
      this.selectedContactType.contactKey === 'TRANSFEROR' ||
      this.selectedContactType.contactKey === 'GUARANTOR' ||
      this.context.isVTBMortgagee
    ) {
      this.shouldCreateSourceContact = false;
    }

    if (this.context.isOtherParty) {
      if (
        (this.context.referencePurchaser && this.context.referencePurchaser.sourceContactId) ||
        this.context.selectCreateNewClient ||
        (this.context.matter && this.context.matter.matterLink)
      ) {
        this.shouldCreateSourceContact = true;
      } else {
        this.shouldCreateSourceContact = false;
      }
    }

    //referencePurchaser contactName is null.
    if (this.context.contactName) {
      // contactName = this.capitalizeTypedName(this.context.contactName);
      //According to DPPMP 28468, remove auto formatting
      contactName = this.context.contactName;
    }

    if (this.context.matterParticipant && this.context.matterParticipant.contact) {
      this.contact = this.context.matterParticipant.contact;
      this.contact.setContactBasedDefaultValue(this.selectedContactType.contactKey);
      this.contact.localGender = this.contact.gender;
    } else {
      if (this.isSingleSolicitorVisible()) {
        this.contact = this.context.contact;
        this.subContact =
          this.contact && Array.isArray(this.contact.subContacts) && this.contact.subContacts.length > 0
            ? this.contact.subContacts[this.contact.subContacts.length - 1]
            : null;
      } else {
        this.contact = new Contact();
        if (this.context.contactType === 'MORTGAGEE') {
          this.contact.depositsAccepted = false;
          this.contact.mortgagesRegistered = true;
          this.contact.setDefaultProvinceValue(
            this.documentProfileCache,
            this.matter && this.matter.provinceCode,
            this.contact.mailingAddress
          );
          this.contact.serviceAddress.sameAsAddressTypeCode = AddressTypes.mailing;
          this.contact.setDefaultProvinceValue(
            this.documentProfileCache,
            this.matter && this.matter.provinceCode,
            this.contact.serviceAddress
          );
          this.contact.reportAddress.sameAsAddressTypeCode = AddressTypes.mailing;
          this.contact.setDefaultProvinceValue(
            this.documentProfileCache,
            this.matter && this.matter.provinceCode,
            this.contact.reportAddress
          );
        }

        if (
          this.selectedContactType &&
          this.context.matterParticipant &&
          (this.selectedContactType.contactKey === 'CLIENT' || this.selectedContactType.contactKey === 'PRIVATE_LENDER')
        ) {
          this.context.matterParticipant.contact = this.contact;
        }
        this.contact.setDefaultValues(this.selectedContactType.contactKey);
        if (this.context.legacyContact) {
          const names: string[] = ContactNameUtil.parseLastFirstMiddleNames(this.context.legacyContact.lastName);
          this.contact.lastName = names && names.length ? names[0] : this.context.legacyContact.lastName;
          if (this.context.legacyContactTitle) {
            this.signingOfficeText = this.context.legacyContactTitle;
          }
          this.contact.email = this.context.legacyContact.email;
          this.contact.identificationRecords = [];
          if (Array.isArray(this.context.legacyContact.identificationRecords)) {
            for (let i: number = 0; i < this.context.legacyContact.identificationRecords.length; i++) {
              this.contact.identificationRecords[i] = new IdentificationRecord();
              this.contact.identificationRecords[i].createNewIdentificationRecordClone(
                this.context.legacyContact.identificationRecords[i]
              );
              this.contact.identificationRecords[i].id = null;
              this.contact.identificationRecords[i].contactId = null;
            }
          }
        }
        if (this.isLawFirmSolicitorVisible()) {
          let mailingAddress: Address = _.find(this.contact.address, (address: Address) => {
            return address.addressTypeCode === AddressTypes.mailing;
          });
          if (!mailingAddress) {
            // set law firm mailing address
            this.contact.initializeAddressesWithDefaultProvince(
              [AddressTypes.mailing],
              this.documentProfileCache,
              this.matter && this.matter.provinceCode
            );
          }

          let solicitorContact: Contact = this.contact.createSolicitorContact(
            this.documentProfileCache,
            this.matter && this.matter.provinceCode
          );
          if (solicitorContact && contactName) {
            solicitorContact.handleFullNameWithoutFormatting(contactName);
          }
          this.contact.addSolicitor(solicitorContact);
          this.subContact = this.contact.subContacts[this.contact.subContacts.length - 1];
          this.setSolicitorAddressDdOptions();
        }

        if (this.isLawFirmVisible()) {
          this.contact.subContacts = [];
        }
      }
    }

    if (
      this.context.matterParticipantRole == MatterParticipantRoleTypes.CONSENTING_SPOUSE &&
      this.selectedContactType.contactKey === 'CLIENT'
    ) {
      if (this.context.gender) {
        this.contact.localGender = this.context.gender;
        this.contact.gender = this.context.gender;
      }
    }
    if (this.selectedContactType && contactName) {
      if (this.selectedContactType.contactKey === 'PRIVATE_LENDER') {
        if (contactName && contactName.split('"').length > 0) {
          this.contact.organizationName = contactName.split('"')[1];
          this.contact.handleFullNameWithoutFormatting(contactName);
          //  this.contact.attention = contactName.split('"')[1];
        }
      }

      if (this.selectedContactType.contactKey === 'LAW_FIRM' && this.context.isLawFirm) {
        if (contactName && contactName.split('"').length > 0) {
          this.contact.legalFirmName = contactName.split('"')[1];
        }
      }

      if (
        this.selectedContactType.contactKey === 'INSURANCE_BROKER' ||
        this.selectedContactType.contactKey === 'CONDO_CORPORATION' ||
        this.selectedContactType.contactKey === 'STRATA_CORPORATION'
      ) {
        this.contact.organizationName = contactName;
      }

      if (this.selectedContactType.contactKey === 'REALESTATEBROKER') {
        if (contactName && contactName.split('"').length > 0) {
          this.contact.organizationName = contactName.split('"')[1];
        }
      }

      if (
        this.selectedContactType.contactKey === 'REALESTATEAGENT' ||
        this.selectedContactType.contactKey === 'CLIENT'
      ) {
        if (!!this.context.reducedContactDetails && contactName.trim().length > 0) {
          this.populateDefaultValuesFromContactName(contactName);
        } else {
          if (contactName && contactName.split('"').length > 0) {
            this.contact.handleFullNameWithoutFormatting(contactName);
          }
        }
      }

      if (this.selectedContactType.contactKey === 'SURVEYOR') {
        if (contactName && contactName.split('"').length > 0) {
          this.contact.organizationName = contactName.split('"')[1];
        }
      }

      if (this.selectedContactType.contactKey === 'RESIDENCE_ASSOCIATION') {
        if (contactName && contactName.split('"').length > 0) {
          this.contact.organizationName = contactName.split('"')[1];
        }
      }
    }

    if (this.selectedContactType) {
      if (!this.contact.barristerSolicitor && this.selectedContactType.contactKey === 'LAWYER') {
        this.contact.barristerSolicitor = 'BARRISTERS_SOLICITORS';
      }
      if (this.isProfileIdDetailsVisible() || this.isProfileIdDetailsFlaCapacityVisible()) {
        this.contact.initializeContactIdDetails();
      }
      if (
        this.contact &&
        this.selectedContactType.contactKey != 'REALESTATEBROKER' &&
        this.selectedContactType.contactKey != 'GUARANTOR' &&
        this.selectedContactType.contactKey != 'TRANSFEROR'
      ) {
        this.defaultFieldsService
          .getValues(sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId))
          .map((value: any) => value && value.ConfigurationType && value.ConfigurationType.configurationOptionValues)
          .subscribe((values) => {
            if (Array.isArray(values)) {
              values.forEach((item: any) => {
                const oci: OtherContactInformation = new OtherContactInformation();
                oci.fieldName = item.configurationValue;
                this.defaultOtherContactInfo.push(oci);
              });
            }
            this.contact.copyOtherContactInformations(this.defaultOtherContactInfo);
          });
      }
    }

    if (this.selectedContactType.contactKey === 'GUARANTOR' && this.isProfileIdDetailsVisible()) {
      this.contact.contactName.lastName = contactName;
      this.contact.organizationName = contactName;
      this.contact.localGender = 'QUESTION';
      this.contact.gender = 'QUESTION';
      this.contact.initializeContactIdDetails();
    }
    /* comment this out as now longer per story DPPMP 11981
    if(this.selectedContactType && this.selectedContactType.contactKey === 'INSURANCE_BROKER') {
         if(this.context.matter.fireInsuranceContactInfo.insuranceBrokerType === constValues.insurerOrBroker.BROKER) {
             this.insurerOrBroker = constValues.insurerOrBroker.BROKER;
         } else {
             this.insurerOrBroker = constValues.insurerOrBroker.INSURER;
         }
     }
     */

    if (this.selectedContactType.contactKey === 'REALESTATEAGENT' && this.context && this.context.brokerContact) {
      this.updateAgentWithSelectedBroker();
    }

    if (
      this.selectedContactType.contactKey === 'CONDO_CORPORATION' ||
      this.selectedContactType.contactKey === 'STRATA_CORPORATION'
    ) {
      let provinceOption: SelectItem = dropDowns.accessibleProvinces.find(
        (accessibleProvince) => accessibleProvince.value == this.context.matter.provinceCode
      );

      this.contact.mailingAddress.provinceName = provinceOption && provinceOption.label;
      this.provinceReadOnly = true;
    }
    // Move contact.nameOnDocuments from the ngInit of new-manage-contact.component.ts yo here
    this.contact.nameOnDocuments = this.contact.reLineName;
  }

  confirmationPopupShown: boolean = false;
  confirmPopupSubscription: Subscription;

  beforeDismiss(): boolean {
    const result = {contactKey: this.context.contactType, action: 'cancel'};
    this.acted = true;
    if (this.contact.isDirty) {
      if (!this.confirmationPopupShown) {
        this.handleUnsavedChanges(result);
        this.confirmationPopupShown = true;
        return true;
      } else {
        if (this.confirmPopupSubscription) {
          this.confirmPopupSubscription.unsubscribe();
        }
        this.dialogService.closeDialog();
        this.confirmationPopupShown = false;
        return true;
      }
    }
    return false;
  }

  populateDefaultValuesFromContactName(contactName: string) {
    let names: string[] = ContactNameUtil.parseLastFirstMiddleNames(contactName);
    ContactNameUtil.convertStringArrayToContactName(names, this.contact.contactName, true);
    if (this.context.gender) {
      this.contact.localGender = this.context.gender;
      this.contact.gender = this.context.gender;
    }
  }

  public handleUnsavedChanges(result?): void {
    this.confirmPopupSubscription = this.dialogService.confirmUnsavedChange(true).subscribe((response: any) => {
      if (response == 'DONT_SAVE') {
        this.matterParticipantService.cleanFla(this.context.matterParticipant, this.matter);
        this.dialog.close(result);
      } else if (response == 'CANCEL') {
        this.confirmationPopupShown = false;
      } else if (response == 'SAVE') {
        this.saveContact();
      }
    });
  }

  unlockSignerContact(): void {
    if (this.contact.contactAssociations) {
      this.contact.contactAssociations.forEach((ca) => {
        if (ca.associatedContact && ca.associatedContact.sourceContactId) {
          this.contactQueryService.unlockContact(ca.associatedContact.sourceContactId);
        }
      });
    }
  }

  closeSaveDialog(isSourceContactCreated: boolean) {
    //this.contact.cleanupCommonContactData(this.selectedContactType.contactKey);
    this.unlockSignerContact();
    let result = {
      contactKey: this.context.contactType,
      action: 'save',
      contact: this.contact,
      createdNewClient: isSourceContactCreated,
      signingOfficeText: this.signingOfficeText
    };

    this.matterParticipantService.cleanFla(this.context.matterParticipant, this.matter);
    this.dialog.close(result);
    this.acted = true;
  }

  // Handle cancel button
  cancelDialog() {
    this.unlockSignerContact();
    this.removeConsentingSouse();
    const result = {contactKey: this.context.contactType, action: 'cancel'};
    this.acted = true;

    if (this.contact.isDirty) {
      this.handleUnsavedChanges(result);
      this.confirmationPopupShown = true;
    } else {
      this.matterParticipantService.cleanFla(this.context.matterParticipant, this.matter);
      this.dialog.close(result);
    }
  }

  removeConsentingSouse() {
    if (this.context.matterParticipant && this.context.matterParticipant.getConsentedSpouseFamilyLawAct()) {
      this.matterParticipantService.removeConsentingSpouse(
        this.context.matterParticipant.getConsentedSpouseFamilyLawAct().consentedSpouseParticipant,
        this.matter
      );
    }
  }

  ngDoCheck(): void {
    if (this.contact) {
      if (this.isIdDetailsVisible()) {
        this.contact.fillGenderSpecificDefaultFields(this.contactUntouched);
      }
    }

    if (this.contactUntouched && this.contact && !this.contact.isDirty) {
      this.contact.isDirty = !this.contactUntouched.equals(this.contact);
    }
  }

  // capitalizeTypedName(string : string) {
  //     if(string.indexOf(' ') >= 0) {
  //         return string.replace(/\w\S*/g, function(txt) {return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
  //     } else {
  //         return string.charAt(0).toUpperCase() + string.slice(1);
  //     }
  // }

  onRealEstateBrokerChange(broker: Contact): void {
    this._brokerName = broker.organizationName;
  }

  validPrivateContacts(): boolean {
    let ret = true;
    if (this.newContactPrivateInfoComponent) {
      this.newContactPrivateInfoComponent.isFormDirty = true;
      ret = this.newContactPrivateInfoComponent.isFormValid();
    }
    return ret;
  }

  protected cleanUpBlankPrivateContact(): void {
    if (
      this.contact.privateContacts &&
      this.contact.privateContacts.persons &&
      this.contact.privateContacts.persons.length === 1
    ) {
      let privateContact: Contact = this.contact.privateContacts.persons[0];

      if (
        privateContact.gender === 'QUESTION' &&
        !privateContact.fullName &&
        !privateContact.dear &&
        !privateContact.workPhone &&
        !privateContact.cellPhone &&
        !privateContact.email
      ) {
        this.contact.privateContacts.deletePrivateContact(privateContact);
      }
    }
  }

  protected cleanUpBlankStatusCert(): void {
    if (this.contact.statusCerts && this.contact.statusCerts.length === 1) {
      let statusCert: Contact = this.contact.statusCerts[0];

      if (
        this.contact.isCondoCorporation &&
        !statusCert.statusCertPositionAndName &&
        statusCert.mailingAddress.isEmpty
      ) {
        this.contact.clearCondoStatusCerts();
      }
    }
  }

  checkPrivateContactSurnameErrors(): void {
    // this.errorService.removeAllDpFieldError();
    let fieldKey = 'privateContact.surname';
    if (this.contact.privateContacts && this.contact.privateContacts.persons) {
      this.contact.privateContacts.persons.forEach((person: Contact) => {
        let fieldId = 'surname_' + person.identifier;
        if (!person.lastName) {
          this.errorService.addDpFieldError(DPError.createDPError(fieldKey, fieldId));
        } else {
          this.errorService.removeDpFieldError(fieldKey, fieldId);
        }
      });
    }
  }

  checkSingleSolicitorSurnameErrors(): boolean {
    const fieldId = 'surname_' + this.subContact.identifier;
    const fieldKey = 'solicitor.surname';
    if (this.subContact && this.subContact.contactName && !this.subContact.contactName.lastName) {
      this.errorService.addDpSaveError(DPError.createDPError(fieldKey, fieldId));
    } else {
      this.errorService.removeDpFieldError(fieldKey, fieldId);
    }

    return this.errorService.hasNoErrors();
  }

  isValidFlaSurname(): void {
    if (this.matter.isMatterProvinceNB) {
      this.isValidFlaSurnameForNB();
    } else {
      this.isValidFlaSurnameForDefault();
    }
  }

  isValidFlaConsentedSpouse(fla: FamilyLawAct, title: string, consentedSpouseContact: Contact) {
    if (
      consentedSpouseContact &&
      (!consentedSpouseContact.lastName ||
        consentedSpouseContact.lastName == '' ||
        (consentedSpouseContact.lastName && consentedSpouseContact.lastName.toString().trim() == ''))
    ) {
      this.errorService.addDpSaveError(
        DPError.createCustomDPError(
          `mainClient.familyLawAct.consentingSpouse.surname_surname_${this.context.matterParticipant.matterParticipantId}`,
          this.context.matterParticipant.contact.surnameLastFullName +
            ' ' +
            title +
            "'s surname in " +
            provinceBasedFieldLabels.get('participant.fla.tab.label', this.context.matter.provinceCode) +
            ' section is required.',
          '<MAIN_TOPIC>',
          'ERROR',
          0,
          `surname_${this.context.matterParticipant.matterParticipantId}`
        )
      );
      this.hasFlaError = true;
    } else {
      if (
        this.errorService.getDPError(
          `mainClient.familyLawAct.consentingSpouse.surname_surname_${this.context.matterParticipant.matterParticipantId}`
        )
      ) {
        this.errorService.removeDpSaveError(
          `mainClient.familyLawAct.consentingSpouse.surname_surname_${this.context.matterParticipant.matterParticipantId}`
        );
      }
      this.hasFlaError = false;
    }
  }

  isValidFlaSurnameForDefault(): void {
    let consentedSpouseContact = this.context.matterParticipant.consentingSpouseContact;
    this.context.matterParticipant.familyLawActs.forEach((fla: FamilyLawAct) => {
      switch (fla.familyLawActStatementType) {
        case 'CONSENTED_SPOUSE':
          this.isValidFlaConsentedSpouse(fla, 'Consenting Spouse', consentedSpouseContact);
          break;
        case 'INTERSPOUSAL_AGREEMENT':
        case 'AUTHORIZED_BY_COURT':
          if (
            consentedSpouseContact &&
            (!consentedSpouseContact.lastName ||
              consentedSpouseContact.lastName == '' ||
              (consentedSpouseContact.lastName && consentedSpouseContact.lastName.toString().trim() == ''))
          ) {
            this.errorService.addDpSaveError(
              DPError.createCustomDPError(
                `mainClient.familyLawAct.spouseName.surname_surname_${this.context.matterParticipant.matterParticipantId}`,
                this.context.matterParticipant.contact.surnameLastFullName +
                  " Spouse's surname in Homestead Status section is required.",
                '<MAIN_TOPIC>',
                'ERROR',
                0,
                `surname_${this.context.matterParticipant.matterParticipantId}`
              )
            );

            this.hasFlaError = true;
          } else {
            if (
              this.errorService.getDPError(
                `mainClient.familyLawAct.spouseName.surname_surname_${this.context.matterParticipant.matterParticipantId}`
              )
            ) {
              this.errorService.removeDpSaveError(
                `mainClient.familyLawAct.spouseName.surname_surname_${this.context.matterParticipant.matterParticipantId}`
              );
            }
            this.hasFlaError = false;
          }

          if (
            fla.registeredOnDate &&
            fla.registeredOnDate &&
            fla.registeredOnDate !== '//' &&
            Utils.isNotValidDate(fla.registeredOnDate)
          ) {
            this.errorService.addDpSaveError(
              DPError.createCustomDPError(
                `mainClient.familyLawAct.registeredOnDate.surname_surname_${this.context.matterParticipant.matterParticipantId}`,
                this.context.matterParticipant.contact.surnameLastFullName + '  Registered on Date is invalid.',
                '<MAIN_TOPIC>',
                'ERROR',
                0,
                `surname_${this.context.matterParticipant.matterParticipantId}`
              )
            );
            this.hasFlaError = true;
          } else {
            if (
              this.errorService.getDPError(
                `mainClient.familyLawAct.registeredOnDate.surname_surname_${this.context.matterParticipant.matterParticipantId}`
              )
            ) {
              this.errorService.removeDpSaveError(
                `mainClient.familyLawAct.registeredOnDate.surname_surname_${this.context.matterParticipant.matterParticipantId}`
              );
            }
            this.hasFlaError = false;
          }
          break;
      }
    });
  }

  isValidFlaSurnameForNB(): void {
    if (this.context.matterParticipant.isConsentedSpouseSelectedForNB()) {
      let consentedSpouseContact = this.context.matterParticipant.consentingSpouseContact;
      this.isValidFlaConsentedSpouse(
        this.context.matterParticipant.familyLawActs[0],
        this.context.matterParticipant.familyLawActs[0].getConsentingSpouseLabel(true),
        consentedSpouseContact
      );
    }
  }

  checkFlaEror(): boolean {
    if (this.isProfileIdDetailsFlaCapacityVisible() && this.isFamilyLawActVisible()) {
      this.isValidFlaSurname();
    }
    return this.errorService.hasNoErrors();
  }

  checkProvinceCode(): boolean {
    // For the short term solution, remove the restriction regarding the province of the Condo corporation
    // if(this.context.contactType === 'CONDO_CORPORATION'
    //     && this.contact.selfManagedManagementCompanyType !== CondoManagedTypeConstValue.MANAGEMENT_COMPANY) {
    //     if(this.context.matter.provinceCode !== this.contact.mailingAddress.provinceCode) {
    //         this.modalErrorComponent.createDPSaveError("contact.provinceCode");
    //         return false;
    //     }
    // }
    return true;
  }

  updateIdDetailsDefaultValue() {
    if (this.needUpdateIdDetailsDefaultValue()) {
      this.contact.initializeContactIdDetails();
      this.contact.setClientIdBusinessAddressDefaultValue(
        this.documentProfileCache,
        this.matter && this.matter.provinceCode
      );
      this.contact.setIdDetailsDefaultLabel();
    }
  }

  /**
   *  When cirf and refrerral add a new client, the address and phones aren't shown. So UI doesn't have chance to set default value
   *  After importing Data and saving the client without open shutter, it will bring out of sync and read only issue
   *  So set default values for cirf and refrerral when they add a new client
   * @param documentProfileCache
   */
  setContactDefaultValuesForReducedClient(documentProfileCache: DocumentProfileCache) {
    if (this.context.reducedContactDetails && this.contact.isPersonOrOrganization) {
      this.contact.workPhone;
      this.contact.cellPhone;
      this.contact.faxPhone;
      this.contact.homePhone;
      if (Array.isArray(this.contact.address)) {
        this.contact.address.forEach((address: Address) => {
          if (address.addressTypeCode !== AddressTypes.clientIdBusiness) {
            AddressUtil.setDefaultProvinceValue(
              address,
              true,
              documentProfileCache && documentProfileCache.cachedDocumentProfile,
              this.matter && this.matter.provinceCode,
              {
                localProvinceName: null,
                cachedDefaultProvinceName: null
              }
            );
            if (address.addressTypeCode !== AddressTypes.mailing) {
              address.country = 'Canada';
            }
          }
        });
      }
    }
  }

  cleanupNotes() {
    if (
      this.selectedContactType &&
      this.selectedContactType.contactKey === 'CLIENT' &&
      this.context.isOtherParty &&
      !this.shouldCreateSourceContact
    ) {
      this.contact.journalNotes = [];
    }
  }

  saveContact(): void {
    if (this.isMyOwnAccountLawFirm()) {
      return;
    }
    this.contact.cleanupRedundantData();
    this.updateIdDetailsDefaultValue();
    // this.contact.setOtherCategoryName();
    this.isCommonContactFormDirty = true;
    this.cleanUpBlankPrivateContact();
    this.cleanUpBlankStatusCert();
    this.checkPrivateContactSurnameErrors();
    if (this.isLawFirmSolicitorVisible()) {
      this.cleanSolicitorAddress();
    }

    this.cleanupNotes();

    this.setContactDefaultValuesForReducedClient(this.documentProfileCache);

        if(this.profileComponent) {
            if (this.context?.isEdit && this.matter?.isWillMatter() && this.context.contactType === 'WILL_PARTICIPANT') {
                this.closeSaveDialog(true);
                return;
            }
            if(this.isContactExist && this.contact.contactType === 'REALESTATEBROKER') {
                this.modalErrorComponent.createDPSaveError('contact.uniqueCompanyName');
      } else if (
        (this.isSingleSolicitorVisible() && this.checkSingleSolicitorSurnameErrors()) ||
        (this.contact.isLawFirm && this.contact.id)
      ) {
        this.contactQueryService.createContact(this.subContact, null, true).subscribe(
          (subContact: Contact) => {
            this.contact.subContacts.pop();
            this.contact.subContacts.push(new Contact(subContact));
            this.closeSaveDialog(true);
            this.statusBarService.currentHelpText = StatusBarMessages.contact.CREATE;
          },
          (error: ApplicationError) => {
            console.log(error);
            this.createApiError(error);
          }
        );
      }
      //if we create a contact which doesn't have source contact, we allow this.contactCategory is "question"
      else if (
        !this.isSingleSolicitorVisible() &&
        this.contact.isValidContact(this.errorService, this.context.fieldPrefix, !this.shouldCreateSourceContact) &&
        this.checkFlaEror() &&
        this.checkProvinceCode()
      ) {
        if (this.validPrivateContacts() && !this.hasFlaError) {
          if (this.shouldCreateSourceContact) {
            if (!this.isContactCheckRequestSent) {
              if (this.selectedContactType.contactKey == 'CLIENT') {
                this.updateIdDetailsDefaultValue();
              }
              if (this.selectedContactType.contactKey === 'PRIVATE_CONTACT' && this.context.parentContact) {
                this.contact.organizationId = this.context.parentContact.sourceContactId;
              }
              if (
                this.selectedContactType.contactKey !== 'CLIENT' &&
                this.selectedContactType.contactKey !== 'PRIVATE_LENDER' &&
                this.selectedContactType.contactKey !== 'REALESTATEAGENT'
              ) {
                this.contact.removeOtherContactFieldsIfNotPopulated();
              }
              this.contactQueryService.createContact(this.contact, null, true).subscribe(
                (contact: Contact) => {
                  contact.localGender = contact.gender;
                  this.contact = contact;
                  if (this.contact.contactType === 'REALESTATEAGENT') {
                    this.context.matter.realEstateBrokerName = this._brokerName;
                  }
                  this.closeSaveDialog(true);
                  this.statusBarService.currentHelpText = StatusBarMessages.contact.CREATE;
                },
                (error: ApplicationError) => {
                  console.log(error);
                  this.createApiError(error);
                  if (this.selectedContactType && this.selectedContactType.contactKey == 'SURVEYOR') {
                    this.newContactPrivateInfoComponent.initializePrivateContacts();
                    this.newContactPrivateInfoComponent.isFormDirty = false;
                  }
                }
              );
            }
          } else {
            this.closeSaveDialog(false);
            this.statusBarService.currentHelpText = StatusBarMessages.contact.CREATE;
          }
        }
      }
    } else if (
      (!this.selectedContactType ||
        (this.selectedContactType && this.contact.isContactDialogFormValid(this.selectedContactType.contactKey))) &&
      this.errorService.hasNoErrors()
    ) {
      this.contactQueryService.createContact(this.contact, null, true).subscribe(
        (contact: Contact) => {
          contact.localGender = contact.gender;
          this.contact = contact;
          this.closeSaveDialog(true);
          this.statusBarService.currentHelpText = StatusBarMessages.contact.CREATE;
        },
        (error: ApplicationError) => {
          console.log(error);
          this.createApiError(error);
        }
      );
    }
  }

  createApiError(error): void {
    if (this.appConfig?.isUnityBCConveyancingEnabled && this.matter?.isMatterProvinceBC) {
      this.modifyContactErrorMessageByProvince(error, this.matter.provinceCode);
    }
    if (error.fieldErrors && error.fieldErrors.length > 0) {
      error.fieldErrors.forEach((error) => {
        this.modalErrorComponent.createCustomDPSaveError(
          'none',
          error.message ? error.message : error.code,
          'CONTACT',
          'ERROR'
        );
      });
    } else {
      this.errorService.addDpSaveError(
        DPError.createCustomDPError(error.errorCode, error.errorCode + ':' + error.errorMessage, null, 'ERROR')
      );
    }
  }

  modifyContactErrorMessageByProvince(error: ApplicationError, provinceCode: ProvinceCode) {
    error.fieldErrors?.forEach((fieldError) => {
      let message = provinceBasedContactTypeErrorMessages.get(
        fieldError.errorCode,
        this.contact?.contactType,
        provinceCode
      );
      if (message) {
        fieldError.message = message;
      }
    });
  }

  selectTab(selectedTab: string): void {
    this.selectedTab = selectedTab || 'Profile';
    if (
      this.isSelectedTabIdDetails() &&
      this.contact.isDirty &&
      (this.isProfileIdDetailsVisible() || this.isProfileIdDetailsFlaCapacityVisible())
    ) {
      this.contact.initializeContactIdDetails();
    }
    setTimeout(function () {
      jQuery('.mat-modal-dialog')
        .find('input[type=text],dp-checkbox label,textarea,select')
        .filter(':visible:first')
        .focus();
    }, 300);
  }

  isProfileIdDetailsVisible(): boolean {
    return this.selectedContactType && this.selectedContactType.contactKey === 'GUARANTOR';
  }

  isOnlyProfileVisible(): boolean {
    return !this.isProfileIdDetailsVisible() && !this.isProfileIdDetailsFlaCapacityVisible();
  }

  isSelectedTabIdDetails(): boolean {
    return this.selectedTab === 'ID-Details';
  }

  isProfileIdDetailsFlaCapacityVisible() {
    return this.selectedContactType && this.selectedContactType.contactKey === 'CLIENT';
  }

  isFamilyLawActVisible(): boolean {
    let numberOfPurchasersIncludingNewOne =
      this.matter && this.matter.mainClients ? this.matter.mainClients.length + 1 : null; //Adding one for the contact that is being added
    return (
      this.matter &&
      !this.matter.isCustomMatter() &&
      this.selectedContactType &&
      this.selectedContactType.contactKey === 'CLIENT' &&
      !this.isMatterParticipantRolePrivateLender() &&
      PurchaserFamilyLawAct.isFamilyLawActVisibleForParticipant(
        this.context.matterParticipant,
        this.context.isOtherParty,
        this.matter.provinceCode,
        numberOfPurchasersIncludingNewOne,
        this.matter.matterType
      ) &&
      (this.context.isMainClient || !this.matter.isMatterProvinceNB) &&
      !this.isSignerContactRecord()
    ); //In NB only main client has fla
  }

  isCapacityVisible(): boolean {
    return (
      this.matter &&
      !this.matter.isCustomMatter() &&
      this.selectedContactType &&
      ((this.selectedContactType.contactKey === 'CLIENT' &&
        this.context.matter &&
        this.context.matter.purchasersCapacity === 'OTHER') ||
        (this.selectedContactType.contactKey === 'PRIVATE_LENDER' &&
          this.context.mortgage &&
          this.context.mortgage.mortgageCapacityType === 'OTHER'))
    );
  }

  isStatusCertificateVisible(): boolean {
    return (
      this.matter &&
      !this.matter.isCustomMatter() &&
      this.selectedContactType &&
      this.selectedContactType.contactKey === 'CONDO_CORPORATION' &&
      !this.context.removeStatusCertificatePage
    );
  }

  isIdDetailsVisible(): boolean {
    return (
      (this.selectedContactType &&
        this.selectedContactType.contactKey === 'CLIENT' &&
        !this.context.isOtherParty &&
        !this.context.isSignerForOtherParty) ||
      (this.selectedContactType && this.selectedContactType.contactKey === 'GUARANTOR')
    );
  }

  isNotesSectionVisible(): boolean {
    return (
      this.context.matterParticipantRole != MatterParticipantRoleTypes.CONSENTING_SPOUSE &&
      this.selectedContactType &&
      this.selectedContactType.contactKey === 'CLIENT' &&
      (!this.context.isOtherParty || this.shouldCreateSourceContact) &&
      !this.isSignerContactRecord()
    );
  }

  isLawFirmVisible(): boolean {
    return this.isLawFirm() && this.context.isLawFirm;
  }

  isSingleSolicitorVisible(): boolean {
    return this.isLawFirm() && !this.context.isLawFirm && this.context.isOnlySolicitor;
  }

  isLawFirmSolicitorVisible(): boolean {
    return this.isLawFirm() && !this.context.isLawFirm && !this.context.isOnlySolicitor;
  }

  isLawFirm(): boolean {
    return this.context.contactType === 'LAW_FIRM';
  }

  getIdDetailsLabel(): string {
    if (this.selectedContactType && this.selectedContactType.contactKey === 'GUARANTOR') {
      return 'ID Verification';
    }

    return 'ID Details';
  }

  isProfileTab(): boolean {
    return (
      this.selectedContactType &&
      this.selectedContactType.contactKey != 'LAW_FIRM' &&
      this.selectedContactType.contactKey != 'PRIVATE_CONTACT'
    );
  }

  isCreateNewClientVisible(): boolean {
    if (this.context.matterParticipantRole == MatterParticipantRoleTypes.CONSENTING_SPOUSE) {
      return this.context.onlyMatterLevelContact ? false : true;
    } else {
      //For other side party link matter, we need show the Create Contact CheckBox.
      return (
        this.context.isOtherParty ||
        (this.selectedContactType && this.selectedContactType.contactKey === 'PRIVATE_CONTACT') ||
        (this.selectedContactType &&
          this.selectedContactType.contactKey === 'REALESTATEAGENT' &&
          this.context.matter &&
          !this.context.matter.matterLink)
      );
    }
  }

  isAllClientDetailsAvailable(): boolean {
    let result: boolean;
    if (
      this.context.matterParticipantRole === MatterParticipantRoleTypes.CONSENTING_SPOUSE &&
      this.context.onlyMatterLevelContact
    ) {
      result = false;
    } else {
      result = !this.isCreateNewClientVisible() || (this.isCreateNewClientVisible() && this.shouldCreateSourceContact);
    }

    return result;
  }

  get createNewClientLabel(): string {
    let label: string = null;
    if (
      this.context.isOtherParty ||
      this.context.matterParticipantRole == MatterParticipantRoleTypes.CONSENTING_SPOUSE
    ) {
      label = 'Create New Client';
    } else if (this.isMortgageeParentContact()) {
      label = 'Attach to Mortgagee Contact';
    } else if (this.isManagementCompanyParentContact()) {
      label = 'Attach to Management Company Contact';
    } else if (
      this.isCondoCorporationParentContact() &&
      this.appConfig?.isUnityBCConveyancingEnabled &&
      this.matter?.isMatterProvinceBC
    ) {
      label = 'Attach to Strata Corporation Contact';
    } else if (this.isCondoCorporationParentContact()) {
      label = 'Attach to Condo Corporation Contact';
    } else if (this.selectedContactType && this.selectedContactType.contactKey === 'REALESTATEAGENT') {
      label = 'Create New Real Estate Agent Contact Record';
    }

    return label;
  }

  get otherPartyLabel(): string {
    if (this.context.matterParticipantRole && this.context.matterParticipantRole == 'PURCHASER') {
      return this.context.cacheDocumentProfile != null && this.context.cacheDocumentProfile.provinceCode != 'BC'
        ? 'Add New Purchaser'
        : 'Add New Buyer';
    } else if (this.matter && this.matter.isCustomMatter()) {
      return 'Add Other Client ';
    } else if (this.matter && this.matter.isOpportunityMatter()) {
      return 'Add Other Side ';
    } else if (
      this.context.matterParticipantRole === MatterParticipantRoleTypes.WILL_WITNESS_1 ||
      this.context.matterParticipantRole === MatterParticipantRoleTypes.WILL_WITNESS_2
    ) {
      return 'Add Witness';
    } else if (this.matter?.isPurchaseBC) {
      return 'Add New Seller';
    } else {
      return 'Add New Vendor';
    }
  }

  get solicitorLabel(): string {
    return provinceBasedFieldLabels.get('matter.title.solicitor', this.matter?.provinceCode);
  }

  isAttentionParentContact(): boolean {
    return (
      this.context &&
      this.context.parentContact &&
      (this.context.parentContact.isMortgagee ||
        this.context.parentContact.isManagementCompany ||
        this.context.parentContact.isCondoCorporation)
    );
  }

  isMortgageeParentContact(): boolean {
    return this.context && this.context.parentContact && this.context.parentContact.isMortgagee;
  }

  isManagementCompanyParentContact(): boolean {
    return this.context && this.context.parentContact && this.context.parentContact.isManagementCompany;
  }

  isCondoCorporationParentContact(): boolean {
    return this.context && this.context.parentContact && this.context.parentContact.isCondoCorporation;
  }

  // It only creates for create law firm and solicitor as the same time
  setSolicitorAddressDdOptions() {
    this.solicitorAddressDdOptions = this.contact.createSolicitorAddressDdOptions(this.contact);
  }

  cleanSolicitorAddress() {
    if (
      this.subContact &&
      this.subContact.mailingAddress &&
      this.subContact.mailingAddress.sameAsAddressTypeCode === AddressDropdowns.manuallyEntered
    ) {
      //Reset the address for sale and mortgage matters
      //Reset the address for sale and mortgage matters
      this.subContact.mailingAddress.update(new Address());
      this.subContact.setDefaultProvinceValue(
        this.documentProfileCache,
        this.matter && this.matter.provinceCode,
        this.subContact.mailingAddress
      );
      this.subContact.mailingAddress.addressTypeCode = AddressTypes.mailing;
      this.subContact.mailingAddress.sameAsAddressTypeCode = AddressTypes.manuallyEntered;
    }
  }

  updateAgentWithSelectedBroker(): void {
    this.contact.organizationId = this.context.brokerContact.sourceContactId;
    this.contact.parentOrganizationName = this.context.brokerContact.organizationName;
    this.contact.address = [];

    if (this.context.brokerContact.address && this.context.brokerContact.address.length > 0) {
      let realEstateBrokerAddress: Address = new Address(this.context.brokerContact.mailingAddress);
      realEstateBrokerAddress.id = null;
      this.contact.address.push(realEstateBrokerAddress);
    }

    if (!this.contact.faxPhone) {
      this.contact.faxPhone = this.context.brokerContact.faxPhone;
    }

    if (!this.contact.workPhone) {
      this.contact.workPhone = this.context.brokerContact.workPhone;
    }

    if (!this.contact.cellPhone) {
      this.contact.cellPhone = this.context.brokerContact.cellPhone;
    }

    if (!this.contact.email) {
      this.contact.email = this.context.brokerContact.email;
    }
  }

  needUpdateIdDetailsDefaultValue(): boolean {
    return (
      this.selectedContactType &&
      (this.selectedContactType.contactKey === 'GUARANTOR' || this.selectedContactType.contactKey == 'CLIENT')
    );
  }

  isMatterParticipantRolePrivateLender(): boolean {
    return this.context && this.context.matterParticipantRole == 'PRIVATE_LENDER';
  }

  isContactComponentInitialized() {
    return this.documentProfileCache && this.documentProfileCache.cachedDocumentProfile;
  }

  isCreateSourceContactAutoEnabled(): boolean {
    return (
      this.context.isOtherParty &&
      ((this.matter && !!this.matter.matterLink) || this.isPurchaseOrSaleOpportunityWithActingForBoth())
    );
  }

  isSignerContactRecord(): boolean {
    return (
      this.context.matterParticipantRole &&
      (this.context.matterParticipantRole == 'SIGNING_OFFICER' ||
        this.context.matterParticipantRole == 'ESTATE_TRUSTEE' ||
        this.context.matterParticipantRole == 'POWER_OF_ATTORNEY')
    );
  }

  isPurchaseOrSaleOpportunityWithActingForBoth(): boolean {
    return this.matter && this.matter.isOpportunityPurchaseOrSale && this.matter.isActingForBothPurchaseAndSaleMatters;
  }
}
